<template>
    <div
        v-click-outside="close"
        class="relative text-left h-full inline-flex justify-center content-center items-center"
    >
        <button
            @click="toggleDropdown"
            type="button"
            class="relative w-full h-full cursor-pointer bg-white pl-3 pr-10 text-left text-gray-700 focus:outline-none border-none"
            :aria-expanded="isOpen"
        >
			<span class="block truncate text-xs text-gray-700 font-medium tracking-wide">
				{{ selectedOption.value }}
			</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
		        <DropdownUpDownArrowIcon
                    fill="currentColor"
                    size="16px"
                />
	        </span>
        </button>
        <ul
            v-if="isOpen"
            class="absolute top-0 mt-[30px] right-0 z-10 max-h-60 w-full text-center overflow-auto bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none"
            tabindex="-1"
            role="listbox"
        >
            <li
                v-for="(option, index) in options"
                :key="index"
                @click="selectOption(option)"
                :class="{ 'bg-blue-500 text-white': option === selectedOption }"
                class="text-gray-700 relative cursor-pointer select-none py-2 text-xs px-4"
                role="option"
            >
		        <span
                    :class="{ 'font-medium': option === selectedOption }"
                    class="block truncate"
                >
	                {{ option.value }}
		        </span>
                <span v-if="option === selectedOption" class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5"></span>
            </li>
        </ul>
    </div>
</template>

<script>
import DropdownUpDownArrowIcon from "../../assets/icons/DropdownUpDownArrowIcon.vue";
import DropdownCheckIcon from "../../assets/icons/DropdownCheckIcon.vue";

export default
{
    emits: ['update'],

    components: {
        DropdownCheckIcon,
        DropdownUpDownArrowIcon
    },

    props: {
        options: {
            type: Array,
            required: true,
        },
        selectedOption: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            isOpen: false,
        };
    },

    methods: {
        close() {
            this.isOpen = false;
        },

        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },

        selectOption(option) {
            this.$emit('update', option);
            this.isOpen = false;
        },
    },
};
</script>
