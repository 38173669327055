<template>
    <div class="bg-white">
        <div class="w-full flex items-center bg-gray-50 justify-between text-[10px]  text-gray-500 font-medium uppercase h-[40px]">
            <div class="w-full max-w-[200px] tracking-wide border-r border-gray-100 flex items-center justify-center">
                Responsible
            </div>
            <div class="w-1/4 flex items-center justify-center">Lead Started</div>
            <div class="w-1/4 flex items-center justify-center">Lead Not Processed</div>
            <div class="w-1/4 flex items-center justify-center">Lead Refused</div>
        </div>
        <div
            v-if="data.length === 0 && !isLoading"
            class="text-[10.5px] w-full flex justify-center h-20 items-center text-gray-400"
        >
            No Data Available...
        </div>
        <div v-else class="flex flex-col">
            <div class="overflow-x-auto max-h-[250px] w-full">
                <div
                    v-for="leadStatistic in data"
                    :key="leadStatistic.id"
                    class="w-full flex items-center hover:bg-gray-50 justify-between text-[11px] text-gray-500 font-medium"
                >
                    <div class="w-full max-w-[200px] text-[10px] tracking-wide font-light h-[40px] border-r border-gray-100 flex items-center justify-center">
                        <span class="uppercase">{{ leadStatistic.responsible }}</span>
                    </div>
                    <div class="w-1/4 h-[40px] text-[10px] font-normal flex items-center justify-center">
                        {{ leadStatistic.lead_started }}
                    </div>
                    <div class="w-1/4 h-[40px] text-[10px] font-normal flex items-center justify-center">
                        {{ leadStatistic.lead_not_processed }}
                    </div>
                    <div class="w-1/4 h-[40px] text-[10px] font-normal flex items-center justify-center">
                        {{ leadStatistic.lead_refused }}
                    </div>
                </div>
            </div>
            <div class="w-full flex items-center justify-between text-[10px] text-gray-600 font-normal border-t border-gray-100 uppercase">
                <label class="w-full max-w-[200px] h-[40px] flex border-r border-gray-100 items-center justify-center">TOTAL</label>
                <label class="w-1/4 h-[40px] flex items-center justify-center">{{ total_started }}</label>
                <label class="w-1/4 h-[40px] flex items-center justify-center">{{ total_not_processed }}</label>
                <label class="w-1/4 h-[40px] flex items-center justify-center">{{ total_refused }}</label>
            </div>
        </div>
    </div>
</template>
<script>
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";

export default {
    emits: ['reload'],

    components: {
        LoaderAnimation,
        ChevronDownIcon,
        ReloadIcon
    },

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            default: [],
        },
        total_started: {
            type: Number,
            default: 0,
        },
        total_not_processed: {
            type: Number,
            default: 0,
        },
        total_refused: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            IsOpen: true,
        }
    },

    methods: {
        reload() {
            this.$emit('reload', this.$route.query);
        },
    },

    mounted() {
        this.reload();
    }
}
</script>
