<template>
    <div class="custom-shadow-0 | flex flex-col gap-[20px] bg-white pb-[20px] rounded-lg border border-gray-100">
        <div class="flex items-center justify-between h-[42px] border-b pl-[24px] border-gray-100">
            <h1 class="text-[12px] font-light">
                Debt Ratio
            </h1>
            <div class="flex gap-2 border-gray-100 mr-2">
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="UpdateLeadDebtRatio()"
                    class="w-[28px] rounded bg-blue-50 h-[28px] group hover:bg-blue-500 duration-500 flex items-center justify-center"
                >
                    <save-icon
                        stroke-width="2"
                        class="h-[16px] stroke-blue-500 fill-transparent group-hover:stroke-blue-100 duration-500"
                    />
                </button>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="ApproveSection"
                    :class="[IsValidated ? 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 ' : 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-green-100', 'duration-500 h-[28px] px-[24px] cursor-pointer text-xs rounded']"
                >
                    {{ IsValidated ? 'Decline' : 'Approve' }} Section
                </button>
            </div>
        </div>

        <div class="grid grid-cols-4 gap-[24px] px-[24px]">
            <approbation-step-money-input
                Label="Salary"
                :ContextValue="payload.salary"
                @update-value="payload.salary = $event"
            />
            <approbation-step-money-input
                Label="Rent/Mortgage"
                :ContextValue="payload.mortgage_rent"
                @update-value="payload.mortgage_rent = $event"
            />
            <approbation-step-money-input
                Label="Power Bill"
                :ContextValue="payload.power_bill"
                @update-value="payload.power_bill = $event"
            />
            <approbation-step-money-input
                Label="Other Expenses"
                :ContextValue="payload.other_expenses"
                @update-value="payload.other_expenses = $event"
            />
            <approbation-step-money-input
                Label="Other Housing Expenses"
                :ContextValue="payload.other_housing_expenses"
                @update-value="payload.other_housing_expenses = $event"
            />
            <approbation-step-money-input
                Label="Auto Loan"
                :ContextValue="payload.auto_loan"
                @update-value="payload.auto_loan = $event"
            />
            <approbation-step-money-input
                Label="Furniture Loans"
                :ContextValue="payload.furniture_loan"
                @update-value="payload.furniture_loan = $event"
            />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {UpdateLead} from "../../../../services/Lead";
import SaveIcon from "../../../../assets/icons/SaveIcon.vue";
import {vMaska} from "maska";
import ApprobationStepMoneyInput from "./CustomInput/ApprobationStepMoneyInput.vue";
import {UpdateLeadDebtRatio} from "../../../../services/LeadDebtRatio";
import NotificationMixin from "../../../../mixins/NotificationMixin";

export default
{
    components: {
        ApprobationStepMoneyInput,
        SaveIcon
    },

    directives: {
        maska: vMaska
    },

    mixins: [
        NotificationMixin
    ],

    emits: ['approve-section'],

    props: {
        IsValidated: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            payload: {
                auto_loan: 0.00,
                salary: 0.00,
                furniture_loan: 0.00,
                mortgage_rent: 0.00,
                other_expenses: 0.00,
                other_housing_expenses: 0.00,
                power_bill: 0.00,
            }
        }
    },

    computed: {
        ...mapState([
            'ContextLead'
        ]),
    },

    methods: {
        ApproveSection() {
            this.$emit('approve-section', {approved_step: 'debt_ratio', approved_step_status: !this.IsValidated});

            this.UpdateLeadDebtRatio();
        },

        UpdateLeadDebtRatio() {
            UpdateLeadDebtRatio(this.ContextLead.lead_debt_ratio.id, {
                'lead_debt_ratio': this.payload
            })
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Lead Debt Ratio Updated Successfully')
                    }
                })
                .catch(() => {
                    this.showNotification('error', 'An Error Occurred While Updating Debt Ratio')
                })
        }
    },

    mounted() {
        if (this.ContextLead && this.ContextLead.lead_debt_ratio) {
            this.payload.salary = this.ContextLead['lead_debt_ratio']['salary'];
            this.payload.auto_loan = this.ContextLead['lead_debt_ratio']['auto_loan'];
            this.payload.furniture_loan = this.ContextLead['lead_debt_ratio']['furniture_loan'];
            this.payload.mortgage_rent = this.ContextLead['lead_debt_ratio']['mortgage_rent'];
            this.payload.other_expenses = this.ContextLead['lead_debt_ratio']['other_expenses'];
            this.payload.other_housing_expenses = this.ContextLead['lead_debt_ratio']['other_housing_expenses'];
            this.payload.power_bill = this.ContextLead['lead_debt_ratio']['power_bill'];
        }
    }
}
</script>
