import api from './Api';

const BaseURL = 'v1/lead-note';

export const RetrieveLeadNote = async (id) => {
    return await api()
        .get(`${BaseURL}/${id}`)
};

export const RetrieveLeadNotes = async (lead_id, note_type_id) => {
    return await api()
        .get(`${BaseURL}?lead_id=${lead_id}&note_type_id=${note_type_id}`)
}

export const UpdateLeadNote = async (id, data) => {
    return await api()
        .put(`${BaseURL}/${id}`, data)
}

export const StoreLeadNote = async (data) => {
    return await api()
        .post(`${BaseURL}`, data)
}

export const DestroyLeadNote = async (id) => {
    return await api()
        .delete(`${BaseURL}/${id}`)
}

