<template>
	<div class="flex justify-between gap-10 w-full scroll-smooth relative">
		<div class="flex gap-3 items-center">
			<h1 class="text-lg font-medium text-gray-800"> {{title}} </h1>
		</div>
		<div class="flex gap-2">
			<slot />
		</div>
	</div>
</template>

<script>
export default
{
	props: {
		title: {
			type: String,
			required: true,
		}
	}
}
</script>