import api from './Api.js';

const BaseURL = 'v1/lead-dispatcher';

export const RetrieveLead = async (page, limit) => {
	return await api()
		.get(`${BaseURL}?page=${page}&limit=${limit}`);
}

export const RetrieveLeadStatistics = async (start_at, end_at) => {
	return await api()
		.get(`${BaseURL}/statistics?start_at=${start_at}&end_at=${end_at}`);
}

export const RetrieveAccounts = async () => {
	return await api()
		.get(`${BaseURL}/account`);
}

export const UpdateAccount = async (uuid, payload) => {
	return await api()
		.put(`${BaseURL}/account/${uuid}`, payload);
}
