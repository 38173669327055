<template>
    <div class="bg-white rounded-[4pt] w-full h-fit divide-y divide-[#F2F2F2] mb-[50px]">
        <div class="h-[62px] flex items-center justify-between px-[18px]">
            <input placeholder="Filter..." type="text" class="placeholder:text-gray-400 text-[11.5px] max-w-[185px] font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" />
            <router-link
                to="/automation/new"
                class="bg-theme-blue flex items-center justify-center px-[12px] bg-opacity-90 hover:bg-opacity-100 duration-500 tracking-wide text-[11.5px] text-white min-h-[32px] rounded-[2pt]"
            >
                New Automation
            </router-link>
        </div>
        <div @scroll="HandleScroll" ref="table" class="flex w-full relative h-fit overflow-scroll">
            <div class="overflow-scroll w-full">
                <table class="overflow-scroll bg-white w-full min-w-screen top-0 max-w-full h-fit">
                    <thead>
                        <tr class="max-h-[38px] h-[38px] divide-x divide-[#F2F2F2]">
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">Title</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">Event</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">Created At</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">Created By</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">Updated At</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">Updated By</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">Affected Client</div></th>
                            <th class="sticky top-0 bg-white max-w-[65px]"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">Visible</div></th>
                            <th class="sticky top-0 bg-white max-w-[65px]"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]"></div></th>
                        </tr>
                    </thead>
                    <tbody class="divide-[#F2F2F2] divide-y">
                        <tr v-for="Item in 1" class="h-[55px] divide-x divide-[#F2F2F2] max-h-[55px]">
                            <td><div class="flex items-center justify-start">
                                <router-link
                                    class="cursor-pointer text-[10.5px] px-[4px] text-theme-blue ml-0 tracking-wide font-medium text-left max-w-[95px] truncate"
                                    to="/"
                                >
                                    Offer Email (EN)
                                </router-link>
                            </div></td>
                            <td><div class="flex items-center text-[10.5px] font-medium text-gray-500 justify-center h-full">
                                Lead Updated
                            </div></td>
                            <td><div class="flex items-center text-[10.5px] font-medium text-gray-500 justify-center h-full">
                                {{ new Date().toDateString() }}
                            </div></td>
                            <td><div class="flex items-center text-[10.5px] font-medium text-gray-500 justify-center h-full">
                                Jonathan Tat
                            </div></td>
                            <td><div class="flex items-center text-[10.5px] font-medium text-gray-500 justify-center h-full">
                                ...
                            </div></td>
                            <td><div class="flex items-center text-[10.5px] font-medium text-gray-500 justify-center h-full">
                                ...
                            </div></td>
                            <td><div class="flex items-center text-[10.5px] font-medium text-gray-500 justify-center h-full">
                                125
                            </div></td>
                            <td><div class="flex items-center justify-center">
                                <base-table-filter-toggle
                                    @click="isSwitched = !isSwitched"
                                    :switched="isSwitched"
                                />
                            </div></td>
                            <td><div class="flex items-center justify-center duration-500 cursor-pointer">
                                <div class="group">
                                    <trash-can-icon
                                        class="mb-0.5 block group-hover:hidden"
                                        color="gray"
                                        size="14px"
                                    />
                                    <trash-can-icon
                                        class="mb-0.5 hidden group-hover:block"
                                        color="red"
                                        size="14px"
                                    />
                                </div>
                            </div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTableFilterToggle from "./BaseTableFilterToggle.vue";
import TrashCanIcon from "../../assets/svg/TrashCanIcon.vue";

export default
{
    components: {TrashCanIcon, BaseTableFilterToggle},
    props: {
        dataset: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            IsScrolling: false,
            isSwitched: true,
        }
    },

    methods: {
        HandleScroll() {
            this.IsScrolling = this.$refs.table.scrollLeft > 0;
        }
    },
}
</script>

<style scoped>
td > div,
th > div {
    padding: 5px
}

td,
th {
    padding: 0
}
</style>
