<template>
    <div class="shadow-custom-shadow-2 border border-gray-100 rounded-lg divide-y">
        <div class="flex bg-white h-[38px] pl-[12pt] items-center justify-between rounded-t-lg">
            <h3 class="text-[10pt] font-medium tracking-wide text-gray-700 flex items-center justify-center gap-1">
                {{ title }}
                <span v-if="pageCounter" class="text-[8pt] text-gray-400 mt-0.5 tracking-wide">
                    {{ `(${this.lastPage === 0 ? 0 : this.firstPage} of ${this.lastPage})` }}
                </span>
            </h3>

            <div class="flex items-center">
                <transition
                    name="modal"
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-300"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <button
                        type="button"
                        v-if="isEditing && !errors"
                        @click="$emit('submit-form')"
                        class="h-[38px] w-[38px] inline-flex justify-center hover:bg-gray-100 duration-500 bg-blue-500 content-center items-center border-l border-gray-100"
                    >
                        <SaveIcon
                            size="18px"
                            class="fill-white"
                        />
                    </button>
                </transition>

                <button
                    v-if="hasActionButton"
                    type="button"
                    class="h-[38px] w-[38px] inline-flex justify-center hover:bg-gray-100 duration-500 content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                >
                    <ThreeDotIcon
                        size="16px"
                        class="fill-gray-500"
                    />
                </button>
                <button
                    v-if="canPrev"
                    @click="$emit('prev')"
                    type="button"
                    class="h-[38px] w-[38px] inline-flex justify-center hover:bg-gray-100 duration-500 content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                >
                    <ChevronLeftIcon
                        size="16px"
                        class="fill-gray-400"
                    />
                </button>
                <button
                    v-if="canNext"
                    @click="$emit('next')"
                    type="button"
                    class="h-[38px] w-[38px] inline-flex justify-center hover:bg-gray-100 duration-500 content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                >
                    <ChevronRightIcon
                        size="16px"
                        class="fill-gray-400"
                    />
                </button>
                <button
                    v-if="canView"
                    @click="$emit('view')"
                    type="button"
                    class="h-[38px] w-[38px] inline-flex justify-center hover:bg-gray-100 duration-500 content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                >
                    <EyeIcon
                        size="20px"
                        class="fill-gray-400"
                    />
                </button>
                <button
                    v-if="canAdd"
                    @click="$emit('add')"
                    type="button"
                    class="h-[38px] w-[38px] inline-flex justify-center hover:bg-gray-100 duration-500 content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                >
                    <CrossDuoIcon
                        size="18px"
                        class="fill-gray-400 rotate-45"
                    />

                </button>
            </div>
        </div>

        <div class="bg-white rounded-b-lg">
            <slot/>
        </div>
    </div>
</template>

<script>
import ThreeDotIcon from "../../assets/icons/ThreeDotIcon.vue";
import ChevronLeftIcon from "../../assets/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon.vue";
import SaveIcon from "../../assets/icons/SaveIcon.vue";
import EyeIcon from "../../assets/icons/EyeIcon.vue";
import CrossDuoIcon from "../../assets/icons/duotone/CrossDuoIcon.vue";

export default {
    emits: [
        'submit-form',
        'next',
        'prev',
        'update-errors',
        'add',
        'view'
    ],

    props: {
        title: {
            type: String,
            default: 'title',
        },
        pageCounter: {
            type: Boolean,
            default: false
        },
        isEditing: {
            Type: Boolean,
            default: false
        },
        canNext: {
            type: Boolean,
            default: false,
        },
        canPrev: {
            type: Boolean,
            default: false,
        },
        canView: {
            type: Boolean,
            default: false,
        },
        canAdd: {
            type: Boolean,
            default: false,
        },
        hasActionButton: {
            type: Boolean,
            default: true,
        },
        firstPage: {
            type: Number,
            default: 1
        },
        lastPage: {
            type: Number,
            default: 1
        },
        errors: {
            type: Boolean,
            default: false,
        },


    },

    components: {
        ChevronRightIcon,
        ChevronLeftIcon,
        ThreeDotIcon,
        SaveIcon,
        EyeIcon,
        CrossDuoIcon
    },
}

</script>
