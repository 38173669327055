<template>
    <div
        class="fixed z-50 duration-300 bg-white"
        :class="SideNavigationIsOpen ? 'left-[286px]' : 'left-[97px]'"
    >
        <div class="inner-shadow-left flex flex-col items-center justify-start duration-300 z-40 bg-white h-screen top-0 fixed -ml-8">
            <div class="min-h-[86px] border-b border-gray-100 w-full flex items-center justify-center">
                <div class="rounded-shadow | group duration-300 hover:bg-[#c1ccf0] h-[67.5px] w-[67.5px] bg-white flex items-center justify-center rounded-full">
                    <a :href="client_url" class="group-hover:bg-light-dark-blue cursor-pointer h-[60px] w-[60px] bg-gray-200 text-xl font-medium text-gray-600 tracking-tighter rounded-full text-md flex items-center justify-center">
                        <client-duo-icon size="32px" class="fill-gray-500 group-hover:fill-[#4667d2]" />
                    </a>
                </div>
            </div>
            <div class="custom-scrollbar | h-full w-[86px] overflow-x-hidden overflow-y-scroll py-[32px]">
                <div class="flex flex-col gap-5 w-[86px] items-center">
                    <div
                        v-for="(lead, index) in leads"
                        :key="index"
                        @click="pageLead(lead?.id)"
                        @mouseenter="showLead(lead)"
                        @mouseleave="hideLead()"
                        class="rounded-shadow | relative group hover:bg-[#c1ccf0] cursor-pointer duration-300 text-center min-w-[67.5px] h-[67.5px] bg-white rounded-md flex items-center justify-center"
                    >
                        <div class="w-[60px] flex items-center group-hover:bg-light-dark-blue rounded-[3.5px] justify-center h-[60px] absolute bg-gray-200 z-0">
                            <img
                                :src="BaseURL + lead.lender.image_src"
                                :alt="lead.id"
                                class="w-[24px] rounded-[6px]"
                            />
                        </div>
                        <div v-if="index === 0" class="w-[16px] h-[16px] bg-green-600 rounded-full right-0 absolute -bottom-[3px] -mr-[3px] flex items-center justify-center">
                            <check-duo-icon size="8px" class="fill-white" />
                        </div>
                        <div v-else-if="index === 1" class="w-[16px] h-[16px] bg-red-600 rounded-full right-0 absolute -bottom-[3px] -mr-[3px] flex items-center justify-center">
                            <cross-duo-icon size="10px" class="fill-white"/>
                        </div>
                        <div v-else-if="index === 2" class="w-[16px] h-[16px] bg-blue-600 rounded-full right-0 absolute -bottom-[3px] -mr-[3px] flex items-center justify-center">
                            <play-duo-icon size="8px"  class="fill-white ml-[1px]"/>
                        </div>
                        <div v-else-if="index === 3" class="w-[16px] h-[16px] bg-gray-500 rounded-full right-0 absolute -bottom-[3px] -mr-[3px] flex items-center justify-center">
                            <question-mark-duo-icon size="10px" class="fill-white"/>
                        </div>
                        <div v-else-if="index === 4" class="w-[16px] h-[16px] bg-amber-500 rounded-full right-0 absolute -bottom-[3px] -mr-[3px] flex items-center justify-center">
                            <pen-duo-icon size="10px" class="fill-white"/>
                        </div>
                        <div v-else-if="index === 5" class="w-[16px] h-[16px] bg-blue-600 rounded-full right-0 absolute -bottom-[3px] -mr-[3px] flex items-center justify-center">
                            <file-duo-icon size="10px" class="fill-white"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full h-[40px] flex divide-x">
                <div class="inner-shadow-left | h-[40px] border-t bg-white border-gray-100 w-[57.5px] flex justify-center items-center hover:bg-light-dark-blue duration-300 cursor-pointer text-gray-400 hover:text-[#4667d2]">
                    <img width="14" height="14" src="https://img.icons8.com/plumpy/24/filter.png" alt="filter"/>
                </div>
                <div class="h-[40px] border-t bg-white border-gray-100 w-[57.5px] flex justify-center items-center hover:bg-light-dark-blue duration-300 cursor-pointer text-gray-400 hover:text-[#4667d2]">
                    <img width="14" height="14" src="https://img.icons8.com/plumpy/24/plus-math.png" alt="plus-math"/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {RetrieveLeads} from "../../services/Lead";
import ClientDuoIcon from "../../assets/icons/duotone/ClientDuoIcon.vue";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import NotificationMixin from "../../mixins/NotificationMixin";
import CheckDuoIcon from "../../assets/icons/duotone/CheckDuoIcon.vue";
import CrossDuoIcon from "../../assets/icons/duotone/CrossDuoIcon.vue";
import {mapState} from "vuex";
import PlayDuoIcon from "../../assets/icons/duotone/PlayDuoIcon.vue";
import QuestionMarkDuoIcon from "../../assets/icons/duotone/QuestionMarkDuoIcon.vue";
import PenDuoIcon from "../../assets/icons/duotone/PenDuoIcon.vue";
import FileDuoIcon from "../../assets/icons/duotone/FileDuoIcon.vue";
import KronosBaseActionModal from "../BaseComponents/KronosBaseActionModal.vue";

export default
{
    components: {
        KronosBaseActionModal,
        FileDuoIcon,
        PenDuoIcon,
        QuestionMarkDuoIcon,
        PlayDuoIcon,
        CrossDuoIcon,
        CheckDuoIcon,
        LoaderAnimation,
        ClientDuoIcon,
    },

    data() {
        return {
            isLoading: false,
            leads: [],
            context_id: null,
            viewAll: true,
            LeadDetailsIsOpen: false,
            HoveredLead: [],
        }
    },

    mixins: [
        NotificationMixin
    ],

    computed: {
        ...mapState([
            'SideNavigationIsOpen',
            'ContextLead'
        ]),

        client_url() {
            return this.$route.params.client_id
                ? `/client/${this.$route.params?.client_id}`
                : `/client/${this?.leads[0]?.client?.id}`;
        },

        BaseURL() {
            return import.meta.env.VITE_BACKEND_BASE_URL + '/storage';
        },
    },

    methods: {
        pageLead(id) {
            window.location.href = `/lead/${id}`
        },

        showLead(lead) {
            this.$store.state.HoveredLead = lead;
            this.$store.state.LeadDetailsIsOpen = true;
        },

        hideLead() {
            this.$store.state.HoveredLead = {};
            this.$store.state.LeadDetailsIsOpen = false;
            this.$forceUpdate();
        },

        async fetchLeads(id) {
            if (!this.isLoading && this.context_id) {
                this.isLoading = true;

                let query = '';

                if (this.$route.name === 'ClientPage'){
                    query = `client_id=${this.$route.params?.client_id}`;
                } else if (this.$route.name === 'LeadPage'){
                    query = `client_id=${this.ContextLead.client.id}`;
                }

                await RetrieveLeads(query)
                    .then(response => {
                        if (response.status === 200) {
                            console.log(response.data)
                            this.leads = response.data.data.leads;
                        }
                    })
                    .catch(() => {
                        this.showNotification('error', 'An Error Occurred While Retrieving Leads')
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
    },

    created() {
        if(this.$route.name === 'ClientPage'){
            this.context_id = this.$route.params.client_id
        }else if (this.$route.name === 'LeadPage'){
            this.context_id = this.$route.params.id
        }


        if (this.context_id) {
            this.fetchLeads(this.context_id);
        }
    },
}
</script>

<style>
.inner-shadow-left {
    box-shadow: 10px 0px 11px -8px rgba(0,0,0,0.1) inset;
    -webkit-box-shadow: 10px 0px 11px -8px rgba(0,0,0,0.1) inset;
    -moz-box-shadow: 10px 0px 11px -8px rgba(0,0,0,0.1) inset;
}

.rounded-shadow {
    box-shadow: 0px 1px 14px -6px rgba(0,0,0,0.65);
    -webkit-box-shadow: 0px 1px 14px -6px rgba(0,0,0,0.65);
    -moz-box-shadow: 0px 1px 14px -6px rgba(0,0,0,0.65);
}

.full-height-115 {
    height: calc(100vh - 86px);
}

.custom-scrollbar::-webkit-scrollbar
{
    width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
    background-color: lightgray;
}

.custom-scrollbar::-webkit-scrollbar-track
{
    background-color: transparent;
}
</style>
