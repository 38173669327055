<template>
    <kronos-base-task-modal
        :is-open="IsOpen"
        @close="$emit('close')"
        title="Note"
        width="350px"
        :has-header="true"
        :save-button="true"
        class="z-60"
        @submit="IsOpen = false"
        button-class="text-sm text-gray-500 border border-gray-500 rounded bg-white hover:bg-gray-200 h-full"
    >
        <label class="text-[13px] item-center text-center tracking-wide text-gray-500">
            Please update your note.
        </label>

        <div class="flex gap-x-3 w-[450px]">
            <form
                class="relative flex-auto"
                @submit.prevent="UpdateLeadNote"
                @keyup.enter.exact="UpdateLeadNote"
            >
                <text-field
                    :input-field="Note.note"
                    @submit="UpdateLeadNote"
                    v-model:value="Note.note"
                />
            </form>
        </div>

        <template v-slot:modal-button>
            <button
                @click="UpdateLeadNote"
                class="text-sm text-white rounded h-[44px] bg-[#4667d2] hover:bg-[#6f89dc]"
            >
                Update
            </button>
        </template>
    </kronos-base-task-modal>
</template>

<script>
import KronosBaseTaskModal from "../../../../UI/BaseComponents/KronosBaseTaskModal.vue";
import SendArrowIcon from "../../../../assets/icons/SendArrowIcon.vue";
import TextField from "../ui/TextField.vue";
import {mapState} from "vuex";
import {UpdateLeadNote} from "../../../../services/LeadNote";
import NotificationMixin from "../../../../mixins/NotificationMixin";

export default {
    emits: ["close", "update"],

    mixins: [
        NotificationMixin
    ],

    components: {
        SendArrowIcon,
        KronosBaseTaskModal,
        TextField
    },

    props: {
        Note: {
            type: Object,
            required: true
        },
        IsOpen: {
            type: Boolean,
            default: false
        },

    },

    computed: {
        ...mapState('entities', ["NoteTypes"])
    },

    methods: {
        async UpdateLeadNote() {
            if (!this.isLoading) {
                this.isLoading = true;

                console.log(this.Note);

                await UpdateLeadNote(this.Note.id, {
                    lead_note: {
                        note_type_id: this.Note.note_type_id,
                        note: this.Note.note
                    }
                })
                    .then(async (response) => {
                        if (response.status === 200) {
                            this.showNotification('success', "Note updated successfully.");
                            this.$emit("update")
                            this.$forceUpdate()
                            this.Note.note = ""
                        }
                    })
                    .catch(() => {
                        this.showNotification('error', "Error updating note");
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.$emit('close');
                    })
            }
        },
    },
}
</script>
