<template>
    <transfer-lead-between-lenders-modal
        :isOpen="transferLeadBetweenLendersModalIsOpen"
        @close="transferLeadBetweenLendersModalIsOpen = false"
    />

    <div v-if="ApproveRequestModalIsOpen" class="w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center z-40 fixed">
        <div class="bg-white w-[575px] h-fit min-h-[40px] rounded-[2pt]">
            <header class="text-gray-500 text-[14.5px] flex h-[44px] items-center justify-between pl-[16px]">
                <div class="flex items-center gap-[6px]">
                    Approve Request <span class="text-[10.5px] max-w-fit">{{ `(${this.ContextLead.amount}$)`}}</span>
                </div>
                <div
                    @click="this.ApproveRequestModalIsOpen = false"
                    class="w-[44px] h-[44px] flex items-center justify-center"
                >
                    <cross-icon
                        color="gray"
                        size="16px"
                    />
                </div>
            </header>
            <div class="p-[16px] gap-[12px] grid grid-cols-2">
                <div class="flex flex-col gap-[4px] w-full">
                    <label class="text-[10.5px] text-[#8E8E8E]">Start At</label>
                    <input
                        disabled
                        :value="new Date(this.GenerateLoanPaymentCalendarParameters.start_at).toISOString().slice(0, 10)"
                        type="date"
                        class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"
                    >
                </div>
                <div class="flex flex-col gap-[4px] w-full">
                    <label class="text-[10.5px] text-[#8E8E8E]">First Payment At</label>
                    <input v-model="this.GenerateLoanPaymentCalendarParameters.first_payment_at" type="date" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]">
                </div>
                <div class="flex flex-col gap-[4px] w-full">
                    <label class="text-[10.5px] text-[#8E8E8E]">Interest Rate (%)</label>
                    <input value="20.000" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]">
                </div>
                <div class="flex flex-col gap-[4px] w-full">
                    <label class="text-[10.5px] text-[#8E8E8E]">Number Of Payments</label>
                    <input v-model="this.GenerateLoanPaymentCalendarParameters.number_of_payments" type="number" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]">
                </div>
                <div class="flex flex-col gap-[4px] w-full">
                    <label class="text-[10.5px] text-[#8E8E8E]">Frequency</label>
                    <base-table-filter-dropdown
                        :selected-option="this.GenerateLoanPaymentCalendarParameters.payment_frequency"
                        :options="this.PaymentCalendarOptions"
                        @update="this.GenerateLoanPaymentCalendarParameters.payment_frequency = $event"
                    />
                </div>
                <div class="flex flex-col gap-[4px] w-full">
                    <label class="text-[10.5px] text-[#8E8E8E]">Account Number</label>
                    <input value="**** 6661" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]">
                </div>
                <div class="flex flex-col gap-[4px] w-full col-span-2">
                    <label class="text-[10.5px] text-[#8E8E8E]">Guarantor</label>
                    <base-table-filter-dropdown
                        :selected-option="this.GenerateLoanPaymentCalendarParameters.guarantor"
                        :options="this.Guarantors"
                        placeholder="Select a Guarantor"
                        @update="this.GenerateLoanPaymentCalendarParameters.guarantor = $event"
                    />
                </div>
                <div class="grid grid-cols-2 col-span-2 gap-[12px] mt-[16px]">
                    <div
                        @click="this.ApproveRequestModalIsOpen = false;"
                        class="cursor-pointer bg-gray-500 h-[32px] text-white rounded-[2pt] flex items-center justify-center text-[11.5px] font-medium"
                    >
                        Cancel
                    </div>
                    <div
                        @click="this.GenerateCalendar()"
                        class="cursor-pointer bg-theme-blue h-[32px] text-white rounded-[2pt] flex items-center justify-center font-medium text-[11.5px]"
                    >
                        Generate
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="LeadNavigation && LeadNavigation.length > 0 && LeadNavigationUri && LeadNavigationUri.length > 0"
        class="custom-shadow-1 | absolute z-30 right-0 bottom-[24px] rounded-l-[4pt] divide-gray-100 divide-x bg-white flex"
    >
        <a
            v-if="LeadNavigationUri[0] !== null"
            :href="LeadNavigationUri[0]"
            class="h-[40px] w-[40px] flex items-center justify-center hover:bg-gray-50 rounded-l-[4pt] cursor-pointer duration-500"
        >
            <chevron-left-small-icon color="#6b7280" size="18px"/>
        </a>
        <a
            v-if="LeadNavigationUri[1] !== null"
            :href="LeadNavigationUri[1]"
            class="h-[40px] w-[40px] flex items-center justify-center hover:bg-gray-50 cursor-pointer duration-500"
        >
            <chevron-right-small-icon color="#6b7280" size="18px"/>
        </a>
    </div>

    <div
        :class="SideNavigationIsOpen ? 'left-[225px]' : 'left-[58px]'"
        class="custom-height-1 w-[86px] z-[3] top-[52px] fixed duration-500 bg-white"
    >
        <div class="flex flex-col w-full items-center h-full">
            <div class="py-[28px] border-b border-gray-100 w-full bg-[#FBFBFB] flex items-center justify-center">
                <a :href="`/client/${ContextLead.client.id}`" class="h-[52px] w-[52px] flex items-center justify-center rounded-full bg-white custom-shadow-1">
                    <div class="h-[42px] w-[42px] flex items-center justify-center rounded-full border border-opacity-50 border-gray-200 bg-[#F2F2F2]">
                        <p class="text-[#595959] font-semibold text-[15px]">{{ ContextLead.client.first_name[0] + ContextLead.client.last_name[0] }}</p>
                    </div>
                </a>
            </div>
            <div class="py-[12px] flex flex-col gap-[16px]">
                <a
                    v-for="(Lead, Index) in Leads"
                    :key="Index"
                    :href="`/lead/${Lead.id}`"
                    class="relative group"
                >
                    <div class="custom-shadow-1 | hidden group-hover:flex flex-col gap-3 absolute rounded-[4pt] px-[16px] py-[10px] left-[78px] top-[-60%] w-[225px] h-fit bg-white">
                        <div class="flex flex-col gap-[-1px]">
                            <p class="text-[10px] tracking-wide font-semibold text-gray-600">{{ `${Lead.lender.name} - ${Lead.amount} $` }}</p>
                            <p class="text-[10px] -mt-0.5 tracking-wide text-gray-500">{{ `${FormatDate(Lead.created_at)}` }}</p>
                        </div>

                        <div class="flex flex-col gap-0.5">
                            <p class="text-[10px] tracking-wide font-semibold text-gray-700">
                                Client :
                                <span class="font-medium text-gray-500">
                                    {{ `${Lead.client.first_name} ${Lead.client.last_name}` }}
                                </span>
                            </p>
                            <p class="text-[10px] tracking-wide font-semibold text-gray-700">
                                Responsible :
                                <span class="font-medium text-gray-500">
                                    {{ `${Lead.user.first_name} ${Lead.user.last_name}` }}
                                </span>
                            </p>
                            <p class="text-[10px] tracking-wide font-semibold text-gray-700">
                                Source :
                                <span class="font-medium text-gray-500">
                                    {{ `Loans Canada` }}
                                </span>
                            </p>
                            <p class="text-[10px] tracking-wide font-semibold text-gray-700">
                                Status :
                                <span class="font-medium text-gray-500">
                                    {{ Lead.lead_status?.lead_status_name }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div
                        v-if="parseInt(Lead.id) === parseInt($route.params.id)"
                        class="absolute left-[-17px] w-[5px] h-[36px] mt-[8px] rounded-r-[2pt] bg-theme-blue"
                    ></div>
                    <div
                        v-if="parseInt(Lead.id) !== parseInt($route.params.id)"
                        class="group-hover:w-[5px] duration-300 absolute left-[-17px] w-[0px] h-[36px] mt-[8px] bg-opacity-50 rounded-r-[2pt] bg-theme-blue"
                    ></div>
                    <div class="custom-shadow-1 | rounded-[4pt] flex items-center justify-center w-[52px] h-[52px]">
                        <div class="w-[44px] h-[44px] bg-[#F2F2F2] flex items-center justify-center rounded-[4pt] border border-opacity-50 border-gray-200">
                            <img
                                class="h-[22px] w-[22px]"
                                :src="BaseURL + Lead.lender.image_src"
                                alt="img"
                            >
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <approbation-step-form
        v-if="approbationStepFormIsOpen"
        @close="closeApprobationForm"
    />

    <kronos-base-layout>
        <div class="flex pb-[44px] flex-row pl-[86px] gap-5 max-w-full mx-auto">
            <div class="flex flex-col gap-4 w-screen fit">
                <div class="relative mt-[40px]">
                    <div>
                        <div class="absolute top-[-40px] flex bg-white rounded-[4pt] rounded-bl-none items-center justify-center h-[92px] w-[92px] shadow-custom-shadow left-0 z-10">
                            <div class="h-[82px] w-[82px] rounded-[4pt] top-0 left-0 bg-[#F2F2F2] border-opacity-75 border gray-200 flex items-center justify-center">
                                <img
                                    class="h-[40px] w-[40px]"
                                    :src="BaseURL + ContextLead.lender.image_src"
                                    alt="img"
                                >
                            </div>
                        </div>
                    </div>

                    <div
                        @click="LeadStatusOptionsIsOpen = !LeadStatusOptionsIsOpen"
                        v-click-outside="CloseLeadStatus"
                        class="absolute top-[-40px] ml-[92px] px-[18px] flex items-center justify-center h-[40px]"
                    >
                        <div class="relative h-full flex mt-1">
                            <div
                                :class="{ 'rounded-[4pt]': LeadStatusOptionsIsOpen, 'rounded-2xl': !LeadStatusOptionsIsOpen }"
                                class="flex items-center bg-theme-blue font-light capitalize tracking-wide duration-500 text-white px-[24px] ease-in-out justify-center h-[24px] text-[10px] cursor-pointer shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {{ ContextLead.lead_status ? ContextLead.lead_status.lead_status_name.replace(/_/g, " ").toLowerCase() : '' }}
                                {{ ContextLead.lead_status && ContextLead.lead_status.id === 2 ? `(${ContextLead.denied_reason?.lead_denied_reason})` : '' }}
                            </div>
                            <div v-if="LeadStatusOptionsIsOpen" class="w-[150px] absolute rounded-[4pt] top-[26px] bg-theme-blue z-40 h-fit py-[4px]">
                                <div v-for="status in LeadStatuses">
                                    <div
                                        @click="UpdateStatus(status.id)"
                                        class="text-white bg-transparent w-full h-[28px] capitalize cursor-pointer tracking-wide duration-500 text-[10px] flex items-center px-[12px]"
                                        v-if="![2].includes(status.id)"
                                    >
                                        {{ status.lead_status_name.replace(/_/g, " ").toLowerCase() ?? '...' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="absolute top-[-40px] right-0 flex items-start justify-center h-[40px] gap-[10px]"
                    >
                        <div class="flex items-center justify-center h-[24px] rounded-[4pt] text-[10px] font-medium tracking-wide pr-[8px] text-[#767676] text-opacity-80">Created at {{ ContextLead.created_at.split('T')[0] }}</div>
                        <div class="flex items-center justify-center h-[24px] rounded-[4pt] text-[10px] font-medium tracking-wide pr-[8px] text-[#767676] text-opacity-80">Last Modification 15 days ago</div>
                        <div class="bg-white w-[36px] h-[24px] rounded-[4pt]"></div>
                        <div class="bg-white w-[36px] h-[24px] rounded-[4pt]"></div>
                    </div>

                    <div class="rounded-[4pt] bg-white h-fit w-full top-[40px] z-0 shadow-custom-shadow">
                        <div class="grid grid-cols-2 h-[52px] border-b border-gray-100">
                            <div class="ml-[92px] pl-[18px] h-full flex flex-row">
                                <h3 class="text-[14px] font-medium text-[#3B3B3B] h-full flex flex-wrap content-center">
                                    {{ ContextLead.client.first_name }} {{ ContextLead.client.last_name }}
                                </h3>
                                <div
                                    v-if="!!IsFraudster"
                                    class="flex justify-center items-center pl-2"
                                >
                                    <div class="rounded-[4pt] bg-red-100 z-40 h-fit py-[4px]">
                                        <div class="text-red-500 bg-transparent w-full h-[14px] capitalize tracking-wide duration-500 text-[10px] flex items-center px-[12px]">
                                            Fraudster
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="h-full ml-auto flex">
                                <div
                                    class="relative w-full h-full"
                                    v-click-outside="CloseThreeDotsMenu"
                                >
                                    <div
                                        @click="ThreeDotsMenuIsOpen = !ThreeDotsMenuIsOpen"
                                        type="button"
                                        class="h-full w-[46px] cursor-pointer flex justify-center content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                                    >
                                        <ThreeDotIcon
                                            size="16px"
                                            class="fill-gray-500"
                                        />
                                    </div>
                                    <div v-if="ThreeDotsMenuIsOpen" class="custom-shadow-1 | rounded-[4pt] py-[4px] w-[150px] h-fit absolute z-10 top-[48px] right-0 bg-white">
                                        <div class="group w-full min-h-[28px] text-gray-600 rounded flex items-center">
                                            <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                                <bell-icon
                                                    color="#818181"
                                                    size="13px"
                                                />
                                                <span class="whitespace-nowrap text-[10.5px] font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                                    Opt-out
                                                </span>
                                            </div>
                                        </div>
                                        <div class="group w-full min-h-[28px] text-gray-600 rounded flex items-center">
                                            <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                                <flag-icon
                                                    color="#818181"
                                                    size="13px"
                                                />
                                                <span class="whitespace-nowrap text-[10.5px] font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                                    Flag as Fraud
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="this.$store.state.SelectedTeam === 0"
                                    @click="checkForLeadApprobationForm"
                                    type="button"
                                    class="h-full min-w-[46px] inline-flex justify-center content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                                >
                                    <file-duo-icon
                                        size="16px"
                                        class="fill-gray-500"
                                    />
                                </div>

                                <div
                                    v-if="this.$store.state.SelectedTeam === 0"
                                    @click="transferLeadBetweenLendersModalIsOpen = true"
                                    type="button"
                                    class="h-full min-w-[46px] inline-flex justify-center content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                                >
                                    <transfer-duo-icon
                                        size="16px"
                                        class="fill-gray-500"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="bg-gray-[#FBFBFB] bg-opacity-40 border-opacity-25 gap-[16px] w-full h-fit py-[18px] px-[18px] grid grid-cols-3 grid-rows-1 sm-justify-between">
                            <lead-next-step
                                :status-id="ContextLead.status_id"
                                @open-approbation="this.OpenApprobationStepForm()"
                                @approve-request="this.OpenApproveRequestModal()"
                            />
                            <div class="h-fit w-full flex justify-between flex-col gap-[16px]">
                                <div class="custom-shadow-0 rounded-[4pt] h-fit bg-white divide-y divide-gray-100">
                                    <div class="text-[10.5px] font-medium text-[#3A3A3A] h-[26px] flex items-center px-3 tracking-wide justify-start">Processus D'Approbation</div>
                                    <div class="h-[58px]">

                                    </div>
                                </div>
                                <div class="custom-shadow-0 rounded-[4pt] h-fit bg-white divide-y divide-gray-100">
                                    <div class="text-[10.5px] font-medium text-[#3A3A3A] h-[26px] flex items-center px-3 tracking-wide justify-start">Payment Schedule</div>
                                    <div class="h-[58px]">

                                    </div>
                                </div>
                            </div>
                            <div class="h-fit w-full">
                                <div class="custom-shadow-0 rounded-[4pt] h-fit bg-white divide-y divide-gray-100">
                                    <div class="text-[10.5px] font-medium text-[#3A3A3A] h-[26px] flex items-center px-3 tracking-wide justify-start">Recent Activity</div>
                                    <div v-if="modificationHistory.length === 0" class="w-full h-[158px] flex pt-[42px] tracking-wide justify-center text-gray-400 text-[10px]"> No Recent Activity</div>
                                    <div v-else class="relative h-[158px] flex flex-col gap-2">
                                        <div class="w-full text-gray-500 text-xs font-light tracking-wide hover:bg-gray-50">
                                            <div
                                                class="flex justify-between px-3 py-2"
                                                v-for="modification in modificationHistory.slice(0, 2)"
                                                :key="modification.id"
                                            >
                                                <div class="w-full">
                                                    <span class="font-semibold">{{`${modification.user.first_name} ${modification.user.last_name}`}}</span>
                                                    <span class="ml-6 text-[9px]">{{ formatDateTime(modification.created_at) }}</span>
                                                    <br>
                                                    <span class="font-light text-[9px]">
                                                        Changed field {{modification.field}} from
                                                    <span class="inline-flex items-center rounded-[2pt] bg-theme-blue text-white px-1 py- text-[9px] ring-1 ring-inset">{{modification.old_value}}</span>
                                                        @
                                                    <span class="inline-flex items-center rounded-[2pt] bg-theme-blue text-white px-1 py- text-[9px] ring-1 ring-inset">{{modification.new_value}}</span>
                                                  </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="modificationHistory.length > 4"
                                            class="flex items-center justify-center bg-gray-200 text-xs h-6 w-full bottom-[30px] absolute text-gray-500 rounded-b-md "
                                        >
                                            <button @click="viewAll = !viewAll; selected = 2"> View All </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <lead-note />

                <div class="w-full h-fit flex gap-[18px]">
                    <lead-general-informations />
                    <lead-payment-calendar />
                </div>
            </div>
        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../UI/Layouts/KronosBaseLayout.vue";
import KronosBaseDataCard from "../UI/BaseComponents/KronosBaseDataCard.vue";
import SendArrowIcon from "../assets/icons/SendArrowIcon.vue";
import ReloadIcon from "../assets/icons/ReloadIcon.vue";
import KronosBaseDropdown from "../UI/BaseComponents/KronosBaseDropdown.vue";
import KronosBaseChatOutput from "../UI/BaseComponents/KronosBaseChatOutput.vue";
import KronosBaseChatInput from "../UI/BaseComponents/KronosBaseChatInput.vue";
import ThreeDotIcon from "../assets/icons/ThreeDotIcon.vue";
import KronosBaseInformationCard from "../UI/BaseComponents/KronosBaseInformationCard.vue";
import KronosBaseInformationCardInput from "../UI/BaseComponents/KronosBaseInformationCardInput.vue";
import KronosBaseContractCard from "../UI/BaseComponents/KronosBaseContractCard.vue";
import KronosBasePaymentHistoryTable from "../UI/BaseComponents/KronosBasePaymentHistoryTable.vue";
import TransferDuoIcon from "../assets/icons/duotone/TransferDuoIcon.vue";
import LeadDispatcherAccountsOrderModal from "../components/Tools/LeadDispatcher/LeadDispatcherAccountsOrderModal.vue";
import TransferLeadBetweenLendersModal from "../components/Tools/Lead/TransferLeadBetweenLendersModal.vue"
import KronosBaseLeadSideNavigation from "../UI/Navigation/KronosBaseLeadSideNavigationForClientPage.vue";
import KronosBaseLeadResponsibles from "../UI/BaseComponents/KronosBaseLeadResponsibles.vue";
import {mapState} from "vuex";
import {vMaska} from "maska";
import ChevronLeftDuoIcon from "../assets/icons/duotone/ChevronLeftDuoIcon.vue";
import CibcIcon from "../assets/icons/bank/CIBC.vue";
import RbcIcon from "../assets/icons/bank/RBC.vue";
import LeadGeneralInformation from "../components/Lead/LeadGeneralInformation.vue";
import ApprobationStepForm from "../components/Lead/Approbation/ApprobationStepForm.vue";
import {RetrieveLeadApprobationStepByLeadId, StoreLeadApprobationStep} from "../services/LeadApprobationStep";
import KronosBaseActionModal from "../UI/BaseComponents/KronosBaseActionModal.vue";
import LeadModificationHistory from "../components/Lead/LeadModificationHistory.vue"
import FileDuoIcon from "../assets/icons/duotone/FileDuoIcon.vue";
import {RetrieveLead, RetrieveLeads, UpdateLead} from "../services/Lead";
import store from "../store";
import notificationMixin from "../mixins/NotificationMixin";
import PaymentCalendar from "../components/PaymentCalendar/PaymentCalendar.vue";
import {RetrieveModificationHistory} from "../services/ModificationHistory";
import KronosBaseCard from "../UI/BaseComponents/KronosBaseCard.vue";
import LeadNote from "../components/Lead/LeadNote/LeadNote.vue"
import ChevronRightIcon from "../assets/icons/ChevronRightIcon.vue";
import ChevronLeftSmallIcon from "../assets/svg/ChevronLeftSmallIcon.vue";
import ChevronRightSmallIcon from "../assets/svg/ChevronRightSmallIcon.vue";
import LeadPaymentCalendar from "../components/Lead/LeadPaymentCalendar/LeadPaymentCalendar.vue";
import LeadGeneralInformations from "../components/Lead/LeadGeneralInformation/LeadGeneralInformations.vue";
import BellIcon from "../assets/svg/BellIcon.vue";
import FlagIcon from "../assets/svg/FlagIcon.vue";
import {RetrieveFlagAsFraudByEmail} from "../services/FlagAsFraud";
import LeadNextStep from "../components/Lead/LeadNextStep.vue";
import CrossIcon from "../assets/svg/CrossIcon.vue";
import BaseTableFilterDropdown from "../UI/Tables/BaseTableFilterDropdown.vue";
import {StoreLoanCalendarPayment} from "../services/LeadCalendar/LoanPaymentCalendar/LoanPaymentCalendar";
import {RetrieveGuarantors} from "../services/Guarantor";

export default
{
    components: {
        BaseTableFilterDropdown,
        CrossIcon,
        LeadNextStep,
        FlagIcon,
        BellIcon,
        LeadPaymentCalendar,
        LeadGeneralInformations,
        ChevronRightSmallIcon,
        ChevronLeftSmallIcon,
        ChevronRightIcon,
        PaymentCalendar,
        FileDuoIcon,
        KronosBaseActionModal,
        ApprobationStepForm,
        LeadGeneralInformation,
        CibcIcon,
        RbcIcon,
        ChevronLeftDuoIcon,
        KronosBaseLeadResponsibles,
        KronosBaseLeadSideNavigation,
        TransferLeadBetweenLendersModal,
        LeadDispatcherAccountsOrderModal,
        TransferDuoIcon,
        KronosBasePaymentHistoryTable,
        KronosBaseInformationCardInput,
        KronosBaseInformationCard,
        ThreeDotIcon,
        KronosBaseChatInput,
        KronosBaseChatOutput,
        KronosBaseDropdown,
        ReloadIcon,
        SendArrowIcon,
        KronosBaseDataCard,
        KronosBaseLayout,
        KronosBaseContractCard,
        LeadModificationHistory,
        KronosBaseCard,
        LeadNote
    },

    directives: {
        maska: vMaska
    },

    data() {
        return {
            showTransferModal: false,
            transferLeadBetweenLendersModalIsOpen: false,
            selected: 0,
            approbationStepFormIsOpen: false,
            approbationStepModalIsOpen: false,
            LeadStatusOptionsIsOpen: false,
            modificationHistory: [],
            viewAll: false,
            ApproveRequestModalIsOpen: false,
            Leads: [],
            LeadNavigation: [],
            ThreeDotsMenuIsOpen: false,
            IsFraudster: false,
            ContextStep: 1,
            Guarantors: [],
            PaymentCalendarOptions: [
                {value: 'WEEKLY', label: 'Weekly'},
                {value: 'TWICE_MONTHLY', label: 'Every Two Weeks'},
                {value: 'MONTHLY', label: 'Monthly'},
            ],
            GenerateLoanPaymentCalendarParameters: {
                lead_id: null,
                base_amount: null,
                interest_rate: 20.000,
                payment_frequency: 'TWICE_MONTHLY',
                number_of_payments: 12,
                start_at: null,
                first_payment_at: null,
                guarantor: null,
            }
        }
    },

    mixins: [
        notificationMixin,
    ],

    methods: {
        CloseThreeDotsMenu() {
            this.ThreeDotsMenuIsOpen = false;
        },

        CloseLeadStatus() {
            this.LeadStatusOptionsIsOpen = false;
        },

        async OpenApprobationStepForm() {
            if (this.ContextStep === 1) {
                await this.storeLeadApprobationSteps(true);
            } else {
                this.approbationStepFormIsOpen = true;
            }

        },

        formatDateTime(dateTime) {
            const date = new Date(dateTime);
            const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            };
            return date.toLocaleString('en-CA', options);
        },

        async retrieveModificationHistory() {
            await RetrieveModificationHistory(this.$route.params.id, 'lead_id')
                .then(response => {
                    this.modificationHistory = response.data.data.modification_history;
                })
        },

        UpdateStatus(status_id) {
            UpdateLead(this.ContextLead.id, {
                'status_id': status_id,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.RetrieveContextLead();
                        this.showNotification('success', 'Status Updated Successfully')
                    }
                })
        },

        retrieveLeadApprobationStep() {
            RetrieveLeadApprobationStepByLeadId(this.ContextLead.id)
                .then((response) => {
                    this.ContextStep = 2;
                    this.$store.state.ContextLeadApprobationStep = response.data.data.lead_approbation_step;
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        this.ContextStep = 1;
                    }
                })
        },

        storeLeadApprobationSteps(OpenAfterCreate = false) {
            const data = {
                "lead_id": this.ContextLead.id,
            }

            UpdateLead(this.ContextLead.id, {
                    status_id: 3
                })
                .then((response) => {
                    if (response.status === 200) {
                        StoreLeadApprobationStep(data)
                            .then(async (response) => {
                                await this.retrieveLeadApprobationStep()
                                this.approbationStepFormIsOpen = OpenAfterCreate;
                            })
                            .catch((e) => {
                                console.error(e)
                            })
                    }
                })
        },

        checkForLeadApprobationForm(){
            if (this.ContextLead.is_approved === null && this.ContextLeadApprobationStep === null) {
                this.approbationStepModalIsOpen = true;
            } else {
                this.approbationStepFormIsOpen = true
            }
        },

        async createLeadApprobationStepOpenModal() {
            await this.storeLeadApprobationSteps()
            this.approbationStepModalIsOpen = false;
        },

        closeApprobationForm() {
            this.retrieveLeadApprobationStep();
            this.approbationStepFormIsOpen = false
        },

        FormatDate(inputDateTime) {
            const dateTime = new Date(inputDateTime);

            const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

            const month = monthNames[dateTime.getMonth()];
            const day = dateTime.getDate();
            const year = dateTime.getFullYear();

            const formattedDay = (day < 10 ? '0' : '') + day;

            return month + " " + formattedDay + " " + year;
        },

        RetrieveContextLead() {
            RetrieveLead(this.ContextLead.id)
                .then((response) => {
                    if (response.status === 200) {
                        store.state.ContextLead = response.data.data.lead;
                    }
                })
        },

        async FetchLeads() {
            const query = `client_id=${this.ContextLead.client.id}`;

            await RetrieveLeads(query)
                .then(response => {
                    if (response.status === 200) {
                        this.Leads = response.data.data.leads;
                    }
                })
                .catch(() => {
                    this.showNotification('error', 'An Error Occurred While Retrieving Leads')
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        RetrieveFlagAsFraud() {
            RetrieveFlagAsFraudByEmail(this.ContextLead.client.email_address)
                .then(response => {
                    if (response.status === 200) {
                        this.IsFraudster = false;
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        this.IsFraudster = false;
                    }
                });
        },

        OpenApproveRequestModal() {
            this.GenerateLoanPaymentCalendarParameters.base_amount = this.ContextLead.amount;
            this.GenerateLoanPaymentCalendarParameters.start_at = new Date().getTime();
            this.ApproveRequestModalIsOpen = true;
        },

        GenerateCalendar() {
            StoreLoanCalendarPayment({
                "lead_id": this.ContextLead.id,
                "base_amount": this.ContextLead.amount,
                "interest_rate": this.GenerateLoanPaymentCalendarParameters.interest_rate,
                "number_of_payments": this.GenerateLoanPaymentCalendarParameters.number_of_payments,
                "payment_frequency": this.GenerateLoanPaymentCalendarParameters.payment_frequency,
                "start_at": this.GenerateLoanPaymentCalendarParameters.start_at.toString(),
                "first_payment_at": new Date(this.GenerateLoanPaymentCalendarParameters.first_payment_at).getTime().toString(),
                'guarantor': this.GenerateLoanPaymentCalendarParameters.guarantor,
            })
                .then(response => {
                    if (response.status === 200) {
                        UpdateLead(this.ContextLead.id, {
                            'status_id': 1
                        })
                    }
                })
                .finally(() => {
                    window.location.reload();
                })
        },

        FetchAllGuarantors() {
            RetrieveGuarantors()
                .then(response => {
                    let guarantors = [];

                    response.data.data.guarantors.forEach(guarantor => {
                        guarantors.push({value: guarantor.id, label: guarantor.name_en})
                    })

                    this.Guarantors = guarantors;
                })
        }
    },

    computed: {
        ...mapState([
            'ContextLead',
            'ContextLeadApprobationStep',
            'LeadStatuses',
            'SideNavigationIsOpen'
        ]),

        BaseURL() {
            return import.meta.env.VITE_BACKEND_BASE_URL + '/storage';
        },

        LeadNavigationUri() {
            const index = this.LeadNavigation.findIndex(item => item == this.$route.params.id);

            if (index === -1) {
                return [];
            }

            const before = index > 0 ? `${this.LeadNavigation[index - 1]}${window.location.search}` : null;
            const after = index < this.LeadNavigation.length - 1 ? `${this.LeadNavigation[index + 1]}${window.location.search}` : null;

            return [ before, after ];
        },
    },

    mounted() {
        this.FetchLeads();
        this.retrieveLeadApprobationStep();
        this.retrieveModificationHistory();
        this.RetrieveFlagAsFraud();
        this.FetchAllGuarantors();

        if (this.$route.query.list) {
            this.LeadNavigation = this.$route.query.list.replace(/\[|\]/g, "").split(",").map(Number);
        }

        this.$route.query.approbation_step_form_is_open === '1'
            ? !this.ContextLeadApprobationStep ? this.storeLeadApprobationSteps(true) : this.approbationStepFormIsOpen = true
            : null;
    },
}
</script>

<style scoped>
.custom-scrollbar::-webkit-scrollbar
{
    width: 5px;
}

.custom-height-1 {
    height: calc(100vh - 52px);
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
    background-color: lightgray;
}

.custom-scrollbar::-webkit-scrollbar-track
{
    background-color: transparent;
}

.rounded-full, .rounded-4pt {
    transition: border-radius 0.5s ease;
}
</style>
