<template>
    <transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 scale-100"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="ease-in duration-0"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-100"
    >
        <div
            v-if="isOpen"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-60 z-50 flex items-center justify-center"
        >
            <div
                v-click-outside="close"
                class="max-w-[600px] w-full h-fit bg-white rounded-lg"
            >
                <div class="border-b border-gray-100 h-[55px] flex items-center pl-[12pt] justify-between ">
                    <h2 class="font-medium text-sm text-gray-700">
                        Add Note
                    </h2>
                    <div
                        @click="close"
                        class="border-l flex items-center justify-center border-gray-100 hover:bg-gray-50 duration-300 w-[55px] h-[53px] rounded-r-lg cursor-pointer"
                    >
                        <close-icon
                            size="14px"
                            fill="#6b7280"
                        />
                    </div>
                </div>
                <div class="w-full flex items-center justify-center h-[250px] mt-5 mb-5">
                    <div class="w-1/2 flex flex-col gap-5">
                        <div class="h-full w-full">
                            <textarea
                                class="max-h-full h-16 bg-gray-50 shadow-inner w-full text-xs tracking-wide font-medium text-gray-500 p-[10px] focus:outline-none"
                                v-model="note" placeholder="Note..." >
                            </textarea>
                        </div>
                        <div class="w-full h-full">
                            <button @click="updateUserTask()" class="text-white uppercase border rounded-[6px] flex items-center justify-center w-full bg-[#4667d2] p-[10px] h-[40px]">done</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import CloseIcon from "../../assets/icons/CloseIcon.vue";
import {mapState} from "vuex";
import NotificationMixin from "../../mixins/NotificationMixin";
import {UpdateUserTask} from "../../services/UserTask";

export default {
    emits: ['close'],
    components: {CloseIcon},

    mixins: [
        NotificationMixin,
    ],

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        task_id: {
            type: Number,
        }
    },

    computed: {
        ...mapState([
            'ContextUser',
        ]),
    },

    data() {
        return {
            note: "",
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async updateUserTask() {
            await UpdateUserTask(this.task_id, {
                'note': this.note
            })
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Task Updated Successfully')
                        this.note = "";
                    }
                })
        },
    }
}

</script>

<style scoped>

</style>