import api from './Api.js';

const BaseURL = 'v1/authentication';

export const InitiateAuth = async (formData) => {
	return await api()
		.post(`${BaseURL}/initiate-auth`, formData);
}

export const ValidateChallengeResponse = async (totp) => {
	return await api()
		.post(`${BaseURL}/validate-challenge-response`, {
			'totp': totp
		});
}

export const AuthCheck = async () => {
	return await api()
		.get(`${BaseURL}/auth-check`);
}

export const Logout = async () => {
	return await api()
		.post(`${BaseURL}/logout`);
}

export const GlobalLogout = async () => {
	return await api()
		.post(`${BaseURL}/global-logout`);
}
