<template>
    <div class="bg-white p-[16px] rounded-b-[4pt]">
        <div class="custom-shadow-1 rounded-[4pt] bg-white">
            <table class="px-[12px] w-full rounded-[4pt]">
                <tr class="px-[12px] h-[42px] rounded-t-[4pt] text-white text-[10.5px] w-full bg-[#4D4D4D]">
                    <td class="rounded-tl-[4pt] tracking-wide w-full max-w-[250px] text-center">
                        Notification
                    </td>
                    <td class="min-w-[150px] tracking-wide text-center">
                        Screen
                    </td>
                    <td class="min-w-[150px] tracking-wide text-center">
                        SMS
                    </td>
                    <td class="tracking-wide min-w-[150px] text-center">
                        Email
                    </td>
                    <td class="rounded-tr-[4pt] tracking-wide min-w-[150px] text-center">
                        Time
                    </td>
                </tr>
                <tbody
                    v-for="(NotificationSection, SectionName) in NotificationsSections"
                    :key="SectionName"
                >
                    <tr class="w-full h-[46px] bg-gray-100 text-[12.5px]">
                        <td class="text-[11.5.px] font-medium text-gray-500 px-[14px]">
                            {{ `${SectionName.replace('_', ' ')} Notifications` }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr class="w-full h-[4px]">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr
                        v-for="(Notification, Index) in NotificationSection"
                        :key="Index"
                        class="text-[10.5px] h-[32px] hover:bg-gray-50"
                    >
                        <td class="rounded-tr-[4pt] text-gray-500 tracking-wide min-w-[150px] px-[24px]">
                            {{ Notification.label }}
                            <span v-if="Notification.description" class="text-[9px]">
                                {{ `(${Notification.description})` }}
                            </span>
                        </td>
                        <td class="text-center">
                            <div
                                @click="this.NotificationsSections[SectionName][Index].screen = !this.NotificationsSections[SectionName][Index].screen"
                                :class="[
                                    'w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm shadow-sm transition-colors',
                                    this.NotificationsSections[SectionName][Index].screen ? 'bg-theme-blue' : 'bg-gray-200',
                                ]"
                            ></div>
                        </td>
                        <td class="text-center">
                            <div
                                @click="this.NotificationsSections[SectionName][Index].sms = !this.NotificationsSections[SectionName][Index].sms"
                                :class="[
                                    'w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm shadow-sm transition-colors',
                                    this.NotificationsSections[SectionName][Index].sms ? 'bg-theme-blue' : 'bg-gray-200',
                                ]"
                            ></div>
                        </td>
                        <td class="text-center">
                            <div
                                @click="this.NotificationsSections[SectionName][Index].email = !this.NotificationsSections[SectionName][Index].email"
                                :class="[
                                    'w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm shadow-sm transition-colors',
                                    this.NotificationsSections[SectionName][Index].email ? 'bg-theme-blue' : 'bg-gray-200',
                                ]"
                            ></div>
                        </td>
                        <td class="cursor-pointer text-center text-[10.5px] text-gray-400">
                            <div class="relative">
                                {{ this.NotificationTime[this.NotificationsSections[SectionName][Index].time].label }}
                                <div class="absolute w-full h-fit">
                                    <div
                                        v-if="false"
                                        v-for="(Time, I) in this.NotificationTime"
                                        :key="I"
                                    >
                                        {{ Time }}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="w-full h-[4px]">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default
{
    data() {
        return {
            NotificationTime: [
                {'value': 'work_hours', 'label': 'Work Hours'},
                {'value': 'anytime', 'label': 'Anytime'},
                {'value': 'anytime_except_weekends', 'label': 'Anytime Except Weekends'},
            ],
            NotificationsSections: {
                Lead: [
                    {label: 'New Lead', description: null, screen: true, sms: false, email: false, time: 1},
                    {label: 'New Lead Assigned', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                    {label: 'Lead Approved', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                    {label: 'Denied Requests', description: 'Assigned to Me', screen: false, sms: false, email: false, time: 1},
                    {label: 'Lead Status Change', description: 'Assigned to Me', screen: false, sms: false, email: false, time: 1},
                    {label: 'Lead Flagged as Fraud', description: 'Assigned to Me', screen: false, sms: false, email: false, time: 1},
                    {label: 'Lead Transferred', description: 'Assigned to Me', screen: false, sms: false, email: false, time: 1},
                ],
                Client: [
                    {label: 'New Client', description: null, screen: true, sms: false, email: false, time: 1},
                    {label: 'New Flinks Imported', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                    {label: 'New Documents Uploaded', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                ],
                Communication: [
                    {label: 'SMS Received', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                    {label: 'Email Received', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                    {label: 'Missed Phone Call', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                    {label: 'New Voicemail', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                ],
                Verification: [
                    {label: 'New Verification to Complete', description: null, screen: true, sms: false, email: false, time: 1},
                    {label: 'Verification Completed', description: 'Assigned to Me', screen: true, sms: false, email: false, time: 1},
                ],
                Payment_Gateway: [
                    {label: 'Non Sufficient Fund', description: null, screen: true, sms: false, email: false, time: 1},
                    {label: 'E-Transfer Failed', description: null, screen: true, sms: false, email: false, time: 1},
                ],
                Schedule: [
                    {label: 'New Schedule', description: null, screen: true, sms: false, email: false, time: 1},
                    {label: 'Employee Break/Lunch', description: null, screen: true, sms: false, email: false, time: 1},
                ],
                Programmer: [
                    {label: 'New Issue', description: null, screen: true, sms: false, email: false, time: 1},
                    {label: 'New Suggestion', description: null, screen: true, sms: false, email: false, time: 1},
                ]
            }
        }
    }
}
</script>
