import api from './Api';

const BaseURL = 'v1/lead';

export const RetrieveLeads = async (resource, page= 1, limit= 50) =>
{
    if (resource !== '') {
        return await api().get(`${BaseURL}?page=${page}&limit=${limit}&${resource}`)
    }

    return await api().get(`${BaseURL}?page=${page}&limit=${limit}`)
};

export const RetrieveLead = async (id) => {
    return await api().get(`${BaseURL}/${id}`)
}

export const UpdateLeadResponsible = async (id, user_id, manager_id) =>
{
    return await api()
        .put(`${BaseURL}/${id}`,{
        'user_id': user_id,
        'manager_id': manager_id
    })
}

export const LeadStatistics = async (filter_by, start_at, end_at) => {
    return await api().
    get(`${BaseURL}/statistic?start_at=${start_at}&end_at=${end_at}&filter_by=${filter_by}`)
}

export const UpdateLeadIsApproved = async (id, data) =>
{
    return await api().put(`${BaseURL}/is-approved/${id}`, data)
}

export const UpdateLead = async (id, data) =>
{
    return await api().put(`${BaseURL}/${id}`, data)
}


export const RetrieveLeadsByManger = async (page= 1, limit= 50, ibv, started) =>
{
    return await api().get(`${BaseURL}/me/?page=${page}&limit=${limit}&ibv=${ibv}&started=${started}`)
};

export const LeadStatisticsByManager = async (page= 1, limit= 50, start_at, end_at) =>
{
    return await api().get(`${BaseURL}/dashboard/?page=${page}&limit=${limit}&start_at=${start_at}&end_at=${end_at}`)
};

export const LeadStatisticsByResponsible = async (start_at, end_at) =>
{
    return await api().get(`${BaseURL}/statistics-by-responsible/?start_at=${start_at}&end_at=${end_at}`)
};

