<template>
  <view-all-modal
      :IsOpen="modalViewAll"
      :Title="`General notes`"
      :NotesData="ViewAllNotes"
      :NotesType="NoteTypes[0]"
      @IsClose="closeViewAllModal"
  />
  <kronos-base-layout>
    <div class="flex flex-col gap-[18px]">
      <kronos-base-heading title="Dashboard">
        <kronos-base-date-range-selector
            :context-selected-dates="[$route?.query?.start_at, $route?.query?.end_at]"
            @change-range="fetchDataByDate()"
            @reload="fetchDataByDate()"
        >
        </kronos-base-date-range-selector>
      </kronos-base-heading>

      <div class="flex gap-4">
        <kronos-base-card
            class="w-1/4"
            title="Not Processed Lead"
            :total="this.counter[0] ?? 0"
            :isLoading="isLoading">
        </kronos-base-card>

        <kronos-base-card
            class="w-1/4"
            title="Started Lead"
            :total="this.counter[1] ?? 0"
            :isLoading="isLoading">
        </kronos-base-card>

        <kronos-base-card
            class="w-1/4"
            title="Accepted Lead"
            :total="this.counter[2] ?? 0"
            :isLoading="isLoading">
        </kronos-base-card>

        <kronos-base-card
            class="w-1/4"
            title="Refused Lead"
            :total="this.counter[3] ?? 0"
            :isLoading="isLoading">
        </kronos-base-card>

        <kronos-base-card
            class="w-1/4"
            title="Total Lead"
            :total="this.counter[4] ?? 0"
            :isLoading="isLoading">
        </kronos-base-card>
      </div>

      <lead-statistics-by-responsible-table
          :data="leadStatistics"
          :is-loading="isLoading"
          :total_not_processed="total_lead_not_processed"
          :total_refused="total_lead_refused"
          :total_started="total_lead_started"
      />

      <kronos-base-table
          title="Started Leads"
          :dataset="data"
          :is-loading="isLoading"
          :config="tableConfig"
          @reload="fetchDataByDate()"
          @row-click="redirectToLeadPage"
          @update-status="UpdateLeadStatus"
          @view-all-modal="openViewAllModal"
          @update-responsible="UpdateResponsible"
      />

      <kronos-base-table
          title="Leads"
          :dataset="dataset"
          :is-loading="isLoading"
          :config="tableConfig"
          :total="total"
          :btn="btn"
          @reload="fetchData()"
          @row-click="redirectToLeadPage"
          @update-status="UpdateLeadStatus"
          @update-responsible="UpdateResponsible"
          @view-all-modal="openViewAllModal"
      />

    </div>
  </kronos-base-layout>
</template>

<script>
import {mapState} from "vuex";
import KronosBaseLayout from "../../UI/Layouts/KronosBaseLayout.vue";
import KronosBaseHeading from "../../UI/BaseComponents/KronosBaseHeading.vue";
import KronosBaseDateRangeSelector from "../../UI/BaseComponents/KronosBaseDateRangeSelector.vue";
import KronosBaseTable from "../../UI/BaseComponents/KronosBaseTable.vue";
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";
import {
  LeadStatisticsByResponsible,
  LeadStatisticsByManager,
  UpdateLead,
  RetrieveLeadsByManger
} from "../../services/Lead";
import KronosBaseCard from "../../UI/BaseComponents/KronosBaseCard.vue";
import NotificationMixin from "../../mixins/NotificationMixin";
import LeadStatisticsTable from "../../components/Lead/LeadStatisticsTable.vue";
import LeadStatisticsByResponsibleTable from "../../components/Lead/LeadStatisticsByResponsibleTable.vue";
import {ManagerLeadStatisticsTable} from "../../config/TableViews/ManagerLeadStatisticsTable";
import viewAllModal from "../../components/Lead/LeadNote/modal/ViewAllModal.vue";
import NoteCard from "../../components/Lead/LeadNote/card/NoteCard.vue";
import user from "../../router/routes/user";
import {ContextUser} from "../../services/User";

export default {
  name: 'Dashboard',

  components: {
    NoteCard,
    viewAllModal,
    LeadStatisticsByResponsibleTable,
    LeadStatisticsTable,
    KronosBaseCard,
    ReloadIcon,
    KronosBaseTable,
    KronosBaseDateRangeSelector,
    KronosBaseHeading,
    KronosBaseLayout,
  },

  computed: {
    ...mapState('entities', ["NoteTypes"]),
    ...mapState(['ContextUser']),


    tableConfig() {
      return ManagerLeadStatisticsTable;
    },
  },

  mixins: [
    NotificationMixin
  ],

  data() {
    return {
      isLoading: false,
      dataset: [],
      ViewAllNotes: [],
      SelectedNote: {},
      counter: [],
      leadStatistics: [],
      total_lead_started: 0,
      total_lead_not_processed: 0,
      total_lead_refused: 0,
      modalViewAll: false,
      btn: 1,
      total: 0,
      data: [],
    }
  },

  methods: {
    ContextUser,
    user() {
      return user
    },

    async fetchData() {
      const data = this.$route.query;

      if (data?.start_at && data?.end_at && data?.page && data?.limit) {
        this.isLoading = true;
        this.dataset = [];
        await RetrieveLeadsByManger(data.page, data.limit, data.ibv, data.started)
            .then(response => {
              this.dataset = response.data.data.leads;
              this.total = response.data.data.total;
            })

        this.isLoading = false;
      }
    },

    openViewAllModal(leadId) {
      this.modalViewAll = true;
      this.$store.state.lead_id = leadId
    },
    closeViewAllModal() {
      this.ViewAllNotes = [];
      this.modalViewAll = false;
    },

    redirectToLeadPage(leadId) {
      window.location.href = `/lead/${leadId}`;
    },

    UpdateLeadStatus($event, lead_id) {
      UpdateLead(lead_id, {
        'status_id': $event.target.value
      })
          .then(response => {
            if (response.status === 200) {
              this.showNotification('success', 'Status Updated Successfully')
              this.fetchData();
              this.fetchDataByDate();
            }
          })
    },

    UpdateResponsible($event, lead_id) {
      UpdateLead(lead_id, {
        'user_id': $event.target.value
      })
          .then(response => {
            if (response.status === 200) {
              this.showNotification('success', 'Status Updated Successfully')
              this.fetchData();
              this.fetchDataByDate();
            }
          })
    },

    async fetchDataByDate() {
      const data = this.$route.query;
      if (data?.start_at && data?.end_at && data?.page && data?.limit) {
        this.isLoading = true;
        this.leadStatistics = [];
        await LeadStatisticsByManager(data.page, data.limit, data.start_at, data.end_at)
            .then(response => {
              this.data = response.data.data.leads;
              this.counter = response.data.data.total;
            })
        await LeadStatisticsByResponsible(data.start_at, data.end_at)
            .then(response => {
              this.leadStatistics = response.data.data.leads;
              this.total_lead_started = this.leadStatistics.reduce((total, stat) => total + stat.lead_started, 0);
              this.total_lead_not_processed = this.leadStatistics.reduce((total, stat) => total + stat.lead_not_processed, 0);
              this.total_lead_refused = this.leadStatistics.reduce((total, stat) => total + stat.lead_refused, 0);
            })
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchDataByDate();
  }
}
</script>