import Api from '../Api.js';

const BaseURL = 'v1/client-email';

export const RetrieveClientEmail = async (id) => {
    return await Api()
        .get(`${BaseURL}/${id}`);
}

export const RetrieveClientEmails = async (client_id) => {
    return await Api()
        .get(`${BaseURL}/client/${client_id}`);
}

export const StoreClientEmail = async (data) => {
    return await Api()
        .post(`${BaseURL}`, data);
}

export const UpdateClientEmail = async (id, data) => {
    return await Api()
        .put(`${BaseURL}/${id}`, data);
}

export const DestroyClientEmail = async (id) => {
    return await Api()
        .delete(`${BaseURL}/${id}`);
}
