<template>
    <div
        v-click-outside="close"
        class="relative"
    >
        <div @click="IsOpen = !IsOpen" class="cursor-pointer flex relative flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
            <label class="cursor-pointer text-[9.5px] text-gray-500 tracking-wide">
                {{ label }}
            </label>
            <div class="cursor-pointer border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600">
                {{ options[selected] }}
            </div>
        </div>
        <div v-if="IsOpen" class="h-fit max-h-[250px] overflow-scroll absolute top-[58px] rounded-md left-0 bg-gray-100 w-full z-50">
            <div
                v-for="(option, index) in options"
                :key="index"
                @click="$emit('update-value', index); close()"
                class="h-[42px] px-[12px] first-of-type:rounded-t-md cursor-pointer last-of-type:rounded-b-lg hover:bg-blue-400 hover:text-white duration-500 flex items-center text-gray-600 text-[11.5px]"
            >
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
export default
{
    props: {
        label: {
            type: String,
            value: 'Dropdown'
        },
        options: {
            type: Object,
            required: true,
        },
        selected: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            IsOpen: false,
        }
    },

    methods: {
        close() {
            this.IsOpen = false;
        }
    }
}
</script>
