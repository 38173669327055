<template>
    <div class="h-[100px] relative cursor-pointer mb-[24px] inline-block">
        <div
            class="custom-shadow-0 | h-full w-full bg-white rounded-[2pt]"
            :style="BorderStyle"
        >
            <div class="flex flex-wrap justify-between px-[18px] rounded-[4pt]">
                <div class="w-full text-[24px] flex items-center justify-between pt-[16px] h-[58px] font-medium leading-10 tracking-tight text-[#5A5A5A]">
                    <span class="pt-0.5">
                        {{ !isLoading ? total : 0 }}
                    </span>
                    <div class="h-[42px] w-[42px] rounded-full bg-theme-blue bg-opacity-10"></div>
                </div>
                <dt class="text-[12.5px] font-medium text-[#818181] h-[38px] w-full flex items-center">{{ title }}</dt>
            </div>
        </div>
    </div>
</template>
<script>
export default
{

    props: {
        title: {
            type: String,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
        },
        Status: {
            type: String,
            default: 'normal',
            validator: function (value) {
                return ['normal', 'behind', 'ahead'].includes(value);
            }
        }
    },

    computed: {
        BorderStyle() {
            switch (this.Status) {
                case 'behind':
                    return {
                        'border-bottom': '4px solid #ff3333'
                    };
                case 'ahead':
                    return {
                        'border-bottom': '4px solid #4db34d'
                    };
                default:
                    return {
                        'border-bottom': '4px solid #408DBC'
                    };
            }
        },
    }
}
</script>