<template>
    <kronos-base-information-card
        title="Client Emails"
        :first-page="currentPage + 1"
        :last-page="this.client_emails.length"
        :is-editing="isEditing"
        :can-next="can_next"
        :can-prev="can_prev"
        @next="handlePagination('next')"
        @prev="handlePagination('prev')"
        @submit-form="saveChanges"
        :has-action-button="false"
    >
        <div class="divide-y divide-gray-100ß">
            <div class="flex divide-x divide-gray-100">
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="email"
                    label="Email Address"
                    :has-error="errors.email"
                    v-model:value="client_email.email"
                    :placeholder="client_email.email"

                />
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="select"
                    label="Email Type"
                    :options="['WORK', 'PERSONAL', 'OTHER']"
                    :selected-value="client_email?.type ?? 'OTHER'"
                    @update-value="client_email.type = $event"
                />
            </div>
        </div>
    </kronos-base-information-card>
</template>

<script>
import KronosBaseInformationCardInput from "../../UI/BaseComponents/KronosBaseInformationCardInput.vue";
import KronosBaseInformationCard from "../../UI/BaseComponents/KronosBaseInformationCard.vue";
import {RetrieveClientEmails, UpdateClientEmail} from "../../services/Client/ClientEmail";
import NotificationMixin from "../../mixins/NotificationMixin";

export default {
    components: {
        KronosBaseInformationCard,
        KronosBaseInformationCardInput,
    },

    mixins: [
        NotificationMixin
    ],

    data() {
        return {
            currentPage: 0,
            can_prev: false,
            can_next: false,
            isEditing: false,
            isLoading: true,
            client_email: [],
            client_emails: [],
            errors: {
                email: false,
            }
        }
    },

    watch: {
        'client_email': {
            handler(newValue, oldValue) {
                if (newValue.id === oldValue.id) {
                    this.isEditing = !this.isLoading &&
                        (newValue.email !== this.client_emails[this.currentPage].email ||
                            (newValue.type).toUpperCase() !== (this.client_emails[this.currentPage].type).toUpperCase());

                    this.errors.email = !/\S+@\S+\.\S+/.test(newValue.email);

                    this.isEditing
                        ? [this.can_prev, this.can_next] = [false, false]
                        : this.checkPagination();

                    this.errors.email
                        ? [this.can_prev, this.can_next, this.errors.phone, this.isEditing] = [false, false, true, false]
                        : this.errors.email = false;

                    this.$forceUpdate();
                }
            },
            deep: true,
        },
    },

    methods: {
        saveChanges() {
            let updateData = {
                "client_id": this.client_email.client_id,
                "email": this.client_email.email,
                "type": this.client_email.type,

            };

            UpdateClientEmail(this.client_email.id, updateData)
                .then(response => {
                    this.client_emails = (updateData);
                    this.showNotification('success', "Client email updated successfully.");
                    console.log(response);
                }).catch(error => {
                console.error("Error updating client email:", error);
                this.showNotification('error', "The email doesnt exist");
                this.errors.email = true;


            })
                .finally(() => {
                    this.isEditing = false;
                });
        },

        handlePagination(step) {
            step === 'next'
                ? this.currentPage += 1
                : this.currentPage -= 1;

            this.checkPagination()

            this.client_email = JSON.parse(JSON.stringify(this.client_emails[this.currentPage]));
        },

        checkPagination() {
            this.can_next = this.currentPage + 1 < this.client_emails.length
            this.can_prev = this.currentPage > 0;

            this.$forceUpdate();
        },

        fetchData() {
            this.isLoading = true;

            RetrieveClientEmails(this.$route.params.id)
                .then((response) => {
                    this.client_emails = response.data.data.client_emails;
                    this.client_email = JSON.parse(JSON.stringify(this.client_emails[0]));
                    this.can_next = this.client_emails.length > 0;
                })
                .finally(() => {
                    this.isLoading = false;
                })
        }
    },

    mounted() {
        this.fetchData();

    },

}
</script>