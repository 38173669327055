export default {
	methods: {
		showNotification(type, message) {
			this.$store.commit('addNotification', { type, message });

			setTimeout(() => {
				this.removeNotification();
			}, 5000);
		},

		removeNotification() {
			this.$store.commit('removeNotification');
		},
	},
};