<template>
	<div>
		<transition
			enter-active-class="ease-out duration-500"
			enter-from-class="opacity-0 scale-95"
			enter-to-class="opacity-100 scale-100"
			leave-active-class="ease-in duration-500"
			leave-from-class="opacity-100 scale-100"
			leave-to-class="opacity-0 scale-95"
		>
			<div
				v-if="IsLoading"
				class="bg-white h-screen w-screen flex flex-col gap-10 items-center justify-center absolute top-0 left-0 z-[50] | dark:bg-black"
			>
				<loader-animation
					border="4px solid black"
				/>
				<span class="gradient-text | absolute bottom-0 text-gray-800 tracking-tight text-xl mb-[24px] font-medium w-fit">
					<div class="gradient-bg-1 | rounded-full h-[2px] w-2/3"></div>
					KronosLMS
					<div class="gradient-bg-1 | rounded-full h-[2px] w-5/12 float-right"></div>
				</span>
			</div>
		</transition>
	</div>
</template>

<script>
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import {mapState} from "vuex";

export default
{
	components: {
		LoaderAnimation
	},

	computed: {
		...mapState([
			'IsLoading'
		]),
	}
}
</script>

<style scoped>
.gradient-text {
	background: linear-gradient(90deg, black, #94a3b8);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

.gradient-bg-1 {
	background: linear-gradient(90deg, black, #94a3b8);
}
</style>
