<template>
	<div
		:class="SideNavigationIsOpen ? 'px-[10px]' : 'px-[10.5px]'"
		class="group w-full relative"
	>
        <div
            v-if="isActive && !disableActiveState"
            class="h-full w-[5px] bg-[#4667d2] absolute rounded-r left-0"
        />
		<a
			v-if="!preventClick"
			:href="routingTo"
			:class="SideNavigationIsOpen ? 'w-full justify-start px-[10px]' : 'w-[37px] justify-start'"
			class="hover:rounded-md hover:border-[#c1ccf0] duration-500 hover:bg-light-dark-blue border flex items-center border-transparent h-[37px] cursor-pointer relative"
		>
			<div
				:class="[
					SideNavigationIsOpen ? 'w-[29px] pr-[10px]' : 'w-[19px] ml-[6.5px]',
					'cursor-pointer flex items-center text-white'
				]"
			>
				<slot />
			</div>
			<p
				v-if="SideNavigationIsOpen"
				class="duration-300 tracking-wide cursor-pointer text-[10.5px] truncate text-[#E7E7E7] group-hover:text-[#4667d2] font-normal"
			>
				{{ label }}
            </p>
		</a>
		<div
			v-else
			:class="SideNavigationIsOpen ? 'w-full justify-start px-[10px]' : 'w-[37px] justify-start'"
			class="hover:rounded-md hover:border-[#c1ccf0] duration-500 hover:bg-light-dark-blue border flex items-center border-transparent h-[37px] cursor-pointer"
		>
			<div
				:class="[
					SideNavigationIsOpen ? 'w-[29px] pr-[10px]' : 'w-[19px] ml-2',
					'cursor-pointer flex items-center text-white'
				]"
			>
				<slot />
			</div>
			<p
				v-if="SideNavigationIsOpen"
				class="duration-300 tracking-wide cursor-pointer text-[10.5px] truncate text-[#E7E7E7] group-hover:text-[#4667d2] font-normal"
			>
				{{ label }}
			</p>
		</div>
	</div>
</template>

<script>
import TransferDuoIcon from "../../assets/icons/duotone/TransferDuoIcon.vue";
import {mapState} from "vuex";

export default
{
    components: {
        TransferDuoIcon
    },

	props: {
		label: {
			type: String,
			required: true,
			default: 'label',
		},
		routingTo: {
			type: String,
			required: false,
			default: '/',
		},
		preventClick: {
			type: Boolean,
			default: false,
		},
		disableActiveState: {
			type: Boolean,
			default: false,
		}
	},

    computed: {
        isActive() {
            return this.$route.path === this.routingTo;
        },

	    ...mapState([
		    'SideNavigationIsOpen'
	    ]),
    }
}
</script>
