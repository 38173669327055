<template>
    <div
        :class="[
            calendar_folder_is_open ? '' : 'translate-x-[100%]',
            SideNavigationIsOpen ? 'full-width-1' : 'full-width-2'
        ]"
        class="custom-height custom-shadow-1 | rounded-tl-[12px] fixed bottom-0 bg-white right-0 z-10 duration-500"
    >

    </div>

    <div
        :class="SideNavigationIsOpen ? 'left-[225px]' : 'left-[58px]'"
        class="custom-height-1 w-[86px] z-[3] top-[52px] fixed duration-500 bg-white"
    >
        <div
            v-if="!!this.client"
            class="flex flex-col w-full items-center h-full"
        >
            <div class="py-[28px] border-b border-gray-100 w-full bg-[#FBFBFB] flex items-center justify-center">
                <div
                    class="absolute left-[0px] w-[5px] h-[28px] rounded-r-[2pt] bg-theme-blue"
                ></div>
                <a :href="`/client/${this.client.id}`" class="h-[52px] w-[52px] flex items-center justify-center rounded-full bg-white custom-shadow-1">
                    <div class="h-[42px] w-[42px] flex items-center justify-center rounded-full border border-opacity-50 border-gray-200 bg-[#F2F2F2]">
                        <p class="text-[#595959] font-semibold text-[15px]">{{ !!this.client ? this.client?.first_name[0] + this.client?.last_name[0] : '' }}</p>
                    </div>
                </a>
            </div>
            <div class="py-[12px] flex flex-col gap-[16px]">
                <a
                    v-for="(Lead, Index) in leads"
                    :key="Index"
                    :href="`/lead/${Lead.id}`"
                    class="relative group"
                >
                    <div class="custom-shadow-1 | hidden group-hover:flex flex-col gap-3 absolute rounded-[4pt] px-[16px] py-[10px] left-[78px] top-[-60%] w-[225px] h-fit bg-white">
                        <div class="flex flex-col gap-[-1px]">
                            <p class="text-[10px] tracking-wide font-semibold text-gray-600">{{ `${Lead.lender.name} - ${Lead.amount} $` }}</p>
                            <p class="text-[10px] -mt-0.5 tracking-wide text-gray-500">{{ `${FormatDate(Lead.created_at)}` }}</p>
                        </div>

                        <div class="flex flex-col gap-0.5">
                            <p class="text-[10px] tracking-wide font-semibold text-gray-700">
                                Client :
                                <span class="font-medium text-gray-500">
                                    {{ `${Lead.client.first_name} ${Lead.client.last_name}` }}
                                </span>
                            </p>
                            <p class="text-[10px] tracking-wide font-semibold text-gray-700">
                                Responsible :
                                <span class="font-medium text-gray-500">
                                    {{ `${Lead.user.first_name} ${Lead.user.last_name}` }}
                                </span>
                            </p>
                            <p class="text-[10px] tracking-wide font-semibold text-gray-700">
                                Source :
                                <span class="font-medium text-gray-500">
                                    {{ `Loans Canada` }}
                                </span>
                            </p>
                            <p class="text-[10px] tracking-wide font-semibold text-gray-700">
                                Status :
                                <span class="font-medium text-gray-500">
                                    {{ Lead?.lead_status?.lead_status_name ?? "..." }}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="group-hover:w-[5px] duration-300 absolute left-[-17px] w-[0px] h-[36px] mt-[8px] bg-opacity-50 rounded-r-[2pt] bg-theme-blue"></div>
                    <div class="custom-shadow-1 | rounded-[4pt] flex items-center justify-center w-[52px] h-[52px]">
                        <div class="w-[44px] h-[44px] bg-[#F2F2F2] flex items-center justify-center rounded-[4pt] border border-opacity-50 border-gray-200">
                            <img
                                class="h-[22px] w-[22px]"
                                :src="BaseURL + Lead.lender.image_src"
                                alt="img"
                            >
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <kronos-base-layout>
        <div class="flex pb-[44px] flex-row pl-[86px] gap-5 max-w-full mx-auto">
            <div class="flex flex-col gap-4 w-screen fit">
                <div class="relative mt-[40px]">
                    <div>
                        <div class="absolute top-[-40px] flex bg-white rounded-[4pt] rounded-bl-none items-center justify-center h-[92px] w-[92px] shadow-custom-shadow left-0 z-10">
                            <div class="h-[82px] w-[82px] rounded-[4pt] top-0 left-0 bg-[#F2F2F2] border-opacity-75 border gray-200 flex items-center justify-center">
                                <img
                                    class="h-[40px] w-[40px]"
                                    src="../assets/images/user-avatar-32.png" height="256" width="256"
                                    alt="img"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="rounded-md bg-white h-fit w-full top-[55px] z-0 shadow-custom-shadow">
                        <div class="grid grid-cols-2 h-[55px] border-b border-gray-100">
                            <div class="ml-[110px] pl-[12pt] h-full">
                                <h3 class="text-md font-medium text-gray-900 h-full flex flex-wrap content-center">
                                    {{client?.first_name}} {{client?.last_name}}
                                </h3>
                            </div>
                            <div class="h-full ml-auto">
                                <button
                                    type="button"
                                    class="h-full w-[55px] inline-flex justify-center content-center items-center rounded-tr-lg bg-white border-l border-gray-100"
                                >
                                    <ThreeDotIcon
                                        size="16px"
                                        class="fill-gray-500"
                                    />
                                </button>
                            </div>
                        </div>
                        <div class="bg-gray-200 bg-opacity-40 border-opacity-25 gap-[12pt] py-[12pt] px-[12pt] h-auto w-full flex items-center justify-between sm:justify-center">
                            <kronos-base-active-loans-data-card title="Active Loans"/>
                            <kronos-base-data-card title="Upcoming Payments"/>
                            <kronos-base-data-card title="Bank Accounts"/>
                        </div>
                    </div>
                </div>

                <client-payment-calendar-table
                    @open-folder="calendar_folder_is_open = !calendar_folder_is_open"
                    class="w-full h-fit"
                    :dataset="payment_calendars"
                />
            </div>
        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../UI/Layouts/KronosBaseLayout.vue";
import {mapState} from "vuex";
import {RetrieveClient} from "../services/Client/Client";

import KronosBaseDataCard from "../UI/BaseComponents/KronosBaseDataCard.vue";
import SendArrowIcon from "../assets/icons/SendArrowIcon.vue";
import ReloadIcon from "../assets/icons/ReloadIcon.vue";
import KronosBaseDropdown from "../UI/BaseComponents/KronosBaseDropdown.vue";
import KronosBaseChatOutput from "../UI/BaseComponents/KronosBaseChatOutput.vue";
import KronosBaseChatInput from "../UI/BaseComponents/KronosBaseChatInput.vue";
import ThreeDotIcon from "../assets/icons/ThreeDotIcon.vue";
import KronosBaseInformationCard from "../UI/BaseComponents/KronosBaseInformationCard.vue";
import KronosBaseInformationCardInput from "../UI/BaseComponents/KronosBaseInformationCardInput.vue";
import ClientEmailInformationCard from "../components/Client/ClientEmailInformationCard.vue";
import ClientAddressInformationCard from "../components/Client/ClientAddressInformationCard.vue";
import ClientPhoneInformationCard from "../components/Client/ClientPhoneInformationCard.vue";
import KronosBaseLeadSideNavigation from "../UI/Navigation/KronosBaseLeadSideNavigationForClientPage.vue";
import LeadPage from "./LeadPage.vue";
import KronosBaseActiveLoansDataCard from "../UI/BaseComponents/KronosBaseActiveLoansDataCard.vue";

import KronosBaseLeadSideNavigationForClientPage from "../UI/Navigation/KronosBaseLeadSideNavigationForClientPage.vue";
import {RetrieveClientBankAccounts} from "../services/Client/ClientBankAccount";
import {RetrieveLeads} from "../services/Lead";
import {RetrieveLoanCalendarPayment} from "../services/LeadCalendar/LoanPaymentCalendar/LoanPaymentCalendar";
import ClientPaymentCalendarTable from "../UI/Tables/ClientPaymentCalendarTable.vue";

export default
{
    components: {
        ClientPaymentCalendarTable,
        ClientAddressInformationCard,
        ClientEmailInformationCard,
        ClientPhoneInformationCard,
        KronosBaseLeadSideNavigationForClientPage,
        LeadPage,
        KronosBaseLeadSideNavigation,
        KronosBaseInformationCardInput,
        KronosBaseInformationCard,
        ThreeDotIcon,
        KronosBaseChatInput,
        KronosBaseChatOutput,
        KronosBaseDropdown,
        ReloadIcon,
        SendArrowIcon,
        KronosBaseDataCard,
        KronosBaseLayout,
        KronosBaseActiveLoansDataCard,
    },

    data() {
        return {
            client: null,
            leads: [],
            bankAccounts: [],
            loanPayments : [],
            payment_calendars: [],
            calendar_folder_is_open: false,
        }
    },

    computed: {
        ...mapState([
            'ContextUser',
            'SideNavigationIsOpen'
        ]),

        BaseURL() {
            return import.meta.env.VITE_BACKEND_BASE_URL + '/storage';
        },
    },

    methods: {
        async fetchData(client_id) {
            await RetrieveClientBankAccounts(client_id)
                .then(response => {
                    this.bankAccounts = response.data.data.client_bank_accounts;
                })
            const query = `client_id=${client_id}`;

            await RetrieveLeads(query, 1,50)
                .then(response => {
                    this.leads = response.data.data.leads;
                })

            await RetrieveClient(client_id)
                .then((response) => {
                    this.client = response.data.data.client;
                })
        },

        FormatDate(inputDateTime) {
            const dateTime = new Date(inputDateTime);

            const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

            const month = monthNames[dateTime.getMonth()];
            const day = dateTime.getDate();
            const year = dateTime.getFullYear();

            const formattedDay = (day < 10 ? '0' : '') + day;

            return month + " " + formattedDay + " " + year;
        },

        async FetchPaymentCalendars() {
            for (const lead of this.leads) {
                this.payment_calendars.push(await RetrieveLoanCalendarPayment(lead.id));
            }
        }
    },

    async mounted() {
        await this.fetchData(this.$route.params.client_id);
        await this.FetchPaymentCalendars();
    }
}
</script>

<style scoped>
.custom-height-1 {
    height: calc(100vh - 52px);
}

.custom-scrollbar::-webkit-scrollbar
{
    width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
    background-color: lightgray;
}

.custom-scrollbar::-webkit-scrollbar-track
{
    background-color: transparent;
}

.custom-height
{
    height: calc(100vh - 52px - 16px);
}

.full-width-1
{
    width: calc(100vw - 86px - 225px - 16px) !important;
}

.full-width-2
{
    width: calc(100vw - 86px - 52px - 16px) !important;
}
</style>