import api from './Api';

const BaseURL = 'v1/flag-as-fraud';

export const RetrieveFlagAsFraudByEmail = async (email) =>
{
    return await api()
        .get(`${BaseURL}/${email}`)
}

export const UpdateOrStoreFlagAsFraud = async (data) =>
{
    return await api()
        .post(`${BaseURL}`, data)
}
