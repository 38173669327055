<template>
    <div class="cursor-pointer min-h-[45px] w-full flex gap-2.5 bg-white items-center justify-end">
        <button
            @click="$emit('openDenyModal')"
            class="hover:bg-red-600 hover:text-red-100 h-[32px] px-[32px] rounded-md cursor-pointer text-[11.5px] tracking-wide bg-red-100 text-red-700 hover:bg-opacity-100 duration-500"
        >
            Deny Request
        </button>
        <button
            v-if="allSectionsAreCheckedAndFilled"
            @click="$emit('openApproveModal')"
            class="group flex items-center hover:text-white bg-opacity-10 justify-center rounded-md hover:bg-opacity-90 bg-blue-600 text-blue-600 h-[32px] px-[24px] cursor-pointer text-[11.5px] tracking-wide duration-500"
        >
            Approve
        </button>
    </div>
</template>

<script>
export default {
    props: {
        allSectionsAreCheckedAndFilled: Boolean,
    },
    emits: ['openDenyModal', 'openApproveModal'],
};
</script>
