<template>
    <div class="flex flex-col gap-[16px]">
        <div class="flex gap-[16px]">
            <div class="bg-white w-full h-[34px] rounded-[2pt] custom-shadow-0 flex items-center">
                <div class="w-[55px] bg-gray-100 text-[#595959] text-[12.5px] text-opacity-90 font-medium tracking-wide h-full rounded-l-[2pt] flex items-center justify-center">10</div>
                <span class="text-[10.5px] pt-0.5 tracking-wide pl-[16px] text-[#5A5A5A] font-medium text-opacity-90">Waiting Documents</span>
            </div>
            <div class="bg-white w-full h-[34px] rounded-[2pt] custom-shadow-0 flex items-center">
                <div class="w-[55px] bg-gray-100 text-[#595959] text-[12.5px] text-opacity-90 font-medium tracking-wide h-full rounded-l-[2pt] flex items-center justify-center">10</div>
                <span class="text-[10.5px] pt-0.5 tracking-wide pl-[16px] text-[#5A5A5A] font-medium text-opacity-90">Missing IBV</span>
            </div>
            <div class="bg-white w-full h-[34px] rounded-[2pt] custom-shadow-0 flex items-center">
                <div class="w-[55px] bg-gray-100 text-[#595959] text-[12.5px] text-opacity-90 font-medium tracking-wide h-full rounded-l-[2pt] flex items-center justify-center">14</div>
                <span class="text-[10.5px] pt-0.5 tracking-wide pl-[16px] text-[#5A5A5A] font-medium text-opacity-90">Waiting Verification</span>
            </div>
            <div class="bg-white w-full h-[34px] rounded-[2pt] custom-shadow-0 flex items-center">
                <div class="w-[55px] bg-gray-100 text-[#595959] text-[12.5px] text-opacity-90 font-medium tracking-wide h-full rounded-l-[2pt] flex items-center justify-center">123</div>
                <span class="text-[10.5px] pt-0.5 tracking-wide pl-[16px] text-[#5A5A5A] font-medium text-opacity-90">Waiting Signature</span>
            </div>
        </div>

        <div
            class="flex gap-[16px]"
            @click="$emit('change', (SelectedCardStats === 'status' ? 'statistics' : 'status'))"
        >
            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Total' : 'Avg. Time'"
                :total="(SelectedCardStats === 'status' ? this.counter[0] : this.counter[1])"
                :isLoading = "IsLoading"
                :Status="SelectedCardStats === 'status' ? 'normal' : 'behind'"
            >
            </kronos-base-card>
            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Not Assigned' : 'Lead Completed'"
                :total="(SelectedCardStats === 'status' ? this.counter[2] : this.counter[3])"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>

            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Started' : 'Avg. Lend'"
                :total="(SelectedCardStats === 'status' ? this.counter[4] : this.counter[5] + '$')"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>

            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Accepted' : 'New Clients'"
                :total="(SelectedCardStats === 'status' ? this.counter[6] : this.counter[7])"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>

            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Denied' : 'Returning Client'"
                :total="(SelectedCardStats === 'status' ? this.counter[8] : this.counter[9])"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>
        </div>
    </div>
</template>

<script>
import KronosBaseCard from "../../../UI/BaseComponents/KronosBaseCard.vue";

export default
{
    emits: [
        'change',
    ],

    components: {
        KronosBaseCard
    },

    props: {
        SelectedCardStats: {
            type: String,
            default: 'status',
            validator: function (value) {
                return ['status', 'statistics'].includes(value);
            }
        },
        counter: {
            type: Array,
            default: [0,0,0,0,0,0,0,0,0,0],
        },
        IsLoading: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
