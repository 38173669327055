<template>
    <form @submit.prevent="SubmitUpdatePassword">
        <div class="flex flex-col -mt-1 gap-[2px] mb-[24px] justify-center items-center w-full">
            <h1 class="flex tracking-wide text-[26px] font-medium text-[#212529]">
                Create New Password
            </h1>
            <span class="text-sm text-gray-500 text-opacity-75 text-center -mt-0.5 tracking-wider">
                Enter a new password below. Make sure it's strong and secure
            </span>
        </div>
        <div class="w-full flex flex-col gap-4 mt-2">
            <div class="flex w-full flex-col gap-4">
                <kronos-auth-input
                    v-model:value="this.Form.Password"
                    :error="Errors.Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                />
                <kronos-auth-input
                    v-model:value="this.Form.PasswordConfirmation"
                    :error="Errors.Password"
                    name="password-confirmation"
                    placeholder="Password Confirmation"
                    type="password"
                />
                <button type="submit" class="bg-[#212529] px-[24px] font-medium text-white h-[52px] text-sm rounded-md bg-opacity-90 hover:bg-opacity-100 duration-500">
                    Save Password
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import KronosAuthInput from "../../../UI/BaseComponents/Inputs/KronosAuthInput.vue";
import { UpdatePassword } from "../../../services/ForgotPassword";
import NotificationMixin from "../../../mixins/NotificationMixin";

export default
{
    components: {
        KronosAuthInput
    },

    mixins: [
        NotificationMixin,
    ],

    props: {
        IsLoading: {
            type: Boolean,
            default: false,
        },
        Email: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            Form: {
                Password: null,
                PasswordConfirmation: null,
            },
            Errors: {
                Password: null,
            }
        }
    },

    methods: {
        SubmitUpdatePassword() {
            if (!this.IsLoading && !this.CheckAndResetPassword()) {
                this.$emit('loading', true);

                UpdatePassword(this.Email, this.Form.Password, this.Form.PasswordConfirmation)
                    .then((response) => {
                        if (response.status === 200) {
                            this.showNotification('success', 'Password successfully Updated')

                            setTimeout(() => {
                                this.$router.push('/login');
                            }, 1500);
                        }
                    })
                    .catch((error) => {
                        this.Errors.Password = 'Invalid Password';

                        if (error.response.status === 500) {
                            this.showNotification('An Error Occurred While Updating Your Password');
                        }
                    })
                    .finally(() => {
                        this.$emit('loading', false);
                    })
            }
        },

        CheckAndResetPassword() {
            this.Errors.Password = null;

            if (this.Form.Password === this.Form.PasswordConfirmation) {
                if (!this.Form.Password || !this.Form.PasswordConfirmation) {
                    this.Errors.Password = "Invalid Password";
                } else if (this.Form.Password.length < 8) {
                    this.Errors.Password = "Password must at least be 8 characters"
                } else if (this.Form.Password.length > 20) {
                    this.Errors.Password = "Password must be less then 20 characters"
                } else if (!/[A-Z]/.test(this.Form.Password)) {
                    this.Errors.Password = "Password must contain a capital letter.";
                } else if (!/[!@#$%]/.test(this.Form.Password)) {
                    this.Errors.Password = "Password must contain at least one special character: !@#$%^";
                }
            } else {
                this.Errors.Password = "Password doesn't match";
            }

            return !!this.Errors.Password;
        },
    }
}
</script>
