export const IbvReportTable = [
	{
		'label': 'ID',
		'key': 'id',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'NAME',
		'key': 'flinks_accounts.0.flinks_account_holder.name',
		'type': 'string',
		'min_w': '300px',
	},
	{
		'label': 'EMAIL',
		'key': 'flinks_accounts.0.flinks_account_holder.email',
		'type': 'string',
		'min_w': '300px',
	},
	{
		'label': 'INSTITUTION',
		'key': 'institution',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'FLINKS ID',
		'key': 'flinks_login_id',
		'type': 'string',
		'min_w': '400px',
	},
	{
		'label': 'CREATED AT',
		'key': 'created_at',
		'type': 'date',
		'min_w': '200px',
	},
	{
		'label': 'UPDATED AT',
		'key': 'updated_at',
		'type': 'date',
		'min_w': '200px',
	},
];
