<template>
	<span
		class="loader"
		:style="`height: ${size}; width: ${size}; border: ${border}`"
	></span>
</template>

<script>
export default
{
	props: {
		size: {
			type: String,
			default: '48px',
		},
		border: {
			type: String,
			default: '3px solid #FFF',
		}
	}
}
</script>

<style scoped>
.loader {
	border-bottom-color: transparent !important;
	border-radius: 50% !important;
	display: inline-block !important;
	box-sizing: border-box !important;
	animation: rotation 1s linear infinite !important;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>