import Api from "./Api";   

const BaseURL = 'automation';

export const ImportTransactions = async ($data) =>
{
    return await Api()
        .post(`${BaseURL}/ml/import-transactions`, $data);
}

export const RetrieveTransactions = async (transaction_type_id = 'all', limit, page = 1) =>
{
    return await Api()
        .get(`${BaseURL}/ml/retrieve-transactions?limit=${limit}&transaction_type_id=${transaction_type_id}&page=${page}`)
}

export const UpdateTransaction = async (transaction_type_id, transaction_id) =>
{
    return await Api()
        .post(`${BaseURL}/ml/update-transaction`, {
            transaction_type_id: transaction_type_id,
            transaction_id: transaction_id
        });
}

export const UpdateTransactions = async (transactions, transaction_type_id) =>
{
    return await Api()
        .post(`${BaseURL}/ml/update-transactions`, {
            transaction_type_id: transaction_type_id,
            transactions: transactions
        });
}
