export const LeadDispatcherTable = [
	{
		'label': 'Name',
		'key': 'first_name',
		'type': 'string',
		'min_w': '250px',
	},
	{
		'label': 'Last Name',
		'key': 'last_name',
		'type': 'string',
		'min_w': '250px',
	},
	{
		'label': 'Province',
		'key': 'state',
		'type': 'string',
		'min_w': '250px',
	},
	{
		'label': 'Email',
		'key': 'email_address',
		'type': 'string',
		'min_w': '250px',
	},
	{
		'label': 'Lead Type',
		'key': 'lead_status',
		'type': 'custom',
		'min_w': '100px',
		'max_w': '150px',
		'custom': {
			'RECURRING': 'text-warning-text bg-warning-bg font-medium',
			'NEW': 'text-successText bg-successBg font-medium',
		}
	},
	{
		'label': 'Source',
		'key': 'source',
		'type': 'string',
		'min_w': '250px',
	},
	{
		'label': 'Amount',
		'key': 'amount',
		'type': 'string',
		'min_w': '250px',
		'suffix': '$',
	},
	{
		'label': 'Price',
		'key': 'sold.price',
		'type': 'string',
		'min_w': '250px',
		'suffix': '$',
	},
	{
		'label': 'Sold To',
		'key': 'sold.account.name',
		'type': 'string',
		'min_w': '250px',
	},
	{
		'label': 'Created At',
		'key': 'created_at',
		'type': 'date',
		'min_w': '250px'
	},
	{
		'label': 'Sold At',
		'key': 'sold.created_at',
		'type': 'date',
		'min_w': '250px',
	}
];