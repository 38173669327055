<template>
    <div class="bg-white rounded-[4pt] w-full h-fit divide-y divide-[#F2F2F2] mb-[50px]">
        <div class="h-[45px] flex items-center justify-between pl-[24px]">
            <div class="text-[#6E6E6E] text-[13.5px] tracking-wide pt-0.5">Leads</div>
        </div>
        <div @scroll="HandleScroll" ref="table" class="flex w-full relative h-fit overflow-scroll">
            <div
                :class="[IsScrolling ? 'border-transparent' : 'border-gray-100', 'border-r sticky left-0 h-[750px] z-50 min-w-[253.5px] w-[253.5px]']"
                class="sticky left-0 padding-0 margin-0 z-50 min-w-[253.5px] w-[253.5px]"
            >
                <div class="flex h-full w-full">
                    <table class="overflow-scroll bg-white w-full border-r border-gray-100 relative">
                        <thead>
                            <tr class="max-h-[38px] h-[38px] divide-x divide-gray-100">
                                <th class="sticky top-0 bg-white">
                                    <div class="border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[36px] text-[#6E6E6E] text-[11.5px]">
                                        <div
                                            :class="['w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm bg-gray-200 shadow-sm transition-colors']"
                                        ></div>
                                    </div>
                                </th>
                                <th class="sticky top-0 bg-white">
                                    <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center w-[76px] text-[#6E6E6E] text-[11.5px]">
                                        Lender
                                    </div>
                                </th>
                                <th class="sticky top-0 bg-white">
                                    <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center w-[139px] text-[#6E6E6E] text-[11.5px]">
                                        Clients
                                    </div>
                                </th>
                            </tr>
                            <tr class="max-h-[46px] h-[46px] min-h-[46px] divide-x divide-gray-100">
                                <td class="sticky top-[38px] bg-white min-w-[36px] w-[36px]">
                                    <div class="flex items-center h-[46px] justify-center border-b border-gray-100"></div>
                                </td>
                                <td class="sticky top-[38px] bg-white min-w-[76px] w-[76px]">
                                    <div class="flex items-center h-[46px] justify-center border-b border-gray-100"></div>
                                </td>
                                <td class="sticky top-[38px] bg-white min-w-[139px] w-[139px]">
                                    <div class="flex items-center h-[46px] justify-center border-b border-gray-100">
                                        <input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" />
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody class="divide-[#F2F2F2] divide-y">
                            <tr v-for="FixedColumnItem in dataset" class="h-[76px] divide-x divide-[#F2F2F2] max-h-[76px]">
                                <td class="min-w-[36px] w-[36px]"><div class="flex items-center justify-center">
                                    <div
                                        :class="['w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm bg-gray-200 shadow-sm transition-colors']"
                                    ></div>
                                </div></td>
                                <td class="min-w-[76px] w-[76px]"><div class="flex items-center justify-center"></div></td>
                                <td class="min-w-[139px] w-[139px]"><div class="flex items-center justify-center">item 1</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                v-show="IsScrolling"
                style="z-index: 1000"
                class="sticky left-[253.5px] max-w-[10px] top-0 w-[10px] min-w-[10px] h-[750px] bg-gradient-to-r from-gray-200 to-transparent"
            ></div>
            <div class="overflow-scroll">
                <table class="overflow-scroll bg-white w-full z-10 absolute top-0 max-w-full h-fit">
                    <thead>
                        <tr class="max-h-[38px] h-[38px] divide-x divide-[#F2F2F2]">
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[115px] text-[#6E6E6E] text-[11.5px]">Contact</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[115px] text-[#6E6E6E] text-[11.5px]">Status</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 4</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 5</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 6</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 7</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 8</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 9</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 10</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 10</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 11</div></th>
                            <th class="sticky top-0 bg-white"><div class="font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[225px] text-[#6E6E6E] text-[11.5px]">Col 11</div></th>
                        </tr>
                        <tr class="h-[46px] divide-x divide-[#F2F2F2] max-h-[46px]">
                            <td class="min-w-[115px] w-[115px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[115px] w-[115px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="min-w-[225px] w-[225px] sticky top-[38px] bg-white"><div class="flex items-center h-[46px] justify-center border-b border-gray-100"><input type="text" class="bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                        </tr>
                    </thead>
                    <tbody class="divide-[#F2F2F2] divide-y">
                        <tr v-for="Item in dataset" class="h-[76px] divide-x divide-[#F2F2F2] max-h-[76px]">
                            <td><div class="flex items-center justify-center">item 1</div></td>
                            <td><div class="flex items-center justify-center">item 2</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                            <td><div class="flex items-center justify-center">item 3</div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default
{
    props: {
        dataset: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            IsScrolling: false,
        }
    },

    methods: {
        HandleScroll() {
            this.IsScrolling = this.$refs.table.scrollLeft > 0;
        }
    },
}
</script>

<style scoped>
td > div,
th > div {
    padding: 5px
}

td,
th {
    padding: 0
}
</style>
