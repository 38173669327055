<template>
    <div class="flex items-center justify-center w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-50 z-50">
        <div class="w-[300px] h-fit bg-white rounded-[4pt] -mt-[100px]">
            <header class="h-[43px] flex pl-[12px] items-center border-b border-gray-100 justify-between">
                <span class="mt-0.5 text-[12.5px] text-gray-600">
                    Buy Phone Number
                </span>
                <button
                    class="border-l border-gray-100 w-[42px] flex items-center justify-center h-[42px]"
                >
                    <plus-icon
                        color="gray"
                        size="14px"
                    />
                </button>
            </header>
            <div class="flex items-center justify-center py-[12px]">
                <ol class="text-[11.5px] text-gray-500 flex flex-col gap-2">
                    <li
                        v-for="(Phone, Index) in PhoneOptions"
                        :key="Index"
                        class="flex gap-[10px] items-center justify-center"
                    >
                        <div
                            @click="this.SelectedPhoneOption = Index"
                            :class="[
                                    'w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm shadow-sm transition-colors',
                                    this.SelectedPhoneOption === Index ? 'bg-theme-blue' : 'bg-gray-200',
                                ]"
                        ></div>
                        {{ Phone }}
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import PlusIcon from "../../assets/svg/PlusIcon.vue";

export default
{
    components: {PlusIcon},
    data() {
        return {
            ConfirmPurchaseModalIsOpen: false,
            PhoneOptions: ['+1 (514) 123-1234', '+1 (514) 123-1234', '+1 (514) 123-1234', '+1 (514) 123-1234'],
            SelectedPhoneOption: 0,
        }
    }
}
</script>

<style scoped>

</style>