<template>
    <div class="flex items-center justify-center w-screen h-screen bg-gray-50 ">
        <div class="custom-shadow-1 | rounded-[12px] max-w-[1280px] w-full h-fit bg-white ">
            <div class="flex items-center pl-[18px] justify-between h-[50px]">
                <p class="text-sm text-gray-600">Leads</p>
                <div>
                    <div
                        @click="IsOpen = !IsOpen"
                        class="w-[50px] flex items-center justify-center h-[50px] cursor-pointer"
                    >
                        <chevron-left-duo-icon
                            :class="[{ 'rotate-[-90deg]': IsOpen }, 'duration-500 fill-gray-500']"
                            size="14px"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="IsOpen"
                class="overflow-x-auto border-t border-gray-100"
            >
                <table class="custom-table | border-0" cellpadding="0" cellspacing="0">
                    <thead>
                        <tr>
                            <td
                                v-for="(header, index) in sortedHeaders"
                                :key="index"
                                :class="[{ 'sticky-left': header.is_fixed }, 'h-[40px] bg-white']"
                                :style="{ 'min-width': header.width, left: calculateLeftPosition(index - 1) + 'px' }"
                            >
                                <div class="border-gray-100 text-[10.5px] flex items-center justify-center border-r border-b w-full h-full p-[8px]">
                                    <div v-if="header.type !== 'checkbox'">
                                        {{ header.label }}
                                    </div>
                                    <div v-else class="flex items-center justify-center">
                                        <input type="checkbox" class="rounded-[2px] cursor-pointer w-[14px] h-[14px]"/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                v-for="(header, index) in sortedHeaders"
                                :key="index"
                                :class="{ 'sticky-left': header.is_fixed }"
                                class="bg-white h-[50px]"
                                :style="{ 'min-width': header.width, left: calculateLeftPosition(index - 1) + 'px' }"
                            >
                                <div class="border-b border-gray-100 border-r w-full h-full p-[8px] flex items-center justify-center">
                                    <input
                                        :class="[header.type === 'checkbox' ? 'hidden' : 'w-full h-full', 'bg-gray-100 px-[12px] rounded-[4px]']"
                                        :type="header.type"
                                        v-model="filters[header.key]"
                                        placeholder="Filter..."
                                    >
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, rowIndex) in filteredRows" :key="rowIndex">
                            <td v-for="(header, index) in sortedHeaders" :key="index"
                                :class="[{ 'sticky-left': header.is_fixed }, 'bg-white', 'text-center h-[65px]']"
                                :style="{ 'min-width': header.width, left: calculateLeftPosition(index - 1) + 'px' }"
                            >
                                <div class="w-full h-full border-r border-b border-gray-100 text-[10.5px] flex items-center justify-center">
                                    <div v-if="header.type !== 'checkbox'">
                                        {{ row[header.key] }}
                                    </div>
                                    <div v-else class="flex items-center justify-center">
                                        <input type="checkbox" class="cursor-pointer w-[14px] h-[14px]"/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="h-[45px]">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronLeftDuoIcon from "../assets/icons/duotone/ChevronLeftDuoIcon.vue";

export default
{
    components: {
        ChevronLeftDuoIcon
    },

    data() {
        return {
            IsOpen: true,
            headers: [
                { type: 'checkbox', label: '', key: '', width: '40px', is_fixed: true, fixed_order: 1 },
                { type: 'text', label: 'First Name', key: 'first_name', width: '200px', is_fixed: true, fixed_order: 1 },
                { type: 'text', label: 'Last Name', key: 'last_name', width: '200px', is_fixed: true, fixed_order: 2 },
                { type: 'text', label: 'Age', key: 'age', width: '100px', is_fixed: false, fixed_order: 3 },
                { type: 'text', label: 'Email', key: 'email', width: '300px', is_fixed: false, fixed_order: 4 },
                { type: 'text', label: 'Address', key: 'address', width: '400px', is_fixed: false, fixed_order: 5 },
                { type: 'text', label: 'City', key: 'city', width: '200px', is_fixed: false, fixed_order: 6 },
                { type: 'text', label: 'State', key: 'state', width: '100px', is_fixed: false, fixed_order: 7 },
                { type: 'number', label: 'Country', key: 'country', width: '200px', is_fixed: false, fixed_order: 8 },
                { type: 'number', label: 'Phone', key: 'phone', width: '150px', is_fixed: false, fixed_order: 9 },
                { type: 'text', label: 'Company', key: 'company', width: '300px', is_fixed: false, fixed_order: 10 },
                // Add more headers as needed
            ],
            rows: [
                {
                    'first_name': 'John', 'last_name': 'Doe', 'age': 30, 'email': 'john@example.com',
                    'address': '123 Main St', 'city': 'New York', 'state': 'NY', 'country': 'USA',
                    'phone': '123-456-7890', 'company': 'ABC Inc'
                },
                {
                    'first_name': 'Jane', 'last_name': 'Smith', 'age': 25, 'email': 'jane@example.com',
                    'address': '456 Elm St', 'city': 'Los Angeles', 'state': 'CA', 'country': 'USA1',
                    'phone': '987-654-3210', 'company': 'XYZ Corp'
                },
                {
                    'first_name': 'Janest', 'last_name': 'Tat', 'age': 25, 'email': 'jane@example.com',
                    'address': '456 Elm St', 'city': 'Los Angeles', 'state': 'CA', 'country': 'USA1',
                    'phone': '987-654-3210', 'company': 'XYZ Corp'
                },
                {
                    'first_name': 'Jane', 'last_name': 'Tat', 'age': 25, 'email': 'jane@example.com',
                    'address': '456 Elm St', 'city': 'Los Angeles', 'state': 'CA', 'country': 'USA1',
                    'phone': '987-654-3210', 'company': 'XYZ Corp'
                },
                // Add more rows as needed
            ],
            filters: {}
        };
    },
    computed: {
        sortedHeaders() {
            return this.headers.slice().sort((a, b) => a.fixed_order - b.fixed_order);
        },
        filteredRows() {
            const filterKeys = Object.keys(this.filters);
            if (filterKeys.every(key => this.filters[key] === '')) return this.rows;
            return this.rows.filter(row => {
                return filterKeys.every(key => {
                    const filterValue = this.filters[key].toString().toLowerCase();
                    const cellValue = String(row[key]).toString().toLowerCase();
                    return cellValue.includes(filterValue);
                });
            });
        }
    },
    methods: {
        calculateLeftPosition(index) {
            let left = 0;
            for (let i = 0; i <= index; i++) {
                if (this.headers[i].is_fixed) {
                    left += parseInt(this.headers[i].width);
                }
            }
            return left;
        }
    }
};
</script>

<style>
.custom-table {
    width: auto;
    min-width: 100%; /* Ensure table takes up full container width */
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.custom-table th,
.custom-table td {
    font-size: 11.5px; /* Set the text size */
}

.custom-table th {
    background-color: #f9fafb;
    font-weight: 500;
    color: #4b5563;
    text-align: left;
}
.sticky-left {
    position: sticky;
    left: 0;
}

.sticky-left:first-child {
    z-index: 1; /* Ensure the first fixed column is above others */
}

.sticky-left:first-child th {
    z-index: 2; /* Ensure the first fixed column header is above others */
}

.sticky-left td {
    background-color: #fff; /* Ensure fixed column cells have white background */
}

::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

input:focus {
    outline: none;
}
</style>
