import Api from '../Api.js';

const BaseURL = 'v1/client-document';

export const RetrieveClientDocuments = async (id) => {
    return await Api()
        .get(`${BaseURL}/${id}`);
}

export const StoreClientDocument = async (payload) => {
    return await Api()
        .post(`${BaseURL}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
}

export const UpdateClientDocument = async (id, payload) => {
    return await Api()
        .put(`${BaseURL}/${id}`, payload);
}