<template>
    <div class="w-screen h-screen fixed top-0 left-0 z-50">
        <div class="fixed custom-shadow-1 w-screen h-screen flex items-center z-50 justify-center bg-transparent">
            <div class="bg-white w-[400px] h-fit rounded-[4pt]">
                <header class="h-[40px] text-[12px] flex items-center justify-between px-[12px] text-gray-500">
                    Add Filter
                    <cross-icon
                        @click="$emit('close')"
                        size="12px"
                        class="cursor-pointer"
                        color="gray"
                    />
                </header>
                <div class="px-[12px] py-[8px]">
                    <div>
                        <div class="relative w-full">
                            <div
                                @click="PageDropdownIsOpen = !PageDropdownIsOpen"
                                v-click-outside="() => this.PageDropdownIsOpen = false"
                                class="cursor-pointer h-[30px] w-full text-[10.5px] text-[#363636] flex justify-between items-center pl-[12px] bg-[#EEEEEE] rounded-[2pt]"
                            >
                                <div class="text-[#909090] flex items-center h-[30px] mt-0.5">
                                    {{ selectedPageLabel || "Page" }}
                                </div>
                                <div class="w-[30px] h-[30px] flex items-center justify-center">
                                    <chevron-left-small-icon
                                        class="rotate-[-90deg]"
                                        size="14px"
                                        color="#909090"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="PageDropdownIsOpen"
                                class="absolute w-full text-[10.5] mt-2 py-[4px] bg-white custom-shadow-1 rounded-[4pt] z-10"
                            >
                                <div
                                    v-for="page in Pages"
                                    :key="page.value"
                                    @click="SelectPage(page)"
                                    class="px-[12px] text-xs cursor-pointer h-[24px] pt-[1px] duration-500 flex items-center text-[#909090] hover:text-theme-blue justify-start hover:bg-theme-blue hover:bg-opacity-20"
                                >
                                    {{ page.label }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <input
                                id="name-fr"
                                placeholder="Name FR"
                                v-model="nameFR"
                                type="text"
                                class="w-full h-[30px] text-[10.5px] placeholder:text-gray-150 text-[#909090] pl-[12px] bg-[#EEEEEE] rounded-[2pt]"
                                required
                            />
                        </div>
                        <div class="mt-2">
                            <input
                                id="name-en"
                                placeholder="Name EN"
                                v-model="nameEN"
                                type="text"
                                class="w-full h-[30px] text-[10.5px] placeholder:text-gray-150 text-[#909090] pl-[12px] bg-[#EEEEEE] rounded-[2pt]"
                                required
                            />
                        </div>
                        <div class="mb-[18px] my-[24px] relative">
                            <hr class="border-[#EEEEEE] border-[1.5px]" />
                            <div class="absolute w-full">
                                <div class="bg-white text-[10.5px] -mt-[9px] w-fit mx-auto px-[12px] text-gray-500">
                                    Criterias
                                </div>
                            </div>
                        </div>
                        <div class="max-h-[350px]">
                            <div
                                v-for="(criteria, index) in Criterias"
                                :key="index"
                                class="flex w-full items-center justify-between gap-[8px] pb-[8px]"
                            >
                                <div class="relative w-full">
                                    <div
                                        @click="selectedPage && getAvailableColumns(index).length ? criteria.ColumnDropdownIsOpen = !criteria.ColumnDropdownIsOpen : null"
                                        v-click-outside="() => this.Criterias[index].ColumnDropdownIsOpen = false"
                                        :class="[
                                            'bg-[#EEEEEE]', 'text-[10.5px]', 'text-[#363636]', 'flex', 'justify-between', 'items-center', 'pl-[12px]', 'h-[30px]', 'rounded-[2pt]',
                                            { 'cursor-pointer': selectedPage && getAvailableColumns(index).length, 'cursor-not-allowed': !selectedPage || !getAvailableColumns(index).length }
                                        ]"
                                    >
                                        <div class="text-[#909090] flex items-center h-[30px] mt-0.5">
                                            {{ criteria.labelColumn || "Column" }}
                                        </div>
                                        <div class="w-[30px] h-[30px] flex items-center justify-center">
                                            <chevron-left-small-icon
                                                class="rotate-[-90deg]"
                                                size="14px"
                                                color="#909090"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        v-if="criteria.ColumnDropdownIsOpen"
                                        class="absolute w-full text-[10.5] mt-2 py-[4px] bg-white custom-shadow-1 rounded-[4pt] z-10 max-h-72 overflow-y-auto"
                                    >
                                        <div
                                            v-for="column in getAvailableColumns(index)"
                                            :key="column.value"
                                            @click="SelectColumn(index, column)"
                                            class="px-[12px] text-xs cursor-pointer h-[24px] pt-[1px] duration-500 flex items-center text-[#909090] hover:text-theme-blue justify-start hover:bg-theme-blue hover:bg-opacity-20"
                                        >
                                            {{ column.label }}
                                        </div>
                                    </div>
                                </div>

                                <div class="relative w-full">
                                    <div
                                        @click="criteria.selectedColumn ? criteria.ValueDropdownIsOpen = !criteria.ValueDropdownIsOpen : null"
                                        v-click-outside="() => this.Criterias[index].ValueDropdownIsOpen = false"
                                        :class="[
                                            'bg-[#EEEEEE]', 'text-[10.5px]', 'text-[#363636]', 'flex', 'justify-between', 'items-center', 'pl-[12px]', 'h-[30px]', 'rounded-[2pt]',
                                            { 'cursor-pointer': criteria.selectedColumn, 'cursor-not-allowed': !criteria.selectedColumn }
                                        ]"
                                    >
                                        <div class="text-[#909090] flex items-center h-[30px] mt-0.5">
                                            {{ criteria.labelValue || "Value" }}
                                        </div>
                                        <div class="w-[30px] h-[30px] flex items-center justify-center">
                                            <chevron-left-small-icon
                                                class="rotate-[-90deg]"
                                                size="14px"
                                                color="#909090"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        v-if="criteria.ValueDropdownIsOpen"
                                        class="absolute w-full text-[8.5] mt-2 py-[4px] bg-white custom-shadow-1 rounded-[4pt] z-10 max-h-72 overflow-y-auto"
                                    >
                                        <div
                                            v-for="value in getValuesForColumn(criteria.selectedColumn)"
                                            :key="value.value"
                                            @click="SelectValue(index, value)"
                                            class="px-[12px] text-xs cursor-pointer h-[24px] pt-[1px] duration-500 flex items-center text-[#909090] hover:text-theme-blue justify-start hover:bg-theme-blue hover:bg-opacity-20"
                                        >
                                            {{ value.label }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            @click="AddFilterCriteria"
                            :class="{
                                'cursor-not-allowed bg-gray-400': !canAddCriteria,
                                'cursor-pointer bg-theme-blue': canAddCriteria
                            }"
                            class="rounded-[2pt] h-[24px] text-white text-[9.5px] pt-0.5 mt-[4px] flex items-center justify-center font-medium tracking-wide"
                        >
                            Add Criteria
                        </div>
                    </div>
                    <div class="mt-[24px] cursor-pointer flex gap-2 w-full items-center justify-center">
                        <div
                            @click="$emit('close')"
                            class="rounded-[2px] h-[24px] text-gray-600 text-[10.5px] flex items-center justify-center tracking-wide bg-gray-100 w-1/2"
                        >
                            Close
                        </div>
                        <div
                            @click="submit"
                            :class="{
                                'cursor-not-allowed bg-gray-400': !isFormValid,
                                'cursor-pointer bg-theme-blue': isFormValid
                            }"
                            class="rounded-[2pt] h-[24px] text-[10.5px] flex items-center justify-center tracking-wide text-white w-1/2"
                        >
                            Save
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-screen h-screen bg-black flex items-center justify-center bg-opacity-20 fixed top-0 left-0 z-30"></div>
    </div>
</template>

<script>
import CrossIcon from "../../assets/svg/CrossIcon.vue";
import ChevronLeftSmallIcon from "../../assets/svg/ChevronLeftSmallIcon.vue";
import {StoreCustomFilter} from "../../services/CustomFilter";
import NotificationMixin from "../../mixins/NotificationMixin";
import { mapState } from "vuex";

export default {
    emits: ['close', "update-data"],

    mixins: [
        NotificationMixin
    ],

    components: {
        ChevronLeftSmallIcon,
        CrossIcon,
    },

    props: {
        lenders: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            Criterias: [
                {
                    ColumnDropdownIsOpen: false,
                    ValueDropdownIsOpen: false,
                    selectedColumn: "",
                    selectedValue: "",
                    labelColumn: "",
                    labelValue: ""
                }
            ],
            PageDropdownIsOpen: false,
            selectedPage: "",
            selectedPageLabel: "",
            nameFR: "",
            nameEN: ""
        }
    },

    computed: {
        ...mapState('entities', [
            "Amounts",
            "Columns",
            "Statuses",
            "Pages"
        ]),

        isFormValid() {
            return this.selectedPage && this.nameFR && this.nameEN && this.Criterias.every(criteria => criteria.selectedColumn && criteria.selectedValue);
        },

        canAddCriteria() {
            const lastCriteria = this.Criterias[this.Criterias.length - 1];
            return this.selectedPage && lastCriteria.selectedColumn && lastCriteria.selectedValue && this.getAvailableColumns(-1).length !== 0;
        }
    },

    methods: {
        getAvailableColumns(index) {
            const selectedColumns = this.Criterias.map((criteria, idx) => idx !== index ? criteria.selectedColumn : null).filter(Boolean);

            if (this.selectedPage === "client") {
                return this.Columns.filter(column => column.value === "lender_id" && !selectedColumns.includes(column.value));
            } else if (this.selectedPage === "lead") {
                return this.Columns.filter(column => (column.value === "amount" || column.value === "status_id") && !selectedColumns.includes(column.value));
            }

            return [];
        },

        AddFilterCriteria() {
            if (this.canAddCriteria && this.getAvailableColumns(-1).length) {
                this.Criterias.push({
                    ColumnDropdownIsOpen: false,
                    ValueDropdownIsOpen: false,
                    selectedColumn: "",
                    selectedValue: "",
                    labelColumn: "",
                    labelValue: ""
                });
            }
        },

        SelectPage(page) {
            this.selectedPage = page.value;
            this.selectedPageLabel = page.label;
            this.PageDropdownIsOpen = false;
            this.Criterias = [
                {
                    ColumnDropdownIsOpen: false,
                    ValueDropdownIsOpen: false,
                    selectedColumn: "",
                    selectedValue: "",
                    labelColumn: "",
                    labelValue: ""
                }
            ];
        },

        SelectColumn(index, column) {
            this.Criterias[index].selectedColumn = column.value;
            this.Criterias[index].labelColumn = column.label;
            this.Criterias[index].selectedValue = "";
            this.Criterias[index].labelValue = "";
            this.Criterias[index].ColumnDropdownIsOpen = false;
        },

        SelectValue(index, value) {
            this.Criterias[index].selectedValue = value.value;
            this.Criterias[index].labelValue = value.label;
            this.Criterias[index].ValueDropdownIsOpen = false;
        },

        getValuesForColumn(column) {
            switch (column) {
                case "amount":
                    return this.Amounts;
                case "status_id":
                    return this.Statuses;
                case "lender_id":
                    return this.lenders.map(lender => ({
                        label: lender.name,
                        value: lender.id
                    }));
                default:
                    return [];
            }
        },

        async submit() {
            if (this.isFormValid) {
                await StoreCustomFilter(
                    {
                        model: this.selectedPage,
                        name_fr: this.nameFR,
                        name_en: this.nameEN,
                        query: this.Criterias.map(criteria => `${criteria.selectedColumn}=${criteria.selectedValue}`).join("&")
                    }
                ).then((response) => {
                    if (response.status === 200) {
                        this.showNotification("success", "Custom Filter stored successfully");
                        this.$emit("update-data");
                    }
                }).catch((error) => {
                    console.error("Error custom filter: ", error);
                    this.showNotification("error", "Error creating custom filter")
                }).finally(() => {
                    this.$emit("close");
                })
            }
        }
    }
}
</script>
