<template>
	<kronos-base-layout>
		<kronos-user-card
		    v-for="User in ContextUsers"
		    :first_name="User.first_name"
		    :last_name="User.last_name"
		    :phone="User.phone"
		    :email="User.email"
		    :role="User.role.role"
		    :user_id="User.id"
		    :role_id="User.role_id">
		</kronos-user-card>
	</kronos-base-layout>
</template>

<script>
import { mapState } from "vuex";
import KronosBaseLayout from "../UI/Layouts/KronosBaseLayout.vue";
import KronosUserCard from "../UI/BaseComponents/KronosUserCard.vue";
import { Users } from "../services/User";
import store from "../store";

export default
{
	components: {
		KronosBaseLayout,
		KronosUserCard,
	},

	computed: {
		...mapState([
			'ContextUser',
      'ContextUsers'
		]),
	},

	mounted() {
		Users()
			.then((response) => {
		        store.state.ContextUsers = response.data.data.users;
			});
	},
}
</script>
