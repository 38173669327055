<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 20L4 4.00003M20 4L4.00002 20" :stroke="color" :stroke-width="strokeWidth" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default
{
    props: {
        color: {
            type: String,
            default: ''
        },
        strokeWidth: {
            type: String,
            default: 2,
        },
        size: {
            type: String,
            default: '24px',
        }
    }
}
</script>