<template>
	<div class="h-fit bg-gray-50 rounded-b-lg grid grid-cols-2 gap-[18px] p-[18px]">
		<div class="flex flex-col justify-between">
			<div class="custom-shadow-0 | bg-white h-fit rounded-lg flex w-full">
				<div class="divide-y divide-gray-100 w-full">
					<div class="h-[34px] flex items-center px-[18px] text-xs">
						Loan Details
					</div>
					<div class="w-full h-fit grid grid-cols-2 p-[18px] gap-x-[18px]">
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Amount</label>
							<input type="text" placeholder="0.00" :value="ContextLead.amount" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" id="">
						</div>
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Payment Frequency</label>
							<input type="text" placeholder="Frequency" value="Every 2 Weeks" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" id="">
						</div>
					</div>
				</div>
			</div>
			<div class="custom-shadow-0 | bg-white h-fit rounded-lg flex w-full">
				<div class="divide-y divide-gray-100 w-full">
					<div class="h-[34px] flex items-center px-[18px] text-xs">
						Income Details
					</div>
					<div class="w-full h-fit grid grid-cols-2 p-[18px] gap-x-[18px]">
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Next Paycheck At</label>
							<input type="text" placeholder="Next Paycheck At" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="10 March 2024" id="">
						</div>
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Pay Frequency</label>
							<input type="text" placeholder="Pay Frequency" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="Weekly" id="">
						</div>
					</div>
				</div>
			</div>
			<div class="custom-shadow-0 | bg-white h-fit rounded-lg flex w-full">
				<div class="divide-y divide-gray-100 w-full">
					<div class="h-[34px] flex items-center pl-[18px] text-xs justify-between">
						Joint Account
						<div class="h-full flex items-center justify-center mx-[18px]">
							<button type="button" class="bg-gray-200 relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2" role="switch" aria-checked="false">
								<span class="translate-x-0 pointer-events-none relative inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
							</button>
						</div>
					</div>
					<div class="relative w-full h-fit p-[18px] gap-x-[18px]">
						<div class="h-full w-full bg-gray-400 bg-opacity-10 rounded-b-lg absolute top-0 left-0"></div>
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Joint Account Name</label>
							<input type="text" placeholder="test@gestionkronos.com" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="test@gmail.com" id="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-col gap-[18px]">
			<div class="custom-shadow-0 | bg-white h-fit rounded-lg flex w-full">
				<div class="w-full h-[64px] p-[12px] gap-x-[18px] flex">
					<div class="h-[40px] rounded w-[40px]">
						<rbc-icon class="max-h-[40px] max-w-[40px] rounded"/>
					</div>
					<div class="flex items-center h-full w-full">
						<div class="grid grid-cols-3 gap-[18px] w-full">
							<input v-maska data-maska="#####" type="text" placeholder="test@gestionkronos.com" class="text-center tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="000561" id="">
							<input v-maska data-maska="###" type="text" placeholder="test@gestionkronos.com" class="text-center tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="003" id="">
							<input v-maska data-maska="##########" type="text" placeholder="test@gestionkronos.com" class="text-center tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="000616671" id="">
						</div>
					</div>
				</div>
			</div>
			<div class="custom-shadow-0 | bg-white h-fit rounded-lg flex w-full ">
				<div class="divide-y divide-gray-100 w-full">
					<div class="h-[34px] justify-between flex items-center pl-[18px] text-xs">
						Debt Ratio
						<div class="text-[#4667d2] bg-light-dark-blue font-semibold text-[10px] border-l h-full flex items-center justify-center px-[18px] rounded-tr-lg border-gray-100">
							10.000%
						</div>
					</div>
					<div class="w-full h-fit grid grid-cols-2 p-[18px] gap-[18px]">
						<div class="gap-1 h-fit flex flex-col col-span-2">
							<label class="text-[10px] flex ite ms-center h-[16px] text-gray-400 tracking-wide font-medium">Monthly Income</label>
							<input
								v-maska
								data-maska="9.##"
								data-maska-tokens="9:[0-9]:multiple"
								type="text"
								placeholder="1000.00"
								class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]"
								value="1600.65"
							>
						</div>
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Mortgage/Rent</label>
							<input type="text" placeholder="1000.00" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="1678.09" id="">
						</div>
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Power Bill</label>
							<input type="text" placeholder="1000.00" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="100.00" id="">
						</div>

						<kronos-base-input
							placeholder="256.87"
							label="Auto Loan"
							value="100.00"
						/>

						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Furniture Loan</label>
							<input type="text" placeholder="1000.00" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="126.98" id="">
						</div>
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">CC Debts</label>
							<input type="text" placeholder="1000.00" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="123.54" id="">
						</div>
						<div class="gap-1 h-fit flex flex-col">
							<label class="text-[10px] flex items-center h-[16px] text-gray-400 tracking-wide font-medium">Other Debts/Loans</label>
							<input type="text" placeholder="1000.00" class="tracking-wide bg-gray-100 w-full rounded text-gray-500 focus:outline-0 px-[10px] font-medium h-[32px] text-[11px]" value="1233.98" id="">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RbcIcon from "../../assets/icons/bank/RBC.vue";
import {vMaska} from "maska";
import KronosBaseInput from "../../UI/BaseComponents/Inputs/KronosBaseInput.vue";
import {mapState} from "vuex";

export default
{
	components: {
		KronosBaseInput,
		RbcIcon
	},

	directives: {
		maska: vMaska,
	},

    computed: {
        ...mapState([
            'ContextLead'
        ])
    }
}
</script>