import api from './Api';

const BaseURL = 'v1/ibv';

export const RetrieveIbvs = async (id , resource, page= 1, limit= 50) =>
{
    return await api().get(`${BaseURL}?page=${page}&limit=${limit}&${resource}=${id}`)
};

export const RetrieveIbv = async (id) => {
    return await api().get(`${BaseURL}/${id}`)
}
