import LoginPage from "../../pages/LoginPage.vue";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage.vue";

export default [
	{
		path: '/login',
		component: LoginPage,
		name: 'Login',
		meta: {
			type: 'auth',
			guestOnly: true,
		}
	},
	{
		path: '/forgot-password',
		component: ForgotPasswordPage,
		name: 'ForgotPassword',
		meta: {
			type: 'auth',
			guestOnly: true,
		}
	},
]
