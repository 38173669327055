import Api from '../Api.js';

const BaseURL = 'v1/client-address';

export const RetrieveClientAddress = async (id) => {
    return await Api()
        .get(`${BaseURL}/${id}`);
}

export const RetrieveClientAddresses = async (client_id) => {
    return await Api()
        .get(`${BaseURL}/client/${client_id}`);
}

export const StoreClientAddress = async (data) => {
    return await Api()
        .post(`${BaseURL}`, data);
}

export const UpdateClientAddress = async (id, data) => {
    data.zip = data.zip.replace(' ', '');


    return await Api()
        .put(`${BaseURL}/${id}`, data);
}

export const DestroyClientAddress = async (id) => {
    return await Api()
        .delete(`${BaseURL}/${id}`);
}
