<template>
    <div class="w-full h-fit bg-white shadow-custom-shadow border border-gray-100 rounded-lg">
        <div class="p-[12pt] bg-gray-200 bg-opacity-40 flex flex-col gap-4">
          <div v-for="LoanCalendar in LoanCalendars">
            <payment-calendar-details
                v-if="LoanCalendar !== null"
                :LoanCalendar="LoanCalendar"
            />

            <div v-if="LoanCalendar && LoanCalendar?.loan_payments" class="custom-shadow-1 | divide-y rounded-lg bg-white">
              <payment-calendar-table
                  :LoanCalendar="LoanCalendar"
              />
            </div>
          </div>

        </div>
    </div>
</template>

<script>
import {RetrieveLoanCalendarPayment} from "../../services/LeadCalendar/LoanPaymentCalendar/LoanPaymentCalendar";
import PaymentCalendarDetails from "./PaymentCalendarDetails.vue";
import PaymentCalendarTable from "./PaymentCalendarTable.vue";

export default {
    emits: [
        'edit-payment'
    ],

    data() {
        return {
            isLoading: true,
            LoanCalendars: [],
            showPaymentsModal: false,
            showEditModal: false,
            selectedRowData: [],
        }
    },
    components: {
        PaymentCalendarTable,
        PaymentCalendarDetails,
    },

    methods: {
        fetchData() {
            RetrieveLoanCalendarPayment(this.$route.params.id)
                .then((response) => {
                    this.LoanCalendars = response.data.data.loan_payment_calendar;
                })
        }
    },

    mounted() {
        this.fetchData();
    }
}
</script>
