export const ManagerLeadStatisticsTable = [
	{
		'label': 'ID',
		'key': 'id',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'FIRST NAME',
		'key': 'client.first_name',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'LAST NAME',
		'key': 'client.last_name',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'STATUS',
		'key': 'lead_status.lead_status_name',
		'type': 'custom',
		'min_w': '150px',
		'custom': {
			'NOT_PROCESSED': 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20',
			'ACCEPTED': 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20',
			'DENIED': 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10',
			'STARTED': 'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10',
		}
	},
	{
		'label': 'GUARANTOR',
		'key': 'client.account.name',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'RESPONSIBLE',
		'key': 'user.first_name',
		'type': 'string',
		'min_w': '200px',
	},
    {
		'label': 'AMOUNT',
		'key': 'amount',
		'type': 'string',
		'min_w': '100px',
		'suffix': '$',
	},
	{
		'label': 'NOTE',
		'key': '',
		'type': 'custom',
		'min_w': '200px',
		'custom': {
		}
	},
	{
		'label': 'EMAIL',
		'key': 'client.email_address',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'DOCUMENTS',
		'key': '',
		'type': 'custom',
		'min_w': '100px',
		'custom': {
			'SIGNED': 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10',
			'UNSIGNED': 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20',
		}
	},
	{
		'label': 'NSF',
		'key': '',
		'type': 'string',
		'min_w': '50px',
	},
	{
		'label': 'OPP',
		'key': '',
		'type': 'string',
		'min_w': '50px',
	},
	{
		'label': 'MP',
		'key': '',
		'type': 'string',
		'min_w': '50px',
	},
	{
		'label': 'IBV',
		'key': 'ibv_is_completed',
		'type': 'custom',
		'min_w': '50px',
		'custom': {
			'1': 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10',
			'0': 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20',
		}
	},


];