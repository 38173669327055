<template>
    <div
        :class="[
            this.isChecked ? 'bg-theme-blue' : 'bg-gray-200',
            'w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm shadow-sm transition-colors'
        ]"
    >

    </div>
</template>

<script>
export default
{
    props: {
        isChecked: {
            type: Boolean,
            default: false,
        }
    }
}
</script>