export default
{
	input: {
		email_address: 'Email Address',
		password: 'Password',
		phone_number: 'Phone Number',
	},

	display_settings: 'Display Settings',
	language: 'Language',

	theme: {
		theme: 'Theme',
		dark: 'Dark',
		light: 'Light'
	},

	save: 'Save',
}