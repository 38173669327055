import Api from "../../Api";
import {DecryptResponseData} from "../../../helpers/Helpers";

const BaseURL = 'v1/loan-payment-calendar';

export const RetrieveLoanCalendarPayment = async (lead_id) => {
    const response_data = await Api().get(`${BaseURL}/${lead_id}`);
    return await DecryptResponseData(response_data.data).then(response => {
        return response;
    });
}

export const StoreLoanCalendarPayment = async (payload) => {
    return await Api()
        .post(`${BaseURL}`, payload);
}