<template>
    <kronos-base-layout>
        <div class="flex flex-col gap-10">
            <kronos-base-heading title="IBV Detail Page">
                <kronos-base-date-range-selector
                    :context-selected-dates="[$route?.query?.start_at, $route?.query?.end_at]"
                    @change-range="fetchData()"
                    @reload="fetchData()"
                >
                </kronos-base-date-range-selector>
            </kronos-base-heading>
            <kronos-ibv-static-table
                title="ibv"
                :dataset="dataset"
                :is-loading="isLoading"
                :config="tableConfig"
                :total="total"
                @reload="fetchData()"
            />
        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../../UI/Layouts/KronosBaseLayout.vue";
import {mapState} from "vuex";
import KronosBaseHeading from "../../UI/BaseComponents/KronosBaseHeading.vue";
import KronosBaseDateRangeSelector from "../../UI/BaseComponents/KronosBaseDateRangeSelector.vue";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import KronosBaseFilterByTable from "../../UI/BaseComponents/KronosBaseFilterByTable.vue";
import KronosIbvStaticTable from "../../UI/BaseComponents/KronosIbvStaticTable.vue";
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";
import SettingsIcon from "../../assets/icons/SettingsIcon.vue";
import CloseIcon from "../../assets/icons/CloseIcon.vue";
import {IbvDetailTable} from "../../config/TableViews/IbvDetailTable";

export default
{
    name: 'IbvReportPage',

    components: {
        CloseIcon,
        SettingsIcon,
        ReloadIcon,
        KronosIbvStaticTable,
        KronosBaseFilterByTable,
        ChevronDownIcon,
        KronosBaseDateRangeSelector,
        KronosBaseHeading,
        KronosBaseLayout,
    },

    computed: {
        ...mapState([
            'ContextUser',
            'ContextIbv'
        ]),

        tableConfig() {
            return IbvDetailTable;
        },
    },

    data() {
        return {
            isLoading: false,
            dataset: [],
            total: 0,
            statistics: [],
        }
    },

    methods: {
        async fetchData() {
            const data = this.$route.query;
            if (data?.start_at && data?.end_at && data?.page && data?.limit) {
                this.isLoading = true;
                this.dataset = [this.ContextIbv];
                this.isLoading = false;
            }
        },
    },
}
</script>
