<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :height="size"
        :width="size"
        viewBox="0 0 24 24"
        :fill="fill"
    >
        <path d="M12,2C6.477,2,2,6.477,2,12c0,1.592,0.382,3.091,1.043,4.427l-1.005,4.019c-0.229,0.915,0.6,1.745,1.516,1.516 l4.019-1.005C8.909,21.618,10.408,22,12,22c5.523,0,10-4.477,10-10C22,6.477,17.523,2,12,2z" opacity=".35"/>
    </svg>
</template>

<script>
export default
{
    props: {
        fill: {
            type: String,
            default: '#000000',
        },
        size: {
            type: String,
            default: '48px',
        }
    }
}
</script>
