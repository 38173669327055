<template>
    <div class="w-1/3 h-[235px]">
        <div class="w-full rounded-md bg-white h-full shadow-custom-shadow-2 border border-gray-100">
            <div class="flex border-b border-gray-100 h-[45px] pl-[12pt] items-center justify-between">
                <h5 class="text-xs font-medium tracking-wide text-gray-900">
                    {{ title }}
                </h5>
                <div
	                @click="$emit('add')"
	                class="border-l border-gray-100 w-[45px] h-[46px] flex items-center justify-center text-gray-400 hover:bg-gray-100 duration-500 cursor-pointer"
                >
                    +
                </div>
            </div>
            <div>
                <ul role="list" class="divide-y divide-gray-100">
                    <li
                        v-for="item in 3"
                        class="pl-[12pt] h-[50px]"
                    >
                        <div class="flex items-center space-x-4">
                            <div class="flex-shrink-0">
                                <img
	                                class="w-7 h-7 rounded-md"
	                                src="../../assets/images/user-avatar-32.png"
	                                alt="Bank Logo"
                                >
                            </div>
                            <div class="flex-1 min-w-0">
                                <p class="text-xs font-normal text-gray-700 truncate">
                                    RBC - 123 - 1234
                                </p>
                            </div>
                            <div class="text-sm font-light text-gray-900 pr-1 w-[50px] h-[50px] flex items-center justify-center">
                                <button class="bg-gray-100 h-[50%] w-[50%] flex items-center justify-center rounded-md">
                                    <svg
                                        class="w-3 h-3 fill-gray-700"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 16 3"
                                    >
                                        <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="text-center text-[8pt] cursor-pointer duration-500 rounded-b-lg text-gray-400 tracking-wide h-[40px] font-medium border-t border-gray-100 hover:bg-gray-100 flex items-center justify-center">
                    View All
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default
{
    props: {
        title: {
            type: String,
            default: 'Title',
        }
    }
}
</script>