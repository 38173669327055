<template>
    <div class="h-full w-full flex justify-between flex-col gap-[16px]">
        <div class="custom-shadow-1 rounded-[4pt] flex flex-col h-full w-full  bg-white divide-y divide-gray-100">
            <div class="bg-transparent min-h-[84px] flex items-center justify-between pl-[16px] pr-[8px] px-[12px]">
                <div>
                    <p class="text-[11px] font-medium text-[#6F6F6F]">Next Step</p>
                    <p class="text-[13px] font-medium text-[#595959] tracking-wide">
                        {{ label }}
                    </p>
                    <div class="flex items-center justify-start gap-[4px]">
                        <div class="h-[10px] w-[10px] bg-green-600 rounded-full"></div>
                        <p class="text-[10px] font-medium text-[#767676]">
                            Completed in 1 hour
                        </p>
                    </div>
                </div>
                <div>
                    <button
                        @click="this.submit()"
                        class="h-[24px] tracking-wide text-gray-600 hover:bg-theme-blue hover:text-white duration-500 text-[10px] w-[150px] bg-gray-100 rounded-[2pt]">
                        {{ buttonLabel }}
                    </button>
                </div>
            </div>
            <div class="bg-[#F9F9F9] h-full w-full rounded-b-[4pt]"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            statusId: {
                type: String,
                default: 4,
            }
        },

        computed: {
            label() {
                switch (this.statusId.toString()) {
                    case '3':
                    case '4':
                        return 'Approbation';
                    case '5':
                        return 'Verification';
                    case '6':
                        return 'Loan Offer';
                    case '1':
                        return 'E-Transfer Scheduled';
                }
            },

            buttonLabel() {
                switch (this.statusId.toString()) {
                    case '3':
                        return 'Continue';
                    case '4':
                        return 'Start';
                    case '5':
                        return 'Continue';
                    case '6':
                        return 'Approve Request';
                    case '1':
                        return '22 May 2024 18h 00m 00s';
                }
            }
        },

        methods: {
            submit() {
                if (['3', '4'].includes(this.statusId.toString())) {
                    this.$emit('open-approbation');
                } else if (this.statusId.toString() === '6') {
                    this.$emit('approve-request');
                }
            }
        }
    }
</script>

<style scoped>

</style>