<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 20L12 12M12 12L12 4M12 12L20 12M12 12L4 12" :stroke="color" :stroke-width="strokeWidth" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default
{
    props: {
        size: {
            type: String,
            default: '24px',
        },
        color: {
            type: String,
            default: ''
        },
        strokeWidth: {
            type: String,
            default: 2,
        }
    }
}
</script>