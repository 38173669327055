<template>
    <missing-document-modal
        :IsOpen="MissingDocumentModalIsOpen"
        @close="MissingDocumentModalIsOpen = false"
    />

    <approve-document-modal
        class="z-50"
        :is_open="DocumentApprobationIsOpen"
        :document_id="SelectedDocument.id"
        :document_name="SelectedDocument.label"
        :document_url="BaseURL + SelectedDocument.url"
        :document_is_approved="SelectedDocument.is_validated"
        :document_route_url="SelectedDocument.url"
        @approve="ApproveDocument($event)"
        @replace="openFileInput($event)"
        @close="DocumentApprobationIsOpen = false"
    />

    <div
        v-for="(Document, index) in DocumentsIsOpen"
        :id="`iframeContainer${index}`"
        class="fixed top-0 left-0 w-fit h-fit z-[9999]"
    >
        <div
            v-if="Document.is_open"
            :id="`dragLayer${index}`"
            class="custom-shadow-1 | absolute top-0 left-0 w-fit h-fit cursor-grabbing"
            @mousedown="startDrag($event, index)"
        >
            <div class="h-[40px] rounded-t-lg flex items-center justify-between pl-[12px] w-[400px] bg-white">
                <span class="text-[12.5px] text-gray-600">
                    {{ Document.label }}
                </span>
                <button
                    @click="$emit('close-document', index)"
                    class="w-[40px] h-[40px] flex items-center justify-center"
                >
                    <cross-icon
                        class="stroke-gray-500 h-[14px]"
                    />
                </button>
            </div>
        </div>
        <img
            v-if="Document.is_open"
            :src="BaseURL + payload[index].url" class="object-fit contain rounded-b-lg w-[400px] h-fit mt-[40px]"
        />
    </div>

    <div class="custom-shadow-0 | flex flex-col gap-[20px] bg-white pb-[20px] rounded-lg border border-gray-100">
        <div class="flex items-center justify-between h-[42px] border-b pl-[24px] border-gray-100">
            <h1 class="text-[12px] font-light">
                Documents
            </h1>
            <div class="flex gap-2 border-gray-100 pr-[8px]">
                <button @click="MissingDocumentModalIsOpen = true" class="w-[28px] bg-blue-50 h-[28px] rounded group hover:bg-blue-500 duration-500 flex items-center justify-center">
                    <send-icon
                        stroke-width="2"
                        class="h-[16px] cursor-pointer pl-0.5 stroke-blue-500 group-hover:stroke-blue-100 duration-500"
                    />
                </button>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="this.UpdateLead()"
                    class="w-[28px] rounded bg-blue-50 h-[28px] group hover:bg-blue-500 duration-500 flex items-center justify-center"
                >
                    <save-icon
                        stroke-width="2"
                        class="h-[16px] stroke-blue-500 fill-transparent group-hover:stroke-blue-100 duration-500"
                    />
                </button>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="ApproveSection(!this.IsValidated)"
                    :class="[IsValidated ? 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 ' : 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-green-100', 'duration-500 h-[28px] px-[24px] cursor-pointer text-xs rounded']"
                >
                    {{ IsValidated ? 'Decline' : 'Approve' }} Section
                </button>
            </div>
        </div>

        <div class="grid grid-cols-5 gap-[12px] px-[24px]">
            <div
                v-for="(item, index) in payload"
                :key="index"
                @click="item.is_validated === null ? openFileInput(index) : this.OpenDocument(item)"
                class="cursor-pointer custom-shadow-0 | h-[55px] w-full border border-gray-100 px-[16px] flex items-center rounded-lg justify-center"
            >
                <div :class="[ImageIconColor(item.is_validated, 'bg'), 'cursor-pointer w-[32px] rounded-lg flex items-center justify-center h-[32px]']">
                    <image-icon
                        :class="ImageIconColor(item.is_validated)"
                        store-width="1.5"
                    />
                </div>
                <p class="text-[10.5px] lg:text-[11.5px] font-light mx-auto">{{ item.label }}</p>
                <input type="file" :id="index" class="hidden" @change="handleFileChange($event, index); IsValidated = false;">
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {UpdateLead} from "../../../../services/Lead";
import ImageIcon from "../../../../assets/svg/ImageIcon.vue";
import SendIcon from "../../../../assets/svg/SendIcon.vue";
import SaveIcon from "../../../../assets/icons/SaveIcon.vue";
import MissingDocumentModal from './Modals/MissingDocumentModal.vue';
import {
    RetrieveClientDocuments,
    StoreClientDocument,
    UpdateClientDocument
} from "../../../../services/Client/ClientDocument";
import NotificationMixin from "../../../../mixins/NotificationMixin";
import ApproveDocumentModal from "./Modals/ApproveDocumentModal.vue";
import CrossIcon from "../../../../assets/svg/CrossIcon.vue";
import {UpdateLeadApprobationStep} from "../../../../services/LeadApprobationStep";

export default
{
    emits: ['approve-section'],

    mixins: [
        NotificationMixin,
    ],

    components: {
        CrossIcon,
        ApproveDocumentModal,
        SaveIcon,
        SendIcon,
        ImageIcon,
        MissingDocumentModal
    },

    props: {
        NewDocumentUploaded: {
            type: Boolean,
            default: false,
        },
        IsValidated: {
            type: Boolean,
            default: false,
        },
        DocumentsIsOpen: {
            type: Object,
            default: {
                id_card: {label: 'ID Card', is_open: false, is_available: false},
                void_check: {label: 'Void Check', is_open: false, is_available: false},
                banking_statement: {label: 'Banking Statement', is_open: false, is_available: false},
                paystub: {label: 'Paystub', is_open: false, is_available: false},
                proof_of_residency: {label: 'Proof Of Residency', is_open: false, is_available: false},
            }
        }
    },

    data() {
        return {
            DocumentApprobationIsOpen: false,
            MissingDocumentModalIsOpen: false,
            SelectedDocument: {
                'id': null,
                'url': null,
                'label': null,
                'is_validated': null
            },
            payload: {
                'id_card': {
                    'id': 'id_card',
                    'url': null,
                    'label': 'ID Card',
                    'is_validated': null
                },
                'void_check': {
                    'id': 'void_check',
                    'url': null,
                    'label': 'Void Check',
                    'is_validated': null
                },
                'banking_statement': {
                    'id': 'banking_statement',
                    'url': null,
                    'label': 'Banking Statement',
                    'is_validated': null
                },
                'paystub': {
                    'id': 'paystub',
                    'url': null,
                    'label': 'Paystub',
                    'is_validated': null
                },
                'proof_of_residency': {
                    'id': 'proof_of_residency',
                    'url': null,
                    'label': 'Proof Of Residency',
                    'is_validated': null
                },
            }
        }
    },

    computed: {
        ...mapState(['ContextLead']),

        BaseURL() {
            return import.meta.env.VITE_BACKEND_BASE_URL + '/storage/id_card/';
        },
    },

    methods: {
        OpenDocument(item) {
            this.DocumentApprobationIsOpen = true;
            this.SelectedDocument = item;
        },

        ImageIconColor(status, type = 'stroke') {
            switch (status)
            {
                case true:
                    return type === 'stroke' ? 'stroke-green-500' : 'bg-green-100';
                case false:
                    return type === 'stroke' ? 'stroke-yellow-500' : 'bg-yellow-100';
                default:
                    return type === 'stroke' ? 'stroke-red-500' : 'bg-red-100';
            }
        },

        ApproveSection(is_validated) {
            this.UpdateLead();
            this.$emit('approve-section', {approved_step: 'documents', approved_step_status: is_validated});
        },

        UpdateLead() {
            UpdateLead(this.$route.params.id, this.payload)
        },

        openFileInput(index) {
            this.DocumentApprobationIsOpen = false;
            const element = document.getElementById(index);
            element
                ? element.click()
                : console.error("Element with ID " + index + " not found.");
        },

        startDrag(event, index) {
            event.preventDefault();

            const container = document.getElementById(`iframeContainer${index}`);
            const dragLayer = container.querySelector(`#dragLayer${index}`);

            const offsetX = event.clientX - dragLayer.getBoundingClientRect().left;
            const offsetY = event.clientY - dragLayer.getBoundingClientRect().top;

            const moveHandler = (event) => {
                const x = event.clientX - offsetX;
                const y = event.clientY - offsetY;
                container.style.left = x + 'px';
                container.style.top = y + 'px';
            };

            const endHandler = () => {
                document.removeEventListener('mousemove', moveHandler);
                document.removeEventListener('mouseup', endHandler);
            };

            document.addEventListener('mousemove', moveHandler);
            document.addEventListener('mouseup', endHandler);

            container.style.zIndex = 9999;
        },

        handleFileChange(event, file_name) {
            const form_data = new FormData();
            form_data.append('document', event.target.files[0]);
            form_data.append('document_name', file_name);
            form_data.append('client_id', this.ContextLead.client.id);

            StoreClientDocument(form_data)
                .then((response) => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Document Successfully Stored');
                        this.ApproveSection(false);
                    }
                })
                .catch(() => {
                    this.showNotification('error', 'An Error Occurred While Storing Document');
                })
                .finally(() => {
                    this.RetrieveClientDocuments()
                });
        },

        RetrieveClientDocuments() {
            RetrieveClientDocuments(this.ContextLead.client.id)
                .then((response) => {
                    if (response.status === 200) {
                        const documents = response.data.data.client_document;
                        this.payload.void_check.url = documents['src_void_check'];
                        this.payload.void_check.is_validated = documents['src_void_check'] ? !!documents['src_void_check_is_approved'] : null;
                        this.payload.id_card.url = documents['src_id_card'];
                        this.payload.id_card.is_validated = documents['src_id_card'] ? !!documents['src_id_card_is_approved'] : null;
                        this.payload.banking_statement.url = documents['src_banking_statement'];
                        this.payload.banking_statement.is_validated = documents['src_banking_statement'] ? !!documents['src_banking_statement_is_approved'] : null;
                        this.payload.paystub.url = documents['src_paystub'];
                        this.payload.paystub.is_validated = documents['src_paystub'] ? !!documents['src_paystub_is_approved'] : null;
                        this.payload.proof_of_residency.url = documents['src_proof_of_residency'];
                        this.payload.proof_of_residency.is_validated = documents['src_proof_of_residency'] ? !!documents['src_proof_of_residency_is_approved'] : null;
                    }
                });
        },

        ApproveDocument(event) {
            UpdateClientDocument(this.ContextLead.client.id, {
                'document_name': event,
                'is_approved': true,
            })
                .finally(() => {
                    this.RetrieveClientDocuments();
                });
        }
    },

    mounted() {
        this.RetrieveClientDocuments();
    }
}
</script>

<style scoped>
#dragLayerid_card {
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    height: fit-content;
    cursor: move; /* Set cursor to indicate draggable */
}

#draggableIframeid_card {
    width: fit-content;
    height: fit-content;
    position: absolute;
}
</style>
