<template>
  <div @mouseover="showTooltip = true" @mouseleave="showTooltip = false" class="relative inline-block">
    <slot></slot>
    <div v-if="showTooltip"
         class="absolute p-2 -mt-16 min-w-full text-sm leading-tight text-white bg-gray-800 rounded-lg shadow-lg text-center">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTooltip: false
    };
  },
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>
