<template>
    <div class="custom-shadow-0 | w-full h-fit bg-white rounded-lg">
        <header class="h-[50px] w-full flex justify-between bg-transparent border-b border-gray-100">
            <div class="flex items-center h-full justify-start w-1/3 pl-[14pt] text-sm font-medium text-gray-600 space-x-3">
                <slot/>
            </div>
            <div class="w-2/3 flex items-center justify-end">
                <div
                    @click="reload()"
                    class="border-l w-[50px] flex items-center justify-center h-[50px] border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg"
                >
                    <reload-icon
                        :class="isLoading ? 'animate-spin' : ''"
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
                <div
                    @click="IsOpen = !IsOpen"
                    class="border-l w-[50px] flex items-center justify-center h-[50px] border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg"
                >
                    <chevron-down-icon
                        :class="[IsOpen ? '' : 'rotate-90', 'duration-500']"
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
            </div>
        </header>
        <div v-if="IsOpen">
            <div v-if="isLoading" class="flex items-center h-[100px] justify-center">
                <loader-animation size="24px" border="2.5px solid rgb(209 213 219)" />
            </div>
            <div v-else-if="data.length === 0 && !isLoading" class="text-[10.5px] w-full flex justify-center h-20 items-center text-gray-400">
                No Data Available...
            </div>
            <div v-else class="flex flex-col">
                <div class="w-full min-w-[450px] left-0 tracking-wide bg-gray-100 sticky flex items-center justify-between text-[11px] text-gray-600 font-semibold uppercase">
                    <div class="w-full max-w-[175px] text-[10px] font-normal h-[40px] border-r border-gray-200 flex items-center justify-center">
                        {{ selectedFilter['filterByName'] }}
                    </div>
                    <div class="w-1/2 h-[40px] text-[10px] font-normal flex items-center justify-center">Leads</div>
                </div>
                <div class="overflow-x-auto max-h-[250px] w-full">
                    <div
                        v-for="(leadStatistic, index) in data"
                        :key="index"
                        class="w-full flex items-center hover:bg-gray-50 justify-between text-[11px] text-gray-500 font-medium"
                    >
                        <div class="w-full max-w-[175px] text-[10px] tracking-wide font-light h-[40px] border-r border-gray-100 flex items-center justify-center">
                            <span class="uppercase">{{ leadStatistic.identifier }}</span>
                        </div>
                        <div class="w-1/2 h-[40px] text-[10px] font-normal flex items-center justify-center">{{ leadStatistic.lead_count }}</div>
                    </div>
                </div>
                <div class="w-full flex items-center justify-between text-[10px] text-gray-600 font-normal border-t border-gray-100 uppercase">
                    <label class="w-full max-w-[175px] h-[40px] flex border-r border-gray-100 items-center justify-center">TOTAL</label>
                    <label class="w-1/2 h-[40px] flex items-center justify-center">{{ total }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";

export default {
    emits: ['reload'],

    components: {
        LoaderAnimation,
        ChevronDownIcon,
        ReloadIcon
    },

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            default: [],
        },
        total: {
            type: Number,
            default: 0,
        },

        selectedFilter: {
            type: Object,
            default: 0,
        },
    },

    data() {
        return {
            IsOpen: true,
        }
    },

    methods: {
        reload() {
            this.$emit('reload', this.$route.query);
        },
    },

    mounted() {
        this.reload();
    }
}
</script>
