import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import I18n from "./plugins/KronosI18n"
import vClickOutside from "click-outside-vue3"
import VueCookies from 'vue-cookies'
import * as Sentry from "@sentry/vue";
import Echo from "laravel-echo";

import './index.css'

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'd2b8e62a2c2dc0ca21e7',
    cluster: 'us2',
});

const app = createApp(App)

// Sentry.init({
// 	app,
// 	dsn: "https://bb253118dc06d5aac20caad8f88ce693@o4505154867757056.ingest.sentry.io/4506044179939328",
// });

app.use(router)
app.use(VueCookies)
app.use(I18n)
app.use(vClickOutside)
app.use(store)

app.mount('#app')
