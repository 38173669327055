<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :height="size"
        :width="size"
        viewBox="0 0 24 24"
        :fill="fill"
    >
        <path d="M12,2c-0.828,0-1.5,0.672-1.5,1.5S11.172,5,12,5c3.866,0,7,3.134,7,7c0,3.866-3.134,7-7,7	c-0.828,0-1.5,0.672-1.5,1.5S11.172,22,12,22c5.523,0,10-4.477,10-10C22,6.477,17.523,2,12,2z" opacity=".35"/>
        <path d="M11,16c-0.256,0-0.512-0.098-0.707-0.293l-3-3c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L11,13.586l4.293-4.293	c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-5,5C11.512,15.902,11.256,16,11,16z"/>
        <circle
            cx="3.534"
            cy="12.103"
            r="1.5"
            opacity=".35"
        />
        <circle
            cx="4.697"
            cy="16.234"
            r="1.5"
            opacity=".35"
        />
        <circle
            cx="7.821"
            cy="19.321"
            r="1.5"
            opacity=".35"
        />
        <circle
            cx="7.834"
            cy="4.628"
            r="1.5"
            opacity=".35"
        />
        <circle
            cx="4.645"
            cy="7.752"
            r="1.5"
            opacity=".35"
        />
    </svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
