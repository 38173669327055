<template>
	<kronos-notification-manager />

	<div class="w-screen h-screen flex bg-[#EFEFEF] items-center justify-center relative mx-auto | dark:bg-[#1E2124]">
		<slot />
	</div>
</template>

<script>
import KronosNotificationManager from "../NotificationManager/KronosNotificationManager.vue";

export default
{
	components: {
		KronosNotificationManager,
	}
}
</script>