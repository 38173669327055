<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :height="size"
        :width="size"
        viewBox="0 0 24 24"
        :fill="fill"
    >
        <circle cx="12" cy="12" r="10" opacity=".35"/><path d="M17.348,14.711C17.762,13.896,18,12.977,18,12c0-3.314-2.686-6-6-6c-0.977,0-1.896,0.238-2.711,0.652L17.348,14.711z"/><path d="M6.652,9.289C6.238,10.104,6,11.023,6,12c0,3.314,2.686,6,6,6c0.977,0,1.896-0.238,2.711-0.652L6.652,9.289z"/>
    </svg>
</template>

<script>
export default
{
    props: {
        fill: {
            type: String,
            default: '#000000',
        },
        size: {
            type: String,
            default: '48px',
        }
    }
}
</script>
