<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M 6 3 C 4.895 3 4 3.895 4 5 L 4 15 L 3 15 C 2.448 15 2 15.448 2 16 L 2 18 C 2 19.657 3.343 21 5 21 L 12.294922 21 C 12.105922 20.366 12 19.695 12 19 C 12 17.512 12.467766 16.134 13.259766 15 L 6 15 L 6 5 L 19 5 L 19 11 C 19 11.552 19.448 12 20 12 C 20.552 12 21 11.552 21 11 L 21 5 C 21 3.895 20.105 3 19 3 L 6 3 z M 8.5 7 C 8.224 7 8 7.224 8 7.5 L 8 8.5 C 8 8.776 8.224 9 8.5 9 L 9.5 9 C 9.776 9 10 8.776 10 8.5 L 10 7.5 C 10 7.224 9.776 7 9.5 7 L 8.5 7 z M 12.5 7 C 12.224 7 12 7.224 12 7.5 L 12 8.5 C 12 8.776 12.224 9 12.5 9 L 16.5 9 C 16.776 9 17 8.776 17 8.5 L 17 7.5 C 17 7.224 16.776 7 16.5 7 L 12.5 7 z M 8.5 11 C 8.224 11 8 11.224 8 11.5 L 8 12.5 C 8 12.776 8.224 13 8.5 13 L 9.5 13 C 9.776 13 10 12.776 10 12.5 L 10 11.5 C 10 11.224 9.776 11 9.5 11 L 8.5 11 z M 12.5 11 C 12.224 11 12 11.224 12 11.5 L 12 12.5 C 12 12.776 12.224 13 12.5 13 L 16.5 13 C 16.776 13 17 12.776 17 12.5 L 17 11.5 C 17 11.224 16.776 11 16.5 11 L 12.5 11 z M 19 14 C 16.239 14 14 16.239 14 19 C 14 21.761 16.239 24 19 24 C 21.761 24 24 21.761 24 19 C 24 16.239 21.761 14 19 14 z M 21.050781 17.060547 C 21.304031 17.060547 21.5565 17.156109 21.75 17.349609 C 22.137 17.736609 22.137 18.363 21.75 18.75 L 19.199219 21.300781 C 18.812219 21.687781 18.187781 21.687781 17.800781 21.300781 L 16.25 19.75 C 15.863 19.363 15.863 18.736609 16.25 18.349609 C 16.637 17.962609 17.263391 17.962609 17.650391 18.349609 L 18.5 19.199219 L 20.349609 17.349609 C 20.543109 17.156109 20.797531 17.060547 21.050781 17.060547 z"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
