export default {
    namespaced: true,
    state: {
        Roles: [
            {label: 'PROGRAMMER', value: '1', role_id: '1'},
            {label: 'ADMIN', value: '2', role_id: '2'},
            {label: 'DIRECTOR', value: '3', role_id: '3'},
            {label: 'MANAGER', value: '4', role_id: '4'},
            {label: 'SUPPORT', value: '5', role_id: '5'},
            {label: 'EMPLOYEE', value: '6', role_id: '6'},
        ],
        NoteTypes: [
            {label: 'GENERAL', value: '1'},
            {label: 'DOCUMENT MISSING', value: '2'},
            {label: 'OFFER', value: '3'}
        ],
        Columns: [
            {label: 'Status', value: 'status_id'},
            {label: 'Amount', value: 'amount'},
            {label: 'Lender', value: 'lender_id'}
        ],
        Statuses: [
            {label: 'Accepted', value: '1'},
            {label: 'Denied', value: '2'},
            {label: 'Started', value: '3'},
            {label: 'Not Processed', value: '4'},
            {label: 'Waiting Verification', value: '5'},
            {label: 'Waiting Offer', value: '6'}
        ],
        Amounts: [
            {label: '$ 100', value: '100'},
            {label: '$ 250', value: '250'},
            {label: '$ 500', value: '500'},
            {label: '$ 750', value: '750'},
            {label: '$ 1000', value: '1000'},
            {label: '$ 1500', value: '1500'}
        ],
        Pages: [
            {label: 'Client', value: 'client'},
            {label: 'Lead', value: 'lead'},
        ],
    },
}
