// store/index.js
import { createStore } from 'vuex';
import Entities from "./entities.js";
import TransactionOptionsModule from "./transactionOptionsModule";
import CountryStates from "./CountryStates";

const store = createStore({
    state: {
        IsLoading: false,
        ContextUser: null,
        ContextUsers: null,
        ContextTheme: 'light',
        ContextLocale: 'fr',
        SideNavigationIsOpen: false,
        showDetailLead : false,
        ProfileMenuIsOpen: false,
        HoveredLead: [],
        LeadDetailsIsOpen: false,
        notifications: [],
        ContextLead: null,
        ContextIbv: null,
        QuickSearchIsOpen: false,
        GlobalSearchModalIsOpen: false,
        NotificationCount: 0,
        DenyReasons: [],
        LeadStatuses: [],
        GlobalEmailEditor:
        {
            IsOpen: false,
            ContextClient: null,
        },
        ContextLeadApprobationStep: null,
        TeamOptions: [
            {'value': 'approbation', 'label': 'Approbation'},
            {'value': 'collection', 'label': 'Collection'},
            {'value': 'marketing', 'label': 'Marketing'}
        ],
        SelectedTeam: 0,
        TeamSelectionIsOpen: false,
        SelectedUserProfileSection: 0,
        UserProfileSectionOptions: [
            {'value': 'general', 'label': 'General'},
            {'value': 'preferences', 'label': 'Preferences'}
        ],
        StartAt: null,
        EndAt: null,
    },

    mutations: {
        addNotification(state, notification) {
            state.notifications.push(notification);
        },

        removeNotification(state) {
            state.notifications.splice(0, 1);
        }
    },
    modules: {
        transactionOptions: TransactionOptionsModule,
        entities: Entities,
        CountryStates:  CountryStates
    }
})

export default store
