<template>
    <kronos-base-layout>
        <div class="flex flex-col gap-[16px]">
            <kronos-base-heading title="Automations"></kronos-base-heading>

            <automation-table />
        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../UI/Layouts/KronosBaseLayout.vue";
import KronosBaseDateRangeSelector from "../UI/BaseComponents/KronosBaseDateRangeSelector.vue";
import KronosBaseHeading from "../UI/BaseComponents/KronosBaseHeading.vue";
import AutomationTable from "../UI/Tables/AutomationTable.vue";

export default
{
    components: {
        AutomationTable,
        KronosBaseHeading,
        KronosBaseDateRangeSelector,
        KronosBaseLayout
    }

}
</script>

<style scoped>

</style>