<template>
	<div class="gap-1 h-fit flex focus-within:text-blue-500 text-gray-400 duration-500 flex-col w-full">
		<label class="text-[10px] flex items-center h-[16px]text-gray-400 tracking-wide font-medium">
			{{ label }}
		</label>
		<div class="relative max-w-full focus-within:bg-gray-100 duration-500 rounded-[6px] text-gray-400 focus:text-gray-500">
			<input
				:type="type"
				:placeholder="placeholder"
				class="tracking-wide duration-500 border-none w-full focus:text-blue-500 focus:ring-offset-0 ring-0 outline-0 focus:bg-blue-100 bg-gray-100 rounded focus:outline-0 focus:outline-offset-0 focus:ring-blue-500 focus:ring-[1px] px-[10px] font-medium h-[32px] text-[11.5px]"
				v-model="input_value"
                spellcheck="false"
			>
            <div v-if="showIcon" class="absolute inset-y-0 right-0 pr-3 flex items-center">
                <pen-duo-icon
                    class="duration-500 fill-gray-500 cursor-pointer rotate-[-90deg]"
                    size="16px"
                    @click.stop="onIconClick
"/>
            </div>
		</div>
	</div>
</template>

<script>
import CheckDuoIcon from "../../../assets/icons/duotone/CheckDuoIcon.vue";
import CrossDuoIcon from "../../../assets/icons/duotone/CrossDuoIcon.vue";
import ChevronLeftDuoIcon from "../../../assets/icons/duotone/ChevronLeftDuoIcon.vue";
import OfficeDuoIcon from "../../../assets/icons/duotone/OfficeDuoIcon.vue";
import PenDuoIcon from "../../../assets/icons/duotone/PenDuoIcon.vue";

export default
{
	emits: [
		'update-value',
        'icon-click'
	],

	props: {
		placeholder: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'text'
		},
		value: {
			type: String,
			required: true,
		},
        showIcon: {
            type: Boolean,
            default: false,
        },
	},

    components: {
        PenDuoIcon,
    },

	data() {
		return {
			input_value: this.value,
			original_value: this.value,
			focused: false,
		};
	},

	watch: {
		'input_value'(new_value) {
			if (new_value !== this.original_value) {
				this.focused = true;
                this.updateValue();
			} else {
				this.focused = false;
				console.log('Returned to original value:', this.original_value);
			}
		},
        value(newValue) {
            this.input_value = newValue;
        },

    },

	methods: {
		updateValue() {
			this.$emit('update-value', this.input_value);
		},

		resetValue() {
			this.input_value = this.original_value;
		},

        onIconClick() {
            this.$emit('icon-click');
        },
	}
}
</script>
