<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 19C17.7712 19 19.6569 19 20.8284 17.8284C22 16.6569 22 14.7712 22 11C22 7.22876 22 5.34315 20.8284 4.17157C19.6569 3 17.7712 3 14 3H10C6.22876 3 4.34315 3 3.17157 4.17157C2 5.34315 2 7.22876 2 11C2 14.7712 2 16.6569 3.17157 17.8284C3.82475 18.4816 4.69989 18.7706 6 18.8985" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 11V11.01" :stroke="color" stroke-width="2" stroke-linecap="round"/>
        <path d="M8 11V11.01" :stroke="color" stroke-width="2" stroke-linecap="round"/>
        <path d="M16 11V11.01" :stroke="color" stroke-width="2" stroke-linecap="round"/>
        <path d="M14 19C12.7635 19 11.4022 19.4992 10.1586 20.145C8.16119 21.1821 7.16249 21.7007 6.67035 21.3703C6.1782 21.0398 6.27135 20.0151 6.45766 17.9657L6.5 17.5" :stroke="color" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default
{
    props: {
        size: {
            type: String,
            default: '24px',
        },
        color: {
            type: String,
            default: ''
        },
        strokeWidth: {
            type: String,
            default: 2,
        }
    }
}
</script>