import CryptoJS from 'crypto-js';

export const IsPhone = (value) => {
	if (value) {
		const phoneRegEx = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		const contextAreaCode = value.slice(1, 4);
		const areaCodes = ['514', '581', '819', '438', '418', '450', '367', '579', '873'];

		return value.match(phoneRegEx) && areaCodes.includes(contextAreaCode);
	}
}

export const IsEmail = (value) => {
	return value
		? value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
		: false
}

export const DecryptResponseData = async (response_data) => {
	try {
		const iv = base64ToArrayBuffer(response_data.iv); // Extract IV from encrypted data
		const data = base64ToArrayBuffer(response_data.data); // Extract encrypted data without IV
		const key = import.meta.env.VITE_AES_SECRET;

		const keyBuffer = await crypto.subtle.importKey(
			'raw',
			new TextEncoder().encode(key),
			{name: 'AES-CBC', length: 256},
			false,
			['decrypt']
		);

		const decryptedBuffer = await crypto.subtle.decrypt(
			{name: 'AES-CBC', iv: iv},
			keyBuffer,
			data
		);

		return JSON.parse(new TextDecoder().decode(decryptedBuffer));
	} catch (error) {
		console.error('Error decrypting response data:', error);
		return null;
	}
}

function base64ToArrayBuffer(base64String) {
	const binaryString = atob(base64String);
	const byteArray = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		byteArray[i] = binaryString.charCodeAt(i);
	}
	return byteArray.buffer;
}