export const LeadVerificationTable = [
    {
        'label': 'COMPANY',
        'key': 'company',
        'type': 'string',
        'min_w': '200px',
    },
    {
        'label': 'CLIENT ID',
        'key': 'client_id',
        'type': 'number',
        'min_w': '50px',
    },
    {
        'label': 'FULL NAME',
        'key': 'full_name',
        'type': 'string',
        'min_w': '200px',
    },
    {
        'label': 'AMOUNT',
        'key': 'lead.amount',
        'type': 'string',
        'suffix': '$',
        'min_w': '200px',
    },
    {
        'label': 'APPROVED AT',
        'key': 'approved_at',
        'type': 'date',
        'min_w': '200px',
    },
    {
        'label': 'APPROVED BY',
        'key': 'user.first_name',
        'type': 'string',
        'min_w': '150px',
    },
    {
        'label': 'MANAGER',
        'key': 'manager.first_name',
        'type': 'string',
        'min_w': '100px',
    },
    {
        'label': 'CREATED AT',
        'key': 'created_at',
        'type': 'date',
        'min_w': '200px',
    },
    {
        'label': 'MODIFY',
        'action': 'modifyAction',
        'type': 'string',
        'min_w': '50px',
    },
    {
        'label': 'PDF',
        'action': 'downloadAction',
        'type': 'string',
        'min_w': '50px',
    },

];

// @