<template>
    <div class="custom-shadow-0 | flex flex-col gap-[20px] bg-white pb-[20px] rounded-lg border border-gray-100">
        <div class="flex items-center justify-between h-[42px] border-b pl-[24px] border-gray-100">
            <h1 class="text-[12px] font-light">
                Personal Information
            </h1>
            <div class="flex gap-2 border-gray-100 mr-2">
                <a :href="MapsUrl" target="_blank" class="w-[28px] rounded bg-blue-50 h-[28px] group hover:bg-blue-500 duration-500 flex items-center justify-center">
                    <link-out-icon
                        stroke-width="2"
                        size="14px"
                        class="h-[16px] stroke-blue-500 fill-transparent group-hover:stroke-blue-100 duration-500"
                    />
                </a>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="this.UpdateClient()"
                    class="w-[28px] rounded bg-blue-50 h-[28px] group hover:bg-blue-500 duration-500 flex items-center justify-center"
                >
                    <save-icon
                        stroke-width="2"
                        class="h-[16px] stroke-blue-500 fill-transparent group-hover:stroke-blue-100 duration-500"
                    />
                </button>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="ApproveSection"
                    :class="[IsValidated ? 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 ' : 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-green-100', 'duration-500 h-[28px] px-[24px] cursor-pointer text-xs rounded']"
                >
                    {{ IsValidated ? 'Decline' : 'Approve' }} Section
                </button>
            </div>
        </div>

        <div class="grid grid-cols-4 gap-[24px] px-[24px]">
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">First Name</label>
                <input type="text" v-model="payload.first_name" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Last Name</label>
                <input type="text" v-model="payload.last_name" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Date Of Birth</label>
                <input type="date" v-model="payload.date_of_birth" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Email</label>
                <input type="text" v-model="payload.email_address" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Phone Number</label>
                <input v-maska data-maska="+1 ### #######" type="text" v-model="payload.phone_number" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Address Line 1</label>
                <input type="text" v-model="payload.address_line_1" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">City</label>
                <input type="text" v-model="payload.city" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <approbation-step-select-option
                :selected="payload.state"
                :options="State"
                label="State/Province"
                @update-value="this.payload.state = $event"
            />
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">ZIP/Postal Code</label>
                <input v-maska data-maska="A#A #A#" data-maska-tokens="A:[A-Z]" type="text" v-model="payload.zip" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <approbation-step-select-option
                :selected="payload.country"
                :options="CountryOptions"
                label="Country"
                @update-value="this.payload.country = $event"
            />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {UpdateLead} from "../../../../services/Lead";
import {vMaska} from "maska";
import {UpdateClient} from "../../../../services/Client/Client";
import {UpdateClientAddress} from "../../../../services/Client/ClientAddress";
import SaveIcon from "../../../../assets/icons/SaveIcon.vue";
import ApprobationStepSelectOption from "./CustomInput/ApprobationStepSelectOption.vue";
import LinkOutIcon from "../../../../assets/svg/LinkOutIcon.vue";
import NotificationMixin from "../../../../mixins/NotificationMixin";

export default
{
    components: {
        LinkOutIcon,
        ApprobationStepSelectOption,
        SaveIcon
    },

    emits: ['approve-section'],

    props: {
        IsValidated: {
            type: Boolean,
            default: false,
        }
    },

    directives: {
        maska: vMaska
    },

    data() {
        return {
            CountryOptions: {'US': 'UNITED STATES', 'CA': 'CANADA'},
            CanadianProvinces: { AB: 'Alberta', BC: 'British Columbia', MB: 'Manitoba', NB: 'New Brunswick', NL: 'Newfoundland and Labrador', NS: 'Nova Scotia', NT: 'Northwest Territories', NU: 'Nunavut', ON: 'Ontario', PE: 'Prince Edward Island', QC: 'Quebec', SK: 'Saskatchewan', YT: 'Yukon' },
            AmericanStates: { AL: 'Alabama', AK: 'Alaska', AZ: 'Arizona', AR: 'Arkansas', CA: 'California', CO: 'Colorado', CT: 'Connecticut', DE: 'Delaware', FL: 'Florida', GA: 'Georgia', HI: 'Hawaii', ID: 'Idaho', IL: 'Illinois', IN: 'Indiana', IA: 'Iowa', KS: 'Kansas', KY: 'Kentucky', LA: 'Louisiana', ME: 'Maine', MD: 'Maryland', MA: 'Massachusetts', MI: 'Michigan', MN: 'Minnesota', MS: 'Mississippi', MO: 'Missouri', MT: 'Montana', NE: 'Nebraska', NV: 'Nevada', NH: 'New Hampshire', NJ: 'New Jersey', NM: 'New Mexico', NY: 'New York', NC: 'North Carolina', ND: 'North Dakota', OH: 'Ohio', OK: 'Oklahoma', OR: 'Oregon', PA: 'Pennsylvania', RI: 'Rhode Island', SC: 'South Carolina', SD: 'South Dakota', TN: 'Tennessee', TX: 'Texas', UT: 'Utah', VT: 'Vermont', VA: 'Virginia', WA: 'Washington', WV: 'West Virginia', WI: 'Wisconsin', WY: 'Wyoming' },
            payload: {
                'first_name': null,
                'last_name': null,
                'email_address': null,
                'phone_number': null,
                'address_line_1': null,
                'zip': null,
                'state': null,
                'city': null,
                'country': null,
                'date_of_birth': null
            }
        }
    },

    computed: {
        ...mapState([
            'ContextLead'
        ]),

        State() {
            return this.payload.country === 'CA'
                ? this.CanadianProvinces
                : this.AmericanStates;
        },

        MapsUrl() {
            let address = (this.payload.address_line_1 ?? '').replace(/ /g, "+") + '+' +  (this.payload.zip ?? '').replace(/ /g, "+");

            return `https://www.google.ca/maps/place/${address}`
        }
    },

    mixins: [
        NotificationMixin,
    ],

    methods: {
        ApproveSection() {
            this.UpdateClient();

            this.$emit('approve-section', {approved_step: 'personal_information', approved_step_status: !this.IsValidated});
        },

        UpdateClient() {
            const phone_number_formatted = this.payload.phone_number.split(" ");

            const payload_client = {
                'first_name': this.payload.first_name,
                'last_name': this.payload.last_name,
                'email_address': this.payload.email_address,
                'phone_country_code': phone_number_formatted[0],
                'phone_area_code': phone_number_formatted[1],
                'phone_number': phone_number_formatted[2],
                'date_of_birth': this.payload.date_of_birth,
            };

            UpdateClient(this.ContextLead.client.id, payload_client)
                .then((response) => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Client Successfully Updated');
                    }
                })
                .catch(() => {
                    this.showNotification('error', 'An Error Occurred While Updating Client');
                })

            const payload_client_address = {
                'zip': this.payload.zip,
                'address_line_1': this.payload.address_line_1,
                'country': this.payload.country,
                'state': this.payload.state,
                'city': this.payload.city
            };

            // UpdateClientAddress(this.ContextLead.client.addresses.id, payload_client_address)
            //     .then((response) => {
            //         if (response.status === 200) {
            //             this.showNotification('success', 'Client Address Successfully Updated');
            //         }
            //     })
            //     .catch(() => {
            //         this.showNotification('error', 'An Error Occurred While Updating Client Address');
            //     });
        },

        FormatDate(date) {
            const context_date = new Date(date);
            return `${context_date.getFullYear()}-${(context_date.getMonth() + 1 + '').padStart(2, '0')}-${(context_date.getDate() + '').padStart(2, '0')}`;
        }
    },

    mounted() {
        if (this.ContextLead.client) {
            this.payload.first_name = this.ContextLead.client['first_name'];
            this.payload.last_name = this.ContextLead.client['last_name'];
            this.payload.date_of_birth = this.ContextLead.client['date_of_birth'];
            this.payload.email_address = this.ContextLead.client['email_address'] ?? null;
            this.payload.phone_number = `${this.ContextLead.client['phone_country_code']} ${this.ContextLead.client['phone_area_code']} ${this.ContextLead.client['phone_number']}`;

            if (this.ContextLead.client.addresses) {
                this.payload.country = this.ContextLead.client.addresses['country'] ?? '';
                this.payload.state = this.ContextLead.client.addresses['state'];
                this.payload.zip = this.ContextLead.client.addresses['zip'];
                this.payload.city = this.ContextLead.client.addresses['city'];
                this.payload.address_line_1 = this.ContextLead.client.addresses['address_line_1'];
            }
        }
    }
}
</script>
