<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M8,13.869c0-0.771-0.93-1.159-1.478-0.616l-3.893,3.856c-0.495,0.491-0.495,1.291,0,1.782l3.893,3.856	C7.07,23.29,8,22.902,8,22.131V13.869z"/><path d="M7,20c0,0,6.303,0,7,0c1.105,0,2-0.895,2-2s-0.895-2-2-2c-0.697,0-7,0-7,0V20z"/><circle cx="20" cy="18" r="2"/><path d="M17.478,10.747l3.893-3.856c0.495-0.491,0.495-1.291,0-1.782l-3.893-3.856C16.93,0.71,16,1.098,16,1.869V4h-6 C8.895,4,8,4.895,8,6c0,1.105,0.895,2,2,2h6v2.131C16,10.902,16.93,11.29,17.478,10.747z" opacity=".35"/><circle cx="4" cy="6" r="2" opacity=".35"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
