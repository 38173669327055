<template>
    <div class="bg-transparent p-[16px] rounded-b-[4pt] flex gap-[14px]">
        <div class="custom-shadow-1 | min-w-[34px] flex items-center justify-center h-[34px] bg-white rounded-full">
            <div class="w-[26px] h-[26px] rounded-full bg-[#F2F2F2] text-[10.5px] flex items-center justify-center border border-gray-200 border-opacity-50 font-semibold text-gray-500">
                {{ FormatInitials }}
            </div>
        </div>
        <div class="min-w-0 max-w-full flex flex-col gap-[4px] w-full">
            <div class="flex items-center justify-between">
                <span class="text-[10.5px] text-gray-600 font-medium">
                    {{ `${Note?.user['first_name']} ${truncateString(Note?.user['last_name'], 20)}` }}
                </span>

                <p class="text-[10.5px] text-gray-400">
                    {{ FormatDate(Note['created_at']) }}
                </p>
            </div>

            <p :class="[!!Note['deleted_at'] ? 'bg-gray-100 text-gray-500 rounded-lg w-fit' : '', 'break-words text-[10.5px] text-gray-600']">
                {{ Note['note'] }}
                <span
                    v-if="HasBeenUpdated"
                    class="text-gray-400 text-xs ml-2"
                >
                    (Modified)
                </span>
            </p>
        </div>

        <div class="group-hover:block hidden duration-500">
            <div
                v-if="!Note?.deleted_at"
                class="absolute top-0 right-0 p-3 group flex flex-row gap-1"
            >
                <button v-if="ContextUser.id === Note.user.id">
                    <SettingsIcon
                        @click="this.$emit('update-action')"
                        :size="'20px'" class="hover:fill-gray-700"
                        :fill="'#9CA3AF'"
                    />
                </button>
                <button v-if="[1,2].includes(ContextUser.role_id) || ContextUser.id === Note.user.id">
                    <CrossDuoIcon
                        @click="this.$emit('delete-action')"
                        :size="'20px'"
                        class="hover:fill-gray-700"
                        :fill="'#9CA3AF'"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import CrossDuoIcon from "../../../../assets/icons/duotone/CrossDuoIcon.vue";
import SettingsIcon from "../../../../assets/icons/SettingsIcon.vue";
import Tools from "../../../../mixins/Tools"

export default {
    emits: ["update-action", "delete-action"],

    mixins: [Tools],

    components: {
        CrossDuoIcon,
        SettingsIcon
    },

    props: {
        Note: {
            type: Object,
            required: true,
        },
        ContextUser: {
            type: Object,
            required: true
        }
    },

    computed: {
        FormatInitials() {
            return `${this.Note?.user['first_name'].charAt(0)}${this.Note?.user['last_name'].charAt(0)}`
        },

        HasBeenUpdated() {
            return this.Note['created_at'] !== this.Note['updated_at'] && !this.Note['deleted_at'];
        }
    }
}

</script>