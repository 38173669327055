<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<circle cx="7.5" cy="6.5" r="3.5" opacity=".35"/><path d="M18.5,14c-0.164,0-12.836,0-13,0C3.567,14,2,15.567,2,17.5S3.567,21,5.5,21c0.164,0,12.836,0,13,0	c1.933,0,3.5-1.567,3.5-3.5S20.433,14,18.5,14z"/><path d="M2.338,10.338c-0.602,0.602-1.567,0.668-2.041,0.661	C0.132,10.996,0.003,10.867,0,10.702c-0.007-0.474,0.059-1.44,0.661-2.041c0.602-0.601,1.568-0.668,2.042-0.66	C2.868,8.004,2.997,8.133,3,8.298C3.007,8.771,2.94,9.737,2.338,10.338z" opacity=".35"/><circle cx="16.5" cy="6.5" r="3.5" opacity=".35"/><path d="M21.662,10.338c0.602,0.602,1.567,0.668,2.041,0.661	c0.165-0.003,0.294-0.132,0.297-0.297c0.007-0.474-0.059-1.44-0.661-2.041C22.737,8.059,21.772,7.993,21.298,8	c-0.165,0.003-0.294,0.132-0.297,0.297C20.993,8.771,21.06,9.737,21.662,10.338z" opacity=".35"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
