<template>
    <div
        v-if="IsOpen"
        class="fixed top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center"
    >
        <div class="bg-white w-[500px] rounded-lg">
            <header class="h-[45px] flex items-center text-gray-500 justify-between text-sm pl-[18px]">
                Document Missing
                <div class="w-[45px] h-[45px] flex items-center justify-center">
                    <CrossIcon
                        @click="$emit('close')"
                        class="h-[14px] stroke-gray-500 cursor-pointer"
                    />
                </div>
            </header>
            <div class="grid grid-cols-3 gap-[24px] py-[24px] px-[24px]">
                <div @click="SelectedSendingMethod = 0" :class="[SelectedSendingMethod === 0 ? 'bg-blue-500 border-blue-500' : 'border-blue-400 bg-blue-50', 'border-2 cursor-pointer flex items-center justify-center duration-500 flex-col h-[120px] w-full rounded-lg']">
                    <chat-dots-icon
                        size="28px"
                        class="h-[55px] -mt-[10px]"
                        :class="SelectedSendingMethod === 0 ? 'stroke-blue-100' : 'stroke-blue-400'"
                    />
                    <p :class="[SelectedSendingMethod === 0 ? 'text-blue-50' : 'text-blue-400', 'text-xs tracking-wide']">
                        SMS
                    </p>
                </div>
                <div @click="SelectedSendingMethod = 1" :class="[SelectedSendingMethod === 1 ? 'bg-blue-500 border-blue-500' : 'border-blue-400 bg-blue-50', 'border-2 cursor-pointer flex items-center justify-center duration-500 flex-col h-[120px] w-full rounded-lg']">
                    <send-icon
                        size="28px"
                        class="h-[55px] -mt-[10px]"
                        :class="SelectedSendingMethod === 1 ? 'stroke-blue-100' : 'stroke-blue-400'"
                    />
                    <p :class="[SelectedSendingMethod === 1 ? 'text-blue-50' : 'text-blue-400', 'text-xs tracking-wide']">
                        Email
                    </p>
                </div>
                <div @click="SelectedSendingMethod = 2" :class="[SelectedSendingMethod === 2 ? 'bg-blue-500 border-blue-500' : 'border-blue-400 bg-blue-50', 'border-2 cursor-pointer flex items-center justify-center duration-500 flex-col h-[120px] w-full rounded-lg']">
                    <div class="flex h-[55px] gap-2 -mt-[10px]">
                        <chat-dots-icon
                            size="24px"
                            class="h-[40px] mb-[12px]"
                            :class="SelectedSendingMethod === 2 ? 'stroke-blue-100' : 'stroke-blue-400'"
                        />
                        <send-icon
                            size="24px"
                            class="h-[40px] mt-[12px]"
                            :class="SelectedSendingMethod === 2 ? 'stroke-blue-100' : 'stroke-blue-400'"
                        />
                    </div>
                    <p :class="[SelectedSendingMethod === 2 ? 'text-blue-50' : 'text-blue-400', 'text-xs tracking-wide']">
                        Email & SMS
                    </p>
                </div>
            </div>
            <footer class="h-fit px-[12px] flex items-center justify-center">
                <button class="h-[42px] my-[12px] w-full bg-gray-200 text-gray-600 rounded text-xs hover:text-white hover:bg-black duration-500 hover:font-light">
                    Send Message
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import CrossIcon from "../../../../../assets/svg/CrossIcon.vue";
import ChatDotsIcon from "../../../../../assets/svg/ChatDotsIcon.vue";
import SendIcon from "../../../../../assets/svg/SendIcon.vue";

export default
{
    emits: [
        'close'
    ],

    components: {
        SendIcon,
        ChatDotsIcon,
        CrossIcon
    },

    data() {
        return {
            SelectedSendingMethod: 0,
        }
    },

    props: {
        IsOpen: {
            type: Boolean,
            default: false,
        }
    }
};
</script>