<template>
    <div>
        <div class="flex flex-col -mt-1 gap-[2px] mb-[24px] justify-center items-center w-full">
            <h1 class="flex tracking-wide text-[26px] font-medium text-[#212529]">
                Verification Code
            </h1>
            <span class="text-sm text-gray-500 text-opacity-75 text-center -mt-0.5 tracking-wider">
                Enter the code sent to <br />
                <i class="text-gray-600 text-xs">{{ Email ?? '' }}</i>.
            </span>
        </div>
        <div class="w-full flex flex-col gap-4 mt-10">
            <input
                v-model="VerificationCode"
                type="tel"
                maxlength="4"
                class="opacity-0 w-0 h-0"
                inputmode="numeric"
                v-maska
                data-maska="####"
                autofocus
                id="code"
            />
            <div class="flex justify-center gap-4 -mt-7">
                <div
                    v-for="index in 4"
                    :key="index"
                    @click="FocusElement(index)"
                    :class="CodeError ? 'border-red-500 buzz-animation bg-red-100 text-red-600' : VerificationCode.length === index - 1 ? 'border-[#212529] text-gray-700' : 'text-gray-700 border-gray-200'"
                    class="w-[64px] h-[82px] bg-gray-50 border-[4px] flex items-center justify-center rounded text-center text-3xl font-semibold focus:outline-none focus:border-[#4667d2] focus:ring-1 focus:ring-[#212529] duration-500"
                >
                    {{ VerificationCode[index -  1] }}
                </div>
            </div>
            <span
                v-if="CodeError"
                class="text-xs text-red-500 tracking-wide buzz-animation"
            >
                Invalid code. Try again or resend.
            </span>
            <button
                @click="Seconds <= 0 ? ResendCode() : ''"
                :class="[Seconds > 0 ? 'cursor-not-allowed' : 'cursor-pointer', 'font-medium w-fit px-[24px] mt-4 mx-auto bg-[#212529] rounded-lg bg-opacity-10 hover:bg-opacity-100 hover:text-white py-[12px] text-[#212529] text-xs focus:outline-none transition duration-500 ease-in-out']"
            >
                Resend Code <span v-if="Seconds > 0">({{ FormatTime }})</span>
            </button>
        </div>
    </div>
</template>

<script>
import { ResendCode, ValidateCode } from "../../../services/ForgotPassword";
import { vMaska } from "maska";

export default
{
    emits: [
        'next-step',
        'loading'
    ],

    directives: {
        maska: vMaska
    },

    props: {
        IsLoading: {
            type: Boolean,
            default: false,
        },
        Email: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            Seconds: 60,
            VerificationCode: "",
            CodeError: false,
        }
    },

    watch: {
        'VerificationCode'(newValue) {
            if (newValue.length === 4 && !this.verificationCodeValidating) {
                this.verificationCodeValidating = true;
                this.ValidateCode();
            } else if (newValue.length < 4) {
                this.verificationCodeValidating = false;
                this.CodeError = false;
            }
        },
    },

    computed: {
        FormatTime() {
            return `${this.PadNumber(Math.floor(this.Seconds / 60))}:${this.PadNumber(this.Seconds % 60)}`;
        },
    },

    methods: {
        PadNumber(number) {
            return number < 10 ? `0${number}` : number;
        },

        FocusElement() {
            document.getElementById('code').focus();
        },

        ValidateCode() {
            if (!this.IsLoading) {
                this.$emit('loading', true);

                ValidateCode(this.VerificationCode, this.Email)
                    .then((response) => {
                        if (response.status === 200) {
                            this.$emit("next-step");
                        }
                    })
                    .catch(() => {
                        this.CodeError = true;
                    })
                    .finally(() => {
                        this.verificationCodeValidating = false;
                        this.$emit('loading', false);
                    })
            }
        },

        ResendCode() {
            if (!this.IsLoading && this.Seconds <= 0) {
                this.$emit('loading', true);

                ResendCode({email: this.Email})
                    .then(() => {
                        this.showNotification('success', 'Code Resent Successfully');
                    })
                    .catch(() => {
                        this.showNotification('error', 'An Error Occurred When Resending Code');
                    })
                    .finally(() => {
                        this.$emit('loading', false);
                        this.StartTimer();
                    })
            }
        },

        StartTimer() {
            this.Seconds = 60;
            this.timer = setInterval(() => {
                this.Seconds > 0 ? this.Seconds-- : clearInterval(this.timer);
            }, 1000);
        },
    },

    mounted() {
        this.StartTimer();
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
}
</script>

<style scoped>
@keyframes buzz {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.buzz-animation {
    animation: buzz 0.5s ease-in-out both;
}
</style>
