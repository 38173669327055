<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="6" width="16" height="4" :stroke="color" :stroke-width="storeWidth" stroke-miterlimit="5.75877" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="6" y="14" width="16" height="4" :stroke="color" :stroke-width="storeWidth" stroke-miterlimit="5.75877" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default
{
    props: {
        size: {
            type: String,
            default: '24',
        },
        color: {
            type: String,
            default: ''
        },
        storeWidth: {
            type: String,
            default: 2,
        }
    }
}
</script>
