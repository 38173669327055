import api from './Api';

const BaseURL = 'v1/user-task';

export const DestroyUserTask = async (id) =>
{
    return await api().delete(`${BaseURL}/${id}`)
}
export const UpdateUserTask = async (id, data) =>
{
    return await api().put(`${BaseURL}/${id}`, data)
}
export const StoreUserTask = async (data) =>
{
    return await api().post(`${BaseURL}`, data)
}
export const RetrieveUserTasks = async (id , resource) =>
{
    return await api().get(`${BaseURL}?${resource}=${id}`)
};