import ClientPage from "../../pages/ClientPage.vue";
import ClientsPage from "../../pages/ClientsPage.vue";

export default [
	{   path: '/client/:client_id',
		component: ClientPage,
		name: 'ClientPage',
		meta: {
			type: 'private',
			authOnly: true,
		}
	},
	{
		path: '/clients',
		component: ClientsPage,
		name: 'ClientsPage',
		meta: {
			type: 'private',
			authOnly: true,
		}
	},
];
