<template>
    <div
        :class="[
            hasError ? 'bg-red-100' : '',
            'h-[53px] flex flex-col py-2'
        ]"
    >
        <label
            :for="label"
            :class="[hasError ? 'text-red-600' : 'text-gray-400', 'pl-[12pt] text-[8pt] min-w-fit text-gray-400 capitalize']"
        >
            {{ label }}
        </label>

        <div class="flex items-center w-full justify-start">
            <KronosBaseDropdown
                v-if="type === 'select'"
                :selected-option="selectedValue"
                :options="options"
                @update="$emit('update-value', $event)"
                class="w-full py-1 px-[3pt]"
            />

            <KronosBaseDropDownValue
                v-if="type === 'valueDropdown'"
                :selected-option="selectedValue"
                :options="options"
                @update="$emit('update-value', $event)"
                class="w-full py-1 px-[3pt]"
            />

            <input
                v-else
                v-maska
                :data-maska="mask"
                :id="label"
                :name="label"
                :type="type"
                :class="[styleClasses, hasError ? 'text-red-500' : '' ]"
                :placeholder="placeholder"
                :value="value"
                :readonly="isReadonly"
                :checked="value"
                @input="$emit('update-value', $event.target.value)"
                :maxlength="charLimit"
            />


        </div>
    </div>
</template>

<script>
import KronosBaseDropdown from "./KronosBaseDropdown.vue";
import KronosBaseDropDownValue from "./KronosBaseDropDownValue.vue";
import {vMaska} from "maska";

export default {
    emits: ['update-value', 'update:value', 'errors'],

    directives: {
        maska: vMaska,
    },

    components: {
        KronosBaseDropdown,
        KronosBaseDropDownValue
    },

    props: {
        label: {
            type: String,
            default: 'label'
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: 'Placeholder',
        },
        options: {
            type: Array,
            required: false,
        },
        selectedValue: {
            type: String,
            required: false,
        },
        mask: {
            type: String,
            required: false,
        },
        value: {
            type: [String, null, Number, Boolean],
            required: false,
            default: ''
        },
        inputCase: {
            type: String,
            default: '',
        },
        hasError: {
            type: Boolean,
            default: false
        },
        charLimit: {
            type: Number,
            default: 255
        },
        isReadonly: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            isOpen: false,
        }
    },

    methods: {
        updateValue(event) {
            const value = this.type !== 'checkbox'
                ? event.target.value
                : event.target.checked ? 1 : 0;

            this.$emit('update:value', value)
        }
    },

    computed: {
        styleClasses() {
            if (['email', 'text', 'custom'].includes(this.type)) {
                return `${this.inputCase} w-full rounded-md border-0 bg-transparent text-xs py-1 placeholder:text-gray-400 tracking-wide text-gray-900 px-[12pt] focus:ring-0 px-0 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none focus:invalid:ring-red-600 focus:outline-none`;
            }

            if (this.type === 'checkbox') {
                return `${this.inputCase} h-4 w-4 ml-[12pt] mt-1 rounded border-gray-300 text-gray-900 focus:ring-0 cursor-pointer`;
            }

            if (this.type === 'custom') {
                const numericValue = this.value.replace(/[\s()-]/g, '');
                if (numericValue.length < 10) {
                    return `${this.inputCase} text-red-600`;
                }
            }
        },
    }
}
</script>

<style scoped>

</style>