
<template>
    <kronos-base-task-modal
        :is-open="IsOpen"
        @close="IsOpen = false"
        @submit="close"
        title="Note"
        :can-close="true"
        width="350px"
        :save-button="true"
        :has-header="true"
        class="z-60"
        button-class="text-sm text-gray-500 border border-gray-500 rounded bg-white hover:bg-gray-200 h-full"
    >
        <p class="flex text-[13px] item-center justify-start tracking-wide text-gray-500">
            This note is gonna be deleted. Would you like to continue?
        </p>

        <template v-slot:modal-button>
            <button
                @click="deleteLeadNote"
                class="text-sm text-white rounded h-[44px] bg-[#4667d2] hover:bg-[#6f89dc]"
            >
                Delete
            </button>
        </template>
    </kronos-base-task-modal>
</template>

<script>
import KronosBaseTaskModal from "../../../../UI/BaseComponents/KronosBaseTaskModal.vue";
import UpdateModal from "./UpdateModal.vue";
import { DestroyLeadNote } from "../../../../services/LeadNote";
import NotificationMixin from "../../../../mixins/NotificationMixin";

export default {
    emits: ["close", "update"],

    mixins: [
        NotificationMixin
    ],

    components: {
        UpdateModal,
        KronosBaseTaskModal
    },

    props: {
        IsOpen: {
            type: Boolean,
            default: false
        },
        SelectedOpen: {
            type: Object,
            required: true
        },
        updateData: {
            type: Function,
            required: false
        }
    },

    methods: {
        async deleteLeadNote() {
            if (!this.isLoading) {
                this.isLoading = true;

                await DestroyLeadNote(this.SelectedOpen.id)
                    .then(async (response) => {
                        if (response.status === 200) {
                            this.showNotification("success", "Note deleted successfully.");
                            this.$emit("update")
                            this.$forceUpdate()

                        }
                    })
                    .catch(() => {
                        this.showNotification("error", "Error deleting Note");
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.close();
                    })
            }
        },

        close() {
            this.$emit("close");
        }
    }
}
</script>
