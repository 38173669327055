<template>
    <div class="w-full h-fit bg-white shadow-custom-shadow border border-gray-100 rounded-lg">
        <div class="flex h-[45px] pl-[12pt] justify-between border-b border-gray-100">
            <h3 class="text-md font-medium text-gray-900 flex flex-wrap content-center">
                Payments history
            </h3>
            <div class="h-full ml-auto inline-flex justify-center content-center items-center">
                <button
                    type="button"
                    class="h-full pr-4 text-[16pt] font-light text-gray-400"
                >
                    +
                </button>
                <button
                    type="button"
                    class="h-full rounded-tr-lg bg-white border-l border-gray-100 pr-4 pl-4"
                >
                    <reload-icon
                        class=""
                        size="16px"
                        fill="darkgray"
                    />
                </button>
            </div>
        </div>

        <div class="p-[12pt] bg-gray-200 bg-opacity-40">
            <table class="table border-opacity-2 bg-white h-auto w-full border-round shadow-custom-shadow-2 rounded-lg divide-y divide-gray-100 ">
                <thead class="text-[10pt] font-medium tracking-wide text-gray-700">
                <tr class="h-14">
                    <th class="w-1/6 font-medium" scope="col">Identifier</th>
                    <th class="w-1/6 font-medium" scope="col">Status</th>
                    <th class="w-1/6 font-medium" scope="col">Paid amount</th>
                    <th class="w-1/6 font-medium" scope="col">Rate</th>
                    <th class="w-1/6 font-medium" scope="col">Balance</th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-100 text-center text-xs text-gray-900">
                <tr
                    class="h-10"
                    v-for="data in info"
                    :key="data.Identifier"
                >
                    <td>{{data.Identifier}}</td>
                    <td>{{data.Status}}</td>
                    <td>{{data.PaidAmount}}</td>
                    <td>{{data.Rate}}</td>
                    <td>{{data.Balance}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";

export default
{
    components: {
        ReloadIcon,
    },
    data() {
        return {
            info: [
                { Identifier: "1", Status: "Paid", PaidAmount: "324$", Balance:"500$" , Rate: "2.5%"},
                { Identifier: "2", Status: "Not paid", PaidAmount: "324$", Balance:"500$" , Rate: "2.5%"},
                { Identifier: "3", Status: "Not paid", PaidAmount: "324$", Balance:"500$" , Rate: "2.5%"},
                { Identifier: "4", Status: "Not paid", PaidAmount: "324$", Balance:"500$" , Rate: "2.5%"},
                { Identifier: "5", Status: "Paid", PaidAmount: "324$", Balance:"500$" , Rate: "2.5%"},
            ],
            showDropdown: false,
        }
    },
}
</script>