<template>
	<lead-dispatcher-accounts-order-modal
		:isOpen="leadDispatcherAccountOrderModalIsOpen"
		@close="leadDispatcherAccountOrderModalIsOpen = false"
	/>
	<kronos-base-layout>
		<div class="flex flex-col gap-10">
			<kronos-base-heading title="Lead Dispatcher">
				<kronos-base-date-range-selector
					:context-selected-dates="[$route?.query?.start_at, $route?.query?.end_at]"
					@change-range="fetchData()"
					@reload="fetchData()"
				>
					<template v-slot:actions>
						<div
							@click="leadDispatcherAccountOrderModalIsOpen = !leadDispatcherAccountOrderModalIsOpen"
							class="w-[40px] h-[40px] flex items-center justify-center cursor-pointer hover:bg-gray-50 duration-300 hover:rounded-l-lg"
						>
							<tune-icon
								size="14px"
								fill="#6b7280"
							/>
						</div>
					</template>
				</kronos-base-date-range-selector>
			</kronos-base-heading>
			<div>
				<dl class="mx-auto grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-4 gap-x-4">
					<div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-4 sm:px-6 xl:px-8 rounded-lg">
						<dt class="text-sm font-medium leading-6 text-gray-500">Total Lead Sold</dt>
						<dd
							:class=" diff(statistics?.total?.previous ?? 0, statistics?.total?.current ?? 0) >= 0 ? 'text-green-500' : 'text-rose-600'"
							class="text-xs font-medium"
						>
							<div v-if="!isLoading">
								{{ diff(statistics?.total?.previous ?? 0, statistics?.total?.current ?? 0) }}% ({{ statistics?.total?.previous ?? 0 }})
							</div>
							<div v-else class="skeleton | h-[16px] rounded-sm w-[65px]"></div>
						</dd>

						<div class="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
							<div v-if="!isLoading">
								{{ statistics?.total?.current ?? 0 }}
							</div>
							<div v-else class="skeleton | h-[32px] w-1/3 rounded-sm"></div>
						</div>
					</div>
					<div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-4 sm:px-6 xl:px-8 rounded-lg">
						<div class="text-sm font-medium leading-6 text-gray-500">Income</div>
						<div
							:class=" diff(parseInt(statistics?.income?.previous) ?? 0, parseInt(statistics?.income?.current) ?? 0) >= 0 ? 'text-green-500' : 'text-rose-600'"
							class="text-xs font-medium"
						>
							<div v-if="!isLoading">
								{{ diff(parseInt(statistics?.income?.previous) ?? 0, parseInt(statistics?.income?.current) ?? 0) }}% (${{ statistics?.income?.previous ?? 0 }})
							</div>
							<div v-else class="skeleton | h-[16px] rounded-sm w-[65px]"></div>
						</div>
						<div class="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
							<div v-if="!isLoading">
								${{ statistics?.income?.current ?? 0 }}
							</div>
							<div v-else class="skeleton | h-[32px] w-1/3 rounded-sm"></div>
						</div>
					</div>
					<div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-4 sm:px-6 xl:px-8 rounded-lg">
						<dt class="text-sm font-medium leading-6 text-gray-500">QC Lead</dt>
						<dd
							:class=" diff(statistics?.qc_leads?.previous ?? 0, statistics?.qc_leads?.current ?? 0) >= 0 ? 'text-green-500' : 'text-rose-600'"
							class="text-xs font-medium"
						>
							<div v-if="!isLoading">
								{{ diff(statistics?.qc_leads?.previous ?? 0, statistics?.qc_leads?.current ?? 0) }}% ({{ statistics?.qc_leads?.previous ?? 0 }})
							</div>
							<div v-else class="skeleton | h-[16px] rounded-sm w-[65px]"></div>
						</dd>
						<div class="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
							<div v-if="!isLoading">
								{{ statistics?.qc_leads?.current ?? 0 }}
							</div>
							<div v-else class="skeleton | h-[32px] w-1/3 rounded-sm"></div>
						</div>
					</div>
					<div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-4 sm:px-6 xl:px-8 rounded-lg">
						<dt class="text-sm font-medium leading-6 text-gray-500">Canada Leads</dt>
						<dd
							:class=" diff(statistics?.canadien_leads?.previous ?? 0, statistics?.canadien_leads?.current ?? 0) >= 0 ? 'text-green-500' : 'text-rose-600'"
							class="text-xs font-medium"
						>
							<div v-if="!isLoading">
								{{ diff(statistics?.canadien_leads?.previous ?? 0, statistics?.canadien_leads?.current ?? 0) }}% ({{ statistics?.canadien_leads?.previous ?? 0 }})
							</div>
							<div v-else class="skeleton | h-[16px] rounded-sm w-[65px]"></div>
						</dd>
						<div class="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
							<div v-if="!isLoading">
								{{ statistics?.canadien_leads?.current ?? 0 }}
							</div>
							<div v-else class="skeleton | h-[32px] w-1/3 rounded-sm"></div>
						</div>
					</div>
				</dl>
			</div>
			<kronos-base-table
				title="Leads"
				:dataset="dataset"
				:is-loading="isLoading"
				:config="tableConfig"
				:total="total"
				@reload="fetchData()"
			/>
		</div>
	</kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../../UI/Layouts/KronosBaseLayout.vue";
import {mapState} from "vuex";
import KronosBaseHeading from "../../UI/BaseComponents/KronosBaseHeading.vue";
import KronosBaseDateRangeSelector from "../../UI/BaseComponents/KronosBaseDateRangeSelector.vue";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import KronosBaseFilterByTable from "../../UI/BaseComponents/KronosBaseFilterByTable.vue";
import {RetrieveLead, RetrieveLeadStatistics} from "../../services/LeadDispatcher";
import KronosBaseTable from "../../UI/BaseComponents/KronosBaseTable.vue";
import {LeadDispatcherTable} from "../../config/TableViews/LeadDispatcherTable";
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";
import SearchIcon from "../../assets/icons/SearchIcon.vue";
import SettingsIcon from "../../assets/icons/SettingsIcon.vue";
import TuneIcon from "../../assets/icons/TuneIcon.vue";
import CloseIcon from "../../assets/icons/CloseIcon.vue";
import LeadDispatcherAccountsOrderModal
	from "../../components/Tools/LeadDispatcher/LeadDispatcherAccountsOrderModal.vue";

export default
{
	name: 'Dashboard',

	components: {
		LeadDispatcherAccountsOrderModal,
		CloseIcon,
		TuneIcon,
		SettingsIcon,
		SearchIcon,
		ReloadIcon,
		KronosBaseTable,
		KronosBaseFilterByTable,
		ChevronDownIcon,
		KronosBaseDateRangeSelector,
		KronosBaseHeading,
		KronosBaseLayout,
	},

	computed: {
		...mapState([
			'ContextUser'
		]),

		tableConfig() {
			return LeadDispatcherTable;
		},
	},

	data() {
		return {
			isLoading: false,
			dataset: [],
			total: 0,
			statistics: [],
			leadDispatcherAccountOrderModalIsOpen: false,
		}
	},

	methods: {
		async fetchData() {
			const data = this.$route.query;

			if (data?.start_at && data?.end_at && data?.page && data?.limit) {
				console.log(data);
				this.isLoading = true;
				this.dataset = [];
				await RetrieveLead(data.page, data.limit)
					.then(response => {
						this.dataset = response.data.data.leads;
						this.total = response.data.data.total;
					})

				// await RetrieveLeadStatistics(data.end_at, data.end_at)
				// 	.then(response => {
				// 		this.statistics = response.data.data.statistics;
				// 	})

				this.isLoading = false;
			}
		},

		diff(prev, current) {
			return ((current - prev) / Math.abs(prev === 0 ? 1 : prev)) * 100;
		},
	},
}
</script>