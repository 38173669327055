<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M11.805,15.288h-0.01c-1.116,0-1.978-1.009-1.787-2.109c0.583-3.355,3.194-3.472,3.194-5.555c0-0.579-0.1-1.874-1.494-1.874	c-0.754,0-1.197,0.482-1.454,0.995C9.906,7.439,9.103,7.781,8.338,7.646l0,0c-1.16-0.204-1.872-1.449-1.38-2.519	C7.662,3.6,9.102,2,11.953,2C16.58,2,17.2,5.623,17.2,7.326c0,4.027-2.999,4.177-3.62,6.497	C13.356,14.66,12.671,15.288,11.805,15.288z"/><path d="M14.209,19.556c0,0.687-0.212,1.266-0.637,1.738C13.144,21.764,12.587,22,11.9,22	c-0.69,0-1.247-0.236-1.672-0.706c-0.425-0.472-0.64-1.051-0.64-1.738c0-0.671,0.215-1.25,0.64-1.741	c0.425-0.488,0.982-0.732,1.672-0.732c0.687,0,1.245,0.244,1.672,0.732C13.996,18.306,14.209,18.884,14.209,19.556z" opacity=".65"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
