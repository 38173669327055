import { createI18n } from 'vue-i18n';

import fr from './../locales/FR/index.js';
import en from './../locales/EN/index.js';

function getLocale()
{
	if (!localStorage.locale) {
		const defaultLocale = "fr";
		const urlParams = new URLSearchParams(window.location.search);
		const lang = urlParams.get('lang');
		const contextLocale = lang ? lang : defaultLocale;

		localStorage.setItem("locale", contextLocale)
	}
	return localStorage.getItem("locale");
}

const i18n = new createI18n({
	getLocale: getLocale(),
	locale: localStorage.getItem("locale") || "fr",
	fallbackLocale: localStorage.getItem("locale") || "en",
	messages: {
		fr,
		en
	}
});

export default i18n;