<template>
    <kronos-base-information-card
        title="Client Phones"
        :first-page="currentPage + 1"
        :last-page="this.client_phones.length"
        :is-editing="isEditing"
        :can-next="can_next"
        :can-prev="can_prev"
        @next="handlePagination('next')"
        @prev="handlePagination('prev')"
        @submit-form="saveChanges"
        :has-action-button="false"

    >
        <div class="divide-y divide-gray-100">
            <div class="flex divide-x divide-gray-100">
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="custom"
                    mask="(###) ###-####"
                    label="Phone"
                    :has-error="errors.phone"
                    v-model:value="client_phone.phone"
                    :placeholder="client_phone.phone"
                />
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="select"
                    label="Phone Type"
                    :options="['WORK', 'PERSONAL', 'CELLPHONE', 'OTHER', 'HOME']"
                    :selected-value="client_phone?.type ?? 'OTHER'"
                    @update-value="client_phone.type = $event"

                />
            </div>
            <div class="flex divide-x divide-gray-100">
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="checkbox"
                    label="SMS Ready"
                    v-model:value="client_phone.sms_ready"
                />
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="checkbox"
                    label="Call Ready"
                    v-model:value="client_phone.call_ready"
                />
            </div>
        </div>
    </kronos-base-information-card>
</template>

<script>
import KronosBaseInformationCardInput from "../../UI/BaseComponents/KronosBaseInformationCardInput.vue";
import KronosBaseInformationCard from "../../UI/BaseComponents/KronosBaseInformationCard.vue";
import {RetrieveClientPhones, UpdateClientPhone} from "../../services/Client/ClientPhone";
import NotificationMixin from "../../mixins/NotificationMixin";

export default {
    components: {
        KronosBaseInformationCard,
        KronosBaseInformationCardInput,
    },

    mixins: [
        NotificationMixin
    ],

    data() {
        return {
            currentPage: 0,
            can_prev: false,
            can_next: false,
            isEditing: false,
            isLoading: true,
            client_phone: [],
            client_phones: [],
            errors: {
                phone: false,
            }
        }
    },

    watch: {
        'client_phone': {
            handler(newValue, oldValue) {
                if (!this.isLoading) {
                    let context_phone = newValue.phone.replace(this.client_phone.phone_regional_code, '').replace(/[\s()-]/g, '').trim()

                    if (newValue.id === oldValue.id) {
                        this.isEditing = !this.isLoading &&
                            (
                                context_phone !== this.client_phones[this.currentPage].phone ||
                                newValue.type !== this.client_phones[this.currentPage].type ||
                                !!newValue.sms_ready !== !!this.client_phones[this.currentPage].sms_ready ||
                                !!newValue.call_ready !== !!this.client_phones[this.currentPage].call_ready
                            );

                        this.isEditing
                            ? [this.can_prev, this.can_next] = [false, false]
                            : this.checkPagination();

                        newValue.phone.length !== 14
                            ? [this.can_prev, this.can_next, this.errors.phone, this.isEditing] = [false, false, true, false]
                            : this.errors.phone = false;

                        this.$forceUpdate();
                    }
                }
            },
            deep: true,
        },
    },

    methods: {
        saveChanges() {
            let updateData = {
                "client_id": this.client_phone.client_id,
                "phone": this.client_phone.phone.replace(this.client_phone.phone_regional_code, '').replace(/[\s()-]/g, '').trim(),
                "type": this.client_phone.type,
                "sms_ready": this.client_phone.sms_ready ? 1 : 0,
                "call_ready": this.client_phone.call_ready ? 1 : 0,

            };

            UpdateClientPhone(this.client_phone.id, updateData)
                .then(response => {
                    this.client_phones = (updateData);
                    console.log(response);
                    this.showNotification('success', "Client phone updated successfully.");


                }).catch(error => {
                console.error("Error updating client phone:", error);
                this.showNotification('error', "The phone doesnt exist");
                this.errors.phone = true;

            })
                .finally(() => {
                    this.isEditing = false;
                });
        },

        handlePagination(step) {
            step === 'next'
                ? this.currentPage += 1
                : this.currentPage -= 1;

            this.checkPagination()

            this.client_phone = JSON.parse(JSON.stringify(this.client_phones[this.currentPage]));
        },

        checkPagination() {
            this.can_next = this.currentPage + 1 < this.client_phones.length
            this.can_prev = this.currentPage > 0;

            this.$forceUpdate();
        },

        fetchData() {
            this.isLoading = true;

            RetrieveClientPhones(this.$route.params.id)
                .then((response) => {
                    this.client_phones = response.data.data.client_phones.map(phone => ({
                        ...phone,
                        call_ready: Boolean(phone.call_ready),
                        sms_ready: Boolean(phone.sms_ready)
                    }));
                    this.client_phones = response.data.data.client_phones;
                    this.client_phone = JSON.parse(JSON.stringify(this.client_phones[0]));
                    this.can_next = this.client_phones.length > 0;
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
    },

    mounted() {
        this.fetchData();

    },

}
</script>