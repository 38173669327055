<template>
    <div class="custom-shadow-1 | w-1/2 h-fit">
        <div class="h-[50px] w-full flex items-center border-b border-gray-100 justify-between bg-white rounded-t-[4pt]">
            <p class="h-[50px] capitalize w-full font-medium tracking-wide text-[13px] text-[#6F6F6F] flex items-center pl-[18px]">
                Payment Calendar
            </p>
            <div>
                <div @click="CalendarViewType = CalendarViewType === 1 ? 2 : 1" class="w-[50px] cursor-pointer h-[50px] flex items-center justify-center">
                    <grid-icon
                        v-if="CalendarViewType === 2"
                        color="#595959"
                        size="18px"
                    />
                    <list-icon
                        v-else
                        color="#595959"
                        size="18px"
                    />
                </div>
            </div>
        </div>
        <div v-if="CalendarViewType === 1">
            <div class="h-[58px] w-full bg-white flex items-center justify-between gap-[16px] px-[18px]">
                <div class="flex gap-[8px] w-1/3">
                    <div
                        @click="this.MonthNavigation('PREV')"
                        class="w-[24px] h-[24px] rounded-[4pt] flex items-center cursor-pointer justify-center bg-gray-100 hover:bg-gray-200 duration-500"
                    >
                        <chevron-left-small-icon color="#6b7280" size="14px"/>
                    </div>
                    <div
                        @click="this.MonthNavigation('NEXT')"
                        class="w-[24px] h-[24px] rounded-[4pt] flex items-center cursor-pointer justify-center bg-gray-100 hover:bg-gray-200 duration-500"
                    >
                        <chevron-right-small-icon color="#6b7280" size="14px"/>
                    </div>
                </div>
                <div class="w-1/3 flex items-center justify-center">
                    <p class="w-[150px] bg-gray-100 text-[11.5px] h-[24px] text-gray-500 rounded-[4pt] flex items-center justify-center">
                        {{ `${CalendarData.MonthName} - ${CalendarData.Year}` }}
                    </p>
                </div>
                <div class="w-1/3 flex items-center justify-end">
                    <div
                        v-click-outside="CloseCalendarSelection"
                        class="h-[24px] w-[145px] bg-gray-100 relative cursor-pointer rounded-[4pt] tracking-wide text-[9.5px] text-[#6b7280] flex font-medium items-center justify-between pl-[10px] pr-[6px]"
                    >
                        <div
                            @click="CalendarSelectionIsOpen = !CalendarSelectionIsOpen"
                            class="flex items-center justify-between w-full truncate"
                        >
                            {{ this.SelectedCalendar.length > 1 ? 'Multiple Selected' : `Calendar #${this.SelectedCalendar[0]}` }}
                            <chevron-right-small-icon
                                :class="[!CalendarSelectionIsOpen ? 'rotate-180' : 'rotate-90', 'duration-500']"
                                color="#6b7280"
                                size="14px"
                            />
                        </div>
                        <div
                            v-if="CalendarSelectionIsOpen"
                            class="absolute w-[150px] top-0 bg-gray-100 mt-[28px] right-0 rounded-[4px]"
                        >
                            <div
                                v-for="Calendar in this.LoanCalendars"
                                @click="this.SelectPaymentCalendar(Calendar.id)"
                                class="text-[9px] py-[6px] px-[10px] flex items-center hover:bg-gray-200 duration-500 last-of-type:hover:rounded-b-[4pt] first-of-type::hover:rounded-t-[4pt]"
                                :class="SelectedCalendar.includes(Calendar.id) ? 'justify-between' : 'justify-end'"
                            >
                                <check-icon
                                    v-if="SelectedCalendar.includes(Calendar.id)"
                                    class="h-fit w-fit"
                                    size="12px"
                                    color="#408DBC"
                                    stroke-width="3"
                                />
                                {{ `${Calendar.is_guarantor ? 'Guarantor' : 'Loan'} #${Calendar.id}` }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid h-fit w-full grid-cols-7 grid-rows-1 bg-white border-t border-gray-100">
                <p
                    class="flex items-center justify-center h-[24px] bg-[#F9F9F9] border-gray- text-[10px] text-gray-600"
                    v-for="Day in DaysOrWeek"
                >
                    {{ Day }}
                </p>
            </div>
            <div class="grid h-fit w-full grid-cols-1 bg-white border-t border-gray-300 pb-[16px] rounded-b-[4pt]">
                <div
                    v-for="Month in MonthData"
                    class="grid grid-cols-7 h-fit min-h-[85px] border-b border-gray-100"
                >
                    <div
                        v-for="Day in Month"
                        class="last-of-type:border-none border-r border-gray-200 flex flex-col gap-[4pt]"
                        :class="!Day ? 'bg-[#F9F9F9]' : 'bg-white'"
                    >
                        <p
                            :class="[IsCurrentDay(Day) ? 'text-white bg-theme-blue rounded-br-[8pt] w-fit font-semibold' : 'text-gray-500 font-medium' ,'text-[11px] px-[8px] py-[4px]']"
                        >
                            {{ Day }}
                        </p>
                        <div class="flex flex-col pb-[6px]">
                            <div
                                v-for="(Payment, PaymentIndex) in CurrentMonthPayments"
                                :key="PaymentIndex"
                            >
                                <div
                                    v-if="this.PaymentBelongsToDate(Payment, Day) && this.SelectedCalendar.includes(Payment.loan_payment_calendar.id)"
                                    class="text-white mt-[0.5px] text-[9px] rounded-[2pt] px-[6px] py-[2.5px] flex items-center justify-between"
                                    :class="Payment.loan_payment_calendar.is_guarantor ? 'bg-gray-400' : 'bg-theme-blue'"
                                >
                                    <p class="tracking-wide">
                                        {{ `#${Payment.id}` }}
                                    </p>
                                    <p class="tracking-wide">
                                        {{ `${Payment.payment_amount.toFixed(2)}$` }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <table class="overflow-scroll bg-white w-full max-w-full ">
                <thead>
                    <tr class="max-h-[38px] h-[38px] divide-x divide-[#F2F2F2]">
                        <td class="bg-white max-h-[38px] truncate w-[4px]"></td>
                        <td class="bg-white max-h-[38px] truncate">
                            <span class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                Order
                            </span>
                        </td>
                        <td class="bg-white max-h-[38px] truncate">
                            <span class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                Status
                            </span>
                        </td>
                        <td class="bg-white max-h-[38px] truncate">
                            <span class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                Date
                            </span>
                        </td>
                        <td class="bg-white max-h-[38px] truncate">
                            <span class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                Payment
                            </span>
                        </td>
                        <td class="bg-white max-h-[38px] truncate">
                            <span class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                Balance
                            </span>
                        </td>
                        <td class="bg-white max-h-[38px] truncate">
                            <span class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                Guarantor Fee
                            </span>
                        </td>
                    </tr>
                </thead>
                <tbody v-if="LoanCalendars && LoanCalendars[0].loan_payments.length > 0" class="divide-[#F2F2F2] divide-y">
                    <tr
                        v-for="(Payment, Index) in LoanCalendars[0].loan_payments"
                        :key="Index"
                        :class="Index === nextEventIndex ? 'hover:bg-theme-blue hover:bg-opacity-10 bg-white divide-x divide-gray-100' : 'hover:bg-gray-100'"
                        class="h-[38px] hover:bg-gray-100 duration-500 bg-white divide-x divide-gray-100"
                    >
                        <td class="w-[4px]" :class="Index === nextEventIndex ? 'bg-theme-blue' : 'bg-white'"></td>
                        <td><div class="cursor-pointer flex items-center justify-center text-[10.5px] font-medium text-theme-blue truncate capitalize">{{ Payment.order }}</div></td>
                        <td class="flex items-center min-h-[38px] justify-center">
                            <div>
                                <div class="cursor-pointer flex items-center rounded-full justify-center text-[10.5px] font-medium truncate capitalize px-[8px] pt-[3px] pb-[2px]" :style="Payment.loan_payment_status.style">{{ Payment.loan_payment_status.name_en }}</div>
                            </div>
                        </td>
                        <td><div class="cursor-pointer flex items-center justify-center text-[10.5px] font-medium text-gray-500 truncate capitalize">{{ Payment.scheduled_at }}</div></td>
                        <td><div class="cursor-pointer flex items-center justify-center text-[10.5px] font-medium text-gray-500 truncate capitalize">{{ Payment.payment_amount }}</div></td>
                        <td><div class="cursor-pointer flex items-center justify-center text-[10.5px] font-medium text-gray-500 truncate capitalize">{{ Payment.residual }}</div></td>
                        <td><div class="cursor-pointer flex items-center justify-center text-[10.5px] font-medium text-gray-500 truncate capitalize">{{ LoanCalendars[1].loan_payments[Index].payment_amount.toFixed(2) }}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import ChevronRightSmallIcon from "../../../assets/svg/ChevronRightSmallIcon.vue";
import ChevronLeftSmallIcon from "../../../assets/svg/ChevronLeftSmallIcon.vue";
import {RetrieveLoanCalendarPayment} from "../../../services/LeadCalendar/LoanPaymentCalendar/LoanPaymentCalendar";
import CheckIcon from "../../../assets/svg/CheckIcon.vue";
import GridIcon from "../../../assets/svg/GridIcon.vue";
import ListIcon from "../../../assets/svg/ListIcon.vue";

export default
{
    components: {
        ListIcon,
        GridIcon,
        CheckIcon,
        ChevronLeftSmallIcon,
        ChevronRightSmallIcon
    },

    data() {
        return {
            CalendarData: {
                Year: null,
                Month: null,
                MonthName: null,
            },
            CalendarViewType: 1,
            LoanCalendars: null,
            DaysOrWeek: ['SUN', 'MON', 'TUE', 'THU', 'WED', 'FRI', 'SAT'],
            CurrentMonthPayments: [],
            SelectedCalendar: [],
            CalendarSelectionIsOpen: false,
        }
    },

    computed: {
        nextEventIndex() {
            const today = new Date();

            const futureEvents = this.LoanCalendars[0].loan_payments
                .map((event, index) => ({
                    ...event,
                    index,
                    date: new Date(event.scheduled_at)
                }))
                .filter(event => event.date >= today && event.loan_payment_status_id === 3);

            if (futureEvents.length === 0) {
                return -1;
            }

            futureEvents.sort((a, b) => a.date - b.date);

            return futureEvents[0].index;
        },

        MonthData() {
            if (!this.CalendarData.Month || !this.CalendarData.Year) {
                return null;
            }

            this.GetMonthSlug(this.CalendarData.Month);

            let date = new Date(this.CalendarData.Year, this.CalendarData.Month - 1, 1);

            let weeks = [];

            let firstDayOfWeek = date.getDay();

            let week = new Array(firstDayOfWeek).fill(null);

            while (date.getMonth() === this.CalendarData.Month - 1) {
                week.push(date.getDate());

                if (date.getDay() === 6) {
                    weeks.push(week);
                    week = [];
                }

                date.setDate(date.getDate() + 1);
            }

            if (week.length > 0) {
                while (week.length < 7) week.push(null);
                weeks.push(week);
            }

            return weeks;
        },
    },

    methods: {
        CloseCalendarSelection() {
            this.CalendarSelectionIsOpen = false;
        },

        PaymentBelongsToDate(Payment, Day) {
            return Payment.scheduled_at === `${this.CalendarData.Year}-${this.CalendarData.Month > 9 ? this.CalendarData.Month : '0' + this.CalendarData.Month }-${Day > 9 ? Day : '0' + Day }`
        },

        SelectPaymentCalendar(id) {
            const index = this.SelectedCalendar.indexOf(id);
            if (index !== -1) {
                this.SelectedCalendar.splice(index, 1); // If ID exists, remove it from the array
            } else {
                this.SelectedCalendar.push(id); // If ID doesn't exist, add it to the array
            }
        },

        IsCurrentDay(Day) {
            const date = new Date();

            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;

            return `${year}-${month}-${day}` === `${this.CalendarData.Year}-${this.CalendarData.Month > 9 ? this.CalendarData.Month : '0' + this.CalendarData.Month }-${Day > 9 ? Day : '0' + Day }`
        },

        GetMonthSlug(month) {
            this.CalendarData.MonthName = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"][month - 1];
        },

        GetCurrentMonthPayments() {
            this.CurrentMonthPayments = [];

            this.LoanCalendars.forEach(item => {
                item.loan_payments.forEach(payment => {
                    if (this.PaymentIsInRange(payment.scheduled_at)) {
                        this.CurrentMonthPayments.push(payment);
                    }
                })
            });
        },

        MonthNavigation(type) {
            if (type === 'PREV') {
                if (this.CalendarData.Month === 1) {
                    this.CalendarData.Month = 12;
                    this.CalendarData.Year -= 1;
                } else {
                    this.CalendarData.Month -= 1;
                }
            } else if (type === 'NEXT') {
                if (this.CalendarData.Month === 12) {
                    this.CalendarData.Month = 1;
                    this.CalendarData.Year += 1;
                } else {
                    this.CalendarData.Month += 1;
                }
            }

            this.GetCurrentMonthPayments();
        },

        FetchData() {
            RetrieveLoanCalendarPayment(this.$route.params.id)
                .then((response) => {
                    this.LoanCalendars = response.data.data.loan_payment_calendar;
                    this.LoanCalendars.forEach(Calendar => {
                       this.SelectedCalendar.push(Calendar.id);
                    });
                    this.GetCurrentMonthPayments();
                })
        },

        PaymentIsInRange(dateStr) {
            const inputDate = new Date(dateStr);
            const startDate = new Date(this.CalendarData.Year, this.CalendarData.Month - 1, 1);
            const endDate = new Date(this.CalendarData.Year, this.CalendarData.Month, 0);

            return inputDate >= startDate && inputDate <= endDate;
        },
    },

    async mounted() {
        this.CalendarData.Year = new Date().getFullYear();
        this.CalendarData.Month = new Date().getMonth() + 1;

        await this.FetchData();
    }
}
</script>
