export default
{
	welcome_back: 'Bienvenue !',
	please_enter_your_credentials: 'Veuillez entrer vos identifiants de connexion.',
	sign_in: 'Connection',
	sign_in_with_email: 'Connection Par Adresse Courriel',
	sign_in_with_phone: 'Connection Par Numéro de Téléphone',

	errors: {
		user_locked: 'Trop de tentatives échouées. Votre compte a été verrouillé, veuillez récupérer votre mot de passe ou contacter le service clientèle pour le déverrouiller.',
		password_required: 'Mot de Passe Requis',
		invalid_email_or_password: 'Adresse Courriel ou Mot de Passe Invalide',
		invalid_phone_or_password: 'Numéro de Téléphone ou Mot de Passe Invalide',
		invalid_email: 'Adresse Courriel Invalide',
		email_required: 'Adresse Courriel Requis',
		invalid_email_format: 'Mot de Passe Invalide',
		phone_is_required: 'Numéro de Téléphone Requis',
		user_not_found: 'Compte Introuvable',
		phone_is_invalid: 'Numéro de Téléphone Invalide',
	}
}