<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 6L8 12L14 18" :stroke="color" :stroke-width="strokeWidth" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default
{
    props: {
        size: {
            type: String,
            default: '24'
        },
        color: {
            type: String,
            default: ''
        },
        strokeWidth: {
            type: String,
            default: 2,
        }
    },
}
</script>
