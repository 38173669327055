<template>
    <kronos-base-action-modal
        :is-open="OpenDenyRequestModalReason"
        @close="OpenDenyRequestModalReason = false"
        :can-close="true"
        title="Denied Request Reason"
        :has-header="true"
        button-class="text-sm text-white rounded h-[44px] bg-black opacity-80 hover:opacity-100 duration-500"
        @submit="updateLeadIsApproved(false); OpenDenyRequestModalReason=false"
    >
        <div v-click-outside="CloseDenyReasonOptions" @click="DenyReasonsOptionsIsOpen = !DenyReasonsOptionsIsOpen" :class="DenyReasonsOptionsIsOpen ? 'rounded-t-[6px]' : 'rounded-[6px]'" class="duration-500 relative w-full h-[144px]">
            <approbation-step-select-option
                :selected="SelectedDeniedReason"
                :options="DeniedReasons"
                @update-value="SelectedDeniedReason = $event"
                label="Denied Reason"
            />
            <textarea class="w-full h-[80px] mt-4 bg-gray-100 rounded-lg text-[11.5px] py-[10px] px-[12px] resize-none" placeholder="Note..." />
        </div>
        <template v-slot:button-text>
            <span class="text-[11.5px]">Deny Request</span>
        </template>
    </kronos-base-action-modal>
    <kronos-base-action-modal
        :is-open="ApproveLeadModalIsOpen"
        @close="ApproveLeadModalIsOpen = false"
        :can-close="true"
        title="Approve Loan Request"
        :has-header="true"
        button-class="text-sm text-white rounded h-[44px] bg-black opacity-80 hover:opacity-100 duration-500"
        @submit="this.StoreOfferNote(); updateLeadIsApproved(true); OpenDenyRequestModalReason=false"
    >
        <div v-click-outside="CloseDenyReasonOptions" @click="DenyReasonsOptionsIsOpen = !DenyReasonsOptionsIsOpen" :class="DenyReasonsOptionsIsOpen ? 'rounded-t-[6px]' : 'rounded-[6px]'" class="duration-500 relative w-full h-[144px]">
            <approbation-step-select-option
                :selected="SelectedAmount"
                :options="LoanAmounts"
                @update-value="SelectedAmount = $event"
                label="Amount Requested"
            />
            <textarea v-model="OfferNote" class="w-full h-[80px] mt-4 bg-gray-100 rounded-lg text-[11.5px] py-[10px] px-[12px] resize-none" placeholder="Offer Note..." />
        </div>
        <template v-slot:button-text>
            <span class="text-[11.5px]">Approve Request</span>
        </template>
    </kronos-base-action-modal>
    <kronos-base-action-modal
        :is-open="SendVerificationModalIsOpen"
        @close="SendVerificationModalIsOpen = false"
        :can-close="true"
        title="Verification"
        :has-header="true"
        button-class="text-sm text-white rounded h-[44px] bg-black opacity-80 hover:opacity-100 duration-500"
        @submit="this.GenerateVerification()"
    >
        <div class="text-sm text-center text-gray-500 py-4">
            Are you sure you want to accept the request? The verification of the request will be sent automatically.
        </div>
        <template v-slot:button-text>
            Continue
        </template>
    </kronos-base-action-modal>
    <kronos-base-action-modal
        :is-open="openMicroLoanModal"
        @close="openMicroLoanModal=false"
        :can-close="true"
        title="Micro Loans"
        width="1040px"
    >
        <div>
            <table class="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name Of Lender</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount Of Loan</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Of Deposit</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pay Frequency</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Amount</th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(loan, index) in arrayOfMicroLoans" :key="index">
                    <td class="px-6 py-4 whitespace-nowrap">{{ loan['Name Of Lender'] }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ loan['Amount Of Loan'] }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ loan['Date Of Deposit'] }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ loan['Pay Frequency'] }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ loan['Payment Amount'] }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td class="p-2">
                        <input
                            v-model="newLoan['Name Of Lender']"
                            type="text"
                            name="input"
                            class="h-[32px] w-full rounded-md bg-gray-100 px-4 text-sm placeholder:text-gray-400 text-gray-500 focus:outline-none focus:ring-0"
                            placeholder="Lender Name"
                        >
                    </td>
                    <td class="p-2">
                        <input
                            v-model="newLoan['Amount Of Loan']"
                            type="number"
                            name="input"
                            class="h-[32px] w-full rounded-md bg-gray-100 px-4 text-sm placeholder:text-gray-400 text-gray-500 focus:outline-none focus:ring-0"
                            placeholder="Amount"
                        >
                    </td>
                    <td class="p-2">
                        <input
                            v-model="newLoan['Date Of Deposit']"
                            type="date"
                            name="input"
                            class="h-[32px] w-full rounded-md bg-gray-100 px-4 text-sm placeholder:text-gray-400 text-gray-500 focus:outline-none focus:ring-0"
                        >
                    </td>
                    <td class="p-2">
                        <select
                            v-model="newLoan['Pay Frequency']"
                            class="h-[32px] w-full rounded-md bg-gray-100 px-4 text-sm text-gray-500 focus:outline-none focus:ring-0"
                        >
                            <option disabled value="">Select frequency</option>
                            <option>Weekly</option>
                            <option>Bi-Weekly</option>
                            <option>Monthly</option>
                        </select>
                    </td>
                    <td class="p-2">
                        <input
                            v-model="newLoan['Payment Amount']"
                            type="number"
                            name="input"
                            class="h-[32px] w-full rounded-md bg-gray-100 px-4 text-sm placeholder:text-gray-400 text-gray-500 focus:outline-none focus:ring-0"
                            placeholder="Pay Amount"
                        >
                    </td>
                    <td class="p-2 text-right">
                        <button @click="addNewLoan">
                            <cross-duo-icon
                                class="duration-500 fill-gray-900 cursor-pointer rotate-45"
                                size="16px"
                            />
                        </button>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </kronos-base-action-modal>
    <div
        v-if="true"
        class="fixed flex items-center justify-center top-0 left-0 w-screen h-screen bg-black z-40 bg-opacity-50"
    >
        <div
            :class="FullScreen ? '' : 'modal-custom-height-width'"
            class="bg-white flex flex-col h-screen w-screen duration-500 min-h-[110px] divide-y divide-gray-100 rounded-[4px] overflow-hidden"
        >
            <div class="h-[45px] w-full flex items-center justify-between">
                <p class="pl-[24px] flex gap-4 text-sm items-center">{{ ContextLead?.client?.first_name }} {{ ContextLead?.client?.last_name }}</p>

                <div class="flex">
                    <div
                        @click="DocumentSelectionIsOpen = !DocumentSelectionIsOpen"
                        class="relative cursor-pointer w-[45px] h-[45px] border-l border-gray-100 flex items-center justify-center"
                    >
                        <img
                            src="/assets/icons/svg/grid.svg"
                            class="h-[14px]"
                        />
                        <div
                            v-if="DocumentSelectionIsOpen"
                            class="custom-shadow-1 | bg-white w-[200px] right-0 absolute top-[50px] rounded-lg"
                        >
                            <div v-for="(Document, index) in DocumentsIsOpen">
                                <div
                                    v-if="Document.is_available"
                                    class="text-xs px-[12px] py-[8px] first-of-type:rounded-t-lg last-of-type:rounded-b-lg hover:bg-blue-500 hover:text-blue-100 duration-500"
                                    @click="DocumentsIsOpen[index].is_open = true"
                                >
                                    {{ Document.label }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <a
                        href="https://ml.gestionkronos.com/ibv"
                        target="_blank"
                        class="cursor-pointer w-[45px] h-[45px] border-l border-gray-100 flex items-center justify-center"
                    >
                        <img
                            src="/assets/icons/svg/link-out.svg"
                            class="h-[14px]"
                        />
                    </a>
                    <button
                        @click="this.FullScreen = !this.FullScreen"
                        class="cursor-pointer w-[45px] h-[45px] border-l border-gray-100 flex items-center justify-center"
                    >
                        <img
                            :src="`/assets/icons/svg/${ FullScreen ? 'reduce' : 'enlarge' }.svg` "
                            class="h-[14px]"
                        />
                    </button>
                    <button @click="close" class="cursor-pointer w-[45px] h-[45px] border-l border-gray-100 flex items-center justify-center">
                        <img
                            src="/assets/icons/svg/cross.svg"
                            class="h-[14px]"
                        />
                    </button>
                </div>
            </div>
            <div class="w-full flex pl-[24px]" style="max-height: calc(100% - 48px)">
                <div class="w-[275px] h-fit max-h-fit overflow-scroll py-[24px] cursor-pointer">
                    <div class="flex flex-col gap-[18px]">
                        <div
                            v-if="ApprobationStepForm"
                            v-for="(Step, Index) in Steps"
                            :key="Index"
                        >
                            <a
                                @click="this.ScrollToSection(`${Index}_is_approved`)"
                                class="h-[45px] flex justify-between rounded-lg tracking-wide font-light items-center text-[10.5px] px-[18px]"
                                :class="ApprobationStepForm && ApprobationStepForm[`${Index}_is_approved`] ? 'bg-green-100 text-green-600' : 'bg-sky-50 text-blue-500'"
                            >
                                <div
                                    :class="ApprobationStepForm && ApprobationStepForm[`${Index}_is_approved`] ? 'bg-green-500' : 'border-[1.5px] bg-transparent border-blue-300'"
                                    class="flex items-center justify-center h-[18px] w-[18px] rounded-full duration-500"
                                >
                                    <CheckIcon
                                        v-if="ApprobationStepForm && ApprobationStepForm[`${Index}_is_approved`]"
                                        class="h-[12px] stroke-white"
                                        stroke-width="3"
                                    />
                                </div>
                                {{ Step.label }}
                            </a>
                        </div>
                        <div
                            v-if="![1, 2].includes(ContextLead.lead_status.id)"
                            class="flex gap-[11px] flex-col"
                        >
                            <a
                                @click="OpenDenyRequestModalReason = true"
                                class="h-[45px] flex justify-center cursor-pointer rounded-lg tracking-wide items-center text-[10.5px] px-[24px] bg-red-100 text-red-600 duration-500 hover:bg-red-500 hover:text-red-50"
                            >
                                Deny Requests
                            </a>
                            <a
                                v-if="AllSectionAreApproved && $route.name !== 'LeadVerifications'"
                                @click="SendVerificationModalIsOpen = true"
                                class="h-[45px] flex justify-center cursor-pointer rounded-lg tracking-wide items-center text-[10.5px] px-[24px] bg-blue-100 text-blue-600 duration-500 hover:bg-blue-500 hover:text-blue-50"
                            >
                                Send Verification
                            </a>
                            <a
                                v-else-if="$route.name === 'LeadVerifications'"
                                @click="ApproveLeadModalIsOpen = true"
                                class="h-[45px] flex justify-center cursor-pointer rounded-lg tracking-wide items-center text-[10.5px] px-[24px] bg-blue-100 text-blue-600 duration-500 hover:bg-blue-500 hover:text-blue-50"
                            >
                                Approve Request
                            </a>
                        </div>
                    </div>
                </div>
                <div class="w-full h-full overflow-y-auto" ref="container">
                    <div v-if="ApprobationStepForm" class="form-custom-height w-full flex flex-col py-[24px] gap-[24px] overflow-scroll pl-[24px] pr-[36px]">
                        <approbation-step-risk-management
                            id="risk_management_is_approved"
                            :IsValidated="!!ApprobationStepForm['risk_management_is_approved']"
                            @approve-section="this.ApproveSection($event)"
                        />
                        <approbation-step-documents
                            id="documents_is_approved"
                            :IsValidated="!!ApprobationStepForm['documents_is_approved']"
                            @approve-section="this.ApproveSection($event)"
                            :DocumentsIsOpen="DocumentsIsOpen"
                            @close-document="this.CloseDocument($event)"
                        />
                        <approbation-step-amount-requested
                            id="amount_requested_is_approved"
                            :IsValidated="!!ApprobationStepForm['amount_requested_is_approved']"
                            @approve-section="this.ApproveSection($event)"
                        />
                        <approbation-step-personal-information
                            id="personal_information_is_approved"
                            :IsValidated="!!ApprobationStepForm['personal_information_is_approved']"
                            @approve-section="this.ApproveSection($event)"
                        />
                        <application-step-references
                            id="reference_is_approved"
                            :IsValidated="!!ApprobationStepForm['reference_is_approved']"
                            @approve-section="this.ApproveSection($event)"
                        />
                        <approbation-step-employer
                            id="employer_is_approved"
                            :IsValidated="!!ApprobationStepForm['employer_is_approved']"
                            @approve-section="this.ApproveSection($event)"
                        />
                        <approbation-step-instant-bank-verification
                            id="ibv_is_approved"
                            :IsValidated="!!ApprobationStepForm['ibv_is_approved']"
                            @approve-section="this.ApproveSection($event)"
                        />
                        <approbation-step-debt-ratio
                            id="debt_ratio_is_approved"
                            :IsValidated="!!ApprobationStepForm['debt_ratio_is_approved']"
                            @approve-section="ApproveSection($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CrossDuoIcon from "../../../assets/icons/duotone/CrossDuoIcon.vue";
import CheckDuoIcon from "../../../assets/icons/duotone/CheckDuoIcon.vue";
import UnavailableDuoIcon from "../../../assets/icons/duotone/UnavailableDuoIcon.vue";
import SearchIcon from "../../../assets/icons/SearchIcon.vue";
import KronosBaseInput from "../../../UI/BaseComponents/Inputs/KronosBaseInput.vue";
import ChevronLeftDuoIcon from "../../../assets/icons/duotone/ChevronLeftDuoIcon.vue";
import ErrorIcon from "../../../assets/icons/ErrorIcon.vue";
import QuestionMarkDuoIcon from "../../../assets/icons/duotone/QuestionMarkDuoIcon.vue";
import KronosBaseActionModal from "../../../UI/BaseComponents/KronosBaseActionModal.vue";
import { mapState } from "vuex";
import ApprobationStepFormSection from "./ApprobationStepFormSection.vue";
import KronosBaseSelect from "../../../UI/BaseComponents/KronosBaseSelect.vue";
import App from "../../../App.vue";
import {
    RetrieveLeadApprobationStepByLeadId,
    UpdateLeadApprobationStep,
    UpdateLeadApprobationStepIsGenerated
} from "../../../services/LeadApprobationStep";
import { UpdateLead } from "../../../services/Lead";
import ApprobationStepApproval from "./ApprobationStepApproval.vue";
import PenDuoIcon from "../../../assets/icons/duotone/PenDuoIcon.vue";
import {StoreLeadVerification} from "../../../services/LeadVerification";
import ApprobationStepPersonalInformation from "./ApprobationSteps/ApprobationStepPersonalInformation.vue";
import ApprobationStepAmountRequested from "./ApprobationSteps/ApprobationStepAmountRequested.vue";
import CheckIcon from "../../../assets/svg/CheckIcon.vue";
import ApplicationStepReferences from "./ApprobationSteps/ApprobationStepReferences.vue";
import ApprobationStepEmployer from "./ApprobationSteps/ApprobationStepEmployer.vue";
import ApprobationStepInstantBankVerification from "./ApprobationSteps/ApprobationStepInstantBankVerification.vue";
import ApprobationStepDebtRatio from "./ApprobationSteps/ApprobationStepDebtRatio.vue";
import ApprobationStepDocuments from "./ApprobationSteps/ApprobationStepDocuments.vue";
import ApprobationStepRiskManagement from "./ApprobationSteps/ApprobationStepRiskManagement.vue";
import ApprobationStepSelectOption from "./ApprobationSteps/CustomInput/ApprobationStepSelectOption.vue";
import NotificationMixin from "../../../mixins/NotificationMixin";
import {RetrieveClientDocuments} from "../../../services/Client/ClientDocument";
import {StoreLeadNote} from "../../../services/LeadNote";

export default
{
    components: {
        ApprobationStepSelectOption,
        ApprobationStepRiskManagement,
        ApprobationStepDebtRatio,
        ApprobationStepInstantBankVerification,
        ApprobationStepEmployer,
        ApplicationStepReferences,
        CheckIcon,
        ApprobationStepAmountRequested,
        ApprobationStepPersonalInformation,
        PenDuoIcon,
        ApprobationStepApproval,
        App,
        KronosBaseSelect,
        ApprobationStepFormSection,
        KronosBaseActionModal,
        QuestionMarkDuoIcon,
        ErrorIcon,
        ChevronLeftDuoIcon,
        KronosBaseInput,
        ApprobationStepDocuments,
        SearchIcon,
        UnavailableDuoIcon,
        CheckDuoIcon,
        CrossDuoIcon
    },

    emits: ['close'],

    data() {
        return {
            SelectedDeniedReason: null,
            FullScreen: false,
            amountRequested: null,
            referenceName: null,
            referencePhone: null,
            referenceEmail: null,
            ApprobationStepForm: null,
            amountRequestedIsApproved: false,
            ibvIsApproved: false,
            debtRatioIsApproved: false,
            SelectedDenyReason: null,
            referenceIsApproved: false,
            riskManagementIsApproved: false,
            personalInformationIsApproved: false,
            employerIsApproved: false,
            openApprobationConfirmationModal: false,
            SendVerificationModalIsOpen: false,
            OpenDenyRequestModalReason: false,
            DenyReasonsOptionsIsOpen: false,
            openMicroLoanModal: false,
            DocumentSelectionIsOpen: false,
            ApproveLeadModalIsOpen: false,
            DocumentsIsOpen: {
                id_card: {label: 'ID Card', is_open: false, is_available: false},
                void_check: {label: 'Void Check', is_open: false, is_available: false},
                banking_statement: {label: 'Banking Statement', is_open: false, is_available: false},
                paystub: {label: 'Paystub', is_open: false, is_available: false},
                proof_of_residency: {label: 'Proof Of Residency', is_open: false, is_available: false},
            },
            Steps: {
                risk_management: {
                    is_approved: false,
                    label: 'Risk Management'
                },
                documents: {
                    is_approved: false,
                    label: 'Documents'
                },
                amount_requested: {
                    is_approved: false,
                    label: 'Amount Requested'
                },
                personal_information: {
                    is_approved: false,
                    label: 'Personal Information'
                },
                reference: {
                    is_approved: false,
                    label: 'References'
                },
                employer: {
                    is_approved: false,
                    label: 'Employer'
                },
                ibv: {
                    is_approved: false,
                    label: 'IBV'
                },
                debt_ratio: {
                    is_approved: false,
                    label: 'Debt Ratio'
                },
            },
            LoanAmounts: { 100: '$100', 250: '$250', 500: '$500', 750: '$750', 1000: '$1000', 1250: '$1250', 1500: '$1500', 1750: '$1750', 2000: '$2000', 2250: '$2250', 2500: '$2500' },
            arrayOfMicroLoans: [
                { 'Name Of Lender': 'PretABC', 'Amount Of Loan': 500, 'Date Of Deposit': '2018-08-08', 'Pay Frequency': 'Monthly', 'Payment Amount': '3800$' },
                { 'Name Of Lender': 'PretABC', 'Amount Of Loan': 500, 'Date Of Deposit': '2018-08-08', 'Pay Frequency': 'Monthly', 'Payment Amount': '3800$' },
                { 'Name Of Lender': 'PretABC', 'Amount Of Loan': 500, 'Date Of Deposit': '2018-08-08', 'Pay Frequency': 'Monthly', 'Payment Amount': 3800 },
            ],
            newLoanFields: [
                { placeholder: 'Lender Name', model: 'Name Of Lender' },
                { placeholder: 'Amount', model: 'Amount Of Loan' },
                { placeholder: 'Deposit Date', model: 'Date Of Deposit' },
                { placeholder: 'Frequency', model: 'Pay Frequency' },
                { placeholder: 'Pay Amount', model: 'Payment Amount' }
            ],
            newLoan: {
                'Name Of Lender': '',
                'Amount Of Loan': '',
                'Date Of Deposit': '',
                'Pay Frequency': '',
                'Payment Amount': '',
            },
            riskManagementCompanies: ['CreditBook', 'Kipir', 'Gestion Kronos'],
            BankAccountCriterias: {
                'No Sufficient Fund': { type: 'Number', value: null },
                'Payment Opposition': { type: 'Number', value: null },
                'Micro Loans': { type: 'Number', value: null },
                'Salary': { type: 'Number', value: null },
                'Has Joint Account': { type: 'Text', value: null },
                'Joint Account Name': { type: 'Text', value: null },
            },
            DebtRatioFields: [
                'Salary',
                'Rent/Mortgage',
                'Power Bill',
                'Other',
                'Personal Loan Amount',
                'Car Loan Amount',
                'Furniture Loan Amount',
            ],
            DeniedReasons: {
                NSF_OPP: 'Denied NSF & OPP',
                OFFER: 'Denied Offer',
                MP: 'Denied Micro Loans',
                REVENUE: 'Denied Revenue',
            },
            SelectedAmount: 0,
            OfferNote: null,
            personalInformationFields: [
                {
                    'label': 'Personal Information',
                    'fields': [
                        {"First Name": null}, {"Last Name": null}, {"Email Address": null}, {"Phone Number": null}, {"Address": null}, {"City": null}, {"Province": null}, {"ZIP Code": null}, {"Apartment": null}
                    ],
                },
            ],
            employerFields: [
                {
                    'label': 'Employer',
                    'fields': [
                        {"Employer Name": null}, {"Phone Number": null}, {"Email Address": null}, {"Employment Date": null}, {"Revenue Origin": null}, {"Employment Situation": null}
                    ],
                }
            ],
            approveApprobationForm: [
                { name: 'Generate Contract', checked: false },
                { name: 'Generate Payment Calendar', checked: false },
                { name: 'Send Approbation Email', checked: false },
            ]
        }
    },

    computed: {
        ...mapState([
            'ContextLead',
            'ContextLeadApprobationStep',
            'DenyReasons',
        ]),

        AllSectionAreApproved() {
            if (this.ApprobationStepForm) {
                return this.ApprobationStepForm['amount_requested_is_approved'] === 1
                    && this.ApprobationStepForm['ibv_is_approved'] === 1
                    && this.ApprobationStepForm['debt_ratio_is_approved'] === 1
                    && this.ApprobationStepForm['reference_is_approved'] === 1
                    && this.ApprobationStepForm['risk_management_is_approved'] === 1
                    && this.ApprobationStepForm['personal_information_is_approved'] === 1
                    && this.ApprobationStepForm['employer_is_approved'] === 1
                    && this.ApprobationStepForm['documents_is_approved'] === 1
            }
            return false;
        }
    },

    mixins: [
        NotificationMixin,
    ],

    methods: {
        ScrollToSection(index) {
            const container = this.$refs.container;
            if (!container) return;

            const targetElement = container.querySelector(`#${index}`);
            if (!targetElement) return;

            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },

        async ApproveSection(event) {
            await UpdateLeadApprobationStep(this.ApprobationStepForm.id, event)
            await this.RetrieveLeadApprobationStep();
            this.ScrollToSection(`${event.approved_step}_is_approved`)
        },

        CloseDenyReasonOptions() {
            this.DenyReasonsOptionsIsOpen = false;
        },

        updateLeadIsApproved(IsApproved) {
            const payload = !IsApproved
                ? {'status_id': 2, 'denied_reason_id': this.SelectedDeniedReason}
                : {'status_id': 6, 'amount': this.SelectedAmount === 0 ? this.ContextLead.amount : this.SelectedAmount}

            UpdateLead(this.ContextLead.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        IsApproved
                            ? this.showNotification('success', 'Lead Has Been Approved Successfully')
                            : this.showNotification('success', 'Lead Has Been Denied Successfully');
                    }
                })
                .finally(() => {
                    this.$emit('close');
                })
        },

        updateLeadApprobationStepsIsGenerated() {
            const data = {
                "is_contract_generated": this.approveApprobationForm[0].checked ? 1 : 0,
                "is_payment_calendar_generated" : this.approveApprobationForm[1].checked ? 1 : 0,
                "is_approbation_email_sent": this.approveApprobationForm[2].checked ? 1 : 0
            }

            UpdateLeadApprobationStepIsGenerated(this.ContextLeadApprobationStep.id, data)
                .then((response) => {
                    this.updateLeadIsApproved(true)
                    this.openApprobationConfirmationModal = false;
                })
                .catch((e) => {
                })
        },

        GenerateVerification() {
            UpdateLead(this.ContextLead.id, {
                    status_id: 5
                })
                .then(response => {
                    if (response.status === 200) {
                        StoreLeadVerification(this.$route.params.id)
                            .then(response => {
                                console.log(response);
                                if (response.status === 200) {
                                    this.SendVerificationModalIsOpen = false;
                                    this.close();
                                }
                            })
                    }
                })
        },

        close() {
            this.$emit('close')
        },

        toggleCheckbox(card) {
            card.checked = !card.checked;
        },

        createRipple(event) {
            const button = event.currentTarget;

            const circle = document.createElement("span");
            const diameter = Math.max(button.clientWidth, button.clientHeight);
            const radius = diameter / 2;

            circle.style.width = circle.style.height = `${diameter}px`;
            circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
            circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
            circle.classList.add("ripple-effect");

            const ripple = button.getElementsByClassName("ripple-effect")[0];

            if (ripple) {
                ripple.remove();
            }

            button.appendChild(circle);
        },

        async RetrieveLeadApprobationStep() {
            await RetrieveLeadApprobationStepByLeadId(this.ContextLead.id)
                .then((response) => {
                    this.ApprobationStepForm = response.data.data.lead_approbation_step;
                })
                .catch((e) => {
                    this.approbationStepModalIsOpen = true;
                })
        },

        RetrieveClientDocuments() {
            RetrieveClientDocuments(this.ContextLead.client.id)
                .then((response) => {
                    if (response.status === 200) {
                        const documents = response.data.data.client_document;
                        this.DocumentsIsOpen.void_check.is_available = !!documents['src_void_check'];
                        this.DocumentsIsOpen.id_card.is_available = !!documents['src_id_card'];
                        this.DocumentsIsOpen.banking_statement.is_available = !!documents['src_banking_statement'];
                        this.DocumentsIsOpen.paystub.is_available = !!documents['src_paystub'];
                        this.DocumentsIsOpen.proof_of_residency.is_available = !!documents['src_proof_of_residency'];
                    }
                });
        },

        CloseDocument(event) {
            this.DocumentsIsOpen[event].is_open = false;
        },

        StoreOfferNote() {
            StoreLeadNote( {
                lead_note: {
                    note_type_id: 3,
                    note: this.OfferNote,
                    lead_id: this.ContextLead.id
                }
            })
                .then(() => {
                    this.showNotification('success', "Note created successfully.");
                })
                .catch((error) => {
                    console.log(error)
                    this.showNotification('error', "Error creating lead note");
                });
        }
    },

    mounted() {
        this.SelectedAmount = this.ContextLead.amount;
        this.RetrieveLeadApprobationStep();
        this.RetrieveClientDocuments();
    },
}
</script>

<style>
@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}
.ripple {
    overflow: hidden;
    position: relative;
}
.ripple-effect {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(100, 100, 100, 0.5);
}

html {
    scroll-behavior: smooth;
}

.modal-custom-height-width {
    max-width: calc(100% - 105px);
    max-height: calc(100vh - 105px);
}

.form-custom-height {
    max-height: calc(100% - 36px);
}
</style>
