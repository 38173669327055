<template>
    <div
        v-if="!leadApprobationStep"
        class="w-full flex items-center justify-center"
    >
        <div class="relative flex w-full justify-center items-center">
            <router-link
                class="w-fit flex gap-2 items-center justify-center cursor-pointer group hover:bg-theme-blue duration-500 bg-gray-100 px-[12px] py-[4px] rounded-[6px]"
                :to="`/lead/${leadId}?approbation_step_form_is_open=1`"
            >
                <span class="text-[10px] text-[#6b7280] tracking-wide font-medium group-hover:text-white cursor-pointer">Start Process</span>
            </router-link>
        </div>
    </div>
    <div
        v-else
        class="w-full flex items-center justify-center"
    >
        <div class="relative w-full mx-[10px] h-[10px]">
            <div class="h-[7px] w-full bg-gray-200 rounded-lg absolute top-0 left-0"></div>
            <div
                class="h-[7px] bg-green-600 rounded-lg absolute top-0 left-0"
                :style="{ width: `${WidthOfProgressBar}%` }"
            >
            </div>
        </div>
    </div>
</template>

<script>
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";

export default {
    components: {
        ChevronDownIcon
    },

    props: {
        leadApprobationStep: {
            type: Object,
            default: () => null
        },
        leadId: {
            type: Number,
            default: 0
        }
    },

    computed: {
        WidthOfProgressBar() {
            if (!this.leadApprobationStep) {
                return 0;
            }

            const keysToCheck = [
                'amount_requested_is_approved', 'ibv_is_approved', 'debt_ratio_is_approved',
                'reference_is_approved', 'risk_management_is_approved', 'personal_information_is_approved',
                'employer_is_approved', 'is_contract_generated', 'is_payment_calendar_generated',
                'is_approbation_email_sent'
            ];

            const trueCount = keysToCheck.reduce((count, key) => {
                return count + (this.leadApprobationStep[key] === 1 ? 1 : 0);
            }, 0);

            return (trueCount / keysToCheck.length) * 100;
        }
    }
}
</script>
