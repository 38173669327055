<template>
    <transition
        enter-active-class="transition ease-in-out duration-200"
        enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in-out duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-4"
    >
        <user-profile-buy-phone-number-modal
            v-if="BuyPhoneNumberModalIsOpen"
        />
    </transition>

    <kronos-base-layout>
        <div class="flex pb-[44px] flex-row gap-5 max-w-full mx-auto">
            <div class="flex flex-col gap-4 w-screen fit">
                <div class="relative mt-[40px]">
                    <div>
                        <div class="absolute top-[-40px] flex bg-white rounded-[4pt] rounded-bl-none items-center justify-center h-[92px] w-[92px] shadow-custom-shadow left-0 z-10">
                            <div class="h-[82px] w-[82px] rounded-[4pt] top-0 left-0 bg-[#F2F2F2] border-opacity-75 border gray-200 flex items-center justify-center">

                            </div>
                        </div>
                    </div>
                    <div class="rounded-t-[4pt] bg-white h-fit w-full top-[40px] z-0 shadow-custom-shadow">
                        <div class="grid grid-cols-2 h-[52px] border-b border-gray-100">
                            <div class="ml-[92px] pl-[18px] h-full">
                                <h3 class="text-[14px] font-medium text-[#3B3B3B] h-full flex flex-wrap content-center">
                                    {{ `${ContextUser.first_name} ${ContextUser.last_name}` }}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <user-profile-general
                        v-if="this.SelectedUserProfileSection === 0"
                        @open-buy-phone-number-modal="this.BuyPhoneNumberModalIsOpen = true"
                    />

                    <user-profile-preferences
                        v-if="this.SelectedUserProfileSection === 1"
                    />
                </div>
            </div>
        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../UI/Layouts/KronosBaseLayout.vue";
import {mapState} from "vuex";
import UserProfilePreferences from "../components/UserProfile/UserProfilePreferences.vue";
import UserProfileGeneral from "../components/UserProfile/UserProfileGeneral.vue";
import UserProfileBuyPhoneNumberModal from "../components/UserProfile/UserProfileBuyPhoneNumberModal.vue";
import KronosBaseSideNavigation from "../UI/Navigation/KronosBaseSideNavigation.vue";

export default
{
    components: {
        KronosBaseSideNavigation,
        UserProfileBuyPhoneNumberModal,
        UserProfileGeneral,
        UserProfilePreferences,
        KronosBaseLayout
    },

    data() {
        return {
            BuyPhoneNumberModalIsOpen: false,
        }
    },

    computed: {
        ...mapState([
            'SelectedUserProfileSection',
            'ContextUser',
        ]),

        IsSelf() {
            return parseInt(this.$route.params.id) === parseInt(this.ContextUser.id);
        }
    },
}
</script>