<template>
    <div class="w-1/3 h-[235px]">
        <div class="w-full rounded-md bg-white h-full shadow-custom-shadow-2 border border-gray-100">
            <div class="flex border-b border-gray-100 h-[45px] pl-[12pt] items-center justify-between">
                <h5 class="text-xs font-medium tracking-wide text-gray-900">
                    {{ title }}
                </h5>
                <div
                    @click="$emit('add')"
                    class="border-l border-gray-100 w-[45px] h-[46px] flex items-center justify-center text-gray-400 hover:bg-gray-100 duration-500 cursor-pointer"
                >
                    +
                </div>
            </div>

            <div class="flex flex-col h-full">
                <div class="flex-1 overflow-y-auto">
                    <div
                        v-show="isLoading"
                        class="text-center mt-4"
                    >
                        <h3 class="text-sm text-gray-400 pt-14">
                            <loader-animation
                                size="18px"
                                border="2px solid lightgray"
                            />
                        </h3>
                    </div>
                    <div
                        v-if="!leads.length > 0 && !isLoading"
                        class="text-center mt-4"
                    >
                        <h3 class="text-xs text-gray-400 pt-14">
                            No Data Available...
                        </h3>
                    </div>
                    <div
                        v-else
                        class="divide-y divide-gray-100"
                    >
                        <div
                            v-for="lead in leads.slice(0, 3)"
                            :key="lead.id"
                            class="pl-[12pt] h-[50px]"
                        >
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <img
                                        class="w-7 h-7 rounded-md"
                                        src="../../assets/images/user-avatar-32.png"
                                        alt="Bank Logo"
                                    >
                                </div>
                                <div class="flex-1 min-w-0" >
                                    <p class="text-xs font-normal text-gray-700 truncate">
                                        {{ `${lead?.id} - ${lead?.lender?.name} - ${lead?.amount}$` }}
                                    </p>
                                </div>
                                <div
                                    class="text-sm font-light text-gray-900 pr-1 w-[50px] h-[50px] flex items-center justify-center">
                                    <a
                                        :href="`/lead/${lead.id}`"
                                        class="bg-gray-100 h-[50%] w-[50%] flex items-center justify-center rounded-md"
                                    >
                                        <svg
                                            class="w-3 h-3 fill-gray-700"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 16 3"
                                        >
                                            <path
                                                d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="leads.length"
                    class="text-center text-[8pt] cursor-pointer duration-500 text-gray-400 tracking-wide h-[40px] font-medium border-t border-gray-100 hover:bg-gray-100 flex items-center justify-center bottom-11 relative"
                >
                    View All
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KronosBaseDateRangeSelector from "./KronosBaseDateRangeSelector.vue";
import KronosBaseTable from "./KronosBaseTable.vue";
import {RetrieveLeads} from "../../services/Lead";
import NotificationMixin from "../../mixins/NotificationMixin";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";

export default {

    components: {
        LoaderAnimation,
        KronosBaseTable,
        KronosBaseDateRangeSelector
    },

    props: {
        title: {
            type: String,
            default: 'Title',
        }
    },

    created() {
        const client_id = this.$route.params.client_id;
        this.fetchLeads(client_id);
    },

    data() {
        return {
            isLoading: false,
            leads: [],
        }
    },

    mixins: [
        NotificationMixin
    ],

    methods: {
        fetchLeads(client_id) {
            this.isLoading = true;

            const query = `client_id=${client_id}`;

            RetrieveLeads(query, 1, 3)
                .then(response => {

                    console.log(client_id)

                    this.leads = response.data.data.leads;

                })
                .catch (error => {
                    this.showNotification('error', 'An Error Occurred While Retrieving Leads')
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },

    mounted() {
        this.fetchLeads(this.$route.params.client_id);
    },
}
</script>