<template>
    <kronos-base-auth-layout class="bg-[#EFEFEF] flex items-center justify-center">
        <transition
            name="modal"
            enter-active-class="ease-out duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-500"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <form
                v-if="!formIsLoading && !$route.query?.challenge_name"
                @submit.prevent="this.InitiateAuth()"
                class="p-[18pt] custom-shadow-1 flex items-center justify-center sm:pt-[8pt] sm:pb-[16pt] sm:px-[16pt] w-full sm:max-w-[475px] h-full text-center sm:h-auto rounded-[12pt] flex-col bg-white"
            >
                <div
                    v-if="!!loggingIn"
                    class="mt-[18pt]"
                >
                    <loader-animation
                        size="32px"
                        border="3px solid black"
                    />
                </div>
                <div v-if="!loggingIn" class="w-full">
                    <div class="flex flex-col gap-3 mt-8">
                        <div class="flex flex-col items-center gap-3 mb-4">
                            <div class="relative">
                                <div class="absolute top-0 h-[48px] w-[48px] bg-[#212529] rounded-tr-lg rounded-bl-lg rounded-tl-[60%] rounded-br-[60%] flex items-center justify-center tracking-wide text-white">GK</div>
                                <div class="h-[48px] w-[48px] bg-[#212529] bg-opacity-30 rounded-tr-lg rounded-bl-lg rounded-tl-xl rounded-br-xl flex items-center justify-center text-white">GK</div>
                            </div>
                            <p class="text-xl font-medium text-[#212529]">Gestion Kronos LMS</p>
                        </div>
                        <div v-if="userIsLocked" class="p-[8pt] w-full bg-red-100 rounded-[4pt] flex gap-3 | dark:bg-red-600">
                            <error-icon
                                class="min-w-[24px]"
                                size="24px"
                                :fill="contextTheme === 'light' ? '#ef4444' :'#fee2e2'"
                            />
                            <span class="pr-0.5 leading-5 font-medium text-left text-xs text-red-600 mt-1 | dark:text-red-100">
                                {{ $t('login.errors.user_locked') }}
                            </span>
                        </div>
                        <div class="w-full flex flex-col">
                            <input
                                v-model="form.email"
                                class="h-[52px] w-full rounded-md px-4 focus:ring-0 border border-transparent focus:outline-none text-sm"
                                :class="!!errors.email.message ? 'bg-red-100 text-red-600 placeholder:text-red-400 focus:border-red-500' : 'text-gray-600 bg-gray-100 focus:border-[#5c74d6]'"
                                type="text"
                                placeholder="Email Address"
                            >
                            <p
                                v-if="errors.email.message"
                                class="text-right text-xs tracking-wide text-red-500 mt-1.5"
                            >
                                {{ errors?.email?.message }}
                            </p>
                        </div>
                        <div class="w-full flex flex-col">
                            <input
                                v-model="form.password"
                                class="h-[52px] w-full rounded-md px-4 focus:ring-0 border border-transparent focus:outline-none text-sm"
                                :class="!!errors.password.message ? 'bg-red-100 text-red-600 placeholder:text-red-400 focus:border-red-500' : 'text-gray-600 bg-gray-100 focus:border-[#212529]'"
                                type="password"
                                placeholder="Password"
                            >
                            <p
                                v-if="errors.password.message"
                                class="text-right text-xs tracking-wide text-red-500 mt-1.5"
                            >
                                {{ errors?.password?.message }}
                            </p>
                        </div>
                    </div>
                    <div class="flex items-center justify-end text-xs duration-500 mt-4">
                        <a href="/forgot-password" class="text-[#212529] duration-500 text-xs hover:bg-[#212529] py-1.5 rounded-md px-[12px] hover:bg-opacity-10 font-medium">
                            Forgot password ?
                        </a>
                    </div>
                    <div class="flex flex-col w-full gap-3 -mt-0.5">
                        <button class="bg-[#212529] px-[24px] w-full font-medium mt-4 text-white h-[52px] text-sm rounded-md bg-opacity-90 hover:bg-opacity-100 duration-500">
                            {{ $t('login.sign_in') }}
                        </button>
                    </div>
                </div>
            </form>

            <form
                v-else-if="!formIsLoading && !!$route.query?.challenge_name"
                @submit.prevent="this.ValidateChallengeResponse()"
                class="p-[18pt] flex items-center justify-center sm:pt-[26pt] sm:pb-[16pt] sm:px-[16pt] w-full sm:max-w-[475px] h-full text-center sm:h-auto rounded-[14pt] flex-col bg-white"
            >
                <div
                    v-if="!!loggingIn"
                    class="mt-[18pt]"
                >
                    <loader-animation
                        size="32px"
                        border="3px solid black"
                    />
                </div>
                <div v-if="!loggingIn" class="w-full">
                    <div class="flex flex-col gap-3 mt-8">
                        <div class="flex flex-col items-center gap-3 mb-4">
                            <div class="relative">
                                <div class="absolute top-0 h-[48px] w-[48px] bg-[#212529] rounded-tr-lg rounded-bl-lg rounded-tl-[60%] rounded-br-[60%] flex items-center justify-center tracking-wide text-white">GK</div>
                                <div class="h-[48px] w-[48px] bg-[#212529] bg-opacity-30 rounded-tr-lg rounded-bl-lg rounded-tl-xl rounded-br-xl flex items-center justify-center text-white">GK</div>
                            </div>
                            <p class="text-xl font-medium text-[#212529]">Gestion Kronos LMS</p>
                        </div>
                        <div v-if="userIsLocked" class="p-[8pt] w-full bg-red-100 rounded-[4pt] flex gap-3 | dark:bg-red-600">
                            <error-icon
                                class="min-w-[24px]"
                                size="24px"
                                :fill="contextTheme === 'light' ? '#ef4444' :'#fee2e2'"
                            />
                            <span class="pr-0.5 leading-5 font-medium text-left text-xs text-red-600 mt-1 | dark:text-red-100">
                                {{ $t('login.errors.user_locked') }}
                            </span>
                        </div>
                        <div class="w-full flex flex-col">
                            <input
                                v-model="totp"
                                class="h-[52px] w-full rounded-md px-4 focus:ring-0 border border-transparent focus:outline-none text-sm"
                                :class="!!errors.totp.message ? 'bg-red-100 text-red-600 placeholder:text-red-400 focus:border-red-500' : 'text-gray-600 bg-gray-100 focus:border-[#212529]'"
                                type="text"
                                placeholder="TOTP"
                            >
                            <p
                                v-if="errors.totp.message"
                                class="text-right text-xs tracking-wide text-red-500 mt-1.5"
                            >
                                {{ errors?.totp?.message }}
                            </p>
                        </div>
                    </div>
                    <div class="flex flex-col w-full gap-3 -mt-0.5">
                        <button class="bg-[#212529] px-[24px] w-full font-medium mt-4 text-white h-[52px] text-sm rounded-md bg-opacity-90 hover:bg-opacity-100 duration-500">
                            {{ $t('login.sign_in') }}
                        </button>
                    </div>
                </div>
            </form>
        </transition>
    </kronos-base-auth-layout>
</template>

<script>
import KronosAuthInput from "../UI/BaseComponents/KronosAuthInput.vue";
import ErrorIcon from "../assets/icons/ErrorIcon.vue";
import AtSignIcon from "../assets/icons/AtSignIcon.vue";
import KronosBaseAuthLayout from "../UI/Layouts/KronosBaseAuthLayout.vue";
import {InitiateAuth, ValidateChallengeResponse} from "../services/Authentication";
import { IsEmail } from "../helpers/Helpers";
import LoaderAnimation from "../assets/animations/LoaderAnimation.vue";
import {vMaska} from "maska";

export default
{
    components: {
        KronosBaseAuthLayout,
        ErrorIcon,
        KronosAuthInput,
        AtSignIcon,
        LoaderAnimation,
    },

    directives: {
        maska: vMaska
    },

    data() {
        return {
            form: {
                email: null,
                password: null,
            },
            totp: null,
            userIsLocked: false,
            formIsLoading: true,
            loggingIn: false,
            errors: {
                email: {
                    status: false,
                    message: null,
                },
                password: {
                    status: false,
                    message: null,
                },
                totp: {
                    status: false,
                    message: null,
                }
            }
        }
    },

    computed: {
        loginType() {
            return this.$route.query.type ? this.$route.query.type : 'email';
        },

        contextTheme() {
            return localStorage.getItem('theme');
        }
    },

    methods: {
        clearErrors() {
            this.errors.email.message = null;
            this.errors.password.message = null;
            this.errors.email.status = false;
            this.errors.password.status = false;
            this.userIsLocked = false;
        },

        parseRequestError(response) {
            if (response.status === 404) {
                this.errors[this.loginType].status = true;
                this.errors[this.loginType].message = this.$t('login.errors.user_not_found');
            } else if (response.status === 403) {
                this.userIsLocked = true;
            } else if (
                response.status === 422 &&
                response.data.error &&
                response.data.error.length !== 0
            ) {
                response.data.error.forEach(error => {
                    if (error.field === 'password') {
                        if (error.message === 'PASSWORD_IS_REQUIRED') {
                            this.errors.password.status = true;
                            this.errors.password.message = this.$t('login.errors.password_required');
                        } else if (error.message === 'INVALID_PASSWORD') {
                            this.errors.password.status = true;
                            this.errors.password.message = 'Invalid Password';
                        }
                    }

                    if (error.field === 'email') {
                        if (error.message === 'EMAIL_IS_REQUIRED') {
                            this.errors.email.status = true;
                            this.errors.email.message = this.$t('login.errors.email_required');
                        } else if (error.message === 'INVALID_EMAIL_FORMAT') {
                            this.errors.email.status = true;
                            this.errors.email.message = this.$t('login.errors.invalid_email');
                        }
                    }
                })
            }
        },

        validateInputs() {
            let hasErrors = false;

            const setError = (field, status, message) => {
                this.errors[field].status = status;
                this.errors[field].message = this.$t(message);
                hasErrors = true;
            };

            if (!this.form.password) {
                setError('password', true, 'login.errors.password_required');
            }

            if (!this.form.email || !IsEmail(this.form.email)) {
                setError('email', true, this.form.email ? 'login.errors.invalid_email' : 'login.errors.email_required');
            }

            return hasErrors;
        },

        async ValidateChallengeResponse() {
            if (!this.loggingIn) {
                this.clearErrors();

                await ValidateChallengeResponse(this.totp)
                    .then(() => {
                        document.cookie = 'SESSION_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                        this.$router.push('/');
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors.totp.status = true;
                            this.errors.totp.message = 'Invalid TOTP Code';
                        }

                        if (error.response.status === 401) {
                            this.$router.push('/login');
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.loggingIn = false;
                        }, 500);
                    });
            }
        },

        async InitiateAuth() {
            if (!this.loggingIn) {
                this.clearErrors();

                if (!this.validateInputs()) {
                    this.loggingIn = true;

                    await InitiateAuth(this.form)
                        .then((response) => {
                            if (response.data.data.challenge_name === 'SOFTWARE_TOKEN_MFA') {
                                this.$router.push('/login?challenge_name=SOFTWARE_TOKEN_MFA')
                            }
                        })
                        .catch(error => {
                            this.parseRequestError(error.response);
                        })
                        .finally(() => {
                            setTimeout(() => {
                                this.loggingIn = false;
                            }, 500);
                        });
                }
            }
        }
    },

    mounted() {
        if (
            !!this.$route?.query?.challenge_name &&
            this.$route.query.challenge_name === 'SOFTWARE_TOKEN_MFA' &&
            !document.cookie.includes('SESSION_ID')
        ) {
            window.location.href = '/login';
        }

        setTimeout(() => {
            this.formIsLoading = false;
        }, 700);
    }
}
</script>

<style scoped>
.gradient-text {
    background: linear-gradient(90deg, black, #94a3b8);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.gradient-bg-1 {
    background: linear-gradient(90deg, black, #94a3b8);
}
</style>