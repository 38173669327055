import Api from '../Api.js';

const BaseURL = 'v1/client-reference';

export const RetrieveClientReference = async (id) => {
    return await Api()
        .get(`${BaseURL}/${id}`);
}

export const RetrieveClientReferences = async (client_id) => {
    return await Api()
        .get(`${BaseURL}/client/${client_id}`);
}

export const StoreClientReference = async (payload) => {
    return await Api()
        .post(`${BaseURL}`, payload);
}

export const UpdateClientReference = async (id, payload) => {
    return await Api()
        .put(`${BaseURL}/${id}`, payload);
}

export const DestroyClientReference = async (id) => {
    return await Api()
        .delete(`${BaseURL}/${id}`);
}
