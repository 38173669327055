<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :height="size"
        :width="size"
        viewBox="0 0 24 24"
        :fill="fill"
    >
        <path d="M18,21H6c-1.657,0-3-1.343-3-3v-8c0-1.657,1.343-3,3-3h12	c1.657,0,3,1.343,3,3v8C21,19.657,19.657,21,18,21z" opacity=".35"></path><path d="M8,7c0-2.209,1.791-4,4-4s4,1.791,4,4h2c0-3.314-2.686-6-6-6S6,3.686,6,7H8z"></path><path d="M11.909,15.541h-0.005c-0.523,0-0.927-0.473-0.838-0.989c0.273-1.573,1.498-1.627,1.498-2.604	c0-0.271-0.047-0.878-0.7-0.878c-0.353,0-0.561,0.226-0.682,0.466c-0.163,0.325-0.54,0.486-0.898,0.423l0,0	c-0.544-0.096-0.878-0.679-0.647-1.181c0.33-0.716,1.005-1.466,2.341-1.466c2.169,0,2.46,1.698,2.46,2.497	c0,1.888-1.406,1.958-1.697,3.046C12.636,15.247,12.315,15.541,11.909,15.541z M13.035,17.542c0,0.322-0.1,0.593-0.299,0.815	c-0.2,0.22-0.462,0.331-0.784,0.331c-0.323,0-0.585-0.111-0.784-0.331c-0.199-0.221-0.3-0.493-0.3-0.815	c0-0.315,0.101-0.586,0.3-0.816c0.199-0.229,0.46-0.343,0.784-0.343c0.322,0,0.584,0.114,0.784,0.343	C12.936,16.956,13.035,17.227,13.035,17.542z"></path>
    </svg>
</template>

<script>
export default
{
    props: {
        fill: {
            type: String,
            default: '#000000',
        },
        size: {
            type: String,
            default: '48px',
        }
    }
}
</script>
