<template>
    <kronos-base-action-modal
        :is-open="openDeleteModal"
        title="Delete Lead Verification"
        @submit="deleteLeadVerification(leadId)"
        @close="openDeleteModal=false"
    >
        <div>
           <p>
               Do you wish to delete this verification ?
           </p>
        </div>
        <button class="text-xs text-white rounded w-full h-[48px] bg-black">
            No
        </button>
        <template v-slot:button-text>
            Yes
        </template>
    </kronos-base-action-modal>

    <approbation-step-form
        v-if="approbationStepFormIsOpen"
        @close="approbationStepFormIsOpen=false"
    />

    <kronos-base-layout>
        <div class="flex flex-col gap-10">
            <kronos-base-heading title="Lead Verifications Page">
                <kronos-base-date-range-selector
                    :context-selected-dates="[$route?.query?.start_at, $route?.query?.end_at]"
                    @change-range="fetchData()"
                    @reload="fetchData()"
                >
                </kronos-base-date-range-selector>
            </kronos-base-heading>
            <kronos-base-static-table
                title="Lead Verifications"
                :dataset="preprocessedDataset"
                id-key="client_id"
                :basePath="'/client/'"
                @action-triggered="handleActionTriggered"
                :is-loading="isLoading"
                :config="tableConfig"
                :total="total"
                @reload="fetchData()"
            />
        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../../UI/Layouts/KronosBaseLayout.vue";
import KronosBaseHeading from "../../UI/BaseComponents/KronosBaseHeading.vue";
import KronosBaseDateRangeSelector from "../../UI/BaseComponents/KronosBaseDateRangeSelector.vue";
import {mapState} from "vuex";
import {DeleteLeadVerification, DownloadLeadVerificationPdf, RetrieveLeadVerifications} from "../../services/LeadVerification";
import KronosBaseStaticTable from "../../UI/BaseComponents/KronosBaseStaticTable.vue";
import {LeadVerificationTable} from "../../config/TableViews/LeadVerificationTable";
import KronosBaseActionModal from "../../UI/BaseComponents/KronosBaseActionModal.vue";
import NotificationMixin from "../../mixins/NotificationMixin";
import {RetrieveLeadApprobationStepByLeadId} from "../../services/LeadApprobationStep";
import ApprobationStepForm from "../../components/Lead/Approbation/ApprobationStepForm.vue";
import {RetrieveLead} from "../../services/Lead";
import store from "../../store";

export default
{
    mixins: [
        NotificationMixin
    ],

    components: {
        ApprobationStepForm,
        KronosBaseActionModal,
        KronosBaseStaticTable,
        KronosBaseDateRangeSelector,
        KronosBaseHeading,
        KronosBaseLayout
    },

    data() {
        return {
            isLoading: false,
            dataset: [],
            total: 0,
            openDeleteModal: false,
            leadId: 0,
            approbationStepFormIsOpen: false,
        }
    },

    methods: {
        async fetchData() {
            const { sort_by = 'created_at', sort_order = 'asc', limit = 50,page = 1, start_at, end_at} = this.$route.query;

            if (start_at && end_at && page && limit && sort_by && sort_order) {

                this.isLoading = true;
                this.dataset = [];

                await RetrieveLeadVerifications(sort_by, sort_order, limit, page, start_at, end_at)
                    .then(response => {
                        this.dataset = response.data.data.lead_verifications;
                        this.total = response.data.data.total;
                    })
                    .catch(e => {
                        this.showNotification('error', 'An Error Occurred While Retrieving Lead Verifications');
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            }
        },

        deleteLeadVerification(lead_id) {
            DeleteLeadVerification(lead_id)
                .then(response => {
                    this.fetchData();
                    this.showNotification('success', 'Lead Verification Successfully Deleted');
                })
                .catch((e) => {
                    this.showNotification('error', 'An Error Occurred While Deleting Lead Verification');
                })
                .finally(() => {
                    this.openDeleteModal = false;
                })
        },

        async retrieveLeadApprobationStep(lead_id) {
            await RetrieveLeadApprobationStepByLeadId(lead_id)
                .then(response => {
                    this.$store.state.ContextLeadApprobationStep = response.data.data.lead_approbation_step;
                    this.approbationStepFormIsOpen = true
                    this.showNotification('success', 'Lead Approbation Form Successfully Retrieved');
                })
                .catch(e => {
                    this.showNotification('error', 'An Error Occurred While Retrieving Lead Approbation Form');
                })
        },

        modifyAction(item) {
            this.$store.state.ContextLead = RetrieveLead(item.lead_id)
                .then((response) => {
                    if (response.status === 200) {
                        this.$store.state.ContextLead = response.data.data.lead;
                    }
                })
            this.approbationStepFormIsOpen = true
        },

        deleteAction(item) {
            this.leadId = item.id;
            this.openDeleteModal = true;
        },

        downloadAction(item) {
            DownloadLeadVerificationPdf(item.src)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${item.src}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    this.showNotification('success', 'PDF Successfully Downloaded');
                })
                .catch(e => {
                    console.error(e);
                    this.showNotification('error', 'An Error Occurred');
                })
        },

        sendAction(item) {
            //TODO
        },

        handleActionTriggered({ action, item }) {
            if(typeof this[action] === 'function') {
                this[action](item);
            }
        },
    },

    mounted() {
        this.fetchData();
    },
    
    computed: {
        ...mapState([
            'ContextLead',
            'ContextLeadApprobationStep',
        ]),
        tableConfig() {
            return LeadVerificationTable
        },
        preprocessedDataset() {
            return this.dataset.map(verification => ({
                ...verification,
                company: `${verification.lead.lender.name}`,
                client_id: `${verification.lead.client_id}`,
                full_name: `${verification.lead.client.first_name} ${verification.lead.client.last_name}`,
                created_at: `${verification.created_at}`
            }));
        }
    },
}
</script>
