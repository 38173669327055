<template>
    <div class="custom-shadow-0 | flex flex-col gap-[20px] bg-white pb-[20px] rounded-lg border border-gray-100">
        <div class="flex items-center justify-between h-[42px] border-b pl-[24px] border-gray-100">
            <h1 class="text-[12px] font-light">
                References
            </h1>
            <div class="flex gap-2 border-gray-100 mr-2">
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="this.UpdateClientReference()"
                    class="w-[28px] rounded bg-blue-50 h-[28px] group hover:bg-blue-500 duration-500 flex items-center justify-center"
                >
                    <save-icon
                        stroke-width="2"
                        class="h-[16px] stroke-blue-500 fill-transparent group-hover:stroke-blue-100 duration-500"
                    />
                </button>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="ApproveSection"
                    :class="[IsValidated ? 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 ' : 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-green-100', 'duration-500 h-[28px] px-[24px] cursor-pointer text-xs rounded']"
                >
                    {{ IsValidated ? 'Decline' : 'Approve' }} Section
                </button>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-[24px] px-[24px]">
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Reference Name</label>
                <input type="text" v-model="payload.name" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Reference Phone Number</label>
                <input v-maska data-maska="+1 ### #######" type="text" v-model="payload.phone_number" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Reference Email</label>
                <input type="text" v-model="payload.email" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {UpdateLead} from "../../../../services/Lead";
import {StoreClientReference, UpdateClientReference} from "../../../../services/Client/ClientReference";
import {vMaska} from "maska";
import SaveIcon from "../../../../assets/icons/SaveIcon.vue";
import NotificationMixin from "../../../../mixins/NotificationMixin";

export default
{
    components: {
        SaveIcon
    },

    emits: ['approve-section'],

    mixins: [
        NotificationMixin
    ],

    props: {
        IsValidated: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            payload: {
                'name': null,
                'phone_number': null,
                'email': null,
            }
        }
    },

    computed: {
        ...mapState([
            'ContextLead'
        ]),
    },

    directives: {
        maska: vMaska
    },

    methods: {
        ApproveSection() {
            this.$emit('approve-section', {approved_step: 'reference', approved_step_status: !this.IsValidated});
            this.UpdateClientReference();
        },

        UpdateClientReference() {
            const phone_number_formatted = this.payload.phone_number.split(" ");

            let payload = {
                'name': this.payload.name,
                'phone_country_code': phone_number_formatted[0],
                'phone_area_code': phone_number_formatted[1],
                'phone_number': phone_number_formatted[2],
                'email': this.payload.email,
            };

            if (this.ContextLead.client.references) {
                UpdateClientReference(this.ContextLead.client.references['id'], payload)
                    .then((response) => {
                        if (response.status === 200) {
                            this.showNotification('success', 'Reference Updated Successfully');
                        }
                    })
                    .catch((error) => {
                        this.showNotification('error', 'An Error Occurred While Updating Reference');
                    })
            } else {
                payload.client_id = this.ContextLead.client.id;
                payload.reference_relationship = 'OTHER';

                StoreClientReference(payload)
            }
        },

        FormatDate(date) {
            const context_date = new Date(date);
            return `${context_date.getFullYear()}-${(context_date.getMonth() + 1 + '').padStart(2, '0')}-${(context_date.getDate() + '').padStart(2, '0')}`;
        }
    },

    mounted() {
        if (this.ContextLead.client && this.ContextLead.client.references) {
            this.payload.name = this.ContextLead.client.references['name'];
            this.payload.phone_number = `${this.ContextLead.client.references['phone_country_code']} ${this.ContextLead.client.references['phone_area_code']} ${this.ContextLead.client.references['phone_number']}`;
            this.payload.email = this.ContextLead.client.references['email'];
        }
    }
}
</script>
