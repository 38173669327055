import api from './Api.js';

const BaseURL = 'v1/user';

export const ContextUser = async () => {
	return await api()
		.get(`${BaseURL}/context-user`);
}

export const Users = async () => {
	return await api()
		.get(`${BaseURL}`);
}

export const RetrieveUser = async (id) => {
	return await api()
		.get(`${BaseURL}/${id}`);
}

export const UpdateUser = async (id, formData) => {
	return await api()
		.put(`${BaseURL}/${id}`, formData);
}

export const DeleteUser = async (id) => {
	return await api()
		.delete(`${BaseURL}/${id}`);
}

export const UpdateUserPassword = async (id, formData) => {
	return await api()
		.put(`${BaseURL}/update-password/${id}`, formData);
}
