import api from './Api';

const BaseURL = 'v1/custom-filter';

export const RetrieveAllCustomFilter = async () =>
{
    return await api().get(BaseURL);
};

export const StoreCustomFilter = async (data) =>
{
    return await api().post(BaseURL, data);
};

export const DestroyCustomFilter = async (id) =>
{
    return await api().delete(`${BaseURL}/${id}`);
};
