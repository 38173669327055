<template>
    <div class="divide-y divide-gray-100">
        <div class="text-[10pt] h-[45px] w-full items-center pl-[24px] flex justify-between tracking-wide text-gray-700">
            <span class="text-[11.5px]">Payment Calendar</span>
            <div
                @click="IsOpen = !IsOpen"
                class="w-[45px] h-[45px] rounded-tr-lg cursor-pointer group hover:bg-gray-50 duration-500 flex items-center justify-center border-l border-gray-100"
            >
                <chevron-down-icon
                    size="14px"
                    :class="[IsOpen ? 'rotate-180' : '', 'duration-500 fill-gray-500']"
                />
            </div>
        </div>
        <table v-if="IsOpen" class="table bg-white h-auto w-full rounded-b-lg divide-y divide-gray-100 ">
            <thead class="text-[10pt] font-medium tracking-wide text-gray-700">
                <tr class="h-[45px]">
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Order</th>
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Status</th>
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Initial Amount</th>
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Payment Amount</th>
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Interest paid</th>
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Residual</th>
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Interest at</th>
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Scheduled at</th>
                    <th class="text-[10.5px] w-1/12 font-medium" scope="col">Processed at</th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-100 text-center text-xs text-gray-900">
                <tr
                    class="h-10 even:bg-gray-50 hover:bg-gray-100 cursor-pointer"
                    v-for="(data, index) in LoanCalendar?.loan_payments"
                    :key="index"
                    @click="EditPayment(data)"
                >
                    <td class="text-[10.5px]">{{ data.order }}</td>
                    <td>
                        <div class="inline-flex items-center gap-x-1.5 rounded-full bg-yellow-100 pl-1.5 pr-2 py-0.5 text-[10.5px] font-medium text-yellow-800">
                            <svg class="h-1.5 w-1.5 fill-yellow-500" viewBox="0 0 6 6" aria-hidden="true">
                                <circle cx="3" cy="3" r="3" />
                            </svg>
                            <span>
                                {{ paymentStatus(data.loan_payment_status_id) }}
                            </span>
                        </div>
                    </td>
                    <td class="text-[10.5px]">{{ data.initial_amount.toFixed(3) }} $</td>
                    <td class="text-[10.5px]">{{ data.payment_amount.toFixed(3) }} $</td>
                    <td class="text-[10.5px]">{{ data.interest_paid.toFixed(3) }}</td>
                    <td class="text-[10.5px]">{{ data.residual.toFixed(3) }} $</td>
                    <td class="text-[10.5px]">{{ data.interest_rate.toFixed(3) }} %</td>
                    <td class="text-[10.5px]">{{ data.scheduled_at }}</td>
                    <td class="text-[10.5px]">{{ data.processed_at === null ? "-" : data.processed_at }}</td>
                </tr>
            </tbody>
        </table>

        <div
            :class="!EditPaymentSlideOverIsOpen ? 'translate-x-[325px]' : 'translate-x-0'"
            class="shadowed-div | fixed w-[325px] h-screen right-0 top-0 bg-white z-50 duration-500"
        >
            <div class="h-[55px] flex items-center justify-between border-b border-gray-100">
                <div class="text-xs pl-[18px]">Edit Loan Payment</div>
                <div
                    @click="EditPaymentSlideOverIsOpen = false"
                    class="hover:bg-gray-100 h-[55px] w-[55px] flex items-center cursor-pointer justify-center border-l border-gray-100"
                >
                    <cross-duo-icon
                        size="18px"
                        class="fill-gray-500"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import CrossDuoIcon from "../../assets/icons/duotone/CrossDuoIcon.vue";

export default
{
    components: {
        CrossDuoIcon,
        ChevronDownIcon
    },

    props: {
        LoanCalendar: {
            required: true,
        }
    },

    data() {
        return {
            IsOpen: false,
            EditPaymentSlideOverIsOpen: false,
            ContextLoanPayment: null,
        }
    },

    methods: {
        CloseEditPayment() {
            // if (!!this.EditPaymentSlideOverIsOpen && this.ContextLoanPayment !== null) {
            //     this.EditPaymentSlideOverIsOpen = false;
            // }
        },

        EditPayment(loan_payment) {
            this.EditPaymentSlideOverIsOpen = true;
            this.ContextLoanPayment = loan_payment
        },

        paymentStatus(id) {
            switch (id) {
                case 1:
                    return "Paid";
                case 2:
                    return "Non Sufficient Fund";
                case 3:
                    return "Not Paid";
                case 4:
                    return "Payment Opposition";
                case 5:
                    return "Cancelled";
                case 6:
                    return "Payment Agreement";
                default:
                    return "";
            }
        },
    }
}
</script>

<style scoped>
.shadowed-div {
    box-shadow: -10px 0px 15px -5px rgba(0, 0, 0, 0.15);
}
</style>