<template>
	<transition
		enter-active-class="ease-out duration-300"
		enter-from-class="opacity-0 scale-100"
		enter-to-class="opacity-100 scale-100"
		leave-active-class="ease-in duration-0"
		leave-from-class="opacity-100 scale-100"
		leave-to-class="opacity-0 scale-100"
	>
		<div
			v-if="isOpen"
			class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-60 z-10 flex items-center justify-center"
		>
			<div
				v-click-outside="close"
				class="w-full h-fit bg-white rounded-lg"
				:class="selectLenderIsOpen ? 'max-w-[750px]' : 'max-w-[650px]'"
			>
				<div class="border-b border-gray-100 h-[55px] flex items-center pl-[12pt] justify-between ">
					<h2 class="font-medium text-[12pt]">
						Lead Transfer
					</h2>
					<div
						@click="close"
						class="border-l flex items-center justify-center border-gray-100 hover:bg-gray-50 duration-300 w-[55px] h-[53px] rounded-r-lg cursor-pointer"
					>
						<close-icon
							size="14px"
							fill="#6b7280"
						/>
					</div>
				</div>

				<transition
					enter-active-class="ease-out duration-500"
					enter-from-class="opacity-0 scale-100"
					enter-to-class="opacity-100 scale-100"
					leave-active-class="ease-out duration-0"
					leave-from-class="opacity-100 scale-100"
					leave-to-class="opacity-0 scale-100"
				>
					<div v-if="!selectLenderIsOpen">
						<div class="flex items-center justify-center gap-16 py-[52px] px-[32px]">
							<div class="flex items-center justify-center flex-col gap-4">
								<div class="rounded-shadow | border-gray-200 border bg-white rounded-lg h-[85px] flex items-center justify-center w-[85px] ">
									<div class="h-[75px] w-[75px] bg-gray-200 rounded-md flex items-center justify-center">
										<img :src="BaseURL + ContextLead.lender.image_src" class="h-[37.5px]"/>
									</div>
								</div>
								<div class="text-sm font-medium text-gray-500">
									{{ ContextLead.lender.name }}
								</div>
							</div>

							<div class="flex items-center justify-center flex-col gap-2">
								<sorting-arrows-duo-icon class="fill-[#4667d2] animate-pulse rotate-180" size="38px"/>
							</div>

							<div @click="selectLenderIsOpen = true;" class="flex items-center justify-center flex-col gap-4 cursor-pointer">
								<div class="rounded-shadow | border-gray-200 border bg-white rounded-lg h-[85px] flex items-center justify-center w-[85px] ">
									<div v-if="new_lender" class="h-[75px] w-[75px] bg-gray-200 rounded-md flex items-center justify-center">
										<img :src="BaseURL + new_lender?.image_src" class="h-[37.5px]"/>
									</div>
									<div v-else class="h-[75px] w-[75px] bg-gray-200 rounded-md flex items-center justify-center">
										<question-mark-duo-icon class="fill-gray-400" size="32px" />
									</div>
								</div>
								<div class="text-sm font-medium text-gray-500 text-center">
									{{ new_lender?.name ?? '...' }}
								</div>
							</div>
						</div>

						<div class="flex">
							<div
								@click="close"
								:class="new_lender ? 'w-1/2' : 'w-full'"
								class="flex items-center justify-center h-[55px] text-sm bg-light-dark-blue text-[#4667d2] cursor-pointer rounded-bl-md"
							>
								Cancel
							</div>

							<div v-if="new_lender" @click="transferLead()" class="w-1/2 flex items-center justify-center h-[55px] text-sm bg-[#4667d2] text-white rounded-br-md cursor-pointer">
								Submit
							</div>
						</div>
					</div>
				</transition>

				<transition
					enter-active-class="ease-out duration-500"
					enter-from-class="opacity-0 scale-100"
					enter-to-class="opacity-100 scale-100"
					leave-active-class="ease-in duration-0"
					leave-from-class="opacity-100 scale-100"
					leave-to-class="opacity-0 scale-100"
				>
					<div v-if="selectLenderIsOpen" class="custom-scrollbar | h-[500px] overflow-y-scroll w-full overflow-x-hidden">
						<div class="w-[750px] border-b border-gray-200 border-t fixed">
							<input v-model="searchFilter" type="text" placeholder="Search ..." class="text-sm px-[24px] w-full border-0 focus:outline-none focus:ring-0 h-[45px]">
						</div>
						<div
							v-if="filteredLender.length > 0"
							class="grid grid-cols-4 gap-8 cursor-pointer p-[24px] mt-[45px]"
						>
							<div
								v-for="lender in filteredLender"
								class="flex items-center justify-center flex-col gap-4 group"
								@click="new_lender = lender; selectLenderIsOpen = false;"
							>
								<div class="rounded-shadow | group-hover:bg-[#c1ccf0] border-gray-200 border bg-white rounded-lg h-[85px] flex items-center justify-center w-[85px] ">
									<div class="group-hover:bg-light-dark-blue h-[75px] w-[75px] bg-gray-200 rounded-md flex items-center justify-center">
										<img :src="BaseURL + lender.image_src" class="h-[37.5px]"/>
									</div>
								</div>
								<div class="truncate group-hover:text-light-dark-blue text-sm font-medium text-gray-500 text-center">
									{{ lender.name }}
								</div>
							</div>
						</div>
						<div v-else class="flex items-center justify-center w-full h-[50%] text-sm text-gray-400">
							No Lender Found : {{searchFilter}}
						</div>
					</div>
				</transition>
			</div>
		</div>
	</transition>
</template>

<script>
import CloseIcon from "../../../assets/icons/CloseIcon.vue";
import LoaderAnimation from "../../../assets/animations/LoaderAnimation.vue";
import NotificationMixin from "../../../mixins/NotificationMixin";
import {StoreTransferredLead} from "../../../services/LeadTransfer";
import TransferDuoIcon from "../../../assets/icons/duotone/TransferDuoIcon.vue";
import SortingArrowsDuoIcon from "../../../assets/icons/duotone/SortingArrowsDuoIcon.vue";
import KronosBaseActionModal from "../../../UI/BaseComponents/KronosBaseActionModal.vue";
import {mapState} from "vuex";
import {RetrieveLenders} from "../../../services/Lender";
import QuestionMarkDuoIcon from "../../../assets/icons/duotone/QuestionMarkDuoIcon.vue";
import {RetrieveLead} from "../../../services/Lead";

export default {
    emits: ['close'],

    components: {
	    QuestionMarkDuoIcon,
	    KronosBaseActionModal,
	    SortingArrowsDuoIcon,
	    TransferDuoIcon,
        LoaderAnimation,
        CloseIcon,
    },

    mixins: [
        NotificationMixin,
    ],

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return  {
	        selectLenderIsOpen: false,
	        new_lender: null,
            isLoading: false,
            lenders: [],
            selected: null,
	        searchFilter: null,
	        filteredLender: [],
        }
    },

	watch: {
		searchFilter() {
			this.filteredList()
		},

		selectLenderIsOpen() {
			this.filteredLender = this.lenders;
		}
	},

    computed: {
		...mapState([
			'ContextLead',
		]),

        BaseURL() {
            return import.meta.env.VITE_BACKEND_BASE_URL + '/storage';
        },
    },

    methods: {
	    filteredList() {
			if (this.searchFilter !== null) {
				this.filteredLender = this.lenders.filter(item => {
					return item.name.toLowerCase().includes(this.searchFilter.toLowerCase());
				});
			} else {
				this.filteredLender = this.lenders;
			}
	    },

        close() {
			this.selectLenderIsOpen = false;
			this.new_lender = null;
			this.searchFilter = null;
            this.$emit('close');
        },

	    RetrieveLenders() {
			RetrieveLenders(`account_id=${this.ContextLead.client.account_id}`)
				.then((response) => {
					this.lenders = response.data.data.lenders.filter(lender => lender.id !== this.ContextLead.lender_id);
				})
	    },

        transferLead() {
            if (this.isLoading === false) {
	            this.isLoading = true;

	            StoreTransferredLead(this.ContextLead.id, this.new_lender.id)
		            .then(response => {
			            if (response.status === 200) {
				            this.showNotification('success', 'Lead Successfully Transferred');
							this.$store.state.isLoading = true;
							RetrieveLead(this.$route.params.id)
								.then(response => {
									this.$store.state.ContextLead = response.data.data.lead;
								})

							this.close();
			            }
		            })
		            .catch(() => {
			            this.showNotification('error', 'An Error Occurred While Storing Transferring This Lead');
		            })
		            .finally(() => {
			            this.isLoading = false;
		            });
            }
        },
    },

	mounted() {
		this.RetrieveLenders();
	}
}
</script>

<style>
.rounded-shadow {
	box-shadow: 0px 1px 14px -6px rgba(0,0,0,0.65);
	-webkit-box-shadow: 0px 1px 14px -6px rgba(0,0,0,0.65);
	-moz-box-shadow: 0px 1px 14px -6px rgba(0,0,0,0.65);
}

.custom-scrollbar::-webkit-scrollbar
{
	width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
	background-color: lightgray;
}

.custom-scrollbar::-webkit-scrollbar-track
{
	background-color: transparent;
}
</style>
