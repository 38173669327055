<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<circle cx="12" cy="12" r="10" opacity=".35"/><path d="M17,16.131c0,0.771,0.93,1.159,1.478,0.616l3.893-3.856c0.495-0.491,0.495-1.291,0-1.782l-3.893-3.856	C17.93,6.71,17,7.098,17,7.869V16.131z"/><rect width="7.799" height="4" x="12" y="10"/><circle cx="12" cy="12" r="2"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
