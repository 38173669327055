<template>
    <kronos-base-layout>
        <div class="flex flex-col gap-[16px]">
            <kronos-base-heading title="Create an automation">
                <a href="/automation" class="h-[28px] w-[75px] bg-white text-gray-500 rounded-[2pt] text-[11.5px] flex items-center justify-center">Cancel</a>
                <div class="text-white tracking-wide h-[28px] w-[75px] bg-theme-blue rounded-[2pt] text-[11.5px] flex items-center justify-center">Save</div>
            </kronos-base-heading>

            <div class="w-full h-fir bg-white rounded-[4pt]">
                <header class="h-[40px] border-b border-gray-100 px-[12px] flex items-center text-[11.5px] text-gray-400 tracking-wide">Configuration</header>
                <div class="p-[24px] pt-[16px] flex gap-[16px]">
                    <div class="w-1/3 flex flex-col gap-0.5">
                        <label class="text-[11.5px] text-gray-400 font-medium">Title</label>
                        <input placeholder="Title" type="text" class="placeholder:text-gray-400 text-[11.5px] font-medium text-gray-500 px-[8px] bg-[#E9E9E9] bg-opacity-70 h-[32px] rounded-[2px] w-full" />
                    </div>
                    <div class="w-1/3 flex flex-col gap-0.5">
                        <label class="text-[11.5px] text-gray-400 font-medium">Model</label>
                        <base-table-filter-dropdown
                            placeholder="Model"
                            :options="Entities.Models"
                            @update="Form.Model = $event"
                            :selected-option="Form.Model"
                        />
                    </div>
                    <div class="w-1/3 flex flex-col gap-0.5">
                        <label class="text-[11.5px] text-gray-400 font-medium">Action</label>
                        <base-table-filter-dropdown
                            placeholder="Action"
                            :options="Entities.Action"
                            @update="Form.Action = $event"
                            :selected-option="Form.Action"
                        />
                    </div>
                </div>
            </div>

            <div class="w-full h-fit bg-white rounded-[4pt]">
                <header class="h-[40px] flex justify-between border-b border-gray-100 px-[12px] items-center text-[11.5px] text-gray-400 tracking-wide">
                    Criterias
                    <div
                        @click="this.AddCriteria()"
                        class="h-[24px] flex items-center cursor-pointer justify-center rounded-[2pt] text-white text-[10.5px] tracking-wide px-[12px] bg-theme-blue"
                    >
                        Add Criteria
                    </div>
                </header>
                <div class="p-[24px] pt-[16px] flex flex-col gap-[24px]">
                    <div
                        v-for="(criteria, index) in Criterias"
                        class="flex w-full gap-[16px]"
                    >
                        <div class="w-1/3 flex flex-col gap-0.5">
                            <label class="text-[11.5px] text-gray-400 font-medium">Field</label>
                            <base-table-filter-dropdown
                                placeholder="Action"
                                :options="this.CriteriaFields()"
                                @update="criteria.field = $event"
                                :selected-option="criteria.field"
                            />
                        </div>
                        <div class="w-1/3 flex flex-col gap-0.5">
                            <label class="text-[11.5px] text-gray-400 font-medium">Operator</label>
                            <base-table-filter-dropdown
                                placeholder="Value"
                                :options="this.Entities.Operators"
                                @update="criteria.operator = $event"
                                :selected-option="criteria.operator"
                            />
                        </div>
                        <div class="w-1/3 flex flex-col gap-0.5">
                            <label class="text-[11.5px] text-gray-400 font-medium">Value</label>
                            <base-table-filter-dropdown
                                placeholder="Value"
                                :options="this.CriteriaValues(criteria.field)"
                                @update="criteria.value = $event"
                                :selected-option="criteria.value"
                            />
                        </div>
                        <div
                            v-if="index !== 0"
                            @click="this.RemoveCriteria(index)"
                            class="w-[50px] group flex items-center justify-center h-[32px] mt-[17.25px] cursor-pointer"
                        >
                            <trash-can-icon
                                class="block group-hover:hidden"
                                color="darkgray"
                                size="14px"
                            />
                            <trash-can-icon
                                class="hidden group-hover:block"
                                color="red"
                                size="14px"
                            />
                        </div>
                        <div v-else class="w-[50px]"></div>
                    </div>
                </div>
            </div>

            <div class="w-full h-fit bg-white rounded-[4pt] mb-[24px]">
                <header class="h-[40px] border-b border-gray-100 px-[12px] flex justify-between items-center text-[11.5px] text-gray-400 tracking-wide">
                    Action & Delay
                    <div
                        @click="this.AddAction()"
                        class="h-[24px] flex items-center cursor-pointer justify-center rounded-[2pt] text-white text-[10.5px] tracking-wide px-[12px] bg-theme-blue"
                    >
                        Add Action
                    </div>
                </header>
                <div class="p-[24px] pt-[16px] flex flex-col gap-[24px]">
                    <div
                        v-for="(Action, index) in Actions"
                        class="flex w-full gap-[16px]"
                    >
                        <div class="w-1/3 flex flex-col gap-0.5">
                            <label class="text-[11.5px] text-gray-400 font-medium">Dispatch Action</label>
                            <base-table-filter-dropdown
                                placeholder="Action"
                                :options="Entities.DispatchActions"
                                @update="Actions[index].event = $event"
                                :selected-option="Actions[index].event"
                            />
                        </div>
                        <div class="w-1/3 flex flex-col gap-0.5">
                            <label class="text-[11.5px] text-gray-400 font-medium">Template</label>
                            <base-table-filter-dropdown
                                placeholder="Action"
                                :options="this.TemplateOption(Action.event)"
                                @update="Action.template = $event"
                                :selected-option="Action.template"
                            />
                        </div>
                        <div class="w-1/3 flex flex-col gap-0.5">
                            <label class="text-[11.5px] text-gray-400 font-medium">Time</label>
                            <input placeholder="Time" type="text" class="placeholder:text-gray-400 text-[11.5px] font-medium text-gray-500 px-[8px] bg-[#E9E9E9] bg-opacity-70 h-[32px] rounded-[2px] w-full" />
                        </div>
                        <div
                            v-if="index !== 0"
                            @click="this.RemoveActions(index)"
                            class="w-[50px] group flex items-center justify-center h-[32px] mt-[17.25px] cursor-pointer"
                        >
                            <trash-can-icon
                                class="block group-hover:hidden"
                                color="darkgray"
                                size="14px"
                            />
                            <trash-can-icon
                                class="hidden group-hover:block"
                                color="red"
                                size="14px"
                            />
                        </div>
                        <div v-else class="w-[50px]"></div>
                    </div>
                </div>
            </div>
        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../UI/Layouts/KronosBaseLayout.vue";
import KronosBaseDateRangeSelector from "../UI/BaseComponents/KronosBaseDateRangeSelector.vue";
import KronosBaseHeading from "../UI/BaseComponents/KronosBaseHeading.vue";
import AutomationTable from "../UI/Tables/AutomationTable.vue";
import TrashCanIcon from "../assets/svg/TrashCanIcon.vue";
import BaseTableFilterDropdown from "../UI/Tables/BaseTableFilterDropdown.vue";

export default
{
    components: {
        BaseTableFilterDropdown,
        TrashCanIcon,
        AutomationTable,
        KronosBaseHeading, KronosBaseDateRangeSelector,
        KronosBaseLayout
    },

    data() {
        return {
            Form: {
                Title: '',
                Model: '',
                Action: '',
            },
            Criterias: [
                {field: '', operator: '', value: ''}
            ],
            Actions: [
                {event: '', template: '', time: ''}
            ],
            Entities: {
                Models: [
                    {value: 'LEAD', label: 'Lead'},
                    {value: 'CLIENT', label: 'Client'},
                ],
                Action: [
                    {value: 'UPDATED', label: 'When Updated'},
                    {value: 'CREATED', label: 'When Created'},
                    {value: 'DELETED', label: 'When Deleted'},
                ],
                DispatchActions: [
                    {value: 'EMAIL', label: 'Send Email'},
                    {value: 'SMS', label: 'Send SMS'},
                ],
                Fields: {
                    Lead: [
                        {value: 'status_id', label: 'Status'},
                        {value: 'user_id', label: 'Responsible'},
                        {value: 'manager_id', label: 'Manager'},
                        {value: 'amount', label: 'Amount Requested'},
                    ],
                    Client: [
                        {value: 'email', label: 'Email'},
                    ]
                },
                Operators: [
                    {value: '=', label: 'Is Equal To'},
                    {value: '!=', label: 'Is Not Equal To'},
                    {value: 'LIKE', label: 'Is Similar To'},
                    {value: '>', label: 'Is Larger Than'},
                    {value: '<', label: 'Is Smaller Than'},
                ],
                Templates: {
                    EMAIL: [
                        {value: 1, label: 'Offer Email (EN)'},
                        {value: 2, label: 'Offer Email (FR)'},
                        {value: 3, label: 'Welcome Email (FR)'},
                        {value: 4, label: 'Welcome Email (EN)'},
                    ],
                    SMS: [
                        {value: 1, label: 'Offer SMS (EN)'},
                        {value: 2, label: 'Offer SMS (FR)'},
                        {value: 3, label: 'Welcome SMS (FR)'},
                        {value: 4, label: 'Welcome SMS (EN)'},
                    ],
                },
                Values: {
                    status_id: [
                        {value: 1, label: 'Accepted'},
                        {value: 2, label: 'Denied'},
                        {value: 3, label: 'Started'},
                        {value: 4, label: 'Not Processed'},
                        {value: 5, label: 'Waiting Verification'},
                        {value: 6, label: 'Waiting Offer'},
                    ],
                    amount: [
                        {value: 100, label: '100$'},
                        {value: 250, label: '250$'},
                        {value: 500, label: '500$'},
                        {value: 750, label: '750$'},
                        {value: 1000, label: '1000$'},
                    ]
                }
            },
        }
    },

    methods: {
        AddCriteria() {
            this.Criterias.push(
                {field: '', operator: '', value: ''},
            )
        },

        AddAction() {
            this.Actions.push(
                {event: '', template: '', time: ''}
            )
        },

        RemoveCriteria(Index) {
            this.Criterias.splice(Index, 1);
        },

        RemoveActions(Index) {
            this.Actions.splice(Index, 1);
        },

        CriteriaFields() {
            if (this.Form.Model === 'LEAD') {
                return this.Entities.Fields.Lead;
            }

            if (this.Form.Model === 'CLIENT') {
                return this.Entities.Fields.Client;
            }

            return [];
        },

        CriteriaValues(field) {
            if (this.Form.Model === 'LEAD') {
                return this.Entities.Values[field];
            }
        },

        TemplateOption(action) {
            if (action === 'EMAIL') {
                return this.Entities.Templates.EMAIL;
            }

            if (action === 'SMS') {
                return this.Entities.Templates.SMS;
            }

            return [];
        }
    }
}
</script>

<style scoped>

</style>