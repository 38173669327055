<template>
    <div
        v-click-outside="Close"
        @click="isOpen = !isOpen"
        id="dropdown"
        class="cursor-pointer h-[32px] relative z-[9999] text-gray-400 rounded-[2px] text-[11.5px] w-full bg-[#E9E9E9] flex items-center px-[8px]"
    >
        {{ this.Placeholder(selectedOption) }}
        <div v-if="isOpen" class="absolute py-[4px] top-[36px] left-0 cursor-pointer flex flex-col w-full bg-white rounded-[2pt] custom-shadow-1 z-20">
            <div
                v-for="option in options"
                @click="$emit('update', option.value)"
                class="h-[26px] px-[8px] truncate capitalize cursor-pointer hover:bg-theme-blue hover:text-theme-blue hover:bg-opacity-20 duration-500 first-of-type:rounded-t-[2px] last-of-type:rounded-b-[2px] flex items-center justify-start"
            >
                <span class="truncate cursor-pointer mt-0.5">
                    {{ option.label }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default
{
    props: {
        options: {
            type: Array,
            default: [{value: false, label: 'NO'}, {value: true, label: 'YES'}],
        },
        selectedOption: {
            default: null,
        },
        hasNoneOption: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: 'Filter ...'
        }
    },

    data() {
        return {
            isOpen: false,
        }
    },

    methods: {
        Close() {
            this.isOpen = false;
        },

        Placeholder(value) {
            for (const option of this.options) {
                if (option.value === value) {
                    return option.label;
                }
            }
            return this.placeholder;
        }
    },
}
</script>
