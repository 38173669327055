<template>
    <div class="custom-shadow-0 | flex flex-col gap-[20px] bg-white pb-[20px] rounded-lg border border-gray-100">
        <div class="flex items-center justify-between h-[42px] border-b pl-[24px] border-gray-100">
            <h1 class="text-[12px] font-light">
                Employer
            </h1>
            <div class="flex gap-2 border-gray-100 mr-2">
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="this.UpdateClientOccupation()"
                    class="w-[28px] rounded bg-blue-50 h-[28px] group hover:bg-blue-500 duration-500 flex items-center justify-center"
                >
                    <save-icon
                        stroke-width="2"
                        class="h-[16px] stroke-blue-500 fill-transparent group-hover:stroke-blue-100 duration-500"
                    />
                </button>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="ApproveSection"
                    :class="[IsValidated ? 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 ' : 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-green-100', 'duration-500 h-[28px] px-[24px] cursor-pointer text-xs rounded']"
                >
                    {{ IsValidated ? 'Decline' : 'Approve' }} Section
                </button>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-[24px] px-[24px]">
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Employer Name</label>
                <input type="text" v-model="payload.employer_name" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Employer Phone Number</label>
                <input v-maska data-maska="+1 ### #######" type="text" placeholder="+1 000 0000000" v-model="payload.employer_phone" class="border-none placeholder:text-gray-300 h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Employment Date</label>
                <input type="date" v-model="payload.hired_at" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Revenue Origin</label>
                <input type="text" v-model="payload.income_source" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Employment Situation</label>
                <input type="text" v-model="payload.work_status" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {vMaska} from "maska";
import SaveIcon from "../../../../assets/icons/SaveIcon.vue";
import {UpdateClientOccupation} from "../../../../services/Client/ClientOccupation";
import NotificationMixin from "../../../../mixins/NotificationMixin";

export default
{
    components: {
        SaveIcon
    },

    emits: ['approve-section'],

    props: {
        IsValidated: {
            type: Boolean,
            default: false,
        }
    },

    directives: {
        maska: vMaska
    },

    data() {
        return {
            EmploymentType: {
                "EMPLOYED": 'Employed',
                "UNEMPLOYED": 'Unemployed',
                "RETIRED": 'Retired',
                "PARENTAL_INSURANCE": 'Parental Insurance',
                "AUTOMOBILE_INSURANCE": 'Automobile Insurance',
                "SOCIAL_ASSISTANCE": 'Social Insurance',
                "OTHER": 'Other'
            },
            EmploymentStatus: {
                "FULL_TIME": 'Full Time',
                "PART_TIME": 'Part Time',
                "CONTRACTUAL": 'Contractual',
                "SEASONAL": 'Seasonal'
            },
            payload: {
                'employer_name': null,
                'employer_phone': null,
                'hired_at': null,
                'work_status': null,
                'income_source': null,
            }
        }
    },

    computed: {
        ...mapState([
            'ContextLead'
        ]),
    },

    mixins: [
        NotificationMixin
    ],

    methods: {
        ApproveSection() {
            this.$emit('approve-section', {approved_step: 'employer', approved_step_status: !this.IsValidated});

            this.UpdateClientOccupation();
        },

        UpdateClientOccupation() {
            const parsed_phone_number = this.payload.employer_phone.split(' ');
            const data = {
                ...this.payload,
                'phone_number': parsed_phone_number[2],
                'phone_country_code': parsed_phone_number[0],
                'phone_area_code': parsed_phone_number[1]
            }

            UpdateClientOccupation(this.ContextLead.client.occupation['id'], {
                'client_occupation': data
            })
                .then((response) => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Client Occupation Has Been Updated Successfully.');
                    }
                })
                .catch(() => {
                    this.showNotification('error', 'An Error Occurred While Updating Client Occupation');
                });
        },

        FormatDate(date) {
            const context_date = new Date(date);
            return `${context_date.getFullYear()}-${(context_date.getMonth() + 1 + '').padStart(2, '0')}-${(context_date.getDate() + '').padStart(2, '0')}`;
        },
    },

    mounted() {
        if(this.ContextLead.client.occupation) {
            this.payload.employer_name = this.ContextLead.client.occupation['employer_name'];
            this.payload.hired_at = this.FormatDate(this.ContextLead.client.occupation['hired_at']);
            this.payload.income_source = this.ContextLead.client.occupation['income_source'];
            this.payload.work_status = this.ContextLead.client.occupation['work_status'];

            if (!!this.ContextLead.client.occupation['phone_country_code'] || !!this.ContextLead.client.occupation['phone_area_code'] || !!this.ContextLead.client.occupation['phone_number']) {
                this.payload.employer_phone = this.ContextLead.client.occupation['phone_country_code'] + this.ContextLead.client.occupation['phone_area_code'] + this.ContextLead.client.occupation['phone_number'];
            }
        }
    }
}
</script>
