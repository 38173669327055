<template>
	<div>
		<div v-if="isLoading" class="fixed z-50 top-0 left-0 bg-black w-screen h-screen bg-opacity-50 flex items-center justify-center">
			<loader-animation border="3px solid white" size="32px"/>
		</div>
		<form @submit.prevent="checkAndResetPasswords">

			<kronos-base-action-modal
				:is-open="modalOpen"
				:can-close="false"
				title=""
				width="525px"
				:has-header="false"
				class="absolute z-40"
			>
				<div class="flex flex-col -mt-1 gap-[2px] mb-1 justify-center items-center w-full">
					<h1 class="flex tracking-wide text-[26px] font-medium text-[#212529]">
						Welcome {{ ContextUser.first_name }} !
					</h1>
					<span class="text-[14px] text-[#6c757d] text-opacity-75 -mt-0.5 tracking-wider text-center w-2/3">
                    Please create a strong, unique password for your first login.
                </span>
				</div>
				<div class="w-full flex flex-col gap-4 mt-4 mb-3">
					<div class="flex w-full flex-col gap-3">
						<div class="w-full flex flex-col">
							<input
								v-model="form.old_password"
								class="h-[52px] w-full rounded-md px-4 focus:ring-0 border border-transparent focus:border-[#5c74d6] focus:outline-none text-sm"
								:class="!!errors.old_password?.message ? 'bg-red-100 text-red-600 placeholder:text-red-400' : 'text-gray-600 bg-gray-100'"
								type="text"
								placeholder="Temporary Password"
							>
							<p
								v-if="errors.old_password.message"
								class="text-right text-xs trakcint-wide text-red-500 mt-2"
							>
								{{ errors?.old_password?.message }}
							</p>
						</div>
						<div class="w-full flex flex-col">
							<input
								v-model="form.password"
								class="h-[52px] w-full rounded-md px-4 focus:ring-0 border border-transparent focus:border-[#5c74d6] focus:outline-none text-sm"
								:class="!!errors.password.message ? 'bg-red-100 text-red-600 placeholder:text-red-400' : 'text-gray-600 bg-gray-100'"
								type="password"
								placeholder="Password"
								autocomplete="disabled"
							>
							<p
								v-if="errors.password.message"
								class="text-right text-xs trakcint-wide text-red-500 mt-2"
							>
								{{ errors?.password?.message }}
							</p>
						</div>
						<div class="w-full flex flex-col">
							<input
								v-model="form.password_confirmation"
								class="h-[52px] w-full rounded-md px-4 focus:ring-0 border border-transparent focus:border-[#5c74d6] focus:outline-none text-sm"
								:class="!!errors.password.message ? 'bg-red-100 text-red-600 placeholder:text-red-400' : 'text-gray-600 bg-gray-100'"
								type="password"
								placeholder="Confirm Password"
								autocomplete="disabled"
							>
							<p
								v-if="errors.password.message"
								class="text-right text-xs trakcint-wide text-red-500 mt-2"
							>
								{{ errors?.password?.message }}
							</p>
						</div>
					</div>
				</div>
				<template v-slot:modal-button>
					<button
						class="bg-[#5c74d6] font-medium text-white h-[48px] text-[14px] rounded-md bg-opacity-90 hover:bg-opacity-100 duration-500"
						type="submit"
					>
						Set New Password
					</button>
				</template>
			</kronos-base-action-modal>
		</form>
	</div>
</template>

<script>
import KronosAuthInput from "./KronosAuthInput.vue";
import KronosBaseActionModal from "./KronosBaseActionModal.vue";
import KronosPageLoader from "./KronosPageLoader.vue";
import {UpdateUserPassword} from "../../services/User";
import {Logout} from "../../services/Authentication";
import NotificationMixin from "../../mixins/NotificationMixin";
import {mapState} from "vuex";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";

export default
{
    components: {
	    LoaderAnimation,
        KronosAuthInput,
        KronosBaseActionModal,
        KronosPageLoader
    },

	computed: {
		...mapState(['ContextUser']),
	},

    data() {
        return {
			isLoading: false,
            form: {
                old_password: null,
                password: null,
	            password_confirmation: null
            },
            errors: {
                password: {
                    status: false,
                    message: null,
                },
                old_password: {
                    status: false,
                    message: null,
                },
            },
            modalOpen: false,
        }
    },

    methods: {
        updatePassword() {
			if (!this.isLoading) {
				this.isLoading = true;
				const formData = {
					old_password: this.form.old_password,
					password: this.form.password,
					password_confirmation: this.form.password_confirmation,
				}

				UpdateUserPassword(this.$store.state.ContextUser.id, formData)
					.then(response => {
						console.log(response.data)
						this.modalOpen = false;
						this.showNotification('success', 'Password updated, redirecting to login pages.')
						setTimeout(() => {
							this.logout()
						}, 1500);
					})
					.catch(e => {
						this.modalOpen = true;
						this.errors.password.status = true;
						this.errors.password.message = e.response.data.meta.description;
					})
					.finally(() => {
						this.isLoading = false;
					})
			}
        },

        async logout() {
            await Logout()
                .then(() => {
                    window.location.href = '/login'
                })
                .catch(e => {
                    console.log(e)
                })
                .finally(() => {
                    setTimeout(() => {}, 500)
                });
        },

        checkAndResetPasswords() {
            this.errors.password.status = false;
            this.errors.old_password.status = false;
            this.errors.password.message = null;
            this.errors.old_password.message = null;

            if (!this.form.old_password) {
                this.errors.old_password.status = true;
                this.errors.old_password.message = "Old Password must not be empty";
            }else if (!this.form.password || !this.form.password_confirmation) {
                this.errors.password.status = true;
                this.errors.password.message = "Invalid password";
            } else if (this.form.password !== this.form.password_confirmation) {
                this.errors.password.status = true;
                this.errors.password.message = "Passwords don't match";
            } else if (this.form.old_password === this.form.password) {
                this.errors.password.status = true;
                this.errors.old_password.status = true;
                this.errors.password.message = "Old Password must not match new password.";
                this.errors.old_password.message = "Old Password must not match new password.";
            } else if (!/[A-Z]/.test(this.form.password) || !/[A-Z]/.test(this.form.password_confirmation)) {
                this.errors.password.status = true;
                this.errors.password.message = "Password must start with a capital letter.";
            } else if (!/[!@#$%]/.test(this.form.password) || !/[!@#$%]/.test(this.form.password_confirmation)) {
                this.errors.password.status = true;
                this.errors.password.message = "Password must contain at least one special character: !@#$%^";
            } else {
                this.updatePassword();
            }
        },
    },

    watch: {
        '$store.state.ContextUser.is_first_login'(newValue) {
            if (newValue) {
                this.modalOpen = true;
            }
        }
    },

	mounted() {
		if (!!this.ContextUser?.first_login_at) {
			this.modalOpen = true;
		}
	},

	mixins: [
        NotificationMixin,
    ],
}
</script>
