import axios from "axios";

let BaseApi = axios.create({
	baseURL: import.meta.env.VITE_BACKEND_BASE_URL + '/api',
});

let Api = function() {
	BaseApi.defaults.withCredentials = true;
	BaseApi.defaults.headers['Content-Type'] = 'application/json'

	return BaseApi;
};

export default Api;
