<template>
    <div class="flex flex-col gap-[18px]">
        <div class="bg-white p-[16px] rounded-b-[4pt]">
            <div class="grid-cols-4 grid gap-[16px]">
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">First Name</label>
                    <input :value="ContextUser.first_name" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Middle Name</label>
                    <input type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Last Name</label>
                    <input :value="ContextUser.last_name" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Email</label>
                    <input :value="ContextUser.email" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Password</label>
                    <input type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Password Confirmation</label>
                    <input type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Gender</label>
                    <input value="M" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Language</label>
                    <input value="EN" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Date of Birth</label>
                    <input value="2000/09/09" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Role</label>
                    <input value="Programmer" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
                <div class="flex flex-col gap-[4px]">
                    <label class="text-[10.5px] text-[#8E8E8E]">Dashboard Type</label>
                    <input value="Manager" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                </div>
            </div>
        </div>

        <div class="bg-white rounded-[4pt]">
            <div class="custom-shadow-1 rounded-[4pt] bg-white">
                <div class="h-[42px] text-[12px] justify-between text-gray-600 flex items-center pl-[16px] w-full border-b border-gray-100">
                    <span>
                        Communication Information
                    </span>
                    <div class="flex gap-0">
                        <button
                            @click="OpenByPhoneNumberModal()"
                            class="h-[42px] cursor-pointer hover:bg-gray-100 duration-500 rounded-tr-[4pt] flex items-center justify-center w-[42px] border-l border-gray-100"
                        >
                            <plus-icon
                                color="gray"
                                size="14px"
                            />
                        </button>
                    </div>
                </div>
                <div class="grid-cols-5 grid gap-[16px] p-[16px]">
                    <div class="flex flex-col gap-[4px]">
                        <label class="text-[10.5px] text-[#8E8E8E]">Phone Number</label>
                        <input value="+15146476479" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                    </div>
                    <div class="flex flex-col gap-[4px]">
                        <label class="text-[10.5px] text-[#8E8E8E]">Ext.</label>
                        <input value="340" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                    </div>
                    <div class="flex flex-col gap-[4px]">
                        <label class="text-[10.5px] text-[#8E8E8E]">Ringtone</label>
                        <input value="1" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                    </div>
                    <div class="flex flex-col gap-[4px]">
                        <label class="text-[10.5px] text-[#8E8E8E]">Answer</label>
                        <input value="12" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                    </div>
                    <div class="flex flex-col gap-[4px]">
                        <label class="text-[10.5px] text-[#8E8E8E]">Type</label>
                        <input value="Inbound/Outbound" type="text" class="bg-[#EEEEEE] h-[32px] text-[#8E8E8E] rounded-[4pt] px-[12px] text-[11.5px]"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white rounded-[4pt]">
            <div class="custom-shadow-1 rounded-[4pt] bg-white">
                <div class="h-[42px] text-[12px] text-gray-600 flex items-center justify-start pl-[16px] w-full border-b border-gray-100">
                    Division Access
                </div>
                <div class="p-[16px]">
                    <table class="custom-shadow-1 w-full">
                        <tr class="text-[10.5px] h-[32px] bg-[#4D4D4D] text-white" >
                            <td class="rounded-tl-[4pt] tracking-wide min-w-[150px] px-[24px]">Division</td>
                            <td class="w-[250px] rounded-tr-[4pt] tracking-wide min-w-[150px] text-center px-[24px]">Has Access</td>
                        </tr>
                        <tr class="h-[4pt]"></tr>
                        <tbody>
                            <tr
                                v-for="(Division, Index) in Divisions"
                                :key="Index"
                                class="text-[10.5px] h-[32px] hover:bg-gray-100 duration-500 group"
                                @click="Division.hasAccess = !Division.hasAccess"
                            >
                                <td class="text-gray-500 tracking-wide min-w-[150px] px-[24px]">
                                    {{ Division.label }}
                                </td>
                                <td class="w-[250px]">
                                    <div
                                        :class="[
                                            'w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm shadow-sm transition-colors',
                                            Division.hasAccess ? 'bg-theme-blue' : 'bg-gray-200 group-hover:bg-gray-300',
                                        ]"
                                    ></div>
                                </td>
                            </tr>
                        </tbody>
                        <tr class="h-[4pt]">
                            <td class="rounded-bl-[4pt]"></td>
                            <td class="rounded-br-[4pt]"></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import PlusIcon from "../../assets/svg/PlusIcon.vue";

export default
{
    components: {
        PlusIcon
    },

    computed: {
        ...mapState([
            'ContextUser'
        ]),
    },

    data() {
        return {
            Divisions: [
                {value: 'approbation', label: 'Approbation', hasAccess: false,},
                {value: 'collection', label: 'Collection', hasAccess: false},
                {value: 'marketing', label: 'Marketing', hasAccess: false},
            ],
        }
    },

    methods: {
        OpenByPhoneNumberModal() {
            this.$emit('open-buy-phone-number-modal');
        }
    }
}
</script>
