import Api from '../Api.js';

const BaseURL = 'v1/client-bank';

export const RetrieveClientBankAccount = async (id) => {
    return await Api()
        .get(`${BaseURL}/${id}`);
}

export const RetrieveClientBankAccounts = async (client_id) => {
    return await Api()
        .get(`${BaseURL}/client/${client_id}`);
}

export const StoreClientBankAccount = async () => {
    return await Api()
        .post(`${BaseURL}`);
}

export const UpdateClientBankAccount = async (id) => {
    return await Api()
        .put(`${BaseURL}/${id}`);
}

export const DestroyClientBankAccount = async (id) => {
    return await Api()
        .delete(`${BaseURL}/${id}`);
}
