export default {
    methods: {
        truncateString(str, num) {
            if (str.length > num) {
                return str.slice(0, num) + "...";
            } else {
                return str;
            }
        },
        FormatDate(dateString) {
            const now = new Date();
            const date = new Date(dateString);
            const diffDays = (now - date) / (1000 * 3600 * 24);
            let format = { year: 'numeric', month: 'short', day: 'numeric' };

            if (diffDays < 1 && now.getDate() === date.getDate()) {
                format = { hour: '2-digit', minute: '2-digit' };
            } else if (diffDays < 7) {
                format = { weekday: 'long' };
            }

            return date.toLocaleDateString('en-US', format);
        },
    },
};