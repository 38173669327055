import api from './Api.js';

const BaseURL = 'v1/lead-transfer';

export const StoreTransferredLead = async (lead_id, lender_id) => {
    return await api()
        .post(`${BaseURL}/${lead_id}`, {
            'new_lender_id': lender_id
        });
}
