<template>
    <form @submit.prevent="SubmitForgotPasswordRequest">
        <div class="flex flex-col mb-[24px] justify-center items-start w-full">
            <h1 class="tracking-wide text-[28px] font-medium text-[#212529]">
                Recover Password
            </h1>
            <span class="text-sm text-gray-500 text-opacity-75 text-left tracking-wider">
                Enter your email to receive a password reset link.
            </span>
        </div>
        <div class="flex w-full flex-col gap-6">
            <kronos-auth-input
                v-model:value="this.Email"
                :error="Errors?.Email"
                name="email-address"
                placeholder="example@gmail.com"
                type="email"
            />
            <button class="bg-[#212529] px-[24px] font-medium text-white h-[52px] text-sm rounded-md bg-opacity-90 hover:bg-opacity-100 duration-500">
                Recover My Password
            </button>
            <router-link
                to="/login"
                class="bg-[#212529] -mt-4 bg-opacity-20 flex items-center justify-center px-[24px] font-medium h-[52px] text-sm rounded-md text-[#212529] hover:bg-gray-200 duration-500"
            >
                Back to Sign In
            </router-link>
        </div>
    </form>
</template>

<script>
import { ForgotPassword } from "../../../services/ForgotPassword";
import KronosAuthInput from "../../../UI/BaseComponents/Inputs/KronosAuthInput.vue";

export default
{
    emits: [
        'next-step',
        'loading'
    ],

    components: {
        KronosAuthInput
    },

    props: {
        IsLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            Email: null,
            Errors: {
                Email: null,
            },
        }
    },

    methods: {
        SubmitForgotPasswordRequest() {
            if (!this.IsLoading) {
                this.$emit('loading', true);

                ForgotPassword({email: this.Email})
                    .then((response) => {
                        if (response.status === 200) {
                            this.$emit("next-step", this.Email);
                        }
                    })
                    .catch((error) => {
                        this.Errors.Email = error.response.status === 404
                            ? this.$t("login.errors.user_not_found")
                            : "Invalid Email Address";
                    })
                    .finally(() => {
                        this.$emit('loading', false);
                    })
            }
        },
    }
}
</script>
