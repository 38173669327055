export default {
	state() {
		return {
			transactionOptions: [
				{ value: null, label: 'Select a transaction type' },
				{ value: 1, label: 'NON SUFFICIENT FUND' },
				{ value: 2, label: 'PAYMENT OPPOSITION' },
				{ value: 3, label: 'PAYROLL' },
				{ value: 4, label: 'E TRANSFER RECEIVED' },
				{ value: 5, label: 'E TRANSFER SENT' },
				{ value: 6, label: 'ATM WITHDRAW' },
				{ value: 7, label: 'ATM DEPOSIT' },
				{ value: 8, label: 'GOVERNMENT PAYMENT' },
				{ value: 9, label: 'GOVERNMENT DEPOSIT' },
				{ value: 10, label: 'MICRO LOANS PAYMENT' },
				{ value: 11, label: 'MICRO LOANS DEPOSIT' },
				{ value: 12, label: 'AUTO LOAN PAYMENT' },
				{ value: 13, label: 'MORTGAGE PAYMENT' },
				{ value: 14, label: 'PERSONAL LOAN PAYMENT' },
				{ value: 15, label: 'MISC PURCHASE' },
				{ value: 16, label: 'GAMBLING' },
				{ value: 17, label: 'MISC BANKING FEE' },
				{ value: 18, label: 'ALCOHOL DRUG' },
				{ value: 19, label: 'INSURANCE' },
				{ value: 20, label: 'RENT' },
				{ value: 21, label: 'TRUSTEE' },
				{ value: 22, label: 'COLLECTION AGENCIES PAYMENT' },
				{ value: 23, label: 'GROCERY' },
				{ value: 24, label: 'SUBSCRIPTION' },
				{ value: 25, label: 'TRANSPORTATION' },
				{ value: 26, label: 'MOBILITY' },
				{ value: 27, label: 'RESTAURANT AND DELIVERY' },
				{ value: 28, label: 'REFUND' },
				{ value: 29, label: 'CC PAYMENT' },
				{ value: 30, label: 'GAZ' },
				{ value: 31, label: 'ONLINE PURCHASE' },
				{ value: 32, label: 'APP PURCHASE' },
				{ value: 33, label: 'MISC PAYMENT' },
				{ value: 34, label: 'TRAVEL ENTERTAINMENT' },
				{ value: 35, label: 'BUY NOW PAY LATER' },
				{ value: 36, label: 'CASH ADVANCE' },
				{ value: 37, label: 'ELECTRICITY' },
				{ value: 38, label: 'INTERNATIONAL TRANSFER' },
				{ value: 39, label: 'GAMBLING DEPOSIT' },
				{ value: 40, label: 'PENSION' },
				{ value: 41, label: 'FAMILY ALLOWANCE' },
				{ value: 42, label: 'UNEMPLOYMENT BENEFITS' },
				{ value: 43, label: 'INTEREST RECEIVED' },
				{ value: 44, label: 'OTHER' }
			]
		}
	},
};