<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :height="size"
      :width="size"
      viewBox="0 0 24 24"
      :fill="fill">
    <path d="M14.394,13.696c0-2.607-2.878-2.251-2.878-3.61c0-0.8,0.629-0.884,0.847-0.884c0.215,0,0.404,0.051,0.565,0.124	c0.378,0.171,0.827,0.012,1.067-0.327c0.307-0.435,0.148-1.046-0.337-1.264c-0.266-0.12-0.598-0.216-1-0.254V7.101	c0-0.348-0.282-0.631-0.631-0.631s-0.631,0.282-0.631,0.631v0.512c-1.056,0.352-1.742,1.319-1.742,2.556	c0,2.736,2.842,2.242,2.842,3.664c0,0.274-0.129,0.87-0.835,0.87c-0.314,0-0.584-0.088-0.804-0.204	c-0.374-0.196-0.838-0.051-1.076,0.297l-0.025,0.037c-0.275,0.403-0.164,0.967,0.26,1.207c0.357,0.202,0.792,0.359,1.318,0.415	v0.443c0,0.348,0.282,0.631,0.631,0.631c0.348,0,0.631-0.282,0.631-0.631v-0.556C13.764,15.984,14.394,14.919,14.394,13.696z"/><path d="M2,12H1.587c-0.475,0-0.753,0.536-0.478,0.924l1.413,1.995c0.234,0.33,0.723,0.33,0.956,0l1.413-1.995 C5.166,12.536,4.889,12,4.413,12H4c0-4.943,4.483-8.839,9.605-7.844c3.119,0.606,5.633,3.12,6.239,6.239 C20.839,15.517,16.943,20,12,20c-1.634,0-3.153-0.491-4.419-1.332c-0.419-0.279-0.98-0.189-1.303,0.197 c-0.381,0.457-0.284,1.15,0.212,1.479c1.913,1.266,4.286,1.894,6.816,1.573c4.501-0.571,8.124-4.254,8.629-8.763 C22.611,7.114,17.903,2,12,2C6.477,2,2,6.477,2,12z" opacity=".35"/>
  </svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
