export const IbvDetailTable = [

	{
		'label': 'ACCOUNT NAME',
		'key': 'flinks_accounts.0.account_type',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'ACCOUNT NUMBER',
		'key': 'flinks_accounts.0.institution_number',
		'type': 'date',
		'min_w': '200px',
	},
	{
		'label': 'CREATED AT',
		'key': 'created_at',
		'type': 'date',
		'min_w': '200px',
	},
	{
		'label': 'CREATED AT',
		'key': 'created_at',
		'type': 'date',
		'min_w': '200px',
	},
	{
		'label': 'CREATED AT',
		'key': 'created_at',
		'type': 'date',
		'min_w': '200px',
	},
	{
		'label': 'CREATED AT',
		'key': 'created_at',
		'type': 'date',
		'min_w': '200px',
	},
	{
		'label': 'CREATED AT',
		'key': 'created_at',
		'type': 'date',
		'min_w': '200px',
	},
	{
		'label': 'CREATED AT',
		'key': 'created_at',
		'type': 'date',
		'min_w': '200px',
	},
];
