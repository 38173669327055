<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M 4 3 C 2.895 3 2 3.895 2 5 L 2 19 C 2 20.105 2.895 21 4 21 L 12 21 L 20 21 C 21.105 21 22 20.105 22 19 L 22 9 C 22 7.895 21.105 7 20 7 L 12 7 L 12 5 C 12 3.895 11.105 3 10 3 L 4 3 z M 4 5 L 6 5 L 6 7 L 4 7 L 4 5 z M 8 5 L 10 5 L 10 7 L 8 7 L 8 5 z M 4 9 L 6 9 L 6 11 L 4 11 L 4 9 z M 8 9 L 10 9 L 10 11 L 8 11 L 8 9 z M 12 9 L 20 9 L 20 19 L 12 19 L 12 17 L 14 17 L 14 15 L 12 15 L 12 13 L 14 13 L 14 11 L 12 11 L 12 9 z M 16 11 L 16 13 L 18 13 L 18 11 L 16 11 z M 4 13 L 6 13 L 6 15 L 4 15 L 4 13 z M 8 13 L 10 13 L 10 15 L 8 15 L 8 13 z M 16 15 L 16 17 L 18 17 L 18 15 L 16 15 z M 4 17 L 6 17 L 6 19 L 4 19 L 4 17 z M 8 17 L 10 17 L 10 19 L 8 19 L 8 17 z"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
