<template>
    <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
        <label class="text-[9.5px] text-gray-500 tracking-wide">
            {{ Label }}
        </label>
        <input
            v-maska
            data-maska="0.99"
            data-maska-tokens="0:\d:multiple|9:\d:optional"
            type="text"
            placeholder="0.00"
            @input="UpdateValue"
            :value="parseFloat(ContextValue).toFixed(2)"
            class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600"
        />
    </div>
</template>

<script>
import {vMaska} from "maska";

export default
{
    props: {
        ContextValue: {

        },
        Label: {
            type: String,
            default: 'Money Input'
        }
    },

    directives: {
        maska: vMaska
    },

    methods: {
        UpdateValue($event) {
            this.$emit('update-value', parseFloat($event.target.value));
        }
    },
}
</script>
