<template>
	<div>
		<transition
			name="modal"
			enter-active-class="ease-out duration-300"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="ease-in duration-200"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				v-if="isOpen"
				class="flex items-center justify-center z-50 w-screen h-screen absolute top-0 left-0 bg-black bg-opacity-60"
			>
				<transition
					enter-active-class="ease-out duration-300"
					enter-from-class="opacity-0 scale-95"
					enter-to-class="opacity-100 scale-100"
					leave-active-class="ease-in duration-200"
					leave-from-class="opacity-100 scale-100"
					leave-to-class="opacity-0 scale-95"
				>
					<div
						v-if="isOpen"
						v-click-outside="close"
						class="bg-white h-fit rounded-lg divide-y divide-gray-50"
						:class="hasHeader ? '' : 'pt-[24px]'"
						:style="`width: ${width}`"
					>
						<header v-if="hasHeader" class="h-[50px] flex items-center px-4 justify-between">
							<p class="text-sm text-gray-800 tracking-wide">
								{{ title }}
							</p>
							<close-icon
								v-if="canClose"
								@click="close()"
								class="cursor-pointer"
								size="18px"
								fill="#6b7280"
							/>
						</header>
						<div class="min-h-[50px] p-4 flex flex-col gap-6">
							<slot />
						</div>
						<div class="h-[60px] flex flex-col gap-6 mx-4 border-t border-gray-50 pb-4">
                            <button
                                @click="submit()"
                                :class="!!buttonClass ? buttonClass : 'text-[10.5px] text-white rounded w-full h-[48px] bg-black'"
                            >
                                <slot name="button-text" class="text-[10.5px]">{{ $t('global.save') }}</slot>
                            </button>
						</div>
					</div>
				</transition>
			</div>
		</transition>
	</div>
</template>

<script>
import CloseIcon from "../../assets/icons/CloseIcon.vue";

export default
{
	props: {
		title: {
			type: String,
			default: 'TITLE'
		},
		isOpen: {
			type: Boolean,
			default: false,
		},
        buttonClass: {
            type: String,
            default: ''
        },
		canClose: {
			type: Boolean,
			default: true,
		},
		width: {
			type: String,
			default: '500px'
		},
		hasHeader: {
			type: Boolean,
			default: true
		},
	},

	components: {
		CloseIcon,
	},

	methods: {
		submit() {
			this.$emit('submit');
		},

		close() {
			if (this.isOpen) {
				this.$emit('close');
			}
		},
	},
};
</script>
