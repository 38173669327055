<template>
    <section class="custom-shadow-1 | flex flex-col bg-white rounded-[6px] border">
        <div
            class="text-sm gap-2 justify-between pl-[18px] rounded-t-[6px] h-[45px] flex border-b border-gray-100 items-center"
            :class="IsValidated ? 'bg-green-100 text-green-600' : 'bg-white text-gray-500'"
        >
            <slot name="header" />
            <div
                class="border-l border-gray-100"
            >
                <slot name="actions"/>
                <div
                    @click="$emit('approve')"
                    :class="IsValidated ? 'hover:bg-red-100' : 'hover:bg-green-100'"
                    class="h-[44px] group duration-500 rounded-tr-[6px] flex items-center cursor-pointer justify-center group-hover:border-transparent w-[44px]"
                >
                    <check-duo-icon
                        class="fill-green-600 group-hover:fill-green-600 mt-0.5"
                        :class="IsValidated ? 'block group-hover:hidden' : 'hidden group-hover:block'"
                        size="14px"
                    />
                    <cross-duo-icon
                        class="fill-green-600 group-hover:fill-red-600 mt-0.5"
                        :class="!IsValidated ? 'block group-hover:hidden' : 'hidden group-hover:block'"
                        size="16px"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-[14px] p-[18px]">
            <slot name="container"/>
        </div>
    </section>
</template>

<script>
import CheckDuoIcon from "../../../assets/icons/duotone/CheckDuoIcon.vue";
import CrossDuoIcon from "../../../assets/icons/duotone/CrossDuoIcon.vue";

export default
{
    emits: ['approve'],

    components: {
        CrossDuoIcon,
        CheckDuoIcon,
    },

    props: {
        IsValidated: {
            type: Boolean,
            default: false,
        },
    }
}
</script>
