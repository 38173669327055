<template>
    <div class="relative">
        <div
            v-if="MassUpdateIsVisible"
            class="fixed position flex bg-opacity-40 bg-black items-center justify-center top-0 left-0 w-screen h-screen z-[55]"
        >
            <div class="custom-shadow-1 bg-white w-[425px] h-fit rounded-[4pt]">
                <header class="flex items-center justify-between px-[16px] h-[40px] border-b border-gray-100 text-[11.5px]">
                    <div>
                        Edit Leads
                        <span class="text-[9.5px] text-gray-400">
                            {{ `(${SelectedLeads.length} Leads Affected)` }}
                        </span>
                    </div>
                    <cross-icon
                        @click="MassUpdateIsVisible = false"
                        class="cursor-pointer"
                        size="12px"
                        color="gray"
                    />
                </header>
                <div class="p-[16px]">
                    <div class="flex gap-[8px]">
                        <KronosTableFilterDropdown
                            placeholder="Column"
                            :options="AvailableColumns"
                            @update="selectedColumn = $event"
                            :selected-option="selectedColumn"
                        />
                        <KronosTableFilterDropdown
                            :options="valueOptions"
                            @update="selectedValue = $event"
                            :selected-option="selectedValue"
                            placeholder="Value"
                        />
                    </div>
                </div>
                <footer class="h-[35px] flex gap-[6px] px-[8px] items-center">
                    <button
                        @click="MassUpdateIsVisible = false"
                        class="font-medium rounded-[2pt] bg-gray-200 text-gray-500 text-[9.5px] pt-0.5 tracking-wide h-[24px] w-1/2"
                    >
                        Cancel
                    </button>
                    <button
                        @click="updateSelectedLeads" class="font-medium rounded-[2pt] bg-theme-blue text-white text-[9.5px] pt-0.5 tracking-wide h-[24px] w-1/2"
                    >
                        Save
                    </button>
                </footer>
            </div>
        </div>

        <div class="bg-white rounded-[4pt] w-full h-fit divide-y divide-[#F2F2F2]">
            <div class="h-[45px] flex items-center justify-between pl-[24px]">
                <div class="flex gap-[12px] items-center">
                    <div class="text-[#6E6E6E] text-[13.5px] tracking-wide pt-0.5">
                        {{ title }}
                    </div>
                    <div
                        v-if="SelectedLeads.length > 0"
                        class="mt-0.5"
                        @click="MassUpdateIsVisible = true"
                    >
                        <div class="bg-theme-blue bg-opacity-20 group cursor-pointer duration-500 hover:bg-opacity-100 pt-[4.5px] rounded-[2px] h-[14px] flex items-center justify-center p-[4px]">
                            <more-horizontal-icon
                                class="block group-hover:hidden"
                                color="rgb(64 141 188)"
                                size="14px"
                            />
                            <more-horizontal-icon
                                class="hidden group-hover:block"
                                color="white"
                                size="14px"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="w-[40px] flex items-center justify-center"
                    @click="isOpen = !isOpen"
                >
                    <chevron-left-small-icon
                        :class="[!isOpen ? '' : 'rotate-[-90deg]', 'duration-300 cursor-pointer']"
                        color="gray"
                        size="16px"
                    />
                </div>
            </div>
            <div
                v-if="IsLoading && isOpen"
                class="h-[150px] text-[11.5px] text-gray-500 tracking-wide font-medium gap-2 flex-col w-full bg-white flex items-center justify-center"
            >
                <loader-animation
                    size="24px"
                    border="2.5px solid #408DBC"
                />
                Loading
            </div>
            <div v-else-if="(!this.IsLoading && this.Dataset.length === 0) && isOpen" class="h-[150px] text-[11.5px] text-gray-500 tracking-wide font-medium gap-2 flex-col w-full bg-white flex items-center justify-center">
                No Data Available...
            </div>
            <div
                v-else-if="isOpen"
                ref="table"
                class="flex w-full relative h-fit overflow-scroll"
                @scroll="this.HandleScroll()"
            >
                <div
                    :class="[IsScrolling ? 'border-transparent' : 'border-gray-100', 'border-r sticky left-0 h-fit max-h-[750px] z-50 min-w-[253.5px] w-[460.5px]']"
                    class="sticky left-0 padding-0 margin-0 z-30 min-w-[390.5px] w-[460.5px]"
                >
                    <div class="flex h-full w-full relative">
                        <table class="overflow-scroll bg-white w-full border-r border-gray-100 relative">
                            <thead>
                            <tr class="max-h-[38px] h-[38px] divide-x divide-gray-100">
                                <th class="sticky top-0 bg-white z-20 min-w-[36px] max-w-[36px] w-[36px]">
                                    <div class="border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center min-w-[36px] max-w-[36px] w-[36px] text-[#6E6E6E] text-[11.5px]">
                                        <base-table-checkbox class="mx-auto" @click="this.HandleCheck('all')" :is-checked="SelectedLeads.length === SortedDataset.length" />
                                    </div>
                                </th>
                                <th class="min-w-[76px] w-[76px] max-w-[76px] sticky top-0 bg-white z-20">
                                    <div @click="this.HandleSort('lender')" class="cursor-pointer border-b flex flex-col min-h-[38px] border-gray-100 font-medium items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                        Lender
                                        <chevron-left-small-icon v-if="Sorting.key === 'lender'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                    </div>
                                </th>
                                <th class="sticky top-0 z-20 bg-white">
                                    <div @click="this.HandleSort('client')" class="cursor-pointer border-b flex flex-col min-h-[38px] border-gray-100 font-medium items-center justify-center text-center w-[139] text-[#6E6E6E] text-[11px]">
                                        Clients
                                        <chevron-left-small-icon v-if="Sorting.key === 'client'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                    </div>
                                </th>
                                <th class="sticky top-0 z-20 bg-white">
                                    <div @click="this.HandleSort('created_at')" class="cursor-pointer border-b flex flex-col min-h-[38px] border-gray-100 font-medium items-center justify-center text-center w-[139] text-[#6E6E6E] text-[11px]">
                                        Date
                                        <chevron-left-small-icon v-if="Sorting.key === 'created_at'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                    </div>
                                </th>
                                <td class="sticky z-20 top-0 bg-white max-h-[38px] truncate">
                                    <div @click="this.HandleSort('contact')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                        Contact
                                        <chevron-left-small-icon v-if="Sorting.key === 'contact'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                    </div>
                                </td>
                            </tr>
                            <tr class="max-h-[46px] h-[46px] w-[36px] max-w-[36px] min-h-[46px] divide-x divide-gray-100">
                                <td class="sticky top-[38px] bg-white min-w-[36px] max-w-[36px] w-[36px]">
                                    <div :class="[IsScrollingX ? 'border-transparent' : 'border-gray-100', 'flex items-center h-[46px] justify-center border-b max-w-[36px]']">
                                        <filter-icon color="rgba(107, 114, 128, 0.7)" size="14px" fill="rgba(229, 231, 235, 0.9)" />
                                    </div>
                                </td>
                                <td class="sticky top-[38px] bg-white min-w-[76px] w-[76px]">
                                    <div :class="IsScrollingX ? 'border-transparent' : 'border-gray-100'" class="flex items-center h-[46px] justify-center border-b">
                                        <input placeholder="Filter..." type="text" class="placeholder:text-gray-400 text-[11.5px] font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" />
                                    </div>
                                </td>
                                <td class="sticky top-[38px] bg-white min-w-[75px] w-[75px]">
                                    <div :class="IsScrollingX ? 'border-transparent' : 'border-gray-100'" class="flex items-center h-[46px] justify-center border-b">
                                        <input placeholder="Filter..." type="text" class="placeholder:text-gray-400 text-[11.5px] font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" />
                                    </div>
                                </td>
                                <td class="sticky top-[38px] bg-white max-w-[45px] w-[45px]">
                                    <div :class="IsScrollingX ? 'border-transparent' : 'border-gray-100'" class="flex items-center h-[46px] justify-center border-b">
                                        <input placeholder="Filter..." type="text" class="placeholder:text-gray-400 text-[11.5px] font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" />
                                    </div>
                                </td>
                                <td class="sticky top-[38px] bg-white min-w-[95px] w-[95px]">
                                    <div :class="IsScrollingX ? 'border-transparent' : 'border-gray-100'" class="flex items-center h-[46px] justify-center border-b">
                                        <input placeholder="Filter..." type="text" class="placeholder:text-gray-400 text-[11.5px] font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" />
                                    </div>
                                </td>
                            </tr>
                            </thead>
                            <tbody v-if="IsScrollingX">
                                <tr>
                                    <td v-for="i in 5" class="sticky top-[84px]"><div class="h-[10px] bg-gradient-to-b from-gray-200 to-transparent"></div></td>
                                </tr>
                            </tbody>
                            <tbody class="divide-[#F2F2F2] divide-y">
                                <tr v-for="FixedColumnItem in SortedDataset" class="h-[76px] divide-x divide-[#F2F2F2] max-h-[76px]">
                                    <td class="min-w-[36px] max-w-[36px] w-[36px]">
                                        <div class="min-w-[36px] max-w-[36px] w-[36px] flex items-center justify-center">
                                            <base-table-checkbox
                                                @click="this.HandleCheck(FixedColumnItem.id)"
                                                :is-checked="this.SelectedLeads.includes(FixedColumnItem.id)"
                                            />
                                        </div>
                                    </td>
                                    <td class="min-w-[76px] w-[76px] max-w-[76px]">
                                        <div class="flex items-center flex-col justify-center h-full gap-2">
                                            <img
                                                class="h-[28px] w-[28px] rounded-[4pt]"
                                                :src="BaseURL + FixedColumnItem.lender.image_src"
                                            />
                                            <div class="bg-theme-blue text-white rounded-full font-medium tracking-wide px-[10px] pt-[2px] pb-[0.5px] text-[9.5px]">
                                                {{ FixedColumnItem.amount }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="max-w-[130px] w-[130px]">
                                        <div class="flex flex-col items-start px-[4px]">
                                            <div class="flex items-center cursor-pointer justify-start gap-[4px]">
                                                <router-link
                                                    :to="`/lead/${FixedColumnItem.id}`"
                                                    class="cursor-pointer text-[10.5px] text-theme-blue ml-0 tracking-wide font-medium text-left max-w-[95px] truncate"
                                                >
                                                    {{ `${FixedColumnItem.client.first_name} ${FixedColumnItem.client.last_name}` }}
                                                </router-link>
                                                <span class="text-[10.5px] text-gray-400 w-[24px] pr-[4px]">
                                                    (40)
                                                </span>
                                            </div>
                                            <div class="text-gray-400 font-medium text-[10.5px]">
                                                {{ `#${FixedColumnItem.client.id}` }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="min-w-[45px] max-w-[45px] w-[45px]">
                                        <div class="flex items-center text-[10px] flex-col text-center font-semibold tracking-wide text-gray-500 justify-center h-full">
                                            <div class="text-center">{{ `${this.GetTime(FixedColumnItem.created_at)}` }}</div>
                                            <div class="text-center truncate">{{ `${new Date(FixedColumnItem.created_at).getUTCDate()} ${this.GetMonth(FixedColumnItem.created_at)}` }}</div>
                                            <div class="text-center">{{ `${new Date(FixedColumnItem.created_at).getFullYear()}` }}</div>
                                        </div>
                                    </td>
                                    <td class="min-w-[95px] w-[95px] max-w-[95px]">
                                        <div class="flex items-center justify-center">
                                            <div class="flex gap-2">
                                                <img class="cursor-pointer" width="14" height="14" src="https://img.icons8.com/plumpy/24/chat-message.png" alt="chat-message"/>
                                                <img class="cursor-pointer" width="14" height="14" src="https://img.icons8.com/plumpy/24/phone.png" alt="phone"/>
                                                <img class="cursor-pointer" width="14" height="14" src="https://img.icons8.com/plumpy/24/new-post.png" alt="new-post"/>
                                                <img class="cursor-pointer" width="14" height="14" src="https://img.icons8.com/plumpy/24/communication.png" alt="communication"/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    v-show="IsScrolling"
                    class="sticky z-30 left-[458px] max-w-[10px] top-0 w-[10px] min-w-[10px] min-h-full max-h-[750px] bg-gradient-to-r from-gray-200 to-transparent"
                ></div>
                <div class="overflow-scroll">
                    <table class="overflow-scroll bg-white w-full z-10 absolute top-0 max-w-full">
                        <thead>
                        <tr class="max-h-[38px] h-[38px] divide-x divide-[#F2F2F2]">
                            <td class="sticky top-0 bg-white z-20 max-h-[38px] truncate">
                                <div @click="this.HandleSort('status')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                    Status
                                    <chevron-left-small-icon v-if="Sorting.key === 'status'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                </div>
                            </td>
                            <td class="sticky top-0 bg-white z-20 max-h-[38px] truncate">
                                <div @click="this.HandleSort('responsible')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                    Responsible
                                    <chevron-left-small-icon v-if="Sorting.key === 'responsible'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                </div>
                            </td>
                            <td class="sticky top-0 bg-white z-20 max-h-[38px] truncate">
                                <div @click="this.HandleSort('manager')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                    Manager
                                    <chevron-left-small-icon v-if="Sorting.key === 'manager'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                </div>
                            </td>
                            <td class="sticky top-0 bg-white z-20 max-h-[38px] truncate">
                                <div @click="this.HandleSort('progress')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                    Progress
                                    <chevron-left-small-icon v-if="Sorting.key === 'progress'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                </div>
                            </td>
                            <td class="sticky top-0 bg-white z-20 max-h-[38px] truncate">
                                <div @click="this.HandleSort('documents')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                    Documents
                                    <chevron-left-small-icon v-if="Sorting.key === 'documents'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                </div>
                            </td>
                            <td class="sticky top-0 bg-white z-20 max-h-[38px] truncate">
                                <div @click="this.HandleSort('credit-inquires')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                    Credit Inquiries
                                    <chevron-left-small-icon v-if="Sorting.key === 'credit-inquires'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                </div>
                            </td>
                            <td class="sticky top-0 bg-white z-20 max-h-[38px] truncate">
                                <div @click="this.HandleSort('payment-history')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                    Payment History
                                    <chevron-left-small-icon v-if="Sorting.key === 'payment-history'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                </div>
                            </td>
                            <td class="sticky top-0 bg-white z-20 max-h-[38px] truncate">
                                <div @click="this.HandleSort('lender')" class="flex-col cursor-pointer font-medium border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center text-[#6E6E6E] text-[11px]">
                                    Micro Loan History
                                    <chevron-left-small-icon v-if="Sorting.key === 'client'" size="16px" :class="[Sorting.type === 'asc' ? 'rotate-[-90deg]' : 'rotate-[90deg]', 'fill-gray-500 -mt-1.5']" />
                                </div>
                            </td>
                        </tr>
                        <tr class="h-[46px] divide-x divide-[#F2F2F2] max-h-[46px]">
                            <td class="sticky top-[38px] bg-white z-20">
                                <div :class="IsScrollingX ? 'border-none' : 'border-b'" class="flex items-center h-[46px] justify-center border-gray-100">
                                    <BaseTableFilterDropdown />
                                </div>
                            </td>
                            <td class="sticky top-[38px] bg-white z-20">
                                <div :class="IsScrollingX ? 'border-none' : 'border-b'" class="flex items-center h-[46px] justify-center border-gray-100">
                                    <BaseTableFilterDropdown :options="Users" />
                                </div>
                            </td>
                            <td class="sticky top-[38px] bg-white z-20"><div :class="IsScrollingX ? 'border-none' : 'border-b'" class="flex items-center h-[46px] justify-center border-gray-100"><input placeholder="Filter..." type="text" class="text-[11.5px] placeholder:text-gray-400 font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="sticky top-[38px] bg-white z-20"><div :class="IsScrollingX ? 'border-none' : 'border-b'" class="flex items-center h-[46px] justify-center border-gray-100"><input placeholder="Filter..." type="text" class="text-[11.5px] placeholder:text-gray-400 font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="sticky top-[38px] bg-white z-20"><div :class="IsScrollingX ? 'border-none' : 'border-b'" class="flex items-center h-[46px] justify-center border-gray-100"><input placeholder="Filter..." type="text" class="text-[11.5px] placeholder:text-gray-400 font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="sticky top-[38px] bg-white z-20"><div :class="IsScrollingX ? 'border-none' : 'border-b'" class="flex items-center h-[46px] justify-center border-gray-100"><input placeholder="Filter..." type="text" class="text-[11.5px] placeholder:text-gray-400 font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="sticky top-[38px] bg-white z-20"><div :class="IsScrollingX ? 'border-none' : 'border-b'" class="flex items-center h-[46px] justify-center border-gray-100"><input placeholder="Filter..." type="text" class="text-[11.5px] placeholder:text-gray-400 font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                            <td class="sticky top-[38px] bg-white z-20"><div :class="IsScrollingX ? 'border-none' : 'border-b'" class="flex items-center h-[46px] justify-center border-gray-100"><input placeholder="Filter..." type="text" class="text-[11.5px] placeholder:text-gray-400 font-medium text-gray-500 px-[8px] bg-[#E9E9E9] h-[32px] rounded-[2px] w-full" /></div></td>
                        </tr>
                        </thead>
                        <thead v-show="IsScrollingX">
                            <tr>
                                <td v-for="ShadowCols in 7" class="sticky top-[83px]"><div class="h-[10px] bg-gradient-to-b from-gray-200 to-transparent"></div></td>
                            </tr>
                        </thead>
                        <tbody v-if="SortedDataset.length > 0" class="divide-[#F2F2F2] divide-y">
                        <tr v-for="Item in SortedDataset" class="h-[76px] divide-x divide-[#F2F2F2] max-h-[76px]">
                            <td class="relative min-w-[115px] w-[115px] max-w-[115px]">
                                <div class="cursor-pointer flex items-center justify-center text-[10.5px] font-medium text-gray-500 truncate capitalize" @click="toggleStatusDropdown(Item.id)">
                                    {{ Item.status_id ? Item.lead_status.lead_status_name.replace('_', ' ').toLowerCase() : '...' }}
                                </div>
                                <div
                                    v-click-outside="() => openStatusDropdown = null"
                                    v-if="openStatusDropdown === Item.id"
                                    class="absolute py-[4px] left-0 top-[18px] text-gray-400 text-[11.5px] flex items-center px-[8px] cursor-pointer flex-col min-w-full w-fit bg-white rounded-[2pt] custom-shadow-1 z-20"
                                >
                                    <ul class="absolute py-[4px] top-[36px] left-0 cursor-pointer flex flex-col w-fit bg-white rounded-[2pt] custom-shadow-1 z-20">
                                        <li
                                            v-for="status in Statuses" :key="status.value" class="h-[26px] px-[8px] truncate capitalize cursor-pointer hover:bg-theme-blue hover:text-theme-blue hover:bg-opacity-20 duration-500 first-of-type:rounded-t-[2px] last-of-type:rounded-b-[2px] flex items-center justify-start"
                                            @click="updateLead(Item.id, status.value, Item.user_id, Item.manager_id)"
                                        >
                                            {{ status.label }}
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td class="min-w-[100px] w-[100px] max-w-[100px] relative">
                                <div class="max-w-full cursor-pointer flex items-center justify-center text-[10.5px] font-medium text-gray-500 truncate" @click="toggleResponsibleDropdown(Item.id)">
                                    <span class="max-w-[90px] mx-auto truncate">
                                        {{ Item.user_id ? `${Item.user.first_name} ${Item.user.last_name}` : '...' }}
                                    </span>
                                </div>
                                <div
                                    v-click-outside="() => openResponsibleDropdown = null"
                                    v-if="openResponsibleDropdown === Item.id"
                                    class="absolute py-[4px] left-0 top-[18px] text-gray-400 text-[11.5px] flex items-center px-[8px] cursor-pointer flex-col w-fit bg-white rounded-[2pt] custom-shadow-1 z-20"
                                >
                                    <ul class="absolute py-[4px] top-[36px] left-0 cursor-pointer flex flex-col w-fit bg-white rounded-[2pt] custom-shadow-1 z-20">
                                        <li v-for="user in usersList" :key="user.value" class="h-[26px] px-[8px] truncate capitalize cursor-pointer hover:bg-theme-blue hover:text-theme-blue hover:bg-opacity-20 duration-500 first-of-type:rounded-t-[2px] last-of-type:rounded-b-[2px] flex items-center justify-start" @click="updateLead(Item.id, Item.status_id, user.id, Item.manager_id)">
                                            {{ `${user?.first_name || ""} ${user?.last_name || ""}` }}
                                        </li>
                                    </ul>
                                </div>
                            </td>

                            <td class="min-w-[100px] w-[100px] max-w-[100px] relative">
                                <div class="max-w-full cursor-pointer flex items-center justify-center text-[10.5px] font-medium text-gray-500 truncate" @click="toggleManagerDropdown(Item.id)">
                                    <span class="max-w-[90px] mx-auto truncate">
                                        {{ Item.manager_id ? `${Item.manager.first_name} ${Item.manager.last_name}` : '...' }}
                                    </span>
                                </div>
                                <div
                                    v-click-outside="() => openManagerDropdown = null"
                                    v-if="openManagerDropdown === Item.id"
                                    class="absolute py-[4px] left-0 top-[18px] text-gray-400 text-[11.5px] flex items-center px-[8px] cursor-pointer flex-col w-fit bg-white rounded-[2pt] custom-shadow-1 z-20"
                                >
                                    <ul class="absolute py-[4px] top-[36px] left-0 cursor-pointer flex flex-col w-fit bg-white rounded-[2pt] custom-shadow-1 z-20">
                                        <li v-for="manager in managersList" :key="manager.value" class="h-[26px] px-[8px] truncate capitalize cursor-pointer hover:bg-theme-blue hover:text-theme-blue hover:bg-opacity-20 duration-500 first-of-type:rounded-t-[2px] last-of-type:rounded-b-[2px] flex items-center justify-start" @click="updateLead(Item.id, Item.status_id, Item.user_id, manager.id)">
                                            {{ `${manager?.first_name || ""} ${manager?.last_name || ""}` }}
                                        </li>
                                    </ul>
                                </div>
                            </td>

                            <td class="min-w-[155px] w-[155px] max-w-[155px]">
                                <div class="w-full flex items-center justify-center">
                                    <div class="relative w-full mx-[10px] h-[10px]">
                                        <LeadTableApprobationStepProgressBar
                                            :lead-approbation-step="Item.lead_approbation_step"
                                            :lead-id="Item.id"
                                        />
                                    </div>
                                </div></td>
                                <td class="min-w-[155px] w-[155px] max-w-[155px]"><div class="flex items-center justify-center w-full">
                                    <div class="grid grid-cols-5 w-full gap-[8px] px-[10px]">
                                        <div class="cursor-pointer relative mx-auto flex items-center justify-center text-[10.5px] text-theme-blue bg-theme-blue bg-opacity-10 h-[24px] w-[28px] pt-[2px] rounded-[2pt] font-semibold">
                                            ID
                                            <div class="absolute bottom-[-3.33px] right-[-3.33px] h-[10px] w-[10px] bg-red-400 rounded-full"></div>
                                        </div>
                                        <div class="cursor-pointer mx-auto relative flex items-center justify-center text-[10.5px] text-theme-blue bg-theme-blue bg-opacity-10 h-[24px] w-[28px] pt-[2px] rounded-[2pt] font-semibold">
                                            SC
                                            <div class="absolute animate-ping bottom-[-3.33px] right-[-3.33px] h-[10px] w-[10px] bg-amber-500 rounded-full"></div>
                                        </div>
                                        <div class="cursor-pointer relative flex mx-auto items-center justify-center text-[10.5px] text-theme-blue bg-theme-blue bg-opacity-10 h-[24px] w-[28px] pt-[2px] rounded-[2pt] font-semibold">
                                            BS
                                            <div class="absolute bottom-[-3.33px] right-[-3.33px] h-[10px] w-[10px] bg-green-500 rounded-full"></div>
                                        </div>
                                        <div class="cursor-pointer relative flex mx-auto items-center justify-center text-[10.5px] text-theme-blue bg-theme-blue bg-opacity-10 h-[24px] w-[28px] pt-[2px] rounded-[2pt] font-semibold">
                                            PC
                                            <div class="absolute bottom-[-3.33px] right-[-3.33px] h-[10px] w-[10px] bg-red-400 rounded-full"></div>
                                        </div>
                                        <div class="cursor-pointer relative flex mx-auto items-center justify-center text-[10.5px] text-theme-blue bg-theme-blue bg-opacity-10 h-[24px] w-[28px] pt-[2px] rounded-[2pt] font-semibold">
                                            RP
                                            <div class="absolute bottom-[-3.33px] right-[-3.33px] h-[10px] w-[10px] bg-red-400 rounded-full"></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="min-w-[220px] w-[220px] max-w-[220px]">
                                <div class="flex items-center justify-center text-[10.5px] font-medium text-gray-500">
                                    item 3
                                </div>
                            </td>
                            <td class="min-w-[220px] w-[220px] max-w-[220px]">
                                <div class="flex items-center justify-center text-[10.5px] font-medium text-gray-500">
                                    item 3
                                </div>
                            </td>
                            <td class="min-w-[220px] w-[220px] max-w-[220px]">
                                <div class="flex items-center justify-center text-[10.5px] font-medium text-gray-500">
                                    item 3
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTableCheckbox from "./BaseTableCheckbox.vue";
import ChevronLeftSmallIcon from "../../assets/svg/ChevronLeftSmallIcon.vue";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import BaseTableFilterDropdown from "./BaseTableFilterDropdown.vue";
import FilterIcon from "../../assets/svg/FilterIcon.vue";
import { RetrieveLeads } from "../../services/Lead";
import MoreHorizontalIcon from "../../assets/svg/MoreHorizontalIcon.vue";
import CrossIcon from "../../assets/svg/CrossIcon.vue";
import KronosTableFilterDropdown from './BaseTableFilterDropdown.vue';
import {mapState} from "vuex";
import {UpdateLead} from "../../services/Lead";
import {Users} from "../../services/User"
import NotificationMixin from "../../mixins/NotificationMixin";
import LeadTableApprobationStepProgressBar from "../../components/Dashboard/LeadTableApprobationStepProgressBar.vue";

export default
{
    mixins: [NotificationMixin],

    components: {
        LeadTableApprobationStepProgressBar,
        CrossIcon,
        MoreHorizontalIcon,
        FilterIcon,
        BaseTableFilterDropdown,
        LoaderAnimation,
        ChevronLeftSmallIcon,
        BaseTableCheckbox,
        KronosTableFilterDropdown
    },

    props: {
        customQuery: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            default: 'Leads'
        }
    },

    data() {
        return {
            openStatusDropdown: null,
            openResponsibleDropdown: null,
            openManagerDropdown: null,
            selectedColumn: null,
            selectedValue: null,
            usersList: [],
            managersList: [],
            isOpen: true,
            Dataset: [],
            IsLoading: false,
            IsScrolling: false,
            IsScrollingX: false,
            SelectedLeads: [],
            Sorting: {
                key: null,
                type: null,
            },
            MassUpdateIsVisible: false,
            AvailableColumns: [
                {value: 'user_id', label: 'Responsible'},
                {value: 'status_id', label: 'Status'},
                {value: 'amount', label: 'Amount'},
                {value: 'manager', label: 'Manager'},
            ]
        }
    },

    computed: {
        BaseQuery() {
            return this.$route.href.split('?')[1];
        },

        BaseURL() {
            return import.meta.env.VITE_BACKEND_BASE_URL + '/storage';
        },

        ...mapState('entities', [
            "Statuses",
            "Amounts"
        ]),

        ContextPage() {
            return this.$route.query.page ?? '1';
        },

        ContextLimit() {
            return this.$route.query.limit ?? '500';
        },

        Users() {
            const unique_users = new Set();

            return this.Dataset.map(obj => {
                const {first_name, last_name} = obj.user;
                const user_id = obj.user_id;
                if (!unique_users.has(user_id)) {
                    unique_users.add(user_id);
                    return {
                        label: `${first_name} ${last_name}`,
                        value: user_id,
                    };
                }
            }).filter(Boolean);
        },

        valueOptions() {
            switch(this.selectedColumn) {
                case "user_id":
                    return this.usersList.map(user => ({
                        value: user.id,
                        label: `${user.first_name} ${user.last_name}`
                    }));
                case "status_id":
                    return this.Statuses.map(status => ({
                        value: status.value,
                        label: status.label
                    }));
                case "amount":
                    return this.Amounts.map(amount => ({
                        value: amount.value,
                        label: amount.label
                    }));
                case "manager":
                    return this.managersList.map(manager => ({
                        value: manager.id,
                        label: `${manager.first_name} ${manager.last_name}`
                    }))
                default:
                    return [];
            }
        },

        SortedDataset() {
            if (!this.Sorting.key) {
                return this.Dataset;
            }

            return [...this.Dataset].sort((a, b) => {
                let result;
                switch (this.Sorting.key) {
                    case "client":
                        result = a.client.first_name.localeCompare(b.client.first_name);
                        break;
                    case "status":
                        result = a.lead_status.lead_status_name.localeCompare(b.lead_status.lead_status_name);
                        break;
                    case "responsible":
                        result = a.user.first_name.localeCompare(b.user.first_name);
                        break;
                    case "manager":
                        result = a.manager.first_name.localeCompare(b.manager.first_name);
                        break;
                    case "created_at":
                        result = a.created_at.localeCompare(b.created_at);
                        break;
                    case "lender":
                        result = a.lender_id.toString().localeCompare(b.lender_id.toString());
                        break;
                    default:
                        result = 0;
                }

                return this.Sorting.type === 'asc' ? result : -result;
            })
        }
    },

    methods: {
        async RetrieveUsers() {
            await Users().then((response) => {
                if(response.status === 200) {
                    this.usersList = response.data.data.users;
                    this.managersList = this.usersList.filter(user => [2, 3, 4].includes(user.role_id));
                }
            });
        },

        HandleScroll() {
            this.IsScrollingX = this.$refs.table.scrollTop > 0;
            this.IsScrolling = this.$refs.table.scrollLeft > 0;
        },

        HandleSort(key) {
            if (this.Sorting.key === key) {
                this.Sorting.type = this.Sorting.type === 'asc' ? 'desc' : null;
                if (this.Sorting.type === null) {
                    this.Sorting.key = null;
                }
            } else {
                this.Sorting.key = key;
                this.Sorting.type = 'asc';
            }
        },

        HandleCheck(id) {
            if (id !== 'all') {
                const index = this.SelectedLeads.indexOf(id);
                index !== -1 ? this.SelectedLeads.splice(index, 1) : this.SelectedLeads.push(id);
            } else {
                this.SelectedLeads = this.SelectedLeads.length === this.Dataset.length ? [] : this.Dataset.map(lead => lead.id);
            }
        },

        toggleResponsibleDropdown(leadId) {
            this.openResponsibleDropdown = this.openResponsibleDropdown === leadId ? null : leadId;
        },

        toggleManagerDropdown(leadId) {
            this.openManagerDropdown = this.openManagerDropdown === leadId ? null : leadId;
        },

        toggleStatusDropdown(leadId) {
            this.openStatusDropdown = this.openStatusDropdown === leadId ? null : leadId;
        },

        async updateLead(leadId, statusId, userId, managerId) {
            await UpdateLead(leadId, {
                "status_id": statusId,
                "user_id": userId,
                "manager_id": managerId
            })
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Updated Successfully');
                        this.FetchLeads();
                        this.openStatusDropdown = null;
                        this.openResponsibleDropdown = null;
                        this.openManagerDropdown = null;
                    }
                })
        },

        async updateSelectedLeads() {
            const updates = this.SelectedLeads.map(leadId => {
                return UpdateLead(leadId, {
                    [this.selectedColumn]: this.selectedValue
                });
            });
            try {
                await Promise.all(updates);
                this.showNotification('success', 'Leads updated successfully');
                this.FetchLeads();
                this.MassUpdateIsVisible = false;
                this.SelectedLeads = [];
            } catch (error) {
                this.showNotification('error', 'Failed to update leads');
            }
        },

        async FetchLeads() {
            this.IsLoading = true;
            let query = `start_at=${this.$store.state.StartAt}&end_at=${this.$store.state.EndAt}`

            if (this.customQuery) {
                query += `&${this.customQuery}`
            }

            if (this.BaseQuery) {
                query += `&${this.BaseQuery}`
            }

            RetrieveLeads(query, this.ContextPage, this.ContextLimit)
                .then(response => {
                    this.Dataset = response.data.data.leads;
                })
                .finally(() => {
                    this.IsLoading = false;
                })
        },

        GetMonth(date) {
            date = new Date(date);

            return ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"][date.getMonth()];
        },

        GetTime(date) {
            date = new Date(date);

            let hours = date.getHours();
            let minutes = date.getMinutes();

            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;

            return `${hours}:${minutes}`;
        }
    },

    async mounted() {
        await this.RetrieveUsers();
        await this.FetchLeads();
    }
}
</script>

<style scoped>
td > div,
th > div {
    padding: 5px 7px;
}

td,
th {
    padding: 0
}
</style>
