
<template>
    <kronos-base-auth-layout>
	    <div v-if="isLoading" class="fixed top-0 left-0 bg-white w-screen h-screen bg-opacity-50 flex items-center justify-center">
			<loader-animation border="4px solid #212529" size="64px"/>
	    </div>
        <transition
            name="modal"
            enter-active-class="ease-out duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-500"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
	        <div class="w-screen max-w-[550px]">
	            <div class="p-[18px] w-full max-w-[475px] mx-auto flex items-center justify-center sm:pt-[22px] sm:pb-[24px] sm:px-[24px] h-full text-center sm:h-auto rounded-[14pt] flex-col bg-white">
                    <forgot-password-step-one
                        v-if="currentStep === 1"
                        :IsLoading="isLoading"
                        @loading="isLoading = $event"
                        @next-step="currentStep++; this.context_email = $event"
                    />
                    <forgot-password-step-two
                        v-else-if="currentStep === 2 && this.context_email"
                        :Email="this.context_email"
                        :IsLoading="isLoading"
                        @loading="isLoading = $event"
                        @next-step="currentStep++"
                    />
                    <forgot-password-step-three
                        v-else-if="currentStep === 3"
                        :Email="this.context_email"
                        :IsLoading="isLoading"
                        @loading="isLoading = $event"
                    />
	            </div>
	        </div>
        </transition>
    </kronos-base-auth-layout>
</template>

<script>
import KronosBaseAuthLayout from "../UI/Layouts/KronosBaseAuthLayout.vue";
import KronosAuthInput from "../UI/BaseComponents/KronosAuthInput.vue";
import ForgotPasswordIcon from "../assets/icons/ForgotPasswordIcon.vue";
import LoaderAnimation from "../assets/animations/LoaderAnimation.vue";
import LoadingIcon from "../assets/icons/LoadingIcon.vue";
import ForgotPasswordStepOne from "../components/ForgotPassword/ForgotPasswordSteps/ForgotPasswordStepOne.vue";
import ForgotPasswordStepTwo from "../components/ForgotPassword/ForgotPasswordSteps/ForgotPasswordStepTwo.vue";
import ForgotPasswordStepThree from "../components/ForgotPassword/ForgotPasswordSteps/ForgotPasswordStepThree.vue";

export default
{
    components: {
        ForgotPasswordStepThree,
        ForgotPasswordStepTwo,
        ForgotPasswordStepOne,
        LoadingIcon,
        LoaderAnimation,
        ForgotPasswordIcon,
        KronosAuthInput,
        KronosBaseAuthLayout
    },

    watch: {
        isLoading() {
            this.isLoading = false;
        }
    },

    data() {
        return {
            currentStep: 1,
            isLoading: false,
            context_email: null,
        }
    },
}
</script>
