<template>
    <div class="flex flex-col gap-[16px]">
        <div class="flex gap-[16px]">
            <div class="bg-white w-full h-[34px] rounded-[2pt] custom-shadow-0 flex items-center">
                <div class="w-[55px] bg-gray-100 text-[#595959] text-[12.5px] text-opacity-90 font-medium tracking-wide h-full rounded-l-[2pt] flex items-center justify-center">10</div>
                <span class="text-[10.5px] pt-0.5 tracking-wide pl-[16px] text-[#5A5A5A] font-medium text-opacity-90">Total</span>
            </div>
            <div class="bg-white w-full h-[34px] rounded-[2pt] custom-shadow-0 flex items-center">
                <div class="w-[55px] bg-gray-100 text-[#595959] text-[12.5px] text-opacity-90 font-medium tracking-wide h-full rounded-l-[2pt] flex items-center justify-center">10</div>
                <span class="text-[10.5px] pt-0.5 tracking-wide pl-[16px] text-[#5A5A5A] font-medium text-opacity-90">Waiting</span>
            </div>
            <div class="bg-white w-full h-[34px] rounded-[2pt] custom-shadow-0 flex items-center">
                <div class="w-[55px] bg-gray-100 text-[#595959] text-[12.5px] text-opacity-90 font-medium tracking-wide h-full rounded-l-[2pt] flex items-center justify-center">14</div>
                <span class="text-[10.5px] pt-0.5 tracking-wide pl-[16px] text-[#5A5A5A] font-medium text-opacity-90">Reached</span>
            </div>
            <div class="bg-white w-full h-[34px] rounded-[2pt] custom-shadow-0 flex items-center">
                <div class="w-[55px] bg-gray-100 text-[#595959] text-[12.5px] text-opacity-90 font-medium tracking-wide h-full rounded-l-[2pt] flex items-center justify-center">123</div>
                <span class="text-[10.5px] pt-0.5 tracking-wide pl-[16px] text-[#5A5A5A] font-medium text-opacity-90">Attempts</span>
            </div>
        </div>

        <div
            class="flex gap-[16px]"
            @click="$emit('change', (SelectedCardStats === 'status' ? 'statistics' : 'status'))"
        >
            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Avg. Days Overdue' : 'Payments Received'"
                :total="(SelectedCardStats === 'status' ? 0 : 0)"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>
            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Payment Overdue' : 'NSF'"
                :total="this.Counter[0] ?? 0"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>

            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Trustee' : 'Avg. Amount Overdue'"
                :total="this.Counter[1] ?? 0"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>

            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Payment Arrangements' : 'Closed Bank Accounts'"
                :total="this.Counter[2] ?? 0"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>

            <kronos-base-card
                class="w-1/4"
                :title="SelectedCardStats === 'status' ? 'Clients Suspended' : ''"
                :total="this.Counter[3] ?? 0"
                :isLoading = "IsLoading"
            >
            </kronos-base-card>
        </div>
    </div>
</template>

<script>
import KronosBaseCard from "../../../UI/BaseComponents/KronosBaseCard.vue";

export default
{
    emits: [
        'change',
    ],

    components: {
        KronosBaseCard
    },

    props: {
        SelectedCardStats: {
            type: String,
            default: 'status',
            validator: function (value) {
                return ['status', 'statistics'].includes(value);
            }
        },
        Counter: {
            type: Array,
            default: [0,0,0,0],
        },
        IsLoading: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
