export default
{
	input: {
		email_address: 'Adresse Courriel',
		password: 'Mot de Passe',
		phone_number: 'Numéro de Téléphone',
	},

	display_settings: 'Paramètres d\'Affichage',
	language: 'Langue',

	theme: {
		theme: 'Thème',
		dark: 'Sombre',
		light: 'Claire'
	},

	save: 'Sauvegarder',
}