<template>
    <div class="w-1/3 h-[235px]">
        <div class="w-full rounded-md bg-white shadow-custom-shadow-2 border border-gray-100">
            <div class="flex border-b border-gray-100 h-[45px] pl-[12pt] items-center justify-between">
                <h5 class="text-xs font-medium tracking-wide text-gray-900">
                    {{ title }}
                </h5>
                <div
	                @click="$emit('add')"
	                class="border-l border-gray-100 w-[45px] h-[46px] flex items-center justify-center text-gray-400 hover:bg-gray-100 duration-500 cursor-pointer"
                >
                    +
                </div>
            </div>
            <div id="contract-card" class="custom-scrollbar | overflow-y-scroll  h-[190px]">
                <ul role="list" class="divide-y divide-gray-100">
                    <li v-for="item in 3" class="pl-[12pt] h-[50px]">
                        <div class="flex items-center space-x-4">
                            <div class="flex-shrink-0">
                                <img
                                    class="w-7 h-7 rounded-md"
                                    src="../../assets/images/user-avatar-32.png"
                                    alt="Bank Logo"
                                >
                            </div>
                            <div class="flex-1 min-w-0">
                                <p class="text-xs font-normal text-gray-700 truncate">
                                    Frais courtage
                                </p>
                            </div>
                            <div class="pr-2 w-md h-[50px] flex items-center">
                                <button class="h-[50%] w-[100%] text-xs font-semibold text-red-900 flex items-center rounded-md p-2 bg-red-200">
                                    Not signed
                                </button>
                            </div>
                        </div>
                    </li>
                    <li v-for="item in 3" v-show="showContract" class="pl-[12pt] h-[50px]">
                        <div class="flex items-center space-x-4">
                            <div class="flex-shrink-0">
                                <img
                                    class="w-7 h-7 rounded-md"
                                    src="../../assets/images/user-avatar-32.png"
                                    alt="Bank Logo"
                                >
                            </div>
                            <div class="flex-1 min-w-0">
                                <p class="text-xs font-normal text-gray-700 truncate">
                                   Facture
                                </p>
                            </div>
                            <div class="pr-2 w-md h-[50px] flex items-center">
                                <button class="h-[50%] w-[100%] text-xs font-semibold text-green-900 flex items-center rounded-md p-2 bg-green-200">
                                    Signed
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div v-show="showButton" @click="vieAll" class="text-center text-[8pt] cursor-pointer duration-500 rounded-b-lg text-gray-400 tracking-wide h-[40px] font-medium border-t border-gray-100 hover:bg-gray-100 flex items-center justify-center">
                    View All
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default
{
    props: {
        title: {
            type: String,
            default: 'Title',
        }
    },

    data() {
        return {
            showContract: false,
            showButton: true
        }
    },

    mounted() {
        const contractCard_element = document.getElementById('contract-card');
        if (contractCard_element) {
            contractCard_element.scrollTop = contractCard_element.scrollHeight;
        }
    },

    methods: {
        vieAll(event) {

            if(event) {
                this.showContract = true;
                this.showButton = false;
            }
        }
    }
}
</script>
<style>
.custom-scrollbar::-webkit-scrollbar
{
    width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
    background-color: lightgray;
}

.custom-scrollbar::-webkit-scrollbar-track
{
    background-color: transparent;
}
</style>