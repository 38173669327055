<template>
    <div
        v-if="is_open"
        class="fixed top-0 left-0 w-screen flex items-center justify-center h-screen bg-black bg-opacity-40"
    >
        <div class="w-[500px] min-h-[100px] h-fit bg-white rounded-lg">
            <div class="h-[45px] w-full flex items-center justify-between pl-[12px] border-b border-gray-100">
                <span class="text-sm text-gray-600">
                    Validate Document <span class="text-[10.5px] text-blue-600">({{ document_name }})</span>
                </span>
                <div class="flex cursor-pointer">
                    <div
                        v-show="document_url.match(/\.([^.]+)$/)[1] === 'pdf'"
                        @click="this.RotateImage()"
                        class="w-[45px] h-[45px] flex items-center hover:bg-gray-100 justify-center cursor-pointer duration-500"
                    >
                        <a :href="document_url" target="_blank">
                            <eye-icon
                                class="stroke-gray-200 h-[14px]"
                            />
                        </a>
                    </div>
                    <div
                        @click="this.RotateImage()"
                        class="w-[45px] h-[45px] flex items-center hover:bg-gray-100 justify-center cursor-pointer duration-500"
                    >
                        <arrow-repeat-icon
                            class="stroke-gray-500 h-[14px]"
                        />
                    </div>
                    <div
                        @click="$emit('close')"
                        class="w-[45px] h-[45px] flex items-center hover:bg-gray-100 justify-center cursor-pointer rounded-tr-lg duration-500"
                    >
                        <cross-icon
                            class="stroke-gray-500 h-[14px]"
                        />
                    </div>
                </div>
            </div>
            <div class="h-fit min-h-[500px] px-[24px] flex items-center justify-center">
                <iframe
                    class="h-full w-full max-h-[400px]"
                    v-if="document_url.match(/\.([^.]+)$/)[1] === 'pdf'"
                    :src="document_url"
                />
                <img
                    v-else
                    id="image"
                    :src="document_url"
                    @click="toggleZoom"
                    :class="[zoomedIn ? 'zoomed' : 'zoomed-out', 'object-fit contain max-h-[450px] h-fit w-fit mx-auto']"
                />
            </div>
            <div class="h-[55px] w-full flex items-center gap-4 justify-between px-[12px]">
                <button
                    @click="$emit('replace', document_id)"
                    class="h-[40px] duration-500 text-xs w-full rounded bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-50"
                >
                    Replace Document
                </button>
                <button
                    v-if="!document_is_approved"
                    @click="$emit('approve', document_id)"
                    class="h-[40px] duration-500 text-xs w-full rounded bg-blue-100 text-blue-500 hover:bg-blue-500 hover:text-blue-50"
                >
                    Approve Document
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import CrossIcon from "../../../../../assets/svg/CrossIcon.vue";
import ArrowRepeatIcon from "../../../../../assets/svg/ArrowRepeatIcon.vue";
import EyeIcon from "../../../../../assets/icons/EyeIcon.vue";

export default
{
    components: {
        EyeIcon,
        ArrowRepeatIcon,
        CrossIcon
    },

    props: {
        is_open: {
            type: Boolean,
            default: false,
        },
        document_name: {
            type: String,
            default: 'DOCUMENT_NAME',
        },
        document_id: {
            type: String,
        },
        document_is_approved: {
            type: Boolean,
            default: false,
        },
        document_url: {
            type: String,
            default: 'https://google.com'
        }
    },

    data() {
        return {
            ContextRotation: 0,
            zoomedIn: false,
        }
    },

    methods: {
        toggleZoom() {
            this.zoomedIn = !this.zoomedIn;
        },

        RotateImage() {
            if (this.ContextRotation === 360) {
                this.ContextRotation = 0;
            } else {
                this.ContextRotation += 90;
            }

            const image = document.getElementById('image');
            const newRotation = this.ContextRotation;
            image.style.transform = `rotate(${newRotation}deg)`;
            image.dataset.rotation = newRotation;
        }
    }
}
</script>

<style scoped>
.zoomed {
    transform: scale(2);
}

.zoomed-out {
    transform: scale(1);
}
</style>
