<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M 17.726562 2.8027344 C 17.370641 2.75075 17 3.0149375 17 3.4296875 L 17 6 L 12 6 A 1.0001 1.0001 0 1 0 12 8 L 17 8 L 17 10.529297 C 17 11.081297 17.6575 11.369187 18.0625 10.992188 L 21.755859 7.5683594 C 22.080859 7.2433594 22.080859 6.715625 21.755859 6.390625 L 18.0625 2.9667969 C 17.96125 2.8727969 17.845203 2.8200625 17.726562 2.8027344 z M 4 6 C 3.448 6 3 6.448 3 7 C 3 7.552 3.448 8 4 8 C 4.552 8 5 7.552 5 7 C 5 6.448 4.552 6 4 6 z M 8 6 C 7.448 6 7 6.448 7 7 C 7 7.552 7.448 8 8 8 C 8.552 8 9 7.552 9 7 C 9 6.448 8.552 6 8 6 z M 6.2734375 12.802734 C 6.1547969 12.820062 6.03875 12.872797 5.9375 12.966797 L 2.2441406 16.390625 C 1.9191406 16.715625 1.9191406 17.243359 2.2441406 17.568359 L 5.9355469 20.992188 C 6.3415469 21.369187 7 21.081297 7 20.529297 L 7 18 L 12 18 A 1.0001 1.0001 0 1 0 12 16 L 7 16 L 7 13.429688 C 7 13.014938 6.6293594 12.75075 6.2734375 12.802734 z M 16 16 C 15.448 16 15 16.448 15 17 C 15 17.552 15.448 18 16 18 C 16.552 18 17 17.552 17 17 C 17 16.448 16.552 16 16 16 z M 20 16 C 19.448 16 19 16.448 19 17 C 19 17.552 19.448 18 20 18 C 20.552 18 21 17.552 21 17 C 21 16.448 20.552 16 20 16 z"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
