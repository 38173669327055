import IbvReportPage from "../../pages/Tools/IbvReportPage.vue";

export default [
	{
		path: '/ibv/:id',
		component: IbvReportPage,
		name: 'IbvReportPage',
		meta: {
			type: 'private',
			authOnly: true,
		}
	},
];
