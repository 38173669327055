<template>
    <kronos-base-information-card
        title="Client Addresses"
        :first-page="currentPage + 1"
        :last-page="this.client_addresses.length"
        :is-editing="isEditing"
        :can-next="can_next"
        :can-prev="can_prev"
        @next="handlePagination('next')"
        @prev="handlePagination('prev')"
        @submit-form="saveChanges"
        :has-action-button="false"
    >
        <div class="divide-y divide-gray-100">
            <kronos-base-information-card-input
                class="w-full"
                type="text"
                label="Address Line 1"
                input-case="capitalize"
                :has-error="errors.address_line_1"
                :placeholder="client_address.address_line_1"
                v-model:value="client_address.address_line_1"

            />
            <div class="flex divide-x divide-gray-100">
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="text"
                    label="City"
                    :has-error="errors.city"
                    input-case="capitalize"
                    :placeholder="client_address.city"
                    v-model:value="client_address.city"

                />
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="select"
                    label="Address Type"
                    :options="['APARTMENT','HOUSE', 'CONDO', 'OTHER']"
                    :selected-value="client_address?.type ?? 'OTHER'"
                    @update-value="client_address.type = $event"
                />
            </div>
            <div class="flex divide-x divide-gray-100">
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="text"
                    label="Apartment"
                    :has-error="errors.apartment"
                    :placeholder="client_address.apartment"
                    v-model:value="client_address.apartment"
                />
                <kronos-base-information-card-input
                    class="w-1/2 uppercase"
                    type="text"
                    label="Postal Code"
                    :has-error="errors.zip"
                    mask="@#@ #@#"
                    mask-token="A:[A-Z]"
                    input-case="uppercase"
                    :placeholder="client_address.zip"
                    v-model:value="client_address.zip"
                />
            </div>
            <div class="flex divide-x divide-gray-100">
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="select"
                    label="State"
                    :options="states"
                    :selected-value="client_address?.state ?? states[0]"
                    @update-value="client_address.state = $event"
                />
                <kronos-base-information-card-input
                    class="w-1/2"
                    type="select"
                    label="Country"
                    :options="['US', 'CA']"
                    :selected-value="selectedCountry"
                    @update-value="selectedCountry = $event"
                />
            </div>
        </div>
    </kronos-base-information-card>
</template>

<script>
import KronosBaseInformationCardInput from "../../UI/BaseComponents/KronosBaseInformationCardInput.vue";
import KronosBaseInformationCard from "../../UI/BaseComponents/KronosBaseInformationCard.vue";
import {UpdateClientAddress, RetrieveClientAddresses} from "../../services/Client/ClientAddress";
import CountryStates from "../../store/CountryStates";
import {mapState} from "vuex";
import NotificationMixin from "../../mixins/NotificationMixin";


export default {
    components:
        {
            KronosBaseInformationCard,
            KronosBaseInformationCardInput,
        },

    mixins: [
        NotificationMixin
    ],

    data() {
        return {
            currentPage: 0,
            can_prev: false,
            can_next: false,
            isEditing: false,
            isLoading: true,
            selectedCountry: 'CA',
            states: CountryStates.state()['CA'],
            client_address: {},
            client_addresses: [],
            errors: {
                address_line_1: false,
                city: false,
                apartment: false,
                zip: false,
            }
        }
    },

    watch:
        {
            'client_address': {
                handler(newValue, oldValue) {
                    if (!this.isLoading) {
                        const default_address = this.client_addresses[this.currentPage];
                        const context_address_line = newValue.address_line_1.toLowerCase().replace(/(\r\n|\n|\r)/gm, "");
                        const default_address_line = default_address.address_line_1.toLowerCase().replace(/(\r\n|\n|\r)/gm, "");

                        if (newValue.id === oldValue.id) {
                            this.isEditing = !this.isLoading &&
                                (
                                    context_address_line !== default_address_line ||
                                    (newValue.type).toUpperCase() !== (default_address.type).toUpperCase() ||
                                    newValue.zip.replace(" ", '') !== default_address.zip.replace(" ", '') ||
                                    newValue.city !== default_address.city ||
                                    newValue.country !== default_address.country ||
                                    newValue.state !== default_address.state ||
                                    newValue.apartment !== default_address.apartment
                                );

                            this.isEditing
                                ? [this.can_prev, this.can_next] = [false, false]
                                : this.checkPagination();

                            const validated_inputs = ['address_line_1', 'city'];
                            validated_inputs.forEach(item => {
                                if (newValue[item].length === 0) {
                                    this.errors[item] = true;
                                    this.isEditing = false;
                                    [this.can_prev, this.can_next] = [false, false]
                                } else {
                                    this.errors[item] = false;
                                }
                            })

                            if (newValue.zip.length !== 7) {
                                this.errors['zip'] = true;
                                this.isEditing = false;
                                [this.can_prev, this.can_next] = [false, false]
                            } else {
                                this.errors['zip'] = false;
                            }

                            this.$forceUpdate();
                        } else {
                            this.isEditing = false;
                        }
                    }
                },
                deep: true,
            },

            selectedCountry(newCountry, oldCountry) {
                if (newCountry !== oldCountry) {
                    this.states = CountryStates.state()[newCountry];
                    this.client_address.state = this.states[0];
                }
            }
        },

    computed: {
        ...mapState([
            'CountryStates'
        ]),

    },

    methods: {
        saveChanges() {
            UpdateClientAddress(this.client_address.id, this.client_address)
                .then(response => {
                    this.isEditing = false;
                    this.client_addresses = JSON.parse(JSON.stringify(this.client_address));
                    console.log(response);
                    this.showNotification('success', "Client address updated successfully.");

                }).catch(error => {
                console.error("Error updating client address:", error);
                this.showNotification('error', "The address doesnt exist");

            });
        },

        handlePagination(step) {
            step === 'next'
                ? this.currentPage += 1
                : this.currentPage -= 1;

            this.checkPagination()

            this.client_address = JSON.parse(JSON.stringify(this.client_addresses[this.currentPage]));
        },

        checkPagination() {
            this.can_next = this.currentPage + 1 < this.client_addresses.length
            this.can_prev = this.currentPage > 0;

            this.$forceUpdate();
        },

        fetchData() {
            this.isLoading = true;

            RetrieveClientAddresses(this.$route.params.id)
                .then((response) => {
                    this.client_addresses = response.data.data.client_addresses;
                    this.client_address = JSON.parse(JSON.stringify(response.data.data.client_addresses[0]));
                    this.can_next = this.client_addresses.length > 0;
                })
                .finally(() => {
                    this.isLoading = false;
                })
        }
    },

    mounted() {
        this.fetchData();
    },
}
</script>
