<template>
    <div class="custom-shadow-0 | flex flex-col gap-[20px] bg-white pb-[20px] rounded-lg border border-gray-100">
        <div class="flex items-center justify-between h-[42px] border-b pl-[24px] border-gray-100">
            <h1 class="text-[12px] font-light">
                Instant Bank Verification
            </h1>
            <div class="flex gap-2 border-gray-100 mr-2">
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    class="w-[28px] rounded bg-blue-50 h-[28px] group hover:bg-blue-500 duration-500 flex items-center justify-center"
                >
                    <save-icon
                        stroke-width="2"
                        class="h-[16px] stroke-blue-500 fill-transparent group-hover:stroke-blue-100 duration-500"
                    />
                </button>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="ApproveSection"
                    :class="[IsValidated ? 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 ' : 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-green-100', 'duration-500 h-[28px] px-[24px] cursor-pointer text-xs rounded']"
                >
                    {{ IsValidated ? 'Decline' : 'Approve' }} Section
                </button>
            </div>
        </div>

        <div class="grid grid-cols-4 gap-[24px] px-[24px]">
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Non Sufficient Fund</label>
                <input type="text" v-model="payload.nsf" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Payment Opposition</label>
                <input type="text" v-model="payload.opp" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Micro Loan</label>
                <input type="text" v-model="payload.micro_loans" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg cursor-not-allowed">
                <label class="text-[9.5px] text-gray-500 tracking-wide cursor-not-allowed">Salary (Detected By AI)</label>
                <input type="text" disabled v-model="payload.salary" class="border-none cursor-not-allowed h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {UpdateLead} from "../../../../services/Lead";
import SaveIcon from "../../../../assets/icons/SaveIcon.vue";

export default
{
    components: {SaveIcon},
    emits: ['approve-section'],

    props: {
        IsValidated: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            payload: {
                opp: 1,
                nsf: 1,
                micro_loans: 2,
                salary: 123.23,
            }
        }
    },

    computed: {
        ...mapState([
            'ContextLead'
        ]),
    },

    methods: {
        ApproveSection() {
            this.$emit('approve-section', {approved_step: 'ibv', approved_step_status: !this.IsValidated});
        },

        // FormatDate(date) {
        //     const context_date = new Date(date);
        //     return `${context_date.getFullYear()}-${(context_date.getMonth() + 1 + '').padStart(2, '0')}-${(context_date.getDate() + '').padStart(2, '0')}`;
        // }
    },

    mounted() {

    }
}
</script>
