<template>
    <add-note-for-user-task-modal
        :is-open="addNoteForUserTaskModalIsOpen"
        @close="addNoteForUserTaskModalIsOpen = false"
        :task_id="task_id"
    />
    <transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 scale-100"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="ease-in duration-0"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-100"
    >
        <div
            v-if="isOpenTask"
            class="custom-shadow-0 | h-auto w-auto right-0 absolute bg-yellow-50 pt-[10pt] px-[10pt] mt-2 rounded-[6px] max-w-lg mx-auto divide-y divide-gray-100"
        >
            <div class="flex divide-x divide-gray-100">
                <div class="pl-[5pt] pb-[16px]">
                    <div class="flex flex-col gap-1">
                        <div class="w-full flex mb-1 text-bold text-slate-700">
                            Daily Tasks
                        </div>
                        <div class="relative w-80 flex flex-col mb-1 font-light text-xs text-slate-700 pt-[4px]">
                            <div
                                v-for="(task, index) in tasks"
                                :key="task.id"
                                class="cursor-pointer flex items-center gap-2 text-amber-500"
                                @click="selectedTask = index; updateUserTask(index); toggleUnderline(index)"
                                :class="{'line-through': task.completed_at !== null}"
                                @contextmenu.prevent="showContextMenu(task, $event, index)"
                            >
                                <div class="min-w-[18px] h-[18px] flex items-center justify-center border-2 rounded-full border-amber-400">
                                    <check-duo-icon v-if="task.completed_at !== null" size="10px" class="fill-amber-400"/>
                                </div>
                                <div
                                    :class="{'text-red-500 font-medium': taskIsLate(task.created_at, task.completed_at)}"
                                    class="mt-1.5 mb-1.5 text-amber-500 flex justify-between w-full"
                                >
                                    <span>{{task.task}}</span>
                                    <span class="font-semibold" v-if="taskIsLate(task.created_at, task.completed_at)">Urgent!</span>
                                </div>
                            </div>
                            <div
                                v-if="isContextMenuVisible"
                                v-click-oustide="CloseContextMenu"
                                ref="contextMenu"
                                class="context-menu rounded-lg text-slate-500 text-sm bg-amber-50"
                            >
                                <div class="custom-shadow-1 | absolute left-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                    <div class="px-4 py-3" role="none">
                                        <p class="truncate text-sm font-medium text-gray-900" role="none"> Assigned By: {{this.tasks[ContextMenuTaskIndex].manager.last_name}}</p>
                                    </div>
                                    <div class="py-1" role="none">
                                        <div>
                                            <span @click="updateUserTask(ContextMenuTaskIndex)" class="text-gray-700 block px-4 py-2 text-sm hover:font-medium" role="menuitem" tabindex="-1" id="menu-item-0 ">Complete</span>
                                            <span @click="updateUserTask(ContextMenuTaskIndex)" class="text-gray-700 block px-4 py-2 text-sm hover:font-medium" role="menuitem" tabindex="-1" id="menu-item-1">Not Complete</span>
                                        </div>
                                        <a @click="addNoteForUserTaskModalIsOpen = true" class="text-gray-700 block px-4 py-2 text-sm hover:font-medium" role="menuitem" tabindex="-1" id="menu-item-2">Add Note</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>

import CheckDuoIcon from "../../assets/icons/duotone/CheckDuoIcon.vue";
import {RetrieveUserTasks, UpdateUserTask} from "../../services/UserTask";
import {mapState} from "vuex";
import NotificationMixin from "../../mixins/NotificationMixin";
import AddNoteForUserTaskModal from "./AddNoteForUserTaskModal.vue";

export default {
    components: {AddNoteForUserTaskModal, CheckDuoIcon},

    props: {
        isOpenTask: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            isUnderlined: false,
            tasks: [],
            selectedTask: null,
            isContextMenuVisible: false,
            ContextMenuTaskIndex: null,
            contextMenuX: 0,
            contextMenuY: 0,
            addNoteForUserTaskModalIsOpen: false,
            task_id: Number,
        }
    },
    mixins: [
        NotificationMixin,
    ],
    computed: {
        ...mapState([
            'ContextUser',
        ]),
    },

    methods: {
        showContextMenu(item, event, index) {
            event.preventDefault();
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.isContextMenuVisible = true;
            this.ContextMenuTaskIndex = index;
            this.task_id = item.id;
            const handleClickOutside = (e) => {
                this.isContextMenuVisible = false;
            };

            document.addEventListener('click', handleClickOutside);
        },
        CloseContextMenu() {
            this.isContextMenuVisible = false;
        },
        taskIsLate(created_at, completed_at) {
            if (!completed_at) {
                const createdDate = new Date(created_at);
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return createdDate < today;
            }
            return false;
        },
        async retrieveUserTasks() {
            await RetrieveUserTasks(this.ContextUser.id, 'user_id')
                .then(response => {
                    this.tasks = response.data.data.tasks;
                })
        },
        async updateUserTask(index) {
            let task = this.tasks[index];
            let date = new Date();
            const completed_at = task['completed_at']
                ? null
                : `${date.toDateString()} ${date.toLocaleTimeString()}`;
            this.tasks[index]['completed_at'] = completed_at;

            await UpdateUserTask(task.id, {
                'completed_at': completed_at
            })
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Task Updated Successfully')
                    }
                })
        },
        toggleUnderline(index) {
            index = this.selectedTask;
            this.isUnderlined = !this.isUnderlined;
        },
    },

    mounted()
    {
        this.retrieveUserTasks()
    }
}

</script>
