import api from './Api';

const BaseURL = 'v1/lead-verification';

export const DownloadLeadVerificationPdf = async (src) =>
{
    return await api().get(`${BaseURL}/download-pdf/${src}`,{ responseType: 'blob' })
}

export const RetrieveLeadVerifications = async (filter_by, sort_by, limit, page, start_at, end_at) =>
{
    return await api().get(`${BaseURL}?filter_by=${filter_by}&sort_by=${sort_by}&limit=${limit}&page=${page}&start_at=${start_at}&end_at=${end_at}`)
}

export const RetrieveLeadVerification = async (id) =>
{
    return await api().get(`${BaseURL}/${id}`)
}

export const StoreLeadVerification = async (lead_id) =>
{
    return await api().post(`${BaseURL}?lead_id=${lead_id}`)
}

export const UpdateLeadVerification = async (id) =>
{
    return await api().put(`${BaseURL}/${id}`)
}

export const DeleteLeadVerification = async (id) =>
{
    return await api().delete(`${BaseURL}/${id}`)
}