export default
{
	welcome_back: 'Welcome Back !',
	please_enter_your_credentials: 'Please enter your login credentials',
	sign_in: 'Sign In',
	sign_in_with_email: 'Sign In With Email Address',
	sign_in_with_phone: 'Sign In With Phone Number',

	errors: {
		user_locked: 'Too many failed attempts. Your account had been locked, please recover your password or contact the customer service to unlock it.',
		password_required: 'Password is Required',
		invalid_email_or_password: 'Invalid Email Address or Password',
		invalid_phone_or_password: 'Invalid Phone Number or Password',
		invalid_email: 'Invalid Email Address',
		email_required: 'Email Address is required',
		invalid_email_format: 'Invalid Email Address Format',
		phone_is_required: 'Phone Number is Required',
		phone_is_invalid: 'Invalid Phone Number',
		user_not_found: 'User Not Found',
	}
}