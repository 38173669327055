<template>
    <div class="custom-shadow-0 | flex flex-col gap-[20px] bg-white pb-[20px] rounded-lg border border-gray-100">
        <div class="flex items-center justify-between h-[42px] border-b pl-[24px] border-gray-100">
            <h1 class="text-[12px] font-light">
                Risk Management
            </h1>
            <div class="flex gap-2 border-gray-100">
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="ApproveSection"
                    :class="[IsValidated ? 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 ' : 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-green-100', 'duration-500 mr-2 h-[28px] px-[24px] cursor-pointer text-xs rounded']"
                >
                    {{ IsValidated ? 'Decline' : 'Approve' }} Section
                </button>
            </div>
        </div>

        <div class="grid grid-cols-3 h-[224px] gap-[24px] px-[24px]">
            <div class="custom-shadow-1 | h-full rounded-lg">
                <div class="h-[38px] w-full text-xs flex items-center px-[14px] text-gray-500">
                    CreditBook
                </div>
                <div class="w-full pb-[12px] px-[12px] flex flex-col gap-1 items-center justify-center">
                    <div class="flex items-center justify-between h-[32px] rounded-md w-full bg-green-100 px-[12px]">
                        <span class="tracking-wide text-[10.5px] text-green-500">Accepted</span>
                        <span class="text-xs text-green-500">1</span>
                    </div>
                    <div class="flex items-center justify-between h-[32px] rounded-md w-full bg-red-100 px-[12px]">
                        <span class="tracking-wide text-[10.5px] text-red-500">Denied</span>
                        <span class="text-xs text-red-500">1</span>
                    </div>
                    <div class="flex items-center justify-between h-[32px] rounded-md w-full bg-yellow-100 px-[12px]">
                        <span class="tracking-wide text-[10.5px] text-yellow-500">Warning</span>
                        <span class="text-xs text-yellow-500">1</span>
                    </div>
                    <div class="flex items-center justify-between h-[32px] rounded-md w-full bg-purple-100 px-[12px]">
                        <span class="tracking-wide text-[10.5px] text-purple-500">In Collection</span>
                        <span class="text-xs text-purple-500">1</span>
                    </div>
                    <div class="flex items-center justify-between h-[32px] rounded-md w-full bg-gray-100 px-[12px]">
                        <span class="tracking-wide text-[10.5px] text-gray-500">Bankruptcy</span>
                        <span class="text-xs text-gray-500">1</span>
                    </div>
                </div>
            </div>
            <div class="custom-shadow-1 | h-full flex flex-col justify-between items-center rounded-lg">
                <div class="h-[38px] w-full text-xs flex items-center px-[14px] text-gray-500">
                    Requests
                </div>
                <div class="flex flex-col w-full px-[12px] relative">
                    <div class="flex gap-4 w-full">
                        <div class="w-full rounded-lg h-[40px] text-xs bg-red-100 flex items-center justify-between px-[12px] text-red-500">
                            <span>Denied</span>
                            <span>{{denied_leads}}</span>
                        </div>
                        <div class="w-full rounded-lg h-[40px] text-xs bg-green-100 flex items-center justify-between px-[12px] text-green-500">
                            <span>Accepted</span>
                            <span>{{accepted_leads}}</span>
                        </div>
                    </div>
                    <div class="absolute bottom-[12px] mx-auto w-full flex items-center justify-center -ml-[12px] h-[50px]">
                        <div class="bg-gray-100 flex flex-col pt-3 w-[150px] mb-[22px] relative text-[18px] text-gray-500 items-center justify-center h-[75px] pb-[3.5] py-[2px]">
                            {{averageLend}} $
                            <span class="text-[9px] -mt-0.5 text-gray-400">Average Lend</span>
                        </div>
                    </div>
                    <div class="flex justify-center items-center relative -mt-4">
                        <canvas id="myCanvas" width="224" height="150"></canvas>
                    </div>
                </div>
            </div>
            <div class="custom-shadow-1 | h-full flex flex-col rounded-lg">
                <div class="h-[38px] w-full text-xs flex items-center px-[14px] text-gray-500">
                    History
                </div>
                <div class="h-full w-full flex flex-col p-[12px] justify-between">
                    <div
                        v-for="Lead in LeadHistory.slice(0,3)"
                        class="h-[45px] w-full bg-gray-100 flex items-center text-sm text-gray-600 justify-between px-[12px] rounded-lg"
                    >
                        <div class="text-xs flex items-center justify-center gap-1">
                            {{ Lead.lender.name }}
                            <span class="text-[10px] tracking-wide">
                                (${{ Lead.amount }})
                            </span>
                        </div>
                        <div class="text-xs capitalize">
                            {{ (Lead.lead_status?.lead_status_name).toLowerCase().replace(/_/g, " ") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {vMaska} from "maska";
import SaveIcon from "../../../../assets/icons/SaveIcon.vue";
import NotificationMixin from "../../../../mixins/NotificationMixin";
import {RetrieveLeads} from "../../../../services/Lead";

export default
{
    components: {
        SaveIcon
    },

    emits: ['approve-section'],

    props: {
        IsValidated: {
            type: Boolean,
            default: false,
        }
    },

    directives: {
        maska: vMaska
    },

    data() {
        return {
            LeadHistory: [],
            accepted_leads: 0,
            denied_leads: 0,
            averageLend: 0,
            payload: {
                'employer_name': null,
                'employer_phone': null,
                'hired_at': null,
                'work_status': null,
                'income_source': null,
            },
        }
    },

    computed: {
        ...mapState([
            'ContextLead'
        ]),
    },

    mixins: [
        NotificationMixin
    ],

    methods: {
        ApproveSection() {
            this.$emit('approve-section', {approved_step: 'risk_management', approved_step_status: !this.IsValidated});
        },

        FormatDate(date) {
            const context_date = new Date(date);
            return `${context_date.getFullYear()}-${(context_date.getMonth() + 1 + '').padStart(2, '0')}-${(context_date.getDate() + '').padStart(2, '0')}`;
        },

        FetchLeads() {
            const query = `client_id=${this.ContextLead.client.id}`

            RetrieveLeads(query, 1, 20)
                .then((response) => {
                    if(response.status === 200) {
                        this.LeadHistory = response.data.data.leads;
                        this.accepted_leads = this.LeadHistory.filter(lead => lead.status_id === 1).length;
                        this.denied_leads = this.LeadHistory.filter(lead => lead.status_id === 2).length;
                        let lead_accepted_count = this.LeadHistory.filter(lead => lead.status_id === 1);
                        this.averageLend = (lead_accepted_count.reduce((total, lead) => total + lead.amount, 0) / lead_accepted_count.length) || 0;
                        this.drawArc();
                    }
                })
        },

        drawArc() {
            const canvas = document.getElementById('myCanvas');
            const ctx = canvas.getContext('2d');
            const deniedLeads = this.denied_leads;
            const acceptedLeads = this.accepted_leads;
            const centerX = 224 / 2;
            const centerY = canvas.parentNode.clientHeight / 2 + 64;
            const radius = 90;

            const percentageDenied = (deniedLeads / (acceptedLeads + deniedLeads)) * 100;
            const percentageAccepted = (acceptedLeads / (acceptedLeads + deniedLeads)) * 100;

            const endAngleDenied = Math.PI + (Math.PI * percentageDenied) / 100;
            const endAngleAccepted = endAngleDenied + (Math.PI * percentageAccepted) / 100;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            if (acceptedLeads === 0 && deniedLeads === 0) {
                ctx.beginPath();
                ctx.arc(centerX, centerY, radius, Math.PI, Math.PI * 2, false);
                ctx.lineWidth = 32;
                ctx.strokeStyle = '#949393';
                ctx.stroke();
            }
            else {
                ctx.beginPath();
                ctx.arc(centerX, centerY, radius, Math.PI, endAngleDenied, false);
                ctx.lineWidth = 32;
                ctx.strokeStyle = '#fca5a5';
                ctx.stroke();

                ctx.beginPath();
                ctx.arc(centerX, centerY, radius, endAngleDenied, endAngleAccepted, false);
                ctx.strokeStyle = '#86efac';
                ctx.stroke();
            }

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            const textXDenied = centerX + (radius * Math.cos(endAngleDenied - (Math.PI * percentageDenied) / 200));
            const textYDenied = centerY + (radius * Math.sin(endAngleDenied - (Math.PI * percentageDenied) / 200));
            ctx.fillStyle = '#030303';

            const textXAccepted = centerX + (radius * Math.cos(endAngleAccepted - (Math.PI * percentageAccepted) / 200));
            const textYAccepted = centerY + (radius * Math.sin(endAngleAccepted - (Math.PI * percentageAccepted) / 200));
            ctx.fillStyle = '#030303';

            if(deniedLeads !== 0) {
                ctx.fillText(((deniedLeads/(acceptedLeads + deniedLeads))*100).toFixed(2).toString()+'%', textXDenied, textYDenied);
            } else {
                ctx.fillText('', textXAccepted, textYAccepted);
            }

            if(acceptedLeads !== 0) {
                ctx.fillText(((acceptedLeads/(acceptedLeads + deniedLeads))*100).toFixed(2).toString()+'%', textXAccepted, textYAccepted);
            } else {
                ctx.fillText('', textXDenied, textYDenied);
            }
        },
    },

    mounted() {
        this.FetchLeads();

        if (this.ContextLead.client.occupation) {
            this.payload.employer_name = this.ContextLead.client.occupation['employer_name'];
            this.payload.hired_at = this.FormatDate(this.ContextLead.client.occupation['hired_at']);
            this.payload.income_source = this.ContextLead.client.occupation['income_source'];
            this.payload.work_status = this.ContextLead.client.occupation['work_status'];

            if (!!this.ContextLead.client.occupation['phone_country_code'] || !!this.ContextLead.client.occupation['phone_area_code'] || !!this.ContextLead.client.occupation['phone_number']) {
                this.payload.employer_phone = this.ContextLead.client.occupation['phone_country_code'] + this.ContextLead.client.occupation['phone_area_code'] + this.ContextLead.client.occupation['phone_number'];
            }
        }
    }
}
</script>
