<template>
    <div v-if="!isSelf" class="w-full">
        <div class="flex flex-row-reverse float-left gap-1.5 items-end">
            <div class="bg-gray-200 w-fit px-3.5 py-2.5 rounded-r-lg rounded-t-lg rounded-bl-0">
                <p class="text-xs text-gray-600 tracking-wide">
                    {{ message }}
                </p>
            </div>
            <div class="text-[7pt] text-gray-600 bg-gray-200 rounded-full h-[20px] w-[20px] flex items-center justify-center">
                {{ sender }}
            </div>
        </div>
    </div>
    <div v-else class="w-full bottom-0">
        <div class="float-right gap-1.5 items-end flex justify-end">
            <div class="bg-blue-500 w-fit max-w-[66.66%] px-3.5 py-2.5 rounded-l-lg rounded-t-lg rounded-br-0">
                <p class="text-xs tracking-wide text-white">
                    {{ message }}
                </p>
            </div>
            <div class="text-[7pt] text-white bg-blue-500 rounded-full h-[20px] w-[20px] flex items-center justify-center">
                {{ sender }}
            </div>
        </div>
    </div>
</template>

<script>
export default
{
    props: {
        message: {
            type: String,
            required: true,
        },
        sender: {
            type: String,
            required: true,
        },
        isSelf: {
            type: Boolean,
            required: true,
        }
    }
}
</script>