<template>
	<div
		v-if="QuickSearchIsOpen"
		class="relative z-10"
		role="dialog"
		aria-modal="true"
	>
		<div class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>

		<div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
			<div
				v-click-outside="close"
				class="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-black ring-opacity-5 transition-all"
			>
				<div class="flex items-center gap-4 h-[50px]">
					<search-duo-icon
						size="24px"
						class="fill-gray-500 absolute w-[50px] -mt-0.5"
					/>
					<input
						v-model="query"
						@input="handleInput"
						type="text"
						class="h-[50px] w-full border-0 bg-transparent px-[50px] text-gray-500 tracking-wide placeholder:text-gray-400 focus:ring-0 text-sm"
						placeholder="Search..."
					>
					<div class="w-[50px] flex items-center justify-center">
						<loader-animation
							v-if="isLoading"
							border="2px solid #4667d2"
							size="16px"
						/>
					</div>
				</div>

				<div v-if="this.results.length === 0 && this.query" class="px-6 py-8 text-center sm:px-14">
					<users-duo-icon class="mx-auto fill-gray-500" size="28px"/>
					<p class="mt-3 font-medium text-xs text-gray-500">We couldn't find any client ({{ this.query }}). <br /> Please try again.</p>
				</div>

				<ul v-else-if="this.results.length > 0" class="max-h-96 overflow-y-auto p-2 text-sm text-gray-700">
					<li
						v-for="result in this.results"
						class="group flex cursor-pointer select-none items-center duration-500"
					>
						<a
							:href="`/client/${result.id}`"
							class="flex items-center hover:bg-light-dark-blue w-full px-3 py-2 rounded-md duration-500"
						>
							<user-duo-icon size="24px" class="fill-gray-500 group-hover:fill-[#4667d2] duration-500" />
							<span class="ml-3 flex-auto truncate text-gray-500 group-hover:text-[#4667d2] duration-500">{{ `${result.first_name} ${result.last_name}` }}</span>
						</a>
					</li>
				</ul>

				<ul class="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto">
					<li class="p-2">
						<ul class="text-sm text-gray-700">
							<li class="group flex cursor-default select-none items-center rounded-md px-3 py-2">
								<user-duo-icon size="24px" class="fill-gray-500" />
								<span class="ml-3 flex-auto truncate text-gray-500">Create new client...</span>
							</li>
							<li class="group flex cursor-default select-none items-center rounded-md px-3 py-2">
								<document-duo-icon size="24px" class="fill-gray-500"/>
								<span class="ml-3 flex-auto truncate text-gray-500">Create new lead...</span>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>

import {mapState} from "vuex";
import {Search} from "../../services/Search";
import UserDuoIcon from "../../assets/icons/duotone/UserDuoIcon.vue";
import UsersDuoIcon from "../../assets/icons/duotone/UsersDuoIcon.vue";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import DocumentDuoIcon from "../../assets/icons/duotone/DocumentDuoIcon.vue";
import SearchDuoIcon from "../../assets/icons/duotone/SearchDuoIcon.vue";

export default {
	components: {
		SearchDuoIcon,
		DocumentDuoIcon,
		LoaderAnimation,
		UsersDuoIcon,
		UserDuoIcon
	},

	watch: {
		'query'() {
			if (this.query.length < 3) {
				this.isLoading = false;
			}
		}
	},

	computed: {
		...mapState([
			'QuickSearchIsOpen'
		]),
	},

	data() {
		return {
			open: false,
			query: '',
			results: [],
			people: [],
			debounceTimeout: null,
			isLoading: false,
		};
	},

	methods: {
		close() {
			this.$store.state.QuickSearchIsOpen = false;
		},

		handleInput() {
			clearTimeout(this.debounceTimeout);

			if (this.query.length >= 3) {
				this.debounceTimeout = setTimeout(() => {
					this.isLoading = true;

					Search(this.query)
						.then((response) => {
							this.results = response.data.data.query;
						})
						.finally(() => {
							this.isLoading = false;
						})
				}, 500);
			}
		},
	}
}
</script>
