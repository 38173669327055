<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :height="size"
        :width="size"
        viewBox="0 0 24 24"
        :fill="fill"
    >
        <circle cx="18.2" cy="6.8" r="2.4"></circle>
        <circle cx="11.7" cy="3.4" r="2.2"></circle>
        <circle cx="5" cy="6.4" r="1.9" opacity=".35"></circle>
        <circle cx="3.2" cy="13.6" r="1.7" opacity=".35"></circle>
        <circle cx="7.6" cy="19.4" r="1.5" opacity=".35"></circle>
        <circle cx="14.9" cy="19.6" r="2.9"></circle>
        <circle cx="19.7" cy="14" r="2.7"></circle>
    </svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>