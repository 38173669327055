<template>
    <div class="w-full flex flex-col">
        <input
            :name="name"
            class="tracking-wide duration-500 w-full focus:ring-offset-0 ring-0 outline-0 focus:outline-0 focus:outline-offset-0 focus:ring-[2px]"
            :class="[!!error ? 'bg-red-100 text-red-600 placeholder:text-red-500 focus:ring-red-500' : 'text-gray-600 bg-gray-100 focus:ring-[#212529]', 'duration-500 h-[52px] w-full rounded-[6px] px-4 border border-transparent focus:outline-none text-sm']"
            :type="type"
            :placeholder="placeholder"
            autocomplete="autocomplete"
            v-on:input="UpdateValue($event.target.value)"
        >
        <p
            v-if="error"
            class="text-right text-xs tracking-wide text-red-500 mt-2"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
export default
{
    props: {
        error: {
            type: [String, null],
            default: null
        },
        type: {
            type: String,
            default: "text"
        },
        placeholder: {
            type: String,
            default: "input",
        },
        name: {
            type: String,
            default: "input"
        }
    },

    methods: {
        UpdateValue(value) {
            this.$emit('update:value', value)
        }
    },
}
</script>
