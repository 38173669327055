<template>
    <view-all-modal
        :IsOpen="modalViewAll"
        :Title="`General notes`"
        :NotesData="ViewAllNotes"
        :NotesType="NoteTypes[0]"
        @IsClose="closeViewAllModal"
    />
    <kronos-base-layout>
        <div class="flex flex-col gap-[16px]">
            <kronos-base-heading title="Dashboard">
                <kronos-base-date-range-selector
                    :context-selected-dates="[$route?.query?.start_at, $route?.query?.end_at]"
                    @change-range="fetchDataByDate()"
                    @reload="fetchDataByDate()"
                >
                </kronos-base-date-range-selector>
            </kronos-base-heading>

            <approbation-stats
                v-if="this.$store.state.SelectedTeam === 0"
                @change="SelectedCardStats = $event; $forceUpdate()"
                :SelectedCardStats="SelectedCardStats"
                :counter="this.counter"
            />

            <collection-stats
                v-if="this.$store.state.SelectedTeam === 1"
                @change="SelectedCardStats = $event; $forceUpdate()"
                :SelectedCardStats="SelectedCardStats"
            />

            <div class="flex flex-col gap-[24px] mb-[24px]">
                <LeadsTable
                    :is-loading="isLoading"
                    :dataset="dataset"
                    custom-query="status_id=[3,5,6]"
                    title="Started Leads"
                />

                <LeadsTable
                    :is-loading="isLoading"
                    :dataset="dataset"
                    custom-query="status_id=4"
                />
            </div>
        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../UI/Layouts/KronosBaseLayout.vue";
import {mapState} from "vuex";
import KronosBaseHeading from "../UI/BaseComponents/KronosBaseHeading.vue";
import KronosBaseDateRangeSelector from "../UI/BaseComponents/KronosBaseDateRangeSelector.vue";
import ChevronDownIcon from "../assets/icons/ChevronDownIcon.vue";
import KronosBaseFilterByTable from "../UI/BaseComponents/KronosBaseFilterByTable.vue";
import KronosBaseTable from "../UI/BaseComponents/KronosBaseTable.vue";
import ReloadIcon from "../assets/icons/ReloadIcon.vue";
import SettingsIcon from "../assets/icons/SettingsIcon.vue";
import CloseIcon from "../assets/icons/CloseIcon.vue";
import {LeadStatisticsByManager, RetrieveLeadsByManger, UpdateLead} from "../services/Lead";
import KronosBaseCard from "../UI/BaseComponents/KronosBaseCard.vue";
import NotificationMixin from "../mixins/NotificationMixin";
import viewAllModal from "../components/Lead/LeadNote/modal/ViewAllModal.vue";
import Approbation from "../components/Dashboard/StatisticsCard/Approbation.vue";
import Collection from "../components/Dashboard/StatisticsCard/Collection.vue";
import LeadsTable from "../UI/Tables/LeadsTable.vue";
import {RetrieveStatCardDashboard} from "../services/StatCard";

export default
{
    name: 'Dashboard',

    components: {
        LeadsTable,
        'approbation-stats': Approbation,
        'collection-stats': Collection,
        viewAllModal,
        KronosBaseCard,
        CloseIcon,
        SettingsIcon,
        ReloadIcon,
        KronosBaseTable,
        KronosBaseFilterByTable,
        ChevronDownIcon,
        KronosBaseDateRangeSelector,
        KronosBaseHeading,
        KronosBaseLayout,
    },

    computed: {
        ...mapState('entities', [
            "NoteTypes"
        ]),
        ...mapState([
            'ContextUser'
        ]),
    },

    mixins: [
        NotificationMixin
    ],

    data() {
        return {
            isLoading: false,
            dataset: [],
            data: [],
            total: 0,
            counter: [],
            statistics: [],
            btn: 1,
            ViewAllNotes: [],
            SelectedNote: {},
            modalViewAll: false,
            CardStatsTypes: ['status', 'statistics'],
            SelectedCardStats: 'status',
        }
    },

    created() {
        this.fetchData();
    },

    methods: {
        UpdateLeadStatus($event, lead_id) {
            UpdateLead(lead_id, {
                'status_id': $event.target.value
            })
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Status Updated Successfully')
                        this.$forceUpdate();
                        this.fetchData();
                        this.fetchDataByDate();
                    }
                })

        },
        openViewAllModal(leadId) {
            this.modalViewAll = true;
            this.$store.state.lead_id = leadId
        },

        closeViewAllModal() {
            this.ViewAllNotes = [];
            this.modalViewAll = false;
        },
        UpdateResponsible($event, lead_id) {
            UpdateLead(lead_id, {
                'user_id': $event.target.value
            })
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Status Updated Successfully')
                        this.fetchData();
                        this.fetchDataByDate();
                    }
                })
        },

        async fetchData() {
            const data = this.$route.query;

            if (data?.start_at && data?.end_at && data?.page && data?.limit) {
                this.isLoading = true;
                this.dataset = [];
                await RetrieveLeadsByManger(data.page, data.limit, data.ibv, data.started)
                    .then(response => {
                        this.dataset = response.data.data.leads;
                        this.total = response.data.data.total;
                    })
            }

            this.isLoading = false;
        },

        redirectToLeadPage(leadId) {
            window.location.href = `/lead/${leadId}`;
        },

        async fetchDataByDate() {
            const data = this.$route.query;

            if (data?.start_at && data?.end_at && data?.page && data?.limit) {
                this.isLoading = true;
                this.data = [];
                await LeadStatisticsByManager(data.page, data.limit, data.start_at, data.end_at)
                    .then(response => {
                        this.data = response.data.data.leads;
                        this.counter = response.data.data.total;
                    })

                this.isLoading = false;
            }
        },
        RetrieveStatCardDashboard() {
            RetrieveStatCardDashboard('dashboard', this.ContextUser.account_id)
                .then((response) => {
                    this.counter = Object.values(response.data.data.stat_card);
                })
        }
    },
    mounted() {
        this.RetrieveStatCardDashboard();
    }
}
</script>