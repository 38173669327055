import { createRouter, createWebHistory } from 'vue-router';
import DashboardPage from "../pages/DashboardPage.vue";
import TransactionsClassificationPage from "../pages/Automation/TransactionsClassificationPage.vue";
import BaseUIPage from "../pages/BaseUIPage.vue";
import { AuthCheck } from "../services/Authentication";
import store from './../store'
import { ContextUser } from "../services/User";
import { Users } from "../services/User";
import LeadDispatcherPage from "../pages/Tools/LeadDispatcherPage.vue";
import IBVReports from "../pages/Tools/IBVReports.vue";
import AuthenticationRoutes from "./routes/authentication";
import UserRoutes from "./routes/user";
import ClientRoutes from "./routes/client";
import LeadRoutes from "./routes/lead";
import IbvRoutes from "./routes/ibv";
import {RetrieveLead} from "../services/Lead";
import ForgotPasswordPage from "../pages/ForgotPasswordPage.vue";
import {RetrieveIbv} from "../services/Ibv";
import {RetrieveDenyReasons} from "../services/DenyReason";
import {RetrieveLeadStatuses} from "../services/LeadStatus";
import LeadVerificationsPage from "../pages/Tools/LeadVerificationsPage.vue";
import ManagerDashboardPage from "../pages/Manager/ManagerDashboardPage.vue";
import AutomationPage from "../pages/AutomationPage.vue";
import NewAutomationPage from "../pages/NewAutomationPage.vue";

const routes = [
    ...AuthenticationRoutes,
    ...UserRoutes,
    ...LeadRoutes,
    ...ClientRoutes,
    ...IbvRoutes,
    {
        path: '/',
        component: DashboardPage,
        name: 'Dashboard',
        meta: {
            type: 'private',
            authOnly: true,
        }
    },
    {
        path: '/automation',
        component: AutomationPage,
        name: 'Automations',
        meta: {
            type: 'private',
            authOnly: true,
        }
    },
    {
        path: '/automation/new',
        component: NewAutomationPage,
        name: 'NewAutomation',
        meta: {
            type: 'private',
            authOnly: true,
        }
    },
    {
        path: '/manager/dashboard',
        component: ManagerDashboardPage,
        name: 'ManagerDashboardPage',
        meta: {
            type: 'private',
            authOnly: true,
            allowedRoles: [3, 4]
        }
    },
    {
        path: '/ui',
        component: BaseUIPage,
        name: 'BaseUI',
        meta: {
            type: 'private',
            authOnly: true,
            localOnly: true,
        }
    },
    {
        path: '/tools/lead-dispatcher',
        component: LeadDispatcherPage,
        name: 'LeadDispatcherPage',
        meta: {
            type: 'private',
            authOnly: true,
        }
    },
    {
        path: '/automation/transactions-classification',
        component: TransactionsClassificationPage,
        name: 'TransactionsClassificationPage',
        meta: {
            type: 'private',
            authOnly: true,
        }
    },
    {
        path: '/ibv-reports',
        component: IBVReports,
        name: 'IBVReports',
        meta: {
            type: 'private',
            authOnly: true,
        }
    },
    {
        path: '/lead-verifications',
        component: LeadVerificationsPage,
        name: 'LeadVerifications',
        meta: {
            type: 'private',
            authOnly: true,
        }

    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        meta: {
            redirect: true,
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    store.state.IsLoading = true;

    let isLoggedIn = false;

    if (to.matched.some(record => record?.meta?.localOnly)) {
        if (import.meta.env.VITE_ENV !== 'local') {
            window.location.href = '/';
        }
    }

    if (to.matched.some(record => record?.meta?.redirect)) {
        window.location.href = '/';
    }

    if (to.name === 'LeadPage') {
        if (to.params.id) {
            RetrieveLead(to.params.id)
                .then((response) => {
                    if (response.status === 200) {
                        store.state.ContextLead = response.data.data.lead;
                    }
                })
                .catch(() => {
                    // window.location.href = '/leads'
                })
        } else {
            // window.location.href = '/leads';
        }
    }

    if (to.name === 'IbvReportPage') {
        if (to.params.id) {
            RetrieveIbv(to.params.id)
                .then((response) => {
                    if (response.status === 200) {
                       store.state.ContextIbv = response.data.data.ibv;
                    }
                })
        } else {
            window.location.href = '/';
        }
    }

    await AuthCheck()
        .then(async response => {
            if (response.status === 200) {
                isLoggedIn = true;
            }

            if (to.meta.type === 'private') {
                await ContextUser()
                    .then(response => {
                        store.state.ContextUser = response.data.data.user;
                    });

                await RetrieveDenyReasons()
                    .then(response => {
                        store.state.DenyReasons = response.data.data.denied_reasons;
                    });

                await RetrieveLeadStatuses()
                    .then(response => {
                        store.state.LeadStatuses = response.data.data.lead_statuses;
                    });

                await Users()
                    .then(response => {
                        store.state.ContextUsers = response.data.data.users;
                    });
            }
        })
        .catch(() => {
            isLoggedIn = false;
        });

    if (to.name === 'ManagerDashboardPage') {
        if (!to.meta.allowedRoles.includes(store.state.ContextUser.role_id)) {
            next('/unauthorized');
        }
    }
    store.state.ContextLocale = window.localStorage.getItem('locale');
    store.state.ContextTheme = window.localStorage.getItem('theme');
    store.state.SideNavigationIsOpen = window.localStorage.getItem('SideNavigationIsOpen')
        ? window.localStorage.getItem('SideNavigationIsOpen') === 'true'
        : false;

    if (to.matched.some(record => record?.meta?.authOnly)) {
        !isLoggedIn
            ? next({path: "/login"})
            : next();
    } else if (to.matched.some(record => record?.meta?.guestOnly)) {
        !!isLoggedIn
            ? next({path: "/"})
            : next();
    } else {
        next();
    }

    store.state.IsLoading = false;
});

export default router;
