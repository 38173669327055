<template>
    <div class="h-fit bg-gray-50 rounded-b-lg p-[18px]">
        <div class="custom-shadow-0 | bg-white h-fit rounded-lg">
            <div class="h-[34px] flex items-center px-[18px] text-xs border-b border-gray-100">
                Modifications on this lead
            </div>
            <div v-if="dataset.length === 0" class="w-full h-[200px] flex items-center justify-center text-gray-400 text-xs"> No Recent Activity</div>
            <div v-else>
                <div
                    class="w-full flex flex-row bg-gray-50 text-sm py-3"
                >
                    <div
                        :class="header.width"
                        class="flex items-center justify-center"
                        v-for="header in headers"
                        :key="header.id"
                    >
                        {{header.name}}
                    </div>
                </div>
                <div :class="open ? 'h-full overflow-x-auto' : 'max-h-[400px] overflow-x-auto'">
                    <div
                        class="w-full text-gray-700 text-xs font-light tracking-wide flex flex-row hover:bg-gray-50"
                        v-for="modification in dataset"
                        :key="modification.id"
                    >
                        <div class="w-1/6 flex items-center justify-center">
                            <span class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                <svg class="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                                    <circle cx="3" cy="3" r="3" />
                                </svg>
                                {{ `${modification.user.first_name} ${modification.user.last_name}` }}
                            </span>
                        </div>
                        <div class="w-2/6 flex items-center justify-center py-3 font-medium ">
                            {{formatDateTime(modification.created_at)}}
                        </div>

                        <div class="w-3/6 flex items-center justify text-xs font-medium">
                            changed field
                            {{modification.field}}
                            from
                            <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 mx-2">{{modification.old_value}}</span>
                            to
                            <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 mx-2">{{modification.new_value}}</span>
                        </div>
                    </div>
                </div>
                <div
                    v-if="dataset.length > 10 "
                    class="flex items-center justify-center text-lg bg-gray-50">
                    <button @click="open = !open" class="rotate-90"> > </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        lead_id: {
            type: Number,
        },
        dataset : {
            type: Array,
            default: [],
        },
    },

    data() {
        return {
            headers: [
                {id: 1, name:'By', width: 'w-1/6'},
                {id: 2, name:'Modification Date', width: 'w-2/6'},
                {id: 3, name:'Description', width: 'w-3/6'},
            ],
            modificationHistory : [],
            open: false,
        }
    },

    methods: {
        formatDateTime(dateTime) {
            const date = new Date(dateTime);
            const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            };
            return date.toLocaleString('en-CA', options);
        },
    },
}
</script>
