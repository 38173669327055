<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<circle cx="12" cy="7" r="5" opacity=".35"/><path d="M18,15H6c-1.657,0-3,1.343-3,3c0,1.657,1.343,3,3,3h12c1.657,0,3-1.343,3-3C21,16.343,19.657,15,18,15z"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
