<template>
    <div class="z-50 w-screen flex items-center justify-center h-screen fixed top-0 left-0 bg-black bg-opacity-25">
        <div class="bg-white rounded-[6px] w-[70%] h-[fit] divide-y divide-gray-100" v-click-outside="Close">
            <div class="h-[50px] flex items-center justify-between">
                <span class="pl-[18px] text-sm text-gray-700">Email Editor</span>
                <div @click="Close()" class="cursor-pointer rounded-tr-[6px] hover:bg-gray-50 w-[50px] flex items-center justify-center border-l h-[50px] border-gray-100">
                    <cross-duo-icon size="16px" class="fill-gray-400" />
                </div>
            </div>
            <div class="h-fit w-full">
                <div class="h-[45px] items-center mx-[18px] text-[12px] flex gap-4 text-gray-500 border-b border-gray-100">
                    From:
                    <div class="bg-blue-200 px-[12px] text-[10.5px] py-1 rounded-[6px] text-blue-600">{{ `${ContextUser.first_name} ${ContextUser.last_name} (${ContextUser.email})` }}</div>
                </div>
                <div class="h-[45px] items-center mx-[18px] justify-between text-[12px] flex gap-4 text-gray-500 border-b border-gray-100">
                    <div class="flex gap-4 items-center">
                        To:
                        <div class="bg-blue-200 px-[12px] text-[10.5px] py-1 rounded-[6px] text-blue-600">{{ `${GlobalEmailEditor.ContextClient.first_name} ${GlobalEmailEditor.ContextClient.last_name} (${GlobalEmailEditor.ContextClient.email_address})` }}</div>
                    </div>
                    <div class="border h-[24px] w-[24px] flex items-center justify-center rounded-full border-gray-300">
                        <cross-duo-icon class="rotate-45 fill-gray-500" size="10px" />
                    </div>
                </div>
                <div class="h-[45px] items-center mx-[18px] text-[12px] flex gap-4 text-gray-500 border-b border-gray-100">
                    <div class="min-w-fit">Send At:</div>
                    <div v-if="!ModifySendDate" @click="ModifySendDate = true" class="cursor-pointer bg-blue-200 pl-[8px] pr-[12px] text-[10.5px] py-1 rounded-[6px] text-blue-600 flex gap-2 justify-center items-center">
                        <img class="h-[18px]" alt="timeicon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACRUlEQVR4nN1Vy27TQBR1smCDQFQCpEJxkT0zeSBBKxYoZceGR8NDog1PkXhmsNoV/EE+hk9B/AFrJARt4zu2KXskozvORDQzdlqWPdKVotw799hn7j32vNONcdHsxrkfjpINIqFPRLatQ0I/5FkviHMfa/6rdzjKrhGhHlMJr+qCyLxPZLpygtZFgw6TtVkTAY9YrNqrw18XutvFGQz8TUXawdysbpis4dmF7U1zJrMBifa7iw61uGoxAS80SZTcWiiLLuTw0o9h2SLn6gsR6vP8/+0YlvFMKVmVXEXRMJq3JbD59M23B2epUAUG/rZIJLCSAPrYy+rf4fkqFoQieeCS5TrmpwQ0mgSOJ2wEETzEHnq6LHn4pDclIK4XDKW6bQhwRF01wQioljhO71hJInItT4urc26C5L4hYFw9c9WQN+l5M7p2UiZbmKxaHCLUa0NAuNpx1eBZTcCT51aOSRjUETAcRXMHHD7VEbAIBlaOctisk4jFexepgN8lCfwJ3ycbVRLhAloNApHcrbtkBJPqHuVwqGUS8DPYObjsOS4ZvcubBy5ISZA5x9QglNkNwtU3TRLBR9eY0nfpVc/CuGiy0eRp1aL9CxLtXyIcPvi7h0vzixZy9aTSYfXKT63C/RRutLi6YqzCZTFHQDis60nQU5N26s2uaJAo7c7MTqKjHgOGZPo2m0hU2vVXbdf+7vclbdfTyStnH9aP1XxGIn+smDupC11zAjmPYlw00bjQp/QnU6ZbOiL8ZE56QbznO53zVOEvwfN9ICwt9u0AAAAASUVORK5CYII=">
                        <span class="mt-0.5">{{ `${SendAt}` }}</span>
                    </div>
                    <div v-else class="flex items-center justify-between w-full">
                        <input class="text-gray-600 cursor-pointer text-[10px] bg-gray-100 px-[12px] py-1 rounded-[6px] focus:outline-0 focus:ring-0" type="datetime-local">
                        <div @click="ModifySendDate = false" class="cursor-pointer border h-[24px] w-[24px] flex items-center justify-center rounded-full border-red-500 bg-red-50">
                            <cross-duo-icon class="fill-red-600" size="10px" />
                        </div>
                    </div>
                </div>
                <div class="h-[45px] w-full flex items-center text-[12.5px] text-gray-500">
                    <input type="text" class="w-full h-[45px] focus:ring-0 focus:outline-0 px-[20px] capitalize" placeholder="Object:"/>
                </div>
            </div>
            <div class="h-[300px] w-full py-[18px] px-[20px]">
                <textarea class="w-full h-full text-[12px] resize-none focus:outline-0 focus:ring-0" placeholder="Write Your Email...."/>
            </div>
            <div class="h-[50px] flex px-[18px] items-center justify-end gap-2">
                <div class="cursor-pointer text-gray-500 text-[12px] bg-gray-100 w-fit h-[30px] flex items-center justify-center rounded-[6px] px-[24px]">Cancel</div>
                <div class="cursor-pointer text-gray-100 text-[12px] bg-black w-fit h-[30px] flex items-center justify-center rounded-[6px] px-[24px]">Send</div>
            </div>
        </div>
    </div>
</template>

<script>
import CrossDuoIcon from "../../assets/icons/duotone/CrossDuoIcon.vue";
import {mapState} from "vuex";

export default
{
    components: {
        CrossDuoIcon,
    },

    computed: {
        ...mapState([
            'ContextUser',
            'GlobalEmailEditor'
        ]),
    },

    data() {
        return {
            SendAt: null,
            ModifySendDate: false,
        }
    },

    methods: {
        Close() {
            this.GlobalEmailEditor.IsOpen = false;
            this.GlobalEmailEditor.ContextClient = null;
        },

        FormatDateTime(inputDate) {
            const currentDate = inputDate instanceof Date ? inputDate : new Date();

            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getFullYear();

            const hours = currentDate.getHours().toString().padStart(2, '0');
            const minutes = currentDate.getMinutes().toString().padStart(2, '0');

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        },
    },

    mounted() {
        this.SendAt = 'NOW';
    }
}
</script>