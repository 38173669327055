<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :height="size"
        :width="size"
        viewBox="0 0 24 24"
        :fill="fill"
    >
        <path d="M17.586,3.586C17.211,3.211,16.702,3,16.172,3H5C3.89,3,3,3.9,3,5v14c0,1.105,0.895,2,2,2h14c1.1,0,2-0.9,2-2V7.828 c0-0.53-0.211-1.039-0.586-1.414L17.586,3.586z M12,19c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3S13.66,19,12,19z M14,9H6 C5.448,9,5,8.552,5,8V6c0-0.552,0.448-1,1-1h8c0.552,0,1,0.448,1,1v2C15,8.552,14.552,9,14,9z"/>
    </svg>
</template>

<script>

    export default
    {
            props: {
                fill: {
                type: String,
                default: '#000000',
            },


                size: {
                type: String,
                default: '48px',
            }
        }
    }
</script>

