<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M 8 5 C 6.895 5 6 5.895 6 7 L 6 17 C 6 18.105 6.895 19 8 19 C 9.105 19 10 18.105 10 17 L 10 7 C 10 5.895 9.105 5 8 5 z M 16 5 C 14.895 5 14 5.895 14 7 L 14 17 C 14 18.105 14.895 19 16 19 C 17.105 19 18 18.105 18 17 L 18 7 C 18 5.895 17.105 5 16 5 z"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>