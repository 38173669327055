<template>
    <div class="w-full h-fit bg-white rounded-lg">
        <header class="h-[50px] w-full flex justify-between bg-transparent border-b border-gray-100">
            <div class="flex items-center h-full justify-start w-1/3 pl-[14pt] text-sm font-medium text-gray-600">
                {{ title }}
            </div>
            <div class="w-2/3 flex items-center justify-end">
                <div
                    @click="reload()"
                    class="border-l w-[50px] flex items-center justify-center h-[50px] border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg"
                >
                    <reload-icon
                        :class="isLoading && 'animate-spin'"
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
                <div
                    @click="isOpen = !isOpen"
                    class="border-l w-[50px] flex items-center justify-center h-[50px] border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg"
                >
                    <chevron-down-icon
                        :class="[isOpen ? '' : 'rotate-90', 'duration-500']"
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
            </div>
        </header>
        <div v-if="isOpen">
            <div
                v-if="isLoading"
                class="flex items-center h-[100px] justify-center"
            >
                <loader-animation
                    size="24px"
                    border="2.5px solid rgb(209 213 219)"
                />
            </div>
            <div
                v-else-if="dataset.length === 0 && !isLoading"
                class="flex h-[100px] items-center justify-center text-xs text-gray-400"
            >
                No Data Available...
            </div>
            <div
                v-else
                class="flex flex-col"
            >
                <div class="relative overflow-x-auto scroll-smooth scroll max-h-[750px]">
                    <div class="flex bg-gray-50 sticky top-0 z-10">
                        <div
                            v-for="(col, index) in config"
                            :key="`header-${index}`"
                            @click="sortData(col.key)"
                            :style="`min-width: ${col.min_w ?? '0px'}; max-width: ${col.max_w ?? '100%'}`"
                            class="flex items-center justify-center w-full h-[50px] text-[11px] bg-gray-50 text-gray-500 font-medium cursor-pointer"
                        >
                            {{ col.label }}
                            <span v-if="col.key && sortColumn === col.key">{{ sortOrder === 'asc' ? '↓' : '↑' }}</span>
                        </div>
                    </div>
                    <div
                        v-for="(item, itemIndex) in dataset"
                        :key="`item-${itemIndex}-${item[idKey]}`"
                        class="flex group duration-300 hover:shadow-outline"
                    >
                        <div v-for="(col, colIndex) in config"
                             :key="`col-${colIndex}-${col.key}-${item[idKey]}`"
                             :style="`min-width: ${col.min_w ?? '0px'}; max-width: ${col.max_w ?? '100%'}`"
                             class="flex items-center justify-center w-full h-[50px] overflow-y-auto cursor-pointer"
                        >
                            <div
                                v-if="['PDF', 'MODIFY'].includes(col.label)"
                                class="h-full flex items-center justify-center"
                            >
                              <div class="w-[150px] border-b border-gray-100 h-[75px] flex items-center justify-center flex-col gap-2 cursor-pointer">
                                <div class="flex gap-3">
                                  <img v-if="col.action === 'modifyAction'" @click="$emit('action-triggered', { action: col.action, item: item })" width="16" height="16" src="https://img.icons8.com/pastel-glyph/64/pencil--v1.png" alt="new-post"/>
                                  <img v-if="col.action === 'downloadAction'" @click="$emit('action-triggered', { action: col.action, item: item })" width="16" height="16" src="https://img.icons8.com/metro/26/download.png" alt="new-post"/>
                                </div>
                              </div>
                            </div>
                            <div v-else
                                 class="w-full h-full flex items-center justify-center text-[11px] bg-white text-gray-500 font-medium cursor-pointer"
                                 @click="col.label === 'FULL NAME' ? selectPerson(item) : ''"
                            >
                                {{ col.prefix ?? '' }}{{ parseType(getNestedValue(item, col.key), col.type) }}{{ col.suffix ?? '' }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-between items-center h-[45px] w-full border-t border-gray-100">
                    <div class="w-1/2">
                        <div
                            v-click-outside="closeSwapLimit"
                            @click="swapLimitIsOpen = !swapLimitIsOpen"
                            class="relative max-w-[80px] w-full flex items-center justify-center gap-2 h-[45px] border-r border-gray-100 hover:bg-gray-50 cursor-pointer rounded-bl-lg duration-300"
                        >
                            <div class="flex items-center justify-center gap-1.5 ml-2">
                                <span class="text-[10px] font-medium text-gray-500">{{ limit }}</span>
                                <chevron-down-icon
                                    size="10px"
                                    fill="#6b7280"
                                />
                            </div>
                            <transition
                                enter-active-class="transition ease-out duration-200"
                                enter-from-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="transition ease-out duration-100"
                                leave-from-class="opacity-100"
                                leave-to-class="opacity-0"
                            >
                                <div v-if="swapLimitIsOpen" class="absolute h-fit w-[80px] top-0 mt-[50px] rounded-b-lg rounded-tl-lg bg-white">
                                    <div
                                        v-for="allowedLimit in allowedLimits"
                                        @click="updateLimit(allowedLimit)"
                                        class="flex items-center justify-center h-[30px] w-full text-[10px] hover:bg-gray-50 duration-500 text-gray-500 font-medium"
                                    >
                                        {{ allowedLimit }}
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="flex border-l border-gray-100">
                        <div
                            v-if="canPrev"
                            @click="switchPage('prev')"
                            class="w-[45px] flex items-center justify-center h-[45px] border-r border-gray-100 hover:bg-gray-50 cursor-pointer rounded-br-lg duration-300"
                        >
                            <chevron-left-icon
                                size="14px"
                                fill="#6b7280"
                            />
                        </div>
                        <div class="w-[60px] flex items-center justify-center h-[45px] border-r border-gray-100 hover:bg-gray-50 cursor-pointer rounded-br-lg duration-300">
							<span class="text-gray-500 text-[10px]">
					    			Page {{ page }}
							</span>
                        </div>
                        <div
                            v-if="canNext"
                            @click="switchPage('next')"
                            class="w-[45px] h-[45px] flex items-center justify-center hover:bg-gray-50 cursor-pointer rounded-br-lg duration-300"
                        >
                            <chevron-right-icon
                                size="14px"
                                fill="#6b7280"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EyeIcon from "../../assets/icons/EyeIcon.vue";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import ChevronLeftIcon from "../../assets/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon.vue";

export default
{
    emits: ['reload', 'update-limit', 'switch-pages', 'action-triggered'],

    props: {
        title: {
            type: String,
            default: 'Base Table',
        },
        dataset: {
            type: Array,
            default: [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Array,
            default: []
        },
        total: {
            type: Number,
            default: 0,
        },
        basePath: {
            type: String,
            default: '/ibv/'
        },
        idKey: {
            type: String,
            default: 'id'
        },
    },

    data() {
        return {
            isOpen: true,
            allowedLimits: [5, 10, 25, 50, 75, 100, 150, 175, 200, 250, 500],
            swapLimitIsOpen: false,
            limit: 50,
            page: 1,
            sortColumn: '',
            sortOrder: 'asc'
        }
    },

    components: {
        EyeIcon,
        ChevronRightIcon,
        ChevronLeftIcon,
        LoaderAnimation,
        ReloadIcon,
        ChevronDownIcon
    },

    watch: {
        '$route.query': {
            handler: function(query) {
                console.log(query);
                this.limit = query?.limit;
            },
            deep: true,
            immediate: true
        }
    },

    computed: {
        canNext() {
            return (parseInt(this.page) * parseInt(this.limit)) < parseInt(this.total)
        },

        canPrev() {
            return this.page > 1;
        },
    },

    methods: {
        switchPage(type) {
            if (['next', 'prev'].includes(type)) {
                const queryParams = this.$route.query;
                queryParams.page = type === 'next' ? parseInt(this.$route.query.page) + 1 : parseInt(this.$route.query.page) - 1;
                this.page = queryParams.page;
                this.$router.push({path: this.$route.path, query: queryParams });

                this.reload();
            }
        },

        reload() {
            this.$emit('reload', this.$route.query);
        },

        closeSwapLimit() {
            this.swapLimitIsOpen = false;
        },

        updateLimit(limit) {
            const queryParams = this.$route.query;
            queryParams.limit = limit;
            queryParams.page = 1;
            this.limit = limit;
            this.page = 1;
            this.$router.replace({path: this.$route.path, query: queryParams });

            this.reload();
        },

        parseType(value, type) {
            switch (type)
            {
                case 'date':
                    return this.formatDate(value);
                default:
                    return value;
            }
        },

        getNestedValue(obj, key) {
            const keyParts = key.split('.');
            const value = keyParts.reduce((accumulator, currentKey) => {
                return accumulator ? accumulator[currentKey] : undefined;
            }, obj);
            return value;
        },

        formatDate(value) {
            if (!value) {
                return '-';
            }

            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear());
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        },

        selectPerson(item) {
            const id = item[this.idKey];
            window.location.href = `${this.basePath}${id}`;
        },

        sortData(column) {
            const columnConfig = this.config.find(col => col.key === column);
            const sortType = columnConfig ? columnConfig.type : 'string';

            if (this.sortColumn === column) {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortColumn = column;
                this.sortOrder = 'asc';
            }

            this.dataset = this.dataset.sort((a, b) => {
                let valA = a[column];
                let valB = b[column];

                if (sortType === 'number') {
                    valA = Number(valA);
                    valB = Number(valB);
                } else if (sortType === 'date') {
                    valA = new Date(valA).getTime();
                    valB = new Date(valB).getTime();
                }

                if (valA < valB) return this.sortOrder === 'asc' ? -1 : 1;
                if (valA > valB) return this.sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        },
    },

    mounted() {
        const queryParams = this.$route.query;
        const currentUrl = window.location.href;
        let newUrl = new URL(currentUrl);

        if (!('start_at' in queryParams) || !('end_at' in queryParams)) {
            const currentDate = new Date();
            const startOfDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                0,
                0,
                0
            );
            const endOfDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                23,
                59,
                59
            );

            queryParams.start_at = startOfDay.toISOString();
            queryParams.end_at = endOfDay.toISOString();
            newUrl.searchParams.set('start_at', startOfDay.toISOString());
            newUrl.searchParams.set('end_at', endOfDay.toISOString());
        } else {
            this.start_at = queryParams.start_at;
            this.end_at = queryParams.end_at;
        }

        if (!('page' in queryParams)) {
            newUrl.searchParams.set('page', "1");
            queryParams.page = 1;
            this.page = 1;
        } else {
            this.page = queryParams.page
        }

        if (!('limit' in queryParams)) {
            newUrl.searchParams.set('limit', "50");
            queryParams.limit = 50;
            this.limit = 50;
        } else {
            this.limit = queryParams.limit
        }

        this.$router.replace({path: this.$route.path, query: queryParams });
        window.history.pushState({}, '', newUrl.toString());

        this.reload();
    }
}
</script>

<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 0px
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
}

::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
</style>