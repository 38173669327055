<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M16.536,4.636C16.339,4.832,13.805,7.367,12,9.172c-1.805-1.805-4.339-4.339-4.536-4.536	c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C4.832,7.661,7.367,10.195,9.172,12c-1.805,1.805-4.339,4.339-4.536,4.536	c-0.781,0.781-0.781,2.047,0,2.828c0.781,0.781,2.047,0.781,2.828,0C7.606,19.222,19.222,7.606,19.364,7.464	c0.781-0.781,0.781-2.047,0-2.828C18.583,3.855,17.317,3.855,16.536,4.636z" opacity=".75"/><path d="M19.364,19.364c0.781-0.781,0.781-2.047,0-2.828c-0.196-0.196-2.731-2.731-4.536-4.536L12,14.828	c1.805,1.805,4.339,4.339,4.536,4.536C17.317,20.145,18.583,20.145,19.364,19.364z"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
