
<template>
    <div class="custom-shadow-0 | mb-[24px] w-full h-fit bg-white rounded-lg">
        <header class="h-[50px] w-full flex justify-between bg-transparent border-b border-gray-100">
            <div class="flex items-center h-full justify-start w-1/3 pl-[14pt] text-[12px] font-light tracking-wide text-gray-600">
                {{ title }}
            </div>
            <div class="w-2/3 flex items-center justify-end">
                <div v-if="btn == 1"
                     @click="updateParam('ibv')"
                     class="border-l w-[50px] flex items-center justify-center h-[50px] border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg"
                >
                    <send-arrow-icon
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
                <div v-if="btn == 1"
                     @click="updateParam('started')"
                     class="border-l w-[50px] flex items-center justify-center h-[50px] border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg"
                >
                    <dashboard-client-icon
                        :class="isLoading && 'animate-spin'"
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
                <div
                    @click="reload()"
                    class="border-l w-[50px] flex items-center justify-center h-[50px] border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg"
                >
                    <reload-icon
                        :class="isLoading && 'animate-spin'"
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
                <div
                    @click="isOpen = !isOpen"
                    class="border-l w-[50px] flex items-center justify-center h-[50px] border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg"
                >
                    <chevron-down-icon
                        :class="[isOpen ? '' : 'rotate-90', 'duration-500']"
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
            </div>
        </header>
        <div v-if="isOpen">
            <div
                v-if="isLoading"
                class="flex items-center h-[100px] justify-center"
            >
                <loader-animation
                    size="24px"
                    border="2.5px solid rgb(209 213 219)"
                />
            </div>
            <div
                v-else-if="dataset.length === 0 && !isLoading"
                class="flex h-[100px] text-[10.5px] items-center justify-center text-xs text-gray-400"
            >
                No Data Available...
            </div>
            <div
                v-else
                class="flex flex-col"
            >
                <div
                    ref="scrollableContainer"
                    @scroll="handleScroll"
                    class="relative overflow-x-auto scroll-smooth scroll max-h-[750px]"
                >
                    <div class="flex bg-gray-100 sticky top-0 z-10 w-fit">
                        <div class="min-w-[240px] left-0 sticky flex items-center justify-center text-[11px] bg-gray-100 text-gray-500 font-medium">
                            <div v-if="$route.name !== 'ClientsPage'" class="h-[50px] min-w-[75px] flex items-center justify-center text-[10px] bg-gray-100 text-gray-500 uppercase"></div>
                            <div class="min-w-[150px] h-[50px] flex items-center justify-center text-[10px] bg-gray-100 text-gray-500 uppercase">Client</div>
                            <div class="min-w-[150px] h-[50px] flex items-center justify-center text-[10px] bg-gray-100 text-gray-500 uppercase">Contact</div>
                            <div :class="[DynamicScrollingShadow ? 'from-gray-100 bg-gradient-to-r to-white-100' : 'bg-white-white group-hover:bg-gray-50', 'border-b border-gray-100 bg-gradient-to-l to-white duration-500 w-[5px] h-[50px]']"></div>
                        </div>

                        <div v-for="col in config">
                            <div v-if="!['ID', 'FIRST NAME', 'LAST NAME'].includes(col.label)"
                                 :style="`min-width: ${col.min_w ?? '0px'}; max-width: ${col.max_w ?? '100%'}`"
                                 class="w-full h-[50px] flex items-center justify-center text-[10px] bg-gray-100 text-gray-500 font-medium">
                                {{ col.label }}
                            </div>
                        </div>
                    </div>
                    <!-- Filters -->
                    <div class="flex bg-white top-0 z-10 w-fit border-b-2 border-gray-100">
                        <div class="min-w-[305px] w-full h-[60px] left-0 sticky flex items-center justify-center text-[11px] bg-white text-gray-500 font-medium">
                            <div v-if="$route.name !== 'ClientsPage'" class="min-w-[75px] h-[60px] flex items-center justify-center text-[10px] bg-white text-gray-500 font-medium">
                                <input type="text" placeholder="filter..." class="tracking-wide rounded-[6px] px-[10px] text-[10.5px] focus:outline-0 focus:ring-0 w-full bg-gray-100 mx-[6px] h-[38px]">
                            </div>
                            <div class="min-w-[150px] h-[60px] flex items-center justify-center text-[10px] bg-white text-gray-500 font-medium">
                                <input type="text" placeholder="filter..." class="tracking-wide rounded-[6px] px-[10px] text-[10.5px] focus:outline-0 focus:ring-0 w-full bg-gray-100 mx-[6px] h-[38px]">
                            </div>
                            <div class="min-w-[150px] h-[60px] flex items-center justify-center text-[10px] bg-white text-gray-500 font-medium">
                                <input type="text" placeholder="filter..." class="tracking-wide rounded-[6px] px-[10px] text-[10.5px] focus:outline-0 focus:ring-0 w-full bg-gray-100 mx-[6px] h-[38px]">
                            </div>
                            <div :class="[DynamicScrollingShadow ? 'from-gray-200 bg-gradient-to-r to-gray-100' : 'bg-white-white group-hover:bg-gray-50', 'border-b border-gray-100 bg-gradient-to-l to-white duration-500 w-[5px] h-[60px]']"></div>
                        </div>

                        <div v-for="col in config">
                            <div v-if="!['ID', 'FIRST NAME', 'LAST NAME', 'NOTE'].includes(col.label)"
                                 :style="`min-width: ${col.min_w ?? '0px'}; max-width: ${col.max_w ?? '100%'}`"
                                 class="w-full h-[60px] flex items-center justify-center text-[10px] bg-white text-gray-500 font-medium"
                            >
                                <input @keyup="AddFilter($event, col.key)" type="text" placeholder="filter..." class="tracking-wide rounded-[6px] px-[10px] text-[10.5px] focus:outline-0 focus:ring-0 w-full bg-gray-100 mx-[6px] h-[38px]">
                            </div>
                        </div>
                    </div>
                    <!-- Body -->
                    <div
                        v-for="item in filteredData"
                        :key="item.id"
                        class="flex hover:bg-gray-100 duration-500 group w-fit"
                        @contextmenu.prevent="showContextMenu(item, $event)"
                    >
                        <div class="left-0 flex sticky bg-white duration-500 group-hover:bg-gray-50 items-center justify-center text-[11px] text-gray-500 font-medium">
                            <div v-if="Object.keys(item).includes('id') && $route.name !== 'ClientsPage'" class="w-[75px]">
                                <div class="w-[75px] h-[75px] border-b border-gray-100 left-0 flex items-center justify-center text-[11px] text-gray-500 font-medium">
                                    <img :src="BaseURL + item.lender.image_src" alt="logo" width="25px" class="rounded-lg"/>
                                </div>
                            </div>
                            <div @click="rowClick(item.id)" v-if="Object.keys(item.client ?? item).includes('first_name')">
                                <div class="min-w-[150px] h-[75px] flex flex-col border-b border-gray-100 gap-1 duration-500 cursor-pointer items-center justify-center text-[11px] text-gray-500 font-medium">
                                    <div>
                                        <span class="text-blue-600" v-if="item.client">{{ item.client.first_name }} {{ item.client.last_name }}</span>
                                        <span class="text-blue-600" v-else>{{ item.first_name }} {{ item.last_name }}</span>
                                    </div>
                                    <span v-if="['LeadsPage', 'Dashboard'].includes($route.name)">
                                        <span class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-[10px] text-gray-700 ring-1 ring-inset ring-blue-700/10">{{ item.amount }} $</span>
                                    </span>
                                </div>
                            </div>
                            <div class="w-[150px] border-b border-gray-100 h-[75px] flex items-center justify-center flex-col gap-2 cursor-pointer">
                                <div class="flex gap-3">
                                    <img width="16" height="16" src="https://img.icons8.com/plumpy/24/chat-message.png" alt="chat-message"/>
                                    <img width="16" height="16" src="https://img.icons8.com/plumpy/24/phone.png" alt="phone"/>
                                    <img @click="OpenEmailModal(item.client ? item.client : item)" width="16" height="16" src="https://img.icons8.com/plumpy/24/new-post.png" alt="new-post"/>
                                </div>
                                <span v-if="['LeadsPage', 'Dashboard'].includes($route.name)">
                                    <span class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-[10px] text-gray-700 ring-1 ring-inset ring-blue-700/10">
                                        {{ `~${Math.floor(Math.random() * 24) + 1}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}` }}
                                    </span>
                                </span>
                            </div>
                            <div :class="[DynamicScrollingShadow ? 'from-gray-200 bg-gradient-to-l to-white bg-opacity-50' : 'bg-white-white group-hover:bg-gray-50', 'border-b border-gray-100 bg-gradient-to-r to-white duration-500 w-[5px] h-[75px]']"></div>
                            <div v-if="Object.keys(item.client ?? item).includes('last_name') || Object.keys(item).includes('id')"></div>
                        </div>
                        <div v-for="(col, index) in config">
                            <div v-if="!['ID', 'FIRST NAME', 'LAST NAME', 'STATUS', 'RESPONSIBLE'].includes(col.label)"
                                 :key="index"
                                 :style="`min-width: ${col.min_w ?? '0px'}; max-width: ${col.max_w ?? '100%'}`"
                                 :class="[col.type === 'custom' ? col.custom[item.type] : 'group-hover:bg-gray-50 duration-300']"
                                 class="w-full overflow-y-auto h-[75px] text-[10px] uppercase flex items-center cursor-pointer justify-center border-b border-gray-100 text-gray-500"
                            >
                                <div class="w-full h-[75px] group-hover:bg-gray-50 duration-500 flex items-center justify-center text-[11px] text-gray-500 font-medium">
                                    <span v-if="(col.label === 'IBV' || col.label === 'DOCUMENTS') && parseType(getNestedValue(item, col.key), col.type)=== 1" class="w-[16px] h-[16px] bg-green-600 rounded-full absolute flex items-center justify-center">
                                        <check-duo-icon size="8px" class="fill-white" />
                                    </span>
                                    <span v-if="(col.label === 'IBV' || col.label === 'DOCUMENTS') && (parseType(getNestedValue(item, col.key), col.type) === 0 || parseType(getNestedValue(item, col.key), col.type)=== '-')" class="w-[16px] h-[16px] bg-red-600 rounded-full absolute flex items-center justify-center">
                                        <cross-duo-icon size="8px" class="fill-white" />
                                    </span>
                                    <span v-if="col.label !== 'STATUS'" :class="[col.type === 'custom' && col.label !== 'IBV' ?  col.custom[parseType(getNestedValue(item, col.key), col.type ?? '')] : 'group-hover:bg-gray-50 duration-500']">
                                        {{ col.prefix ?? '' }}
                                        {{ parseType(getNestedValue(item, col.key), col.type) }}
                                        {{ col.suffix ?? '' }}
                                    </span>
                                    <span v-if="col.label === 'NOTE'">
                                        <message-duo-icon
                                            @click="viewAllModal(item.id, index)"
                                            class="h-8 fill-gray-600 hover:fill-gray-400 transition-colors w-6"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div
                                v-else-if="col.label === 'STATUS'"
                                :style="`min-width: ${col.min_w ?? '0px'}; max-width: ${col.max_w ?? '100%'}`"
                                :class="[col.type === 'custom' ? col.custom[item.type] : 'group-hover:bg-gray-50 duration-300']"
                                class="w-full overflow-y-auto h-[75px] text-[10px] uppercase flex items-center cursor-pointer justify-center border-b border-gray-100 text-gray-500"
                            >
                                <div class=" w-full h-[75px] group-hover:bg-gray-50 duration-500 flex items-center justify-center text-[11px] text-gray-500 font-medium">
                                    <select
                                        class="border-none text-[11px] text-center focus:ring-0 focus:outline-0 bg-transparent"
                                        :class="[col.type === 'custom' ? col.custom[item.lead_status.lead_status_name] : 'group-hover:bg-gray-50 duration-300']"
                                        @change="$emit('update-status', $event, item.id)"
                                    >
                                        <option
                                            v-for="status in LeadStatuses"
                                            :value="status.id"
                                            :selected="status.lead_status_name === parseType(getNestedValue(item, col.key), col.type)"
                                        >
                                            {{ status.lead_status_name.replace(/_/g, " ") }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                          <div
                              v-else-if="col.label === 'RESPONSIBLE'"
                              :style="`min-width: ${col.min_w ?? '0px'}; max-width: ${col.max_w ?? '100%'}`"
                              :class="[col.type === 'custom' ? col.custom[item.type] : 'group-hover:bg-gray-50 duration-300']"
                              class="w-full overflow-y-auto h-[75px] text-[10px] uppercase flex items-center cursor-pointer justify-center border-b border-gray-100 text-gray-500"
                          >
                            <div class=" w-full h-[75px] group-hover:bg-gray-50 duration-500 flex items-center justify-center text-[11px] text-gray-500 font-medium">
                              <select
                                  class="border-none text-[11px] text-center focus:ring-0 focus:outline-0 bg-transparent"
                                  @change="$emit('update-responsible', $event, item.id)"
                              >
                                <option
                                    v-for="responsible in ContextUsers"
                                    :value="responsible.id"
                                    :selected="responsible.first_name === parseType(getNestedValue(item, col.key), col.type)"
                                >
                                  {{ responsible.first_name.replace(/_/g, " ") }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div v-if="isContextMenuVisible" v-click-oustide="CloseContextMenu" ref="contextMenu" class="context-menu rounded-lg text-slate-500 text-sm bg-amber-50 absolute" :style="{ top: contextMenuY + 'px', left: contextMenuX + 'px' }">
                        <div class="custom-shadow-1 | absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                            <div class="px-4 py-3" role="none">
                                <p class="truncate text-sm font-medium text-gray-900" role="none">{{ `${ContextMenuUser.client.first_name} ${ContextMenuUser.client.last_name}` }}</p>
                            </div>
                            <div class="py-1" role="none">
                                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Courriel Offre</a>
                                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Courriel Refus</a>
                                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Courriel Rappel IBV</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-between items-center h-[45px] w-full border-t border-gray-100">
                <div class="w-1/2">
                    <div
                        v-click-outside="closeSwapLimit"
                        @click="swapLimitIsOpen = !swapLimitIsOpen"
                        class="relative max-w-[80px] w-full flex items-center justify-center gap-2 h-[45px] border-r border-gray-100 hover:bg-gray-50 cursor-pointer rounded-bl-lg duration-300"
                    >
                        <div class="flex items-center justify-center gap-1.5 ml-2">
                            <span class="text-[10px] font-medium text-gray-500">{{ limit }}</span>
                            <chevron-down-icon
                                size="10px"
                                fill="#6b7280"
                            />
                        </div>
                        <transition
                            enter-active-class="transition ease-out duration-200"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition ease-out duration-100"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div v-if="swapLimitIsOpen" class="absolute h-fit w-[80px] top-0 mt-[50px] rounded-b-lg rounded-tl-lg bg-white">
                                <div
                                    v-for="allowedLimit in allowedLimits"
                                    @click="updateLimit(allowedLimit)"
                                    class="flex items-center justify-center h-[30px] w-full text-[10px] hover:bg-gray-50 duration-500 text-gray-500 font-medium"
                                >
                                    {{ allowedLimit }}
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="flex border-l border-gray-100">
                    <div
                        v-if="canPrev"
                        @click="switchPage('prev')"
                        class="w-[45px] flex items-center justify-center h-[45px] border-r border-gray-100 hover:bg-gray-50 cursor-pointer rounded-br-lg duration-300"
                    >
                        <chevron-left-icon
                            size="14px"
                            fill="#6b7280"
                        />
                    </div>
                    <div class="w-[60px] flex items-center justify-center h-[45px] border-r border-gray-100 hover:bg-gray-50 cursor-pointer rounded-br-lg duration-300">
							<span class="text-gray-500 text-[10px]">
								Page {{ page }}
							</span>
                    </div>
                    <div
                        v-if="canNext"
                        @click="switchPage('next')"
                        class="w-[45px] h-[45px] flex items-center justify-center hover:bg-gray-50 cursor-pointer rounded-br-lg duration-300"
                    >
                        <chevron-right-icon
                            size="14px"
                            fill="#6b7280"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import ChevronLeftIcon from "../../assets/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon.vue";
import ClientDuoIcon from "../../assets/icons/duotone/ClientDuoIcon.vue";
import AtSignIcon from "../../assets/icons/AtSignIcon.vue";
import AlternateEmailIcon from "../../assets/icons/AlternateEmailIcon.vue";
import MessageDuoIcon from "../../assets/icons/duotone/MessageDuoIcon.vue";
import SendArrowIcon from "../../assets/icons/SendArrowIcon.vue";
import DashboardClientIcon from "../../assets/icons/DashboardClientIcon.vue";
import CheckDuoIcon from "../../assets/icons/duotone/CheckDuoIcon.vue";
import CrossDuoIcon from "../../assets/icons/duotone/CrossDuoIcon.vue";
import CashIcon from "../../assets/icons/duotone/CashIcon.vue";
import CompanyIcon from "../../assets/icons/CompanyIcon.vue";
import EyeIcon from "../../assets/icons/EyeIcon.vue";
import DropdownCheckIcon from "../../assets/icons/DropdownCheckIcon.vue";
import PlayIcon from "../../assets/icons/PlayIcon.vue";
import KronosBaseTooltip from "../NotificationManager/KronosBaseTooltip.vue";
import {mapState} from "vuex";
import {ContextUser, Users} from "../../services/User";

export default
{
    emits: [
        'reload',
        'update-limit',
        'switch-page',
        'row-click',
        'update-status',
        'update-responsible',
        'view-all-modal'
    ],

    props: {
        title: {
            type: String,
            default: 'Base Table',
        },
        dataset: {
            type: Array,
            default: [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Array,
            default: []
        },
        total: {
            type: Number,
            default: 0,
        },
        btn: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            isOpen: true,
            allowedLimits: [5, 10, 25, 50, 75, 100, 150, 175, 200, 250, 500],
            swapLimitIsOpen: false,
            limit: 50,
            LeadStatusIsOpen: false,
            page: 1,
            isContextMenuVisible: false,
            contextMenuX: 0,
            contextMenuY: 0,
            DynamicScrollingShadow: false,
            ContextMenuUser: null,
            DynamicFilter: {},
        }
    },

    components: {
        CrossDuoIcon,
        CheckDuoIcon,
        DashboardClientIcon,
        SendArrowIcon,
        MessageDuoIcon,
        AlternateEmailIcon,
        AtSignIcon,
        ClientDuoIcon,
        ChevronRightIcon,
        ChevronLeftIcon,
        LoaderAnimation,
        ReloadIcon,
        ChevronDownIcon,
        PlayIcon,
        DropdownCheckIcon,
        EyeIcon,
        CompanyIcon,
        CashIcon,
        KronosBaseTooltip,
    },

    watch: {
        '$route.query': {
            handler: function(query) {
                console.log(query);
                this.limit = query?.limit;
            },
            deep: true,
            immediate: true
        }
    },

    computed: {
        ...mapState([
            'LeadStatuses',
            'ContextUsers'

        ]),

        filteredData() {
            return this.dataset.filter(item => {
                return this.matchesNestedKeys(item, this.DynamicFilter);
            });
        },

        canNext() {
            return (parseInt(this.page) * parseInt(this.limit)) < parseInt(this.total)
        },

        BaseURL() {
            return import.meta.env.VITE_BACKEND_BASE_URL + '/storage';
        },

        canPrev() {
            return this.page > 1;
        },
    },

    methods: {
      ContextUser,
      Users,
        AddFilter($event, col) {
            if (!['', null].includes($event.target.value)) {
                this.DynamicFilter[col] = $event.target.value;
            } else if (this.DynamicFilter.hasOwnProperty(col)) {
                delete this.DynamicFilter[col];
            }
        },

        matchesNestedKeys(item, filterObject) {
            return Object.entries(filterObject).every(([key, filterValue]) => {
                const keys = key.split('.');
                const value = keys.reduce((obj, nestedKey) => obj?.[nestedKey], item);
                return value && String(value).toLowerCase().includes(filterValue.toLowerCase());
            });
        },

        handleScroll() {
            this.DynamicScrollingShadow = this.$refs.scrollableContainer.scrollLeft > 0;
        },

        showContextMenu(item, event) {
            this.ContextMenuUser = item;
            event.preventDefault();
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.isContextMenuVisible = true;
            const handleClickOutside = (e) => {
                this.isContextMenuVisible = false;
            };

            document.addEventListener('click', handleClickOutside);
        },

        CloseContextMenu() {
            this.isContextMenuVisible = false;
        },

        rowClick(id) {
            this.$emit('row-click', id);
        },

        viewAllModal(id) {
            this.$emit('view-all-modal', id);
        },

        switchPage(type) {
            if (['next', 'prev'].includes(type)) {
                const queryParams = this.$route.query;
                queryParams.page = type === 'next' ? parseInt(this.$route.query.page) + 1 : parseInt(this.$route.query.page) - 1;
                this.page = queryParams.page;
                this.$router.push({path: this.$route.path, query: queryParams });

                this.reload();
            }
        },

        reload() {
            this.$emit('reload', this.$route.query);
        },

        updateParam(param) {
            const currentUrl = window.location.href;
            let newUrl = new URL(currentUrl);
            const queryParams = this.$route.query;
            queryParams[param] = 1;
            newUrl.searchParams.set('ibv', 0);
            newUrl.searchParams.set('started', 0);
            newUrl.searchParams.set(param, 1);

            window.history.pushState({}, '', newUrl.toString());
            this.$router.replace({path: this.$route.path, query: queryParams });
            window.location.reload()
        },

        closeSwapLimit() {
            this.swapLimitIsOpen = false;
        },

        updateLimit(limit) {
            const queryParams = this.$route.query;
            queryParams.limit = limit;
            queryParams.page = 1;
            this.limit = limit;
            this.page = 1;
            this.$router.replace({path: this.$route.path, query: queryParams });

            this.reload();
        },

        parseType(value, type) {
            switch (type) {
                case 'date':
                    return this.formatDate(value);
                default:
                    return value;
            }
        },

        getNestedValue(obj, key) {
            const keys = key.split('.');
            return keys.reduce((o, k) => (((o && o[k] !== undefined) || (o[k] !== "NOTE") ) ? o[k] : '...'), obj);
        },

        formatDate(value) {
            if (!value) {
                return '...';
            }

            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear());
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        },

        OpenEmailModal(item) {
            this.$store.state.GlobalEmailEditor.IsOpen = true;
            this.$store.state.GlobalEmailEditor.ContextClient = item;
        },
    },

    mounted() {
        const queryParams = this.$route.query;
        const currentUrl = window.location.href;
        let newUrl = new URL(currentUrl);

        if (!('start_at' in queryParams) || !('end_at' in queryParams)) {
            const currentDate = new Date();
            const startOfDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                0,
                0,
                0
            );
            const endOfDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                23,
                59,
                59
            );

            queryParams.start_at = startOfDay.toISOString();
            queryParams.end_at = endOfDay.toISOString();
            newUrl.searchParams.set('start_at', startOfDay.toISOString());
            newUrl.searchParams.set('end_at', endOfDay.toISOString());
        } else {
            this.start_at = queryParams.start_at;
            this.end_at = queryParams.end_at;
        }

        if (!('page' in queryParams)) {
            newUrl.searchParams.set('page', "1");
            queryParams.page = 1;
            this.page = 1;
        } else {
            this.page = queryParams.page
        }

        if (!('limit' in queryParams)) {
            newUrl.searchParams.set('limit', "50");
            queryParams.limit = 50;
            this.limit = 50;
        } else {
            this.limit = queryParams.limit
        }

        if (!('ibv' in queryParams)) {
            newUrl.searchParams.set('ibv', "0");
            queryParams.ibv = 0;
            this.ibv = 0;
        } else {
            this.ibv = queryParams.ibv
        }

        if (!('started' in queryParams)) {
            newUrl.searchParams.set('started', "0");
            queryParams.started = 0;
            this.started = 0;
        } else {
            this.started = queryParams.started
        }

        this.$router.replace({path: this.$route.path, query: queryParams });
        window.history.pushState({}, '', newUrl.toString());

        this.reload();
    }
}
</script>

<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 0px
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
}

::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
.context-menu {
    position: fixed;
    z-index: 9999;
    border: 1px solid #ccc;
    padding: 5px 0;
}

.context-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.context-menu li {
    padding: 5px 10px;
    cursor: pointer;
}

.context-menu li:hover {
    background-color: #f3f3f3;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    padding: 5px;
    width: 100%;
}
</style>
