<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M18,21H6c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h12c1.657,0,3,1.343,3,3v12	C21,19.657,19.657,21,18,21z" opacity=".35"/><path d="M10,18H7c-0.552,0-1-0.448-1-1v-3c0-0.552,0.448-1,1-1h3c0.552,0,1,0.448,1,1v3C11,17.552,10.552,18,10,18z"/><path d="M17,18h-3c-0.552,0-1-0.448-1-1v-3c0-0.552,0.448-1,1-1h3c0.552,0,1,0.448,1,1v3C18,17.552,17.552,18,17,18z"/><path d="M10,11H7c-0.552,0-1-0.448-1-1V7c0-0.552,0.448-1,1-1h3c0.552,0,1,0.448,1,1v3C11,10.552,10.552,11,10,11z"/><path d="M17,11h-3c-0.552,0-1-0.448-1-1V7c0-0.552,0.448-1,1-1h3c0.552,0,1,0.448,1,1v3C18,10.552,17.552,11,17,11z"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
