<template>
    <div class="custom-shadow-1 | overflow-hidden bg-white sm:rounded-lg mb-4">
        <div class="pl-[24px] h-[45px] flex items-center justify-between">
            <h3 class="text-[11.5px] leading-7 text-gray-900">Payment Calendar Details</h3>
            <div
                @click="IsOpen = !IsOpen"
                class="w-[45px] h-[45px] rounded-tr-lg cursor-pointer group hover:bg-gray-50 duration-500 flex items-center justify-center border-l border-gray-100"
            >
                <chevron-down-icon
                    size="14px"
                    :class="[IsOpen ? 'rotate-180' : '', 'duration-500 fill-gray-500']"
                />
            </div>
        </div>
        <div
            v-if="IsOpen"
        >
            <dl class="divide-y divide-x divide-gray-100 grid grid-cols-2">
                <div
                    v-for="(Details, Index) in PaymentCalendarDetailsData"
                    :key="Details"
                    :class="Index === 0 ? 'border-t border-gray-100' : ''"
                    class="px-4 h-[45px] sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 flex items-center"
                >
                    <dt class="text-[10.5px] font-medium text-gray-900">{{ Details.label }}:</dt>
                    <dd class="mt-1 text-[10.5px] leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ Details.value }}</dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script>
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";

export default
{
    components: {
        ChevronDownIcon
    },

    data() {
        return {
            IsOpen: true,
        }
    },

    props: {
        LoanCalendar: {
            type: Object,
            required: true,
        }
    },

    computed: {
        PaymentCalendarDetailsData() {
            return [
                {'label': 'Amount', 'value': this.LoanCalendar.base_amount.toFixed(3) + '$'},
                {'label': 'Number Of Payments', 'value': this.LoanCalendar.number_of_payments + ' (paid every ' + this.LoanCalendar.payment_frequency + 'days)'},
                {'label': 'Nominal Interest Rate', 'value': this.LoanCalendar.interest_rate.toFixed(3) + '%' + '(~' + (this.LoanCalendar.interest_rate/365).toFixed(3) + '%/day)'},
                {'label': 'Interest Start At', 'value': this.LoanCalendar.start_at},
                {'label': 'First Payment At', 'value': this.LoanCalendar.first_payment_at},
                {'label': 'Last Payment At', 'value': this.LoanCalendar.end_at},
                {'label': 'Periodic Payment Amount', 'value': this.LoanCalendar.payment_amount.toFixed(3)},
                {'label': 'Last Payment Amount:', 'value': this.LoanCalendar.loan_payments.at(-1).payment_amount.toFixed(3)},
            ]
        }
    }
}
</script>
