<template>
    <button
        type="button"
        :class="switched ? 'bg-theme-blue' : 'bg-gray-200'"
        class="relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
        role="switch"
        aria-checked="false"
    >
        <span class="sr-only">Use setting</span>
        <span
            aria-hidden="true"
            :class="this.switched ? 'translate-x-3' : 'translate-x-0'"
            class="translate-x-0 pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        ></span>
    </button>
</template>

<script>
export default
{
    props: {
        switched: {
            type: Boolean,
            default: true,
        }
    }
}
</script>

<style scoped>

</style>