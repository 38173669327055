import Api from '../Api.js';

const BaseURL = 'v1/client-phone';

export const RetrieveClientPhone = async (id) => {
    return await Api()
        .get(`${BaseURL}/${id}`);
}

export const RetrieveClientPhones = async (client_id) => {
    return await Api()
        .get(`${BaseURL}/client/${client_id}`);
}

export const StoreClientPhone = async (data) => {
    return await Api()
        .post(`${BaseURL}`, data);
}

export const UpdateClientPhone = async (id, data) => {
    return await Api()
        .put(`${BaseURL}/${id}`, data);
}

export const DestroyClientPhone = async (id) => {
    return await Api()
        .delete(`${BaseURL}/${id}`);
}
