<template>
    <transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 scale-100"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="ease-in duration-0"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-100"
    >
        <div
            v-if="isOpen"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-60 z-20 flex items-center justify-center"
        >
            <div
                v-click-outside="close"
                class="max-w-[800px] w-full h-fit bg-white rounded-lg relative"
            >
                <div class="border-b border-gray-100 h-[55px] flex items-center pl-[12pt] justify-between">
                    <h2 class="font-medium text-sm text-gray-700">
                        <span v-if="!showTasksList">Add Task</span>
                        <span v-if="showTasksList">Task List</span>
                    </h2>
                    <div class="flex flex-row">
                        <div
                            @click="showTasksList = !showTasksList"
                            class="border-l flex items-center justify-center border-gray-100 hover:bg-gray-50 duration-300 w-[55px] h-[53px] rounded-r-lg cursor-pointer text-[#6b7280]"
                        >
                            <img src="../../assets/icons/icons8-eye.png" height="14" width="14"/>
                        </div>
                        <div
                            @click="close"
                            class="border-l flex items-center justify-center border-gray-100 hover:bg-gray-50 duration-300 w-[55px] h-[53px] rounded-r-lg cursor-pointer"
                        >
                            <close-icon
                                size="14px"
                                fill="#6b7280"
                            />
                        </div>
                    </div>
                </div>
                <transition
                    enter-active-class="ease-out duration-500"
                    enter-from-class="opacity-0.5 scale-100"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="ease-out duration-0"
                    leave-from-class="opacity-100 scale-100"
                    leave-to-class="opacity-0 scale-100"
                >
                    <div v-if="!showTasksList" class="w-full flex items-center justify-center h-fit mt-5 mb-5">
                        <div class="w-1/2 flex flex-col gap-5">
                            <div class="relative">
                                <label class="text-[10px] text-gray-500 tracking-wide">
                                    Assign Task To :
                                </label>
                                <div
                                    @click="userOptionIsOpen = !userOptionIsOpen"
                                    class="bg-gray-50 border text-gray-700 flex items-center p-[10px] h-[40px]"
                                    :class="userOptionIsOpen ? 'rounded-t-[6px]' : 'rounded-[6px]'"
                                >
                                    <span
                                        v-if="userIsSelected"
                                        class="text-xs font-medium text-gray-500 tracking-wide"
                                    >
                                        {{ users[this.selectedUser]['first_name'] }}
                                    </span>
                                </div>
                                <div
                                    v-if="userOptionIsOpen"
                                    class="w-full rounded-b-[6px] text-xs font-medium text-gray-500 tracking-wide z-10 h-fit max-h-28 bg-gray-50 px-2 py-2 focus:outline-0 absolute top-0 mt-[63px] overflow-x-auto space-y-2 cursor-pointer"
                                >
                                    <div
                                        class="w-full flex flex-row gap-1 hover:font-semibold cursor-pointer"
                                        @click="selectedUser = index; userOptionIsOpen = false; userIsSelected = true"
                                        v-for="(user, index) in users"
                                        :key="user.id"
                                    >
                                        <span>{{ user.first_name }}</span>
                                        <span> {{ user.last_name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="h-full w-full">
                                <textarea
                                    class="max-h-full h-20 bg-gray-50 shadow-inner w-full text-xs tracking-wide font-medium text-gray-500 p-[10px] focus:outline-none"
                                    v-model="task" placeholder="Task..." >
                                </textarea>
                            </div>
                            <div class="w-full h-full">
                                <button
                                    @click="storeUserTask()"
                                    class="text-white uppercase border rounded-[6px] flex items-center justify-center w-full bg-[#4667d2] p-[10px] h-[40px]"
                                >
                                    done
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition
                    enter-active-class="ease-out duration-500"
                    enter-from-class="opacity-0.5 scale-100"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="ease-in duration-0"
                    leave-from-class="opacity-100 scale-100"
                    leave-to-class="opacity-0 scale-100"
                >
                    <div v-if="showTasksList" class="w-full flex flex-col items-center justify-center mt-10 mb-10 h-[500px]">
                        <div v-if="this.userTasks.length === 0">No tasks have been assigned by you yet</div>
                        <div v-else class="w-[90%] overflow-x-auto">
                            <div
                                class="w-full border rounded mb-2 text-sm p-[12px] flex flex-col gap-[12px]"
                                v-for="(task, index) in userTasks"
                                :key="task.id"
                                :class="[task.completed_at === null ? 'bg-red-100 text-red-900' : 'bg-blue-100 text-blue-900']"
                            >
                                <div class="w-full flex h-fit justify-end gap-2">
                                    <button
                                        class="group relative h-full w-full flex justify-end"
                                        @mouseenter="showChangeUserList = true; selectedUserTask = index"
                                        @mouseleave="showChangeUserList = false"
                                    >
                                        <img src="../../assets/icons/icons8-avatar-48.png" height="15" width="15"/>
                                        <div
                                            v-if="showChangeUserList || hoveringOverUserList"
                                            @mouseenter="hoveringOverUserList = true;"
                                            @mouseleave="hoveringOverUserList = false"
                                            :class="selectedUserTask === 1 || selectedUserTask === 0 ? 'top-0' : 'bottom-2'"
                                            class="hidden group-hover:flex absolute max-h-[200px] min-h-[100px] w-fit mr-2 bottom-2 items-center justify-center border rounded-lg bg-white px-3 space-y-2 flex-col tracking-wide overflow-x-auto"
                                        >
                                            <div
                                                @click="hoveringOverUserList = false; updateUserTask(selectedUserTask, user_index)"
                                                class="w-full flex flex-row gap-1 hover:font-semibold cursor-pointer text-gray-700"
                                                v-for="(user,user_index) in users"
                                                :key="user.id"
                                            >
                                                <span>{{ user.first_name }}</span>
                                                <span> {{ user.last_name }}</span>
                                            </div>
                                        </div>
                                    </button>
                                    <button
                                        class="group relative h-full w-fit flex justify-end"
                                        @mouseenter="viewNote = true; selectedNote = index"
                                        @mouseleave="viewNote = false"
                                    >
                                        <img src="../../assets/icons/icons8-view-48.png" height="15" width="15"/>
                                        <div
                                            v-if="viewNote"
                                            :class="selectedNote === 1 || selectedNote === 0 ? 'top-0' : 'bottom-2'"
                                            class="hidden group-hover:flex absolute mr-2 bottom-2 border rounded-lg bg-white tracking-wide flex-col p-[12px] text-gray-700 h-fit z-20"
                                            >
                                            <span class="w-fit font-semibold"> Note : </span>
                                            <span class="w-[200px] text-left"> {{task.note}}</span>
                                        </div>
                                    </button>
                                    <button @click="destroyUserTask(task.id)">
                                        <img src="../../assets/icons/icons8-delete-48.png" height="15" width="15"/>
                                    </button>
                                </div>
                                <div class="w-full flex justify-between font-semibold">
                                    <span>{{task.user.first_name}}</span>
                                    <span v-if="task.completed_at !== null">{{task.completed_at}} √ </span>
                                    <span v-else>To Be Completed ✕ </span>
                                </div>
                                <div class="w-full">
                                    {{task.task}}
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import CloseIcon from "../../assets/icons/CloseIcon.vue";
import NotificationMixin from "../../mixins/NotificationMixin";
import {Users} from "../../services/User";
import {DestroyUserTask, RetrieveUserTasks, StoreUserTask, UpdateUserTask} from "../../services/UserTask";
import {mapState} from "vuex";
export default {
    emits: ['close'],

    mixins: [
        NotificationMixin,
    ],

    components: {
        LoaderAnimation,
        CloseIcon,
    },

    computed: {
        ...mapState([
            'ContextUser',
        ]),
    },

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            users : [],
            userOptionIsOpen: false,
            selectedUser: 0,
            task:"",
            showTasksList: false,
            showChangeUserList: false,
            userTasks: [],
            userIsSelected: false,
            viewNote: false,
            selectedNote: 0,
            hoveringOverUserList: false,
            selectedUserTask: 0,
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },
        async retrieveUsers() {
            await Users()
                .then(response => {
                    this.users = response.data.data.users;
                    console.log(this.users)
                })
        },
        async retrieveUserTasks(manager_id) {
            await RetrieveUserTasks(manager_id , 'manager_id' )
                .then(response => {
                    this.userTasks = response.data.data.tasks;
                })
        },
        async storeUserTask() {
            if(!this.userIsSelected || !this.task) {
                this.showNotification('error','All Fields Must Be Completed')
            }
            else {
                let formData = new FormData();
                formData.append('task', this.task );
                formData.append('user_id', this.users[this.selectedUser]['id']);
                formData.append('manager_id', this.ContextUser.id);
                await StoreUserTask(formData)
                    .then(response => {
                        if(response.status === 200) {
                            this.showNotification('success', 'Task Stored Successfully')
                            this.userIsSelected = false;
                            this.task = null;
                            this.close()
                        }
                    })
                    .catch(error => {
                        if(error.response.status === 422) {
                            this.showNotification('error','All Fields Must Be Completed')
                        }
                        if(error.response.status === 403)
                        {
                            this.showNotification('error', 'You Cannot Assign A Task !')
                            this.close()
                        }
                    })
            }
        },
        async destroyUserTask(id) {
            await DestroyUserTask(id)
                .then(response => {
                    if(response.status === 200)
                    {
                        this.showNotification('success', 'Task Delete Successfully')
                        this.retrieveUserTasks(this.ContextUser.id);
                    }
                })
                .catch(error => {
                    if(error.response.status === 403)
                    {
                        this.close()
                    }
                    else {
                        this.showNotification('error', 'An Error Occurred While Deleting This Task !')
                    }
                })
        },
        async updateUserTask(index, user_index) {
            let user_id = this.users[user_index].id;
            let user_task_id = this.userTasks[index].id;

            await UpdateUserTask(user_task_id, {
                'user_id': user_id
            })
                .then(response => {
                    if(response.status === 200)
                    {
                        this.retrieveUserTasks(this.ContextUser.id);
                    }
                })
                .catch( e => {
                    this.showNotification('error', 'An Error Occurred While Updating This Task User!')
                })
        }
    },

    mounted() {
        this.retrieveUsers();
        this.retrieveUserTasks(this.ContextUser.id);
    }
}
</script>
