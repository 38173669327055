<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="size"
		:width="size"
		viewBox="0 0 24 24"
		:fill="fill"
	>
		<path d="M10.121,14.121c0.586-0.586,6.414-6.414,7-7c1.172-1.172,1.172-3.071,0-4.243	c-1.172-1.172-3.071-1.172-4.243,0c-0.586,0.586-6.414,6.414-7,7c-1.172,1.172-1.172,3.071,0,4.243	C7.05,15.293,8.95,15.293,10.121,14.121z" opacity=".35"/><path d="M5.879,14.121c0.586,0.586,6.414,6.414,7,7c1.172,1.172,3.071,1.172,4.243,0c1.172-1.172,1.172-3.071,0-4.243	c-0.586-0.586-6.414-6.414-7-7c-1.172-1.172-3.071-1.172-4.243,0C4.707,11.05,4.707,12.95,5.879,14.121z"/>
	</svg>
</template>

<script>
export default
{
	props: {
		fill: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '48px',
		}
	}
}
</script>
