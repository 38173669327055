<template>

<!--    <view-all-modal-->
<!--        :IsOpen="modalViewAll"-->
<!--        :Title="`General notes`"-->
<!--        :NotesData="ViewAllNotes"-->
<!--        :NotesType="NoteTypes[0]"-->
<!--        @IsClose="closeViewAllModal"-->
<!--    />-->

    <kronos-base-layout>
        <div class="flex flex-col gap-10">
            <kronos-base-heading title="Leads Page">
                <kronos-base-date-range-selector
                    :context-selected-dates="[$route?.query?.start_at, $route?.query?.end_at]"
                    @change-range="fetchData()"
                    @reload="fetchData()"
                >
                </kronos-base-date-range-selector>
            </kronos-base-heading>

            <approbation-stats
                v-if="this.$store.state.SelectedTeam === 0"
                @change="SelectedCardStats = $event; $forceUpdate()"
                :SelectedCardStats="SelectedCardStats"
                :counter="this.counter"
            />

            <lead-statistics-table
                :selected-filter="leadFilters[SelectedFilter]"
                :is-loading="LeadsStatisticsIsLoading"
                :data="leadStatistics"
                :total="leadCount"
                @reload="retrieveLeadStatistics()"
            >
                <div class="text-[12px] font-light tracking-wide">Lead Filtered By</div>
                <div
                    class="tracking-wide text-xs"
                    v-click-outside="CloseFilterSelect"
                >
                    <div
                        @click="leadFilterBySelectorIsVisible = !leadFilterBySelectorIsVisible"
                        class="w-fit flex gap-2 items-center justify-center cursor-pointer bg-gray-100 px-[12px] py-[4px] rounded-[6px]"
                    >
                        <span class="text-[10px] font-light text-[#6b7280] cursor-pointer">{{leadFilters[SelectedFilter]['filterByName']}}</span>
                        <chevron-down-icon
                            class="h-5 cursor-pointer"
                            size="10px"
                            fill="#6b7280"
                        />
                    </div>
                    <div
                        v-if="leadFilterBySelectorIsVisible"
                        class="custom-shadow-1 | min-w-[150px] cursor-pointer rounded h-fit bg-white border border-gray-100 absolute z-20 mt-2"
                    >
                        <div
                            @click="SelectedFilter = index;  leadFilterBySelectorIsVisible = false; retrieveLeadStatistics()"
                            v-for="(filter, index) in leadFilters"
                            :key="filter.id"
                            class="flex flex-col w-full px-3 py-2 space-y-2 hover:bg-gray-100 duration-500 cursor-pointer text-[11px] text-gray-600"
                        >
                            <span class="font-light text-[10px]">{{filter.filterByName}}</span>
                        </div>
                    </div>
                </div>
            </lead-statistics-table>

            <LeadsTable
                class="mb-[24px]"
                :is-loading="isLoading"
                :dataset="dataset"
            />

        </div>
    </kronos-base-layout>
</template>

<script>
import KronosBaseLayout from "../UI/Layouts/KronosBaseLayout.vue";
import {mapState} from "vuex";
import KronosBaseHeading from "../UI/BaseComponents/KronosBaseHeading.vue";
import KronosBaseDateRangeSelector from "../UI/BaseComponents/KronosBaseDateRangeSelector.vue";
import ChevronDownIcon from "../assets/icons/ChevronDownIcon.vue";
import KronosBaseFilterByTable from "../UI/BaseComponents/KronosBaseFilterByTable.vue";
import KronosBaseTable from "../UI/BaseComponents/KronosBaseTable.vue";
import ReloadIcon from "../assets/icons/ReloadIcon.vue";
import SettingsIcon from "../assets/icons/SettingsIcon.vue";
import CloseIcon from "../assets/icons/CloseIcon.vue";
import {LeadStatistics, RetrieveLeads, UpdateLead} from "../services/Lead";
import LeadStatisticsTable from "../components/Lead/LeadStatisticsTable.vue";
import notificationMixin from "../mixins/NotificationMixin";
import viewAllModal from "../components/Lead/LeadNote/modal/ViewAllModal.vue";
import NoteCard from "../components/Lead/LeadNote/card/NoteCard.vue";
import BaseTableContainer from "../UI/Tables/BaseTableExample.vue";
import LeadsTable from "../UI/Tables/LeadsTable.vue";
import Approbation from "../components/Dashboard/StatisticsCard/Approbation.vue";
import {RetrieveStatCardDashboard} from "../services/StatCard";

export default
{
    components: {
        LeadsTable,
        'approbation-stats': Approbation,
        BaseTableContainer,
        NoteCard,
        LeadStatisticsTable,
        CloseIcon,
        SettingsIcon,
        ReloadIcon,
        KronosBaseTable,
        KronosBaseFilterByTable,
        ChevronDownIcon,
        KronosBaseDateRangeSelector,
        KronosBaseHeading,
        KronosBaseLayout,
        viewAllModal
    },

    computed: {
        ...mapState([
            'ContextUser'
        ]),
        ...mapState('entities', ["NoteTypes"]),

        tableConfig() {
            return LeadsTable;
        },
    },

    mixins: [
        notificationMixin
    ],

    data() {
        return {
            isLoading: false,
            LeadsStatisticsIsLoading: false,
            dataset: [],
            ViewAllNotes: [],
            SelectedNote: {},
            total: 0,
            statistics: [],
            leadStatistics: [],
            leadCount: 0,
            leadFilterBySelectorIsVisible: false,
            SelectedFilter: 0,
            IsScrolling: false,
            leadFilters : [
                {id:1, filterByName:"Day of week", filterBy:"day_of_week",},
                {id:2, filterByName:"Month", filterBy:"month"},
                {id:3, filterByName:"Responsible", filterBy:"responsible"},
                {id:4, filterByName:"Amount requested", filterBy:"amount_requested"},
                {id:5, filterByName:"Lender", filterBy:"lender"},
                {id:6, filterByName:"Source", filterBy:"source"},
            ],
            modalViewAll: false,
            SelectedCardStats: 'status',
            counter: [],
        }
    },

    methods: {
        openViewAllModal(leadId) {
            this.modalViewAll = true;
            this.$store.state.lead_id = leadId
        },
        closeViewAllModal() {
            this.ViewAllNotes = [];
            this.modalViewAll = false;
        },
        UpdateLeadStatus($event, lead_id) {
            UpdateLead(lead_id, {
                'status_id': $event.target.value
            })
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Status Updated Successfully')
                    }
                })
        },
        UpdateResponsible($event, lead_id) {
            UpdateLead(lead_id, {
                'user_id': $event.target.value
            })
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Status Updated Successfully')
                    }
                })
        },
        async fetchData() {
            const data = this.$route.query;
            let start_at = new Date();

            console.log('test0');
            if (data?.start_at && data?.end_at && data?.page && data?.limit) {

            }

            console.log('test1');
            this.isLoading = true;
            this.dataset = [];
            await RetrieveLeads('', data.page, data.limit)
                .then(response => {
                    this.dataset = response.data.data.leads;
                    this.total = response.data.data.total;
                })

            this.isLoading = false;
        },
        CloseFilterSelect() {
            this.leadFilterBySelectorIsVisible = false;
        },
        redirectToLeadPage(leadId) {
            window.location.href = `/lead/${leadId}`;
        },
        async retrieveLeadStatistics() {
            this.LeadsStatisticsIsLoading = true;
            const data = this.$route.query;
            if (data?.start_at && data?.end_at) {
                this.leadStatistics = [];
                await LeadStatistics(this.leadFilters[this.SelectedFilter]['filterBy'], data.start_at, data.end_at)
                    .then(response => {
                        this.leadStatistics = response.data.data.leads;
                        this.leadCount = response.data.data.total;
                    })
            }
            this.LeadsStatisticsIsLoading = false;
        },
        diff(prev, current) {
            return ((current - prev) / Math.abs(prev === 0 ? 1 : prev)) * 100;
        },
        RetrieveStatCardDashboard() {
            RetrieveStatCardDashboard('dashboard', this.ContextUser.account_id)
                .then((response) => {
                    this.counter = Object.values(response.data.data.stat_card);
                })
        }
    },

    mounted() {
        this.retrieveLeadStatistics();
        this.RetrieveStatCardDashboard();
    },
}
</script>
