import api from "./Api";

const BaseURL = 'v1/forgot-password';

export const ForgotPassword = async (data) => {
	return await api()
		.post(`${BaseURL}`, data);
}

export const ValidateCode = async (code, email) => {
	return await api().post(`${BaseURL}/code/validate`, {
		code: code,
		email: email
	});
}

export const UpdatePassword = async (email, newPassword, confirmPassword) => {
	return await api().post(`${BaseURL}/update-password`, {
		email: email,
		password: newPassword,
		password_confirmation: confirmPassword
	});
};

export const ResendCode = async (data) => {
	return await api().post(`${BaseURL}/code/resend`, data);
}
