export const ClientsTable = [
	{
		'label': 'ID',
		'key': 'id',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'FIRST NAME',
		'key': 'first_name',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'LAST NAME',
		'key': 'last_name',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'ADDRESS',
		'key': 'address',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'BIRTH DAY',
		'key': '',
		'type': 'date',
		'min_w': '200px',
	},
	{
		'label': 'CITY',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'ZIP',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'PROVINCE',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'COUNTRY',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'EMAIL',
		'key': 'email',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'PHONE',
		'key': 'email',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'OCCUPATION',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'NAS',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'EMPLOYER',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'EMPLOYER PHONE',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'LANGUAGE',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
	{
		'label': 'LANGUAGE',
		'key': '',
		'type': 'string',
		'min_w': '200px',
	},
];
