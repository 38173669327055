<template>
	<div
		:class="SideNavigationIsOpen ? 'py-[11px] h-[24px]' : ''"
		class="flex flex-col items-start justify-center w-full px-[20px]"
	>
		<transition
			enter-active-class="ease-out duration-500"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="ease-in duration-0"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
	        <span
		        v-if="SideNavigationIsOpen"
		        class="mr-0 text-[9px] uppercase text-white tracking-wide font-semibold"
	        >
		        {{ label }}
	        </span>
		</transition>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default
{
	computed: {
		...mapState([
			'SideNavigationIsOpen',
		]),
	},

	props: {
		label: {
			type: String,
			default: 'Section',
		}
	}
}
</script>

