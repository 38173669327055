import Api from '../Api.js';

const BaseURL = 'v1/client';

export const RetrieveClient = async (id) => {
	return await Api()
		.get(`${BaseURL}/${id}`);
}

export const RetrieveClients = async () => {
	return await Api()
		.get(`${BaseURL}`);
}

export const StoreClient = async () => {
	return await Api()
		.post(`${BaseURL}`);
}

export const UpdateClient = async (id, payload) => {
	return await Api()
		.put(`${BaseURL}/${id}`, payload);
}

export const DestroyClient = async (id) => {
	return await Api()
		.delete(`${BaseURL}/${id}`);
}
