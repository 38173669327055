<template>
    <delete-modal
        :SelectedOpen="selectedNote"
        :IsOpen="modal.delete"
        @close="CloseModal"
        class="relative z-[99]"
        @update="update"
    />

    <update-modal
        :Note="selectedNote"
        :IsOpen="modal.update"
        @close="CloseModal"
        class="relative z-[99]"
        @update="update"
    />

    <kronos-base-task-modal
        :is-open="IsOpen && (!modal.update && !modal.delete)"
        :title="Title"
        @close="closeViewAll"
        :can-close="true"
        width="350px"
        :has-header="true"
        class="z-[60]"
        button-class="text-sm text-gray-500 border border-gray-500 rounded bg-white hover:bg-gray-200 h-full"
    >
        <NoteCard
            :NoteType="NotesType"
            :NoteData="ViewAllNotes"
            :Title="NotesType.label"
            :HeightCard="true"
            class="sm:w-[640px] md:w-[768px] lg:w-[1024px] xl:w-[1280px] 2xl:w-[1536px]"
            @update-modal="openUpdateModal"
            @delete-modal="openDeleteModal"
        />
    </kronos-base-task-modal>
</template>

<script>
import KronosBaseInformationCard from "../../../../UI/BaseComponents/KronosBaseInformationCard.vue";
import KronosBaseTaskModal from "../../../../UI/BaseComponents/KronosBaseTaskModal.vue";
import SettingsIcon from "../../../../assets/icons/SettingsIcon.vue";
import CrossDuoIcon from "../../../../assets/icons/duotone/CrossDuoIcon.vue";
import SendArrowIcon from "../../../../assets/icons/SendArrowIcon.vue";
import NoteCard from "../card/NoteCard.vue";
import UpdateModal from "./UpdateModal.vue";
import DeleteModal from "./DeleteModal.vue";
import {RetrieveLeadNotes, StoreLeadNote} from "../../../../services/LeadNote";
import NotificationMixin from "../../../../mixins/NotificationMixin";


export default {
    emits: ["IsClose", "update"],

    props: {
        IsOpen: {
            type: Boolean,
            default: false
        },
        Title: {
            type: String,
            default: ""
        },
        NotesData: {
            type: Array,
            required: true
        },
        NotesType: {
            type: Object,
            required: true
        }
    },

    mixins: [
        NotificationMixin
    ],

    components: {
        DeleteModal, UpdateModal,
        NoteCard,
        SendArrowIcon,
        CrossDuoIcon,
        SettingsIcon,
        KronosBaseTaskModal,
        KronosBaseInformationCard
    },

    data() {
        return {
            isLoading: false,
            selectedNote: {},
            notes: [],
            ViewAllNotes: [],
            modal: {
                update: false,
                delete: false,
            },
        };
    },

    methods: {
        closeViewAll() {
            this.$emit("IsClose");
        },

        openDeleteModal(data) {
            this.selectedNote = {id: data.id};
            this.modal.update = false;
            this.modal.delete = true;
        },

        openUpdateModal(data) {
            this.selectedNote = {
                id: data.id,
                note_type_id: data.note_type_id,
                note: data.note
            }
            this.modal.delete = false;
            this.modal.update = true
        },

        update() {
            this.$emit("update")
        },

        CloseModal()
        {
            this.modal.update = false;
            this.modal.delete = false;
        }
    },

    updated() {
        this.ViewAllNotes = this.NotesData;
    }
}
</script>
