import LeadPage from "../../pages/LeadPage.vue";
import LeadsPage from "../../pages/LeadsPage.vue";

export default [
	{
		path: '/lead/:id',
		component: LeadPage,
		name: 'LeadPage',
		meta: {
			type: 'private',
			authOnly: true,
		}
	},
	{
		path: '/leads',
		component: LeadsPage,
		name: 'leads-page',
		meta: {
			type: 'private',
			authOnly: true,
		}
	}
];
