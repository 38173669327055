<template>
    <div class="bg-transparent rounded-[4pt] h-[327px]">
        <div class="h-[50px] capitalize w-full border-b font-medium border-gray-100 tracking-wide text-[13px] text-[#6F6F6F] flex items-center pl-[18px]">
            {{ NoteType.label.toLowerCase() }}
        </div>
        <div class="w-full h-full">
            <div class="bg-[#F9F9F9] p-[16px] flex gap-[14px]">
                <div class="custom-shadow-1 | w-[34px] flex items-center justify-center h-[34px] bg-[#FCFCFC] rounded-full">
                    <div class="w-[26px] h-[26px] rounded-full bg-[#F2F2F2] text-[10.5px] flex items-center justify-center border border-gray-200 border-opacity-50 font-semibold text-gray-500">
                        {{ ContextUser.first_name[0] + ContextUser.last_name[0] }}
                    </div>
                </div>
                <text-field
                    :input-field="NoteData.note"
                    @submit="createLeadNote"
                    v-model:value="NoteData.note"
                />
            </div>
            <div class="flex flex-col h-[185px] bg-white">
                <div v-if="isLoading" class="flex justify-center items-center pt-3">
                    <loader-animation
                        border="1px solid black"
                    />
                </div>
                <div :class="[
                    HeightCard ? 'overflow-y-auto' : 'h-[185px]',
                    'overflow-y-auto flex-grow no-scrollbar'
                ]">
                    <div
                        v-if="LocalNoteData.length > 0 && !isLoading"
                        v-for="Note in LocalNoteData"
                        :key="Note.id"
                    >
                        <note
                            :Note="Note"
                            :ContextUser="ContextUser"
                            @update-action="modalUpdate(Note)"
                            @delete-action="modalDelete(Note)"
                        />
                    </div>
                    <p v-else-if="!isLoading" class="text-center py-5 px-3 text-[10.5px] text-gray-400 flex-grow capitalize">
                        {{ `No ${NoteType.label.toLowerCase()} notes written yet...` }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KronosBaseInformationCard from "../../../../UI/BaseComponents/KronosBaseInformationCard.vue";
import LoaderAnimation from "../../../../assets/animations/LoaderAnimation.vue";
import TextField from "../ui/TextField.vue";
import Note from "../ui/Note.vue";
import { mapState } from "vuex";
import {RetrieveLeadNotes, StoreLeadNote} from "../../../../services/LeadNote";
import NotificationMixin from "../../../../mixins/NotificationMixin";

export default {
    emits: ["view-all-modal", "update-modal", "delete-modal", "heightCard"],

    components: {
        TextField,
        LoaderAnimation,
        KronosBaseInformationCard,
        Note
    },

    props: {
        ViewAll: {
            type: Boolean,
            default: false
        },
        NoteType: {
            type: Object,
            required: true
        },
        Title: {
            type: String,
            default: ""
        },
        NoteData: {
            type: Array,
            required: true
        },
        HeightCard: {
            type: Boolean,
            default: false
        }
    },

    mixins: [
        NotificationMixin
    ],

    data() {
        return {
            isLoading: false,
            LocalNoteData: [],
            submit: {}
        };
    },

    computed: {
        ...mapState([
            'ContextLead',
            'ContextUser'
        ]),
        ...mapState('entities', ["NoteTypes"]),
    },

    methods: {
        async getLeadNotes() {
            this.isLoading = true;

            await RetrieveLeadNotes(this.$route.params.id || this.$store.state.lead_id, this.NoteType.value)
                .then((response) => {
                    if (response.status === 200) {
                        this.LocalNoteData = response.data.data.lead_notes;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        async createLeadNote() {
            if (!this.isLoading && this.NoteData.note.trim() !== "") {
                this.isLoading = true;

                this.submit = {
                    lead_note: {
                        note_type_id: this.NoteType.value,
                        note: this.NoteData.note,
                        lead_id: this.$store.state.lead_id
                    }
                }

                await StoreLeadNote(this.submit)
                    .then((response) => {
                        if (response.status === 200) {
                            this.showNotification('success', "Note created successfully.");
                            this.getLeadNotes();
                            this.NoteData.note = ""
                        }
                    })
                    .catch(() => {
                        this.showNotification('error', "Error creating lead note");
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },

        modalViewAll() {
            this.$emit(
                'view-all-modal',
                {
                    data: this.LocalNoteData,
                    selected: {
                        label: this.NoteType.label,
                        value: this.NoteType.value
                    }
                }
            );
        },
        modalUpdate(Note) {
            this.$emit(
                'update-modal',
                {
                    id: Note.id,
                    note_type_id: this.NoteType.value,
                    note: Note.note
                }
            );
        },
        modalDelete(Note) {
            this.$emit(
                'delete-modal',
                {
                    id: Note.id
                }
            );
        }
    },

    mounted() {
        this.getLeadNotes()
    }
}
</script>
