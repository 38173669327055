<template>
	<div class="w-full h-screen max-h-[550px] bg-white rounded-lg">
		<header class="h-[50px] w-full flex justify-between bg-transparent border-b border-gray-100">
			<div class="flex items-center h-full justify-start w-1/3 pl-[14pt] text-sm font-medium text-gray-600">
				Filter By
			</div>
			<div class="w-2/3 flex items-center justify-end">
				<div class="border-l w-[50px] flex items-center justify-center h-full border-gray-100 cursor-pointer hover:bg-gray-50 duration-500 rounded-tr-lg">
					<chevron-down-icon
						size="14px"
						fill="gray"
					/>
				</div>
			</div>
		</header>
	</div>
</template>

<script>
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";

export default
{
	components: {
		ChevronDownIcon
	}
}
</script>