<template>
    <form
        @submit.prevent="submit"
        @keyup.enter.exact="submit"
        class="relative flex-auto custom-shadow-0"
    >
        <div class="overflow-hidden">
            <textarea
                :maxlength="CharLimit"
                rows="2"
                name="comment"
                id="comment"
                v-model="ParsedField"
                @input="UpdateLiveValue"
                :class="[
                    error ? 'text-red-600 bg-red-100' : 'text-gray-500 bg-white',
                    'text-[10.5px] block w-full placeholder:text-gray-300 no-scrollbar rounded-[4pt] resize-none border-0 h-[60px] p-[8px] focus:outline-none '
                ]"
                style="resize: none"
                placeholder="Add your note..."
            />
        </div>
    </form>
</template>

<script>
import SendArrowIcon from "../../../../assets/icons/SendArrowIcon.vue";
import LoaderAnimation from "../../../../assets/animations/LoaderAnimation.vue";

export default {
    emits: [
        "update",
        "submit",
        "update:value"
    ],

    components: {
        LoaderAnimation,
        SendArrowIcon
    },

    props: {
        inputField: {
            type: String,
            default: ""
        },
    },

    data() {
        return {
            ParsedField: '',
            CharLimit: 254,
        }
    },

    computed: {
        error() {
            return this.ParsedField.length >= this.CharLimit + 1;
        }
    },

    methods: {
        submit() {
            if (this.ParsedField.trim().length !== 0) {
                this.$emit("submit", this.ParsedField);
                this.ParsedField.trim();
            }
            this.ParsedField = ''
        },

        UpdateLiveValue(event) {
            this.$emit('update:value', event.target.value);
        }
    },

    mounted() {
        this.ParsedField = this.inputField;
    }
}
</script>

<style scoped>
textarea {
    box-sizing: border-box;
    border-radius: 4pt;
    resize: none;
}
</style>
