<template>
    <div class="custom-shadow-0 | flex flex-col gap-[20px] bg-white pb-[20px] rounded-lg border border-gray-100">
        <div class="flex items-center justify-between h-[42px] border-b pl-[24px] border-gray-100">
            <h1 class="text-[12px] font-light">
                Amount Requested
            </h1>
            <div class="flex gap-2 border-gray-100 mr-2">
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="this.UpdateLead()"
                    class="w-[28px] rounded bg-blue-50 h-[28px] group hover:bg-blue-500 duration-500 flex items-center justify-center"
                >
                    <save-icon
                        stroke-width="2"
                        class="h-[16px] stroke-blue-500 fill-transparent group-hover:stroke-blue-100 duration-500"
                    />
                </button>
                <button
                    v-if="![1, 2].includes(ContextLead.lead_status.id)"
                    @click="ApproveSection"
                    :class="[IsValidated ? 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 ' : 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-green-100', 'duration-500 h-[28px] px-[24px] cursor-pointer text-xs rounded']"
                >
                    {{ IsValidated ? 'Decline' : 'Approve' }} Section
                </button>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-[24px] px-[24px]">
            <approbation-step-select-option
                label="Amount Requested"
                :selected="payload.amount"
                :options="LoanAmounts"
                @update-value="this.payload.amount = $event"
            />
            <approbation-step-select-option
                label="Payment Frequency"
                :selected="payload.payment_frequency"
                :options="PaymentFrequency"
                @update-value="this.payload.payment_frequency = $event"
            />
            <div class="flex flex-col bg-gray-100 px-[12px] pt-[8px] pb-[3px] rounded-lg">
                <label class="text-[9.5px] text-gray-500 tracking-wide">Next Paycheck At</label>
                <input type="date" v-model="payload.next_paycheck_at" class="border-none h-[24px] text-[12px] rounded bg-transparent tracking-wide text-gray-600" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {UpdateLead} from "../../../../services/Lead";
import SaveIcon from "../../../../assets/icons/SaveIcon.vue";
import SendIcon from "../../../../assets/svg/SendIcon.vue";
import ApprobationStepSelectOption from "./CustomInput/ApprobationStepSelectOption.vue";
import NotificationMixin from "../../../../mixins/NotificationMixin";

export default
{
    components: {ApprobationStepSelectOption, SendIcon, SaveIcon},
    emits: ['approve-section'],

    props: {
        IsValidated: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            PaymentFrequency: { WEEKLY: 'Weekly', TWICE_MONTHLY: 'Twice Monthly', MONTHLY: 'Monthly'},
            LoanAmounts: { 100: '$100', 250: '$250', 500: '$500', 750: '$750', 1000: '$1000', 1250: '$1250', 1500: '$1500', 1750: '$1750', 2000: '$2000', 2250: '$2250', 2500: '$2500' },
            payload: {
                'amount': null,
                'payment_frequency': 'WEEKLY',
                'next_paycheck_at': null,
            }
        }
    },

    mixins: [
        NotificationMixin,
    ],

    computed: {
        ...mapState([
            'ContextLead'
        ]),
    },

    methods: {
        ApproveSection() {
            this.$emit('approve-section', {approved_step: 'amount_requested', approved_step_status: !this.IsValidated});
            this.UpdateLead();
        },

        UpdateLead() {
            UpdateLead(this.ContextLead.id, this.payload)
                .then((response) => {
                    if (response.status === 200) {
                        this.showNotification('success', 'Lead Successfully Updated');
                    }
                })
                .catch(() => {
                    this.showNotification('error', 'An Error Occurred While Updating Lead')
                })
        },

        FormatDate(date) {
            const context_date = new Date(date);
            return `${context_date.getFullYear()}-${(context_date.getMonth() + 1 + '').padStart(2, '0')}-${(context_date.getDate() + '').padStart(2, '0')}`;
        }
    },

    mounted() {
        this.payload.amount = this.ContextLead['amount'];
        this.payload.next_paycheck_at = this.FormatDate(this.ContextLead['next_paycheck_at']);
        this.payload.payment_frequency = this.ContextLead['payment_frequency'];
    }
}
</script>
