import UsersPage from "../../pages/UsersPage.vue";
import UserPage from "../../pages/UserPage.vue";

export default [
	{
		path: '/users',
		component: UsersPage,
		name: 'UsersPage',
		meta: {
			type: 'private',
			authOnly: true,
		}
	},
	{
		path: '/user/:id',
		component: UserPage,
		name: 'UserProfilePage',
		meta: {
			type: 'private',
			authOnly: true,
		}
	},
];
