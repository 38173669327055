<template>
    <div class="h-auto p-[12pt] top-24 flex items-center justify-between bottom-0 left-0 py-[12pt] bg-gray-100 w-full">
        <div class="flex items-center group h-[45px] w-full bg-white rounded-lg">
            <input
                id="chat-input"
                class="text-[9pt] tracking-wide text-gray-700 w-full h-full px-[12pt] border-none bg-white resize-none overflow-hidden rounded-l-lg focus:outline-none"
                placeholder="Message ..."
            />
            <button
                id="chat-send"
                class="opacity-0 flex items-center justify-center rounded-full pl-1.5 text-xs text-white hover:opacity-100 duration-500 tracking-wider bg-blue-500 h-full w-[45px] rounded-r"
            >
                <send-arrow-icon
                    size="14px"
                    fill="white"
                />
            </button>
        </div>
    </div>
</template>

<script>

import SendArrowIcon from "../../assets/icons/SendArrowIcon.vue";

export default
{
    components: {
		SendArrowIcon
	}
}
</script>
