<template>
    <kronos-base-action-modal
        :is-open="addNewTransactionModalIsOpen"
        @submit="importNewTransactions()"
        @close="close()"
        title="Import Transactions"
        class="fixed z-50"
    >
        <div class="mb-3.5 mt-1.5">
            <kronos-auth-input
                v-model:value="form.flinks_login_id"
                type="text"
                label="Flinks Login Id"
                placeholder="exemple@1234"
				:error-message="errors.flinks_login_id.message"
				:error="errors.flinks_login_id.status"
            />
        </div>
    </kronos-base-action-modal>
	<kronos-base-action-modal
		:is-open="massUpdateModalIsOpen"
		@submit="updateTransactions()"
		@close="close()"
		title="Transaction Mass Update"
		class="fixed z-50"
	>
		<div class="mb-1 mt-2">
			<select
				@change="massUpdateTypeId = parseInt($event.target.value)"
                class="w-30 h-[50px] border-slate-200 border rounded-lg text-xs focus:ring-0 cursor-pointer w-full"
			>
				<option
					v-for="option in transactionOptions.transactionOptions"
					:value="option?.value"
					:selected="option?.value === massUpdateTypeId"
				>
					{{ option.label }}
				</option>
			</select>
		</div>
	</kronos-base-action-modal>
	<kronos-base-layout>
		<div class="flex w-full h-full relative flex flex-col gap-6 max-w-[1920px] mx-auto">
			<div class="flex justify-between gap-10 w-full scroll-smooth relative">
	            <div class="flex gap-3 items-center">
		            <img class="h-[24px]" src="/assets/icons/duotone/CardHolderIcon.svg"/>
	                <h1 class="text-lg font-medium text-gray-800"> ML Transactions </h1>
                    <div
	                    :class="isLoading ? 'animate-spin' : ''"
	                    class="cursor-pointer"
	                    @click="fetchTransactions()"
                    >
                        <img class="w-3" src="../../assets/images/refresh_875100.png">
                    </div>
	            </div>
				<div class="flex gap-2">
					<transition
						enter-active-class="ease-out duration-200"
						enter-from-class="opacity-0 scale-95"
						enter-to-class="opacity-100 scale-100"
						leave-active-class="ease-in duration-200"
						leave-from-class="opacity-100 scale-100"
						leave-to-class="opacity-0 scale-95"
					>
						<button
							v-if="selected.length !== 0"
							@click="massUpdateModalIsOpen = true"
							class="right-0 text-xs h-[40px] border rounded-lg text-white bg-opacity-90 hover:bg-opacity-100 duration-300 bg-gray-400 px-[24px]"
						>
							Mass Update
						</button>
					</transition>
	                <button
	                    @click="addNewTransactionModalIsOpen = true"
	                    class="right-0 text-xs h-[40px] border rounded-lg text-white bg-opacity-90 hover:bg-opacity-100 duration-300 bg-blue-500 px-[24px]"
	                >
	                    Import Transactions
	                </button>
				</div>
            </div>
            <div class="custom-shadow-0 | max-h-[1000px] rounded-xl bg-white flex flex-col gap-6 overflow-x-auto w-full">
	            <div>
	                <div class="w-full relative text-m text-left text-gray-500">
	                    <div class="sticky border-b border-gray-100 top-0 left-0 w-full text-[10px] bg-gray-200 flex text-gray-600 h-[50px] uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
		                    <div class="px-6 max-w-[50px] font-semibold flex items-center justify-center h-[50px]">
			                    <input
				                    @change="selectAll($event.target.checked)"
				                    :checked="isChecked"
				                    type="checkbox"
				                    class="focus:ring-none h-[18px] w-[18px] focus:ring-transparent focus:outline-none rounded cursor-pointer border-gray-300"
			                    />
		                    </div>
	                        <div class="px-6 max-w-[50px] font-medium flex items-center text-gray-500 justify-center h-[50px]">
	                            ID
	                        </div>
		                    <div class="custom-width | flex">
		                        <div class="px-6 min-w-[150px] flex h-[50px] flex items-center font-medium text-gray-500">
		                            Institution
		                        </div>
			                    <div class="px-6 min-w-[400px] w-full flex h-[50px] flex items-center font-medium text-gray-500">
		                            Description
		                        </div>
			                    <div class="px-6 max-w-[250px] min-w-[125px] w-full flex h-[50px] flex items-center justify-center font-medium text-gray-500">
		                            Withdrawal
		                        </div>
			                    <div class="px-6 max-w-[250px] min-w-[125px] w-full flex h-[50px] flex items-center justify-center font-medium text-gray-500">
				                    Deposit
			                    </div>
			                    <div class="px-6 max-w-[250px] min-w-[125px] w-full flex h-[50px] flex items-center justify-center font-medium text-gray-500">
		                            Balance
		                        </div>
			                    <div class="px-6 max-w-[250px] min-w-[250px] w-full flex h-[50px] flex items-center justify-center font-medium text-gray-500">
		                            Transaction Type
		                        </div>
		                    </div>
	                    </div>
	                    <div>
		                    <div
			                    v-if="isLoading"
			                    class="flex items-center h-[75px] justify-center"
		                    >
			                    <loader-animation
				                    size="24px"
				                    border="2.5px solid rgb(209 213 219)"
			                    />
		                    </div>
		                    <div
			                    v-else-if="transactions && transactions.length === 0 && !isLoading"
		                        class="flex h-[75px] items-center justify-center text-xs text-gray-400"
                            >
			                    No Data Available...
		                    </div>
	                        <div
		                        v-else
		                        v-for="(transaction, index) in transactions"
		                        class="hover:bg-gray-50 flex border-b text-xs dark:bg-gray-800 h-[60px] dark:border-gray-700 border-slate-100"
	                        >
		                        <div class="px-6 w-[50px] flex items-center justify-center">
			                        <input
				                        @change="updateSelection(transaction?.id)"
				                        :checked="selected.includes(transaction?.id)"
				                        type="checkbox"
				                        class="focus:ring-none h-[18px] w-[18px] focus:ring-transparent focus:outline-none rounded cursor-pointer border-gray-300"
			                        />
		                        </div>
	                            <div class="px-6 w-[50px] flex items-center justify-center">
	                                {{ transaction?.id }}
	                            </div>
		                        <div class="custom-width | flex">
			                        <div class="px-6 min-w-[150px] flex h-[60px] flex items-center">
		                                {{ transaction?.institution }}
		                            </div>
			                        <div class="px-6 min-w-[400px] w-full flex h-[60px] flex items-center">
				                        {{ transaction?.description }}
		                            </div>
			                        <div class="px-6 max-w-[250px] min-w-[125px] w-full flex h-[60px] flex items-center justify-center">
				                        {{ transaction?.debit }}$
		                            </div>
			                        <div class="px-6 max-w-[250px] min-w-[125px] w-full flex h-[60px] flex items-center justify-center">
				                        {{ transaction?.credit }}$
		                            </div>
			                        <div
				                        class="px-6 max-w-[250px] min-w-[125px] w-full flex h-[60px] flex items-center justify-center font-medium"
			                            :class="transaction?.balance > 0 ? 'text-green-500' : 'text-red-500'">
				                        {{ transaction?.balance }}$
			                        </div>
			                        <div class="px-6 max-w-[250px] min-w-[250px] w-full flex h-[60px] flex items-center justify-center">
		                                <select
											@change="updateTransaction(transaction?.id, $event.target.value)"
			                                class="w-full mx-1 h-[38px] border-slate-200 border rounded-lg text-xs focus:ring-0 cursor-pointer"
		                                >
		                                    <option
			                                    v-for="option in transactionOptions.transactionOptions"
			                                    :value="option?.value"
			                                    :selected="option?.value === transaction?.transaction_type_id"
		                                    >
			                                    {{ option.label }}
		                                    </option>
		                                </select>
		                            </div>
		                        </div>
	                        </div>
	                    </div>
		                <div class="sticky flex items-center bottom-0 left-0 w-full text-[10px] flex border-t border-gray-100 dark:bg-gray-700 min-h-[55px] dark:text-gray-400">
			                <div class="flex w-full items-center justify-between bg-white p-2">
				                <div class="cursor-pointer flex items-center gap-3">
					                <select
					                    class="cursor-pointer block w-full rounded-md border border-gray-200 text-gray-900 focus:ring-none focus:outline-none sm:text-xs"
						                @change="contextPage = 1, changeLimit($event.target.value)"
					                >
						                <option
						                    v-for="limit in [25, 50, 75, 100, 150, 200, 250, 300, 400, 500, 750, 900, 1000]"
						                    :value="limit"
						                    :selected="parseInt(limit) === contextLimit"
						                >
							                {{ limit }}
						                </option>
					                </select>
					                <div class="flex items-center gap-1.5 text-[11px]">
						                <span>{{ (transactions && transactions.length === 0) ? '0' : (canNext ? contextPage * contextLimit : total) }}</span>
						                of
						                <span class="font-semibold">{{ total }}</span>
					                </div>
				                </div>
				                <div class="flex flex-1 justify-between sm:justify-end gap-2">
					                <a
						                v-if="canPrev"
						                @click="contextPage-- && fetchTransactions()"
						                class="cursor-pointer text-xs w-[75px] h-[32px] flex items-center justify-center rounded-lg text-gray-500 font-medium border"
					                >
						                Prev
					                </a>
					                <a
						                v-if="canNext"
						                @click="contextPage++ && fetchTransactions()"
						                class="cursor-pointer text-xs w-[75px] h-[32px] flex items-center justify-center rounded-lg text-gray-500 font-medium border"
					                >
						                Next
					                </a>
				                </div>
			                </div>
		                </div>
	                </div>
	            </div>
			</div>
		</div>
	</kronos-base-layout>
</template>

<script>
import KronosBaseActionModal from "../../UI/BaseComponents/KronosBaseActionModal.vue";
import KronosBaseLayout from "../../UI/Layouts/KronosBaseLayout.vue"
import KronosAuthInput from "../../UI/BaseComponents/KronosAuthInput.vue";
import {
	ImportTransactions,
	RetrieveTransactions,
	UpdateTransaction,
	UpdateTransactions
} from "../../services/Automation";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import {mapState} from "vuex";
import NotificationMixin from "../../mixins/NotificationMixin";

export default
{
    components: {
	    LoaderAnimation,
        KronosBaseLayout,
        KronosBaseActionModal,
        KronosAuthInput
    },

    data() {
        return {
			isLoading: false,
			transactions: null,
            addNewTransactionModalIsOpen: false,
	        massUpdateModalIsOpen: false,
            form: {
                flinks_login_id: null,
            },
            errors: {
                flinks_login_id: {
                    message: null,
                    status: false,
                }
            },
            contextPage: 1,
	        contextLimit: 500,
	        total: 0,
	        selected: [],
	        massUpdateTypeId: null,
        }
    },

	mixins: [
		NotificationMixin,
	],

	computed: {
		...mapState([
			'transactionOptions'
		]),

		canNext() {
			return (this.contextPage * this.contextLimit) < this.total
		},

		canPrev() {
			return this.contextPage > 1;
		},

		isChecked() {
			return this.selected.length === this.contextLimit;
		}
	},

    methods: {
        clearErrors() {
            this.errors.flinks_login_id.message = null;
            this.errors.flinks_login_id.status = null;
        },

	    updateSelection(id) {
		    const index = this.selected.indexOf(id);
		    index !== -1
	            ? this.selected.splice(index, 1)
			    : this.selected.push(id);
	    },

        close() {
	        this.massUpdateModalIsOpen = false;
	        this.addNewTransactionModalIsOpen = false;
	        this.form.flinks_login_id = null;
	        this.clearErrors();
        },

	    updateTransactions() {
			this.$store.state.IsLoading = true;
		    UpdateTransactions(this.selected, this.massUpdateTypeId)
			    .then((response) => {
					if (response?.status === 200) {
						this.massUpdateModalIsOpen = false;
						this.massUpdateTypeId = null;
						this.showNotification('success', 'Transactions Successfully Updated');
						this.fetchTransactions();
					}
			    })
			    .finally(() => {
				    this.$store.state.IsLoading = false;
			    })
	    },

	    updateTransaction(transaction_id, transaction_type_id) {
			UpdateTransaction(parseInt(transaction_type_id), parseInt(transaction_id))
				.then((response) => {
					if (response.status === 200) {
						this.showNotification('success', 'Transaction Successfully Updated');
					}
				})
				.catch(() => {
					this.showNotification('error', 'An Error Occurred While Updating Transaction');
				});
	    },

	    selectAll(event) {
		    this.selected = event
			    ? this.transactions.map(obj => obj.id)
		        : [];
	    },

        importNewTransactions() {
            this.clearErrors();

            if (!!this.form.flinks_login_id) {
                this.$store.state.IsLoading = true;

                ImportTransactions(this.form)
                    .then((response) => {  
                        if (response?.status === 200) {
                            this.addNewTransactionModalIsOpen = false;
							this.form.flinks_login_id = null;
	                        this.showNotification('success', 'Transaction Successfully Imported');
	                        this.fetchTransactions();
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 404) {
                            this.errors.flinks_login_id.status = true;
                            this.errors.flinks_login_id.message = 'Invalid Flinks Login Id';
                        }

                        if (error.response.status === 422) {
	                        this.errors.flinks_login_id.status = true;
	                        this.errors.flinks_login_id.message = 'Flinks Login Id Is Required';
						}
                    })
                    .finally(() => {
                        this.$store.state.IsLoading = false;
                    });
            } else {
	            this.errors.flinks_login_id.status = true;
	            this.errors.flinks_login_id.message = 'Flinks Login Id Is Required';
	        }
        },

	    fetchTransactions() {
			this.transactions = null;
			this.isLoading = true;
			this.selected = [];
		    RetrieveTransactions(null, this.contextLimit, this.contextPage)
			    .then(response => {
				    this.transactions = response?.data?.data?.transactions;
					this.total = response?.data?.data?.total;
			    })
			    .finally(() => {
					this.isLoading = false;
			    });
	    },

	    changeLimit(limit) {
			this.contextLimit = parseInt(limit);
		    this.fetchTransactions();
	    }
    },    

    mounted() {
		this.fetchTransactions()
    },
}
</script>

<style scoped>
.custom-width {
	width: calc(100% - 100px);
}

::-webkit-scrollbar {
	 width: 0px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: transparent;
}

::-webkit-scrollbar-thumb:hover {
	background: transparent;
}
</style>
