<template>
    <div class="relative flex w-full max-w-[28rem] m-10 float-left content-center flex-row rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
		<div class="relative m-0 w-1/5 shrink-0 m-8 overflow-hidden rounded-xl rounded-r-none bg-white bg-clip-border text-gray-700">
            <img
	            class="h-full w-full object-cover"
	            src="/src/assets/images/nice-background.jpg"
	            alt="image"
            />
        </div>
        <div class="p-6">
            <h6 class="mb-4 block font-sans text-base font-semibold uppercase leading-relaxed tracking-normal text-red-800 antialiased">
                {{ role }}
            </h6>
            <h4 class="mb-2 block font-sans text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                {{ first_name }} <br> {{ last_name }}
            </h4>
            <p class="mb-8 block font-sans text-base font-normal leading-relaxed text-gray-700 antialiased">
                {{ phone }}
                <br>
                {{ email }}
            </p>
            <span
	            v-if="ContextUser.role_id <= role_id"
	            class="inline-block"
            >
				<a
					:href="`/user/${user_id}`"
                    class="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-red-800 transition-all hover:bg-pink-500/10 active:bg-pink-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                >
					Edit
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
						<path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
					</svg>
				</a>
			</span>
            <span
	            v-if="[1, 2].includes(ContextUser.role_id)"
	            class="inline-block"
            >
				<button
					v-on:click="DeleteUser(user_id)"
                    class="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-red-800 transition-all hover:bg-pink-500/10 active:bg-pink-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                >
					Delete
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
						<path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
					</svg>
				</button>
			</span>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";
import { DeleteUser } from "../../services/User";

export default {
	props: {
		first_name: {
            "type": String,
            "default": '',
        },
        last_name: {
            "type": String,
            "default": '',
        },
        phone: {
            "type": String,
            "default": '',
        },
        role: {
            "type": String,
            "default": '',
        },
        user_id: {
            "type": Number,
            "default": '',
        },
        role_id: {
            "type": Number,
            "default": '',
        },
        email: {
            "type": String,
            "default": '',
        },
    },

    computed: {
		...mapState(['ContextUser']),
    },

	methods: {
		DeleteUser(id) {
			DeleteUser(id)
	            .then(async response => {
	                if (response.status === 200) {
	                    setTimeout(function() {
                            location.reload();
	                    }, 3000);
		            }
		        })
		}
	},
}
</script>
