<template>
    <div class="rounded h-fit">
        <div class="h-fit flex justify-between gap-[16px]">
            <div class="custom-shadow-0 w-1/2 bg-white rounded-[4pt] h-fit divide-gray-100 divide-y">
                <p class="text-[10.5px] font-medium text-[#3A3A3A] h-[26px] flex items-center px-3 tracking-wide justify-start">
                    Responsible
                </p>
                <div class="flex items-center justify-center h-[58px]">
                    <div
                        v-click-outside="CloseResponsible"
                        class="w-full relative"
                    >
                        <span
                            @click="responsible_is_open = !responsible_is_open"
                            class="group hover:bg-light-dark-blue flex items-center justify-center cursor-pointer mx-[12px] h-[28px] rounded-md duration-500"
                        >
				            <span class="relative border w-full h-[32px] group-hover:bg-light-dark-blue flex items-center px-2.5 truncate group-hover:text-[#4667d2] text-[10.5px] rounded border-gray-100 group-hover:border-[#4667d2] text-gray-500 tracking-wide duration-500">
					            {{ ContextLead.user ? `${ContextLead.user.first_name} ${ContextLead.user.last_name[0]}.` : 'None' }}
					            <two-side-arrow-icon class="absolute right-2.5 fill-gray-400" size="14px"/>
				            </span>
				        </span>
                        <transition
                            enter-active-class="ease-out duration-200"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="ease-in duration-200"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="responsible_is_open"
                                class="absolute w-full mt-1.5 z-30"
                            >
                                <div class="custom-shadow-1 | rounded bg-white mx-[12px] border pb-1">
                                    <input
                                        class="border-t-0 border-x-0 pl-[12px] rounded-t border border-b w-full mb-1 h-[34px] border-gray-100 ring-0 focus:ring-0 focus:border-b focus:border-gray-100 text-[10.5px] text-gray-500"
                                        placeholder="Filter..."
                                        type="text"
                                        v-model="filter_employee"
                                    />
                                    <div class="custom-scrollbar | max-h-[150px] overflow-y-auto">
                                        <div
                                            v-for="user in responsibles"
                                            class="text-xs flex flex-col"
                                        >
                                            <div
                                                @click="updateLeadResponsible(user.id, ContextLead.manager_id)"
                                                class="hover:bg-light-dark-blue text-gray-400 text-[10.5px] tracking-wide hover:text-[#4667d2] min-h-[28px] px-3 flex items-center cursor-pointer duration-500 truncate"
                                            >
                                                {{ user?.first_name }}
                                                {{ user?.last_name[0] }}.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>

            <div class="custom-shadow-0 w-1/2 bg-white rounded-[4pt] h-fit divide-gray-100 divide-y">
                <p class="text-[10.5px] font-medium text-[#3A3A3A] h-[26px] flex items-center px-3 tracking-wide justify-start">
                    Manager
                </p>
                <div class="flex items-center justify-center h-[58px]">
                    <div
                        v-click-outside="CloseManager"
                        class="w-full relative"
                    >
			            <span
                            @click="manager_is_open = !manager_is_open"
                            class="group hover:bg-light-dark-blue flex items-center justify-center cursor-pointer mx-[12px] h-[28px] rounded-md duration-500"
                        >
				            <span class="relative border w-full h-[32px] group-hover:bg-light-dark-blue flex items-center px-2.5 truncate group-hover:text-[#4667d2] text-[10.5px] rounded border-gray-100 group-hover:border-[#4667d2] text-gray-500 tracking-wide duration-500">
					            {{ ContextLead.manager_id ? `${ContextLead.manager.first_name} ${ContextLead.manager.last_name[0]}.` : 'None' }}
					            <two-side-arrow-icon class="absolute right-2.5 fill-gray-400" size="14px"/>
				            </span>
				        </span>
                        <transition
                            enter-active-class="ease-out duration-200"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="ease-in duration-200"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="manager_is_open"
                                class="absolute w-full mt-1.5 z-30"
                            >
                                <div class="custom-shadow-1 | rounded bg-white mx-[12px] border pb-1">
                                    <input
                                        class="border-t-0 border-x-0 pl-[12px] rounded-t border border-b w-full mb-1 h-[34px] border-gray-100 ring-0 focus:ring-0 focus:border-b focus:border-gray-100 text-[10.5px] text-gray-500"
                                        placeholder="Filter..."
                                        type="text"
                                        v-model="filter_manager"
                                    />
                                    <div class="custom-scrollbar | max-h-[150px] overflow-y-auto">
                                        <div
                                            v-for="user in managers"
                                            class="text-xs flex flex-col"
                                        >
                                            <div
                                                @click="updateLeadResponsible(ContextLead.user_id, user.id)"
                                                class="hover:bg-light-dark-blue text-gray-400 text-[10.5px] tracking-wide hover:text-[#4667d2] min-h-[28px] px-3 flex items-center cursor-pointer duration-500 truncate"
                                            >
                                                {{ user?.first_name }}
                                                {{ user?.last_name[0] }}.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {RetrieveLead, UpdateLeadResponsible} from "../../services/Lead";
import KronosBaseInformationCardInput from "./KronosBaseInformationCardInput.vue";
import KronosBaseInformationCard from "./KronosBaseInformationCard.vue";
import ThreeDotIcon from "../../assets/icons/ThreeDotIcon.vue";
import KronosBaseDropdown from "./KronosBaseDropdown.vue";
import NotificationMixin from "../../mixins/NotificationMixin";
import {Users} from "../../services/User";
import TwoSideArrowIcon from "../../assets/icons/singletone/TwoSideArrowIcon.vue";
import {mapState} from "vuex";
import store from "../../store";


export default {
    components: {
        TwoSideArrowIcon,
        KronosBaseDropdown,
        ThreeDotIcon,
        KronosBaseInformationCard,
        KronosBaseInformationCardInput
    },

    mixins: [
        NotificationMixin,
    ],

    computed: {
        ...mapState([
            'ContextLead'
        ]),
    },

    watch: {
        filter_employee() {
            if (!!this.filter_employee) {
                this.responsibles = this.users.filter(user =>
                    (user.first_name.toLowerCase() + ' ' + user.last_name.toLowerCase())
                        .includes(this.filter_employee.toLowerCase())
                );
            } else {
                this.responsibles = this.users;
            }
        },

        filter_manager() {
            if (!!this.filter_manager) {
                this.managers = this.users.filter(user =>
                    (user.first_name.toLowerCase() + ' ' + user.last_name.toLowerCase())
                        .includes(this.filter_manager.toLowerCase())
                );
            } else {
                this.managers = this.users;
            }

            this.managers = this.managers.filter(user => this.manager_roles.includes(user?.role_id));
        },
    },

    data() {
        return {
            users: [],
            responsibles: [],
            responsible_is_open: false,
            manager_is_open: false,
            managers: [],
            filter_employee: '',
            filter_manager: '',
            manager_roles: [4, 3],
            isLoading: false,
        }
    },

    methods: {
        fetchLeadData() {
            RetrieveLead(this.ContextLead.id)
                .then((response) => {
                    if (response.status === 200) {
                        store.state.ContextLead = response.data.data.lead;
                    }
                })
        },

        CloseResponsible() {
            this.responsible_is_open = false;
        },

        CloseManager() {
            this.manager_is_open = false;
        },

        fetchUsers() {
            this.isLoading = true;

            Users()
                .then(response => {
                    console.log(response.data.data);
                    this.users = response.data.data.users;
                    this.responsibles = response.data.data.users.filter(user => user.account_id === this.ContextLead.client.account_id && ![this.ContextLead.user_id, this.ContextLead.manager_id].includes(user.id));
                    this.managers = this.users.filter(user => this.manager_roles.includes(user?.role_id) && user.id !== this.ContextLead.manager_id);
                })
                .catch(() => {
                    this.showNotification('error', 'Error While Fetching TotalUsers');

                })
                .finally(() => {
                    this.isLoading = false;
                })
        },

        updateLeadResponsible(responsible_id, manager_id) {
            if (this.isLoading === false) {
                this.isLoading = true;

                UpdateLeadResponsible(this.ContextLead.id, responsible_id, manager_id)
                    .then((response) => {
                        if (response.status === 200) {
                            this.CloseResponsible();
                            this.CloseManager();
                            this.showNotification('success', 'Lead Responsible Successfully Changed');
                            this.fetchUsers();
                            this.fetchLeadData();
                        }
                    })
                    .catch(() => {
                        this.showNotification('error', 'Error While Updating Lead Responsible')
                    })
                    .finally(()=> {
                        this.isLoading = false;
                    })
            }
        }
    },

    mounted() {
        this.fetchUsers();
        this.fetchLeadData(this.ContextLead.id);
    }
}
</script>

<style scoped>
.custom-scrollbar::-webkit-scrollbar
{
    width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
    background-color: lightgray;
}

.custom-scrollbar::-webkit-scrollbar-track
{
    background-color: transparent;
}
</style>
