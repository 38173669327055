<template>
    <div class="bg-white rounded-[4pt] w-full h-fit divide-y divide-[#F2F2F2] mb-[50px]">
        <div class="h-[45px] flex items-center justify-between pl-[24px]">
            <div class="text-[#6E6E6E] text-[13.5px] tracking-wide pt-0.5">Payment Calendars</div>
        </div>
        <div v-if="dataset.length <=0" class="h-[100px] flex items-center justify-center">
            <span class="text-[9.5px] text-gray-500 tracking-wide">No data to show...</span>
        </div>
        <div v-else @scroll="HandleScroll" ref="table" class="w-full h-fit">
            <div class="padding-0 margin-0 border-r min-h-[150px] max-h-[750px">
                <table class="bg-white w-full border-r border-gray-100">
                    <thead class="w-full">
                        <tr class="max-h-[38px] w-full min-w-full h-[38px] divide-x divide-gray-100">
                            <td class="absolute bg-transparent w-[2px] h-full top-0 left-0 max-w-[2px]"></td>
                            <th class="top-0 bg-white max-w-[36px] w-[36px]">
                                <div class="max-w-[36px] border-b min-h-[38px] border-gray-100 flex items-center justify-center text-center w-[36px] text-[#6E6E6E] text-[11.5px]">
                                    <div class="w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm bg-gray-200 shadow-sm transition-colors"></div>
                                </div>
                            </th>
                            <th class="top-0 bg-white">
                                <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">
                                    Lender
                                </div>
                            </th>
                            <th class="top-0 bg-white">
                                <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">
                                    Status
                                </div>
                            </th>
                            <th class="top-0 bg-white">
                                <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">
                                    Next Payment Date
                                </div>
                            </th>
                            <th class="top-0 bg-white">
                                <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">
                                    Due Amount
                                </div>
                            </th>
                            <th class="top-0 bg-white">
                                <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">
                                    Residual
                                </div>
                            </th>
                            <th class="top-0 bg-white">
                                <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">
                                    Clients
                                </div>
                            </th>
                            <th class="top-0 bg-white">
                                <div class="border-b min-h-[38px] border-gray-100 font-medium flex items-center justify-center text-center text-[#6E6E6E] text-[11.5px]">
                                    Clients
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-[#F2F2F2] divide-y w-full">
                        <tr
                            v-for="(FixedColumnItem, Index) in dataset"
                            @click="$emit('open-folder')"
                            :class="Index % 2 === 0 ? 'bg-theme-blue bg-opacity-5' : ''"
                            class="h-[100px] min-w-full divide-x relative divide-[#F2F2F2] max-h-[100px]"
                        >
                            <td :class="[Index % 2 === 0 ? 'bg-theme-blue' : 'bg-white' ,'absolute w-[2px] h-full top-0 left-0 max-w-[2px]']"></td>
                            <td class="min-w-[36px] max-w-[36px]">
                                <div class="max-w-[36px] flex items-center justify-center">
                                    <div
                                        :class="['w-[14px] h-[14px] flex items-center justify-center cursor-pointer mx-auto rounded-sm bg-gray-200 shadow-sm transition-colors']"
                                    ></div>
                                </div>
                            </td>
                            <td class="h-full">
                                <div class="divide-[#F2F2F2] flex items-center justify-center p-0 m-0 flex-col divide-y w-full h-full">
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">PretHebdo</div>
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">Gestion Kronos</div>
                                </div>
                            </td>
                            <td class="h-full">
                                <div class="divide-[#F2F2F2] flex items-center justify-center p-0 m-0 flex-col divide-y w-full h-full">
                                    <div class="w-full flex items-center justify-center h-[50px] text-[9.5px]">
                                        <div class="px-[10px] bg-theme-blue py-1 rounded-full text-white">
                                            À Jours
                                        </div>
                                    </div>
                                    <div class="w-full flex items-center justify-center h-[50px] text-[9.5px]">
                                        <div class="px-[10px] bg-theme-blue py-1 rounded-full text-white">
                                            À Jours
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="h-full">
                                <div class="divide-[#F2F2F2] flex items-center justify-center p-0 m-0 flex-col divide-y w-full h-full">
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">{{ FixedColumnItem[0] ? FixedColumnItem[0].first_payment_at : "..." }}</div>
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">{{ FixedColumnItem[1] ? FixedColumnItem[1].first_payment_at : "..." }}</div>
                                </div>
                            </td>
                            <td class="h-full">
                                <div class="divide-[#F2F2F2] flex items-center justify-center p-0 m-0 flex-col divide-y w-full h-full">
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">{{ FixedColumnItem[0] ? FixedColumnItem[0].payment_amount.toFixed(2) : "..." }}</div>
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">{{ FixedColumnItem[1] ? FixedColumnItem[1].payment_amount.toFixed(2) : "..." }}</div>
                                </div>
                            </td>
                            <td class="h-full">
                                <div class="divide-[#F2F2F2] flex items-center justify-center p-0 m-0 flex-col divide-y w-full h-full">
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">PretHebdo</div>
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">Gestion Kronos</div>
                                </div>
                            </td>
                            <td class="h-full">
                                <div class="divide-[#F2F2F2] flex items-center justify-center p-0 m-0 flex-col divide-y w-full h-full">
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">PretHebdo</div>
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">Gestion Kronos</div>
                                </div>
                            </td>
                            <td class="h-full">
                                <div class="divide-[#F2F2F2] flex items-center justify-center p-0 m-0 flex-col divide-y w-full h-full">
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">PretHebdo</div>
                                    <div class="w-full flex items-center justify-center h-[50px] text-[10.5px]">Gestion Kronos</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import FilterIcon from "../../assets/svg/FilterIcon.vue";

export default
{
    components: {FilterIcon},
    props: {
        dataset: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            IsScrolling: false,
        }
    },

    methods: {
        HandleScroll() {
            this.IsScrolling = this.$refs.table.scrollLeft > 0;
        }
    },
}
</script>

<style scoped>
td > div,
th > div {
    padding: 5px
}

td,
th {
    padding: 0
}
</style>
