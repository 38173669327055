<template>
    <add-task-modal
        :is-open="addTaskModalIsOpen"
        @close="addTaskModalIsOpen=false"
        />
    <div
        v-click-outside="close"
        class="flex items-center justify-center w-full bg-gray-100 h-fit z-10"
    >
        <div class="relative">
            <div class="flex bg-white divide-x divide-gray-100 rounded-lg">
                <div
                    v-if="this.allowedUserRole.includes(this.ContextUser.role_id)"
                    @click="this.addTaskModalIsOpen = true"
                    class="h-[32px] w-[32px] cursor-pointer flex items-center justify-center"
                >
                    <img src="../../assets/icons/icons8-task-50.png" class="mt-0.5" height="13" width="13"/>
                </div>
                <div
                    @click="this.isOpenTask = !this.isOpenTask"
                    class="h-[32px] w-[32px] cursor-pointer flex items-center justify-center text-xs text-gray-600"
                >
                    <document-duo-icon
                        size="15px"
                        fill="#6b7280"
                    />
                </div>
                <slot name="actions" />
                <div
                    @click="$emit('reload')"
                    class="w-[32px] h-[32px] flex border-r border-gray-50 items-center justify-center cursor-pointer hover:bg-gray-50 duration-300 hover:rounded-l-lg"
                >
                    <reload-icon
                        size="12px"
                        fill="#6b7280"
                    />
                </div>
                <div
                    @click="this.isOpen = !this.isOpen, this.selectedDates = []"
                    class="h-[32px] w-[192px] cursor-pointer flex items-center justify-center text-[10.5px] pt-0.5 text-gray-600"
                >
                    {{ selectedDates.length === 0 ? `${contextSelectedDates[0] ? formatDate(contextSelectedDates[0]) : ''} - ${contextSelectedDates[1] ? formatDate(contextSelectedDates[1]) : ''}` : `${selectedDates[0] ? formatDate(selectedDates[0]) : ''} - ${selectedDates[1] ? formatDate(selectedDates[1]) : ''}` }}
                </div>
            </div>

			<transition
				name="modal"
				enter-active-class="ease-out duration-300"
				enter-from-class="opacity-0"
				enter-to-class="opacity-100"
				leave-active-class="ease-in duration-200"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<div
					v-if="isOpen"
					class="custom-shadow-0 | h-auto w-auto right-0 absolute bg-white pt-[10pt] px-[10pt] mt-4 rounded-xl max-w-lg mx-auto divide-y divide-gray-100"
				>
					<div class="flex divide-x divide-gray-100">
						<div class="w-[200px] text-[9pt] flex flex-col gap-1 pr-[10pt] items-center justify-center">
							<div
								v-for="preset in datePresets"
								:key="preset.preset"
								@click="selectedDates = preset.range"
								class="h-[28px] w-full hover:bg-black duration-500 hover:text-white flex items-center justify-start pl-2 cursor-pointer rounded-sm"
							>
								{{ preset.preset }}
							</div>
						</div>
						<div class="pl-[10pt]">
							<div class="flex justify-between items-center h-[32px]">
								<span class="w-[28px] h-[28px] bg-black/80 hover:bg-black cursor-pointer flex items-center justify-center text-white duration-300 rounded" @click="prevMonth">
									<chevron-left-icon
										fill="white"
										size="14px"
									/>
								</span>
								<h1 class="text-[10pt]">{{ formattedMonth }}</h1>
								<span class="w-[28px] h-[28px] bg-black/80 hover:bg-black cursor-pointer flex items-center justify-center text-white duration-300 rounded" @click="nextMonth">
									<chevron-right-icon
										fill="white"
										size="14px"
									/>
								</span>
                            </div>
                            <div class="flex flex-col gap-1 mt-3">
                                <div class="w-full flex mb-1">
                                    <div v-for="(day, index) in daysOfWeek" :key="index" class="text-center text-[9pt] w-[32px] h-[16px]">{{ day }}</div>
                                </div>
                                <div
                                    v-for="(week, weekIndex) in calendar"
                                    :key="weekIndex"
                                    class="flex"
                                >
                                    <div
                                        v-for="dateObj in week"
                                        :key="dateObj.date"
                                        @click="toggleSelection(dateObj)"
                                        :class="getStyle(dateObj)"
                                        class="text-center cursor-pointer w-[32px] h-[32px] flex items-center justify-center"
                                    >
                                        <div class="h-[28px] w-[28px] hover:bg-black hover:text-white flex items-center justify-center rounded-full duration-500">
                                            {{ dateObj.date }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-[42px] mt-4 flex items-center justify-end">
                        <div
                            @click="submit()"
                            class="h-[28px] px-[24px] flex items-center justify-center text-[9pt] rounded-md text-white cursor-pointer"
                            :class="selectedDates.length === 2 ? 'bg-black' : 'bg-black/50'"
                        >
                            Apply
                        </div>
                    </div>
                </div>
            </transition>
            <user-tasks-modal
            :is-open-task="isOpenTask"/>
        </div>
    </div>
</template>

<script>
import ChevronLeftIcon from "../../assets/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon.vue";
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";
import DocumentDuoIcon from "../../assets/icons/duotone/DocumentDuoIcon.vue";
import CheckDuoIcon from "../../assets/icons/duotone/CheckDuoIcon.vue";
import AddTaskModal from "../../components/Task/AddTaskModal.vue";
import KronosBaseActionModal from "./KronosBaseActionModal.vue";
import NotificationMixin from "../../mixins/NotificationMixin";
import {mapState} from "vuex";
import UserTasksModal from "../../components/Task/UserTasks.vue";

export default {
	emits: ['change-range', 'reload'],

    components: {
        UserTasksModal,
        KronosBaseActionModal,
        AddTaskModal,
        CheckDuoIcon,
        DocumentDuoIcon,
        ReloadIcon,
        ChevronRightIcon,
        ChevronLeftIcon
    },

	props: {
		contextSelectedDates: {
			type: Array,
			default: []
		}
	},

    data() {
        return {
            year: 2023,
            month: 8,
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            selectedDates: [],
            isOpen: false,
            isOpenTask: false,
            addTaskModalIsOpen: false,
            allowedUserRole: [3,4]
        }
    },
    mixins: [
        NotificationMixin,
    ],
	computed: {
        ...mapState([
            'ContextUser',
        ]),
		datePresets() {
			return [
				{
					preset: 'Today',
					range: [this.startOfDay(new Date()), this.endOfDay(new Date())]
				},
				{
					preset: 'Yesterday',
					range: [this.startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))), this.endOfDay(new Date(new Date().setDate(new Date().getDate() - 1)))]
				},
				{
					preset: 'This Week',
					range: [new Date(new Date().setHours(0, 0, 0, 0) - new Date().getDay() * 86400000), new Date(new Date().setHours(23, 59, 59, 999) + (6 - new Date().getDay()) * 86400000)]
				},
				{
					preset: 'Last Week',
					range: [new Date(new Date().setHours(0, 0, 0, 0) - (new Date().getDay() + 7) * 86400000), new Date(new Date().setHours(23, 59, 59, 999) - (new Date().getDay() + 1) * 86400000)]
				},
				{
					preset: 'This Month',
					range: [this.startOfMonth(new Date()), this.endOfMonth(new Date())]
				},
				{
					preset: 'Last Month',
					range: [this.startOfLastMonth(new Date()), this.endOfLastMonth(new Date())]
				},
				{
					preset: 'Last 45 Days',
					range: [this.startOfDay(new Date(new Date().setDate(new Date().getDate() - 45))), this.endOfDay(new Date())]
				},
				{
					preset: 'Last 90 Days',
					range: [this.startOfDay(new Date(new Date().setDate(new Date().getDate() - 90))), this.endOfDay(new Date())]
				}
			];
		},

		formattedMonth() {
			const monthNames = [
				'January', 'February', 'March', 'April', 'May', 'June',
				'July', 'August', 'September', 'October', 'November', 'December'
			];
			return `${monthNames[this.month - 1]} ${this.year}`;
		},

		calendar() {
			const firstDay = new Date(this.year, this.month - 1, 1).getDay();
			const daysInMonth = new Date(this.year, this.month, 0).getDate();
			const calendar = [];
			let week = [];

			for (let i = 0; i < firstDay; i++) {
				const prevMonthDay = daysInMonth - firstDay + i + 1;
				week.push({
					date: prevMonthDay,
					isCurrentMonth: false,
				});
			}

			for (let day = 1; day <= daysInMonth; day++) {
				if (week.length === 7) {
					calendar.push(week);
					week = [];
				}

				week.push({
					date: day,
					isCurrentMonth: true,
				});
			}

			if (week.length > 0) {
				let nextMonthDay = 1;
				while (week.length < 7) {
					week.push({
						date: nextMonthDay,
						isCurrentMonth: false,
					});
					nextMonthDay++;
				}
				calendar.push(week);
			}

			return calendar;
		}
	},

    methods: {
        close() {
            this.isOpen = false;
            this.selectedDates = [];
            this.isOpenTask = false;
        },

		getStyle(dateObj)
		{
			if (!dateObj.isCurrentMonth) {
				return 'text-gray-500 text-[8pt] font-medium'
			}

			if (this.isSelected(dateObj) && this.selectedDates.length === 1) {
				return 'bg-black text-white text-[9pt] rounded-full font-medium'
			}

			if (this.isRangeStart(dateObj) && this.isRangeEnd(dateObj)) {
				return 'bg-black text-white text-[9pt] font-medium rounded-full'
			}

			if (this.isRangeStart(dateObj)) {
				return 'bg-black text-white text-[9pt] font-medium rounded-l-full'
			}

			if (this.isRangeEnd(dateObj)) {
				return 'bg-black text-white text-[9pt] font-medium rounded-r-full'
			}

			if (this.isInRange(dateObj)) {
				return 'bg-black text-white text-[9pt] font-medium'
			}

			return 'rounded-full duration-300 font-medium text-[9pt]';
		},

		prevMonth() {
			if (this.month === 1) {
				this.month = 12;
				this.year -= 1;
			} else {
				this.month -= 1;
			}
		},

		nextMonth() {
			if (this.month === 12) {
				this.month = 1;
				this.year += 1;
			} else {
				this.month += 1;
			}
		},

		toggleSelection(dateObj) {
			if (dateObj.isCurrentMonth) {
				if (this.selectedDates.length === 2) {
					this.selectedDates = [];
				}

				const dateStr = `${this.year}-${this.month}-${dateObj.date}`;
				const date = new Date(dateStr);

				this.selectedDates.push(date);
				this.selectedDates = this.selectedDates.sort((a, b) => a - b);
			}
		},

		isSelected(dateObj) {
			if (this.selectedDates.length === 0) {
				return false;
			}

			const dateStr = `${this.year}-${this.month}-${dateObj.date}`;
			const selectedDateStr = `${this.selectedDates[0].getFullYear()}-${this.selectedDates[0].getUTCMonth() + 1}-${this.selectedDates[0].getDate()}`;

			return dateStr === selectedDateStr;
		},

		isRangeStart(dateObj) {
			if (this.selectedDates.length === 2) {
				const smallerDate = new Date(Math.min(...this.selectedDates));
				const dateStr = `${this.year}-${this.month}-${dateObj.date}`;
				const selectedDateStr = `${smallerDate.getFullYear()}-${smallerDate.getUTCMonth() + 1}-${smallerDate.getDate()}`;

				return dateStr === selectedDateStr
			}
			return false;
		},

		isRangeEnd(dateObj) {
			if (this.selectedDates.length === 2) {
				const biggerDate = new Date(Math.max(...this.selectedDates));
				const dateStr = `${this.year}-${this.month}-${dateObj.date}`;
				const selectedDateStr = `${biggerDate.getFullYear()}-${biggerDate.getUTCMonth() + 1}-${biggerDate.getDate()}`;

				return dateStr === selectedDateStr
			}
			return false;
		},

		isInRange(day) {
			if (this.selectedDates.length > 2 || day.date === "") {
				return false;
			}

			const [startDate, endDate] = this.selectedDates;
			const dateStr = `${this.year}-${this.month}-${day.date}`;
			const date = new Date(dateStr);

			return (
				date >= new Date(Math.min(startDate, endDate)) &&
				date <= new Date(Math.max(startDate, endDate))
			);
		},

		startOfDay(date) {
			const start = new Date(date);
			start.setHours(0, 0, 0, 0);
			return start;
		},

		endOfDay(date) {
			const end = new Date(date);
			end.setHours(23, 59, 59, 999);
			return end;
		},

		startOfWeek(date) {
			const currentDate = new Date(date);
			const dayOfWeek = currentDate.getDay();
			const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
			return new Date(currentDate.setDate(diff));
		},

		endOfWeek(date) {
			const currentDate = new Date(date);
			const dayOfWeek = currentDate.getDay();
			const diff = currentDate.getDate() - dayOfWeek + 7;
			return new Date(currentDate.setDate(diff));
		},

		startOfMonth(date) {
			return new Date(date.getFullYear(), date.getMonth(), 1);
		},

		startOfLastMonth(date) {
			return new Date(date.getFullYear(), date.getMonth() - 1, 1);
		},

		endOfMonth(date) {
			return new Date(date.getFullYear(), date.getMonth() + 1, 0);
		},

		endOfLastMonth(date) {
			return new Date(date.getFullYear(), date.getMonth(), 0);
		},

		submit() {
			const currentUrl = window.location.href;
			let newUrl = new URL(currentUrl);
			const queryParams = this.$route.query;

			const start_at = (new Date(this.selectedDates[0].setHours(0, 0, 0, 0))).toISOString();
			const end_at = (new Date(this.selectedDates[1].setHours(23, 59, 59, 999))).toISOString();

			queryParams.start_at = start_at;
			queryParams.end_at = end_at;
			newUrl.searchParams.set('start_at', start_at);
			newUrl.searchParams.set('end_at', end_at);

			window.history.pushState({}, '', newUrl.toString());
			this.$router.replace({path: this.$route.path, query: queryParams });

			this.isOpen = false;
			this.$emit('change-range', [start_at, end_at]);
		},

		formatDate(value) {
			const date = new Date(value);
			if (!isNaN(date.getTime())) {
				const day = date.getDate().toString().padStart(2, "0");
				const month = (date.getMonth() + 1).toString().padStart(2, "0");
				const year = date.getFullYear();
				return `${day}-${month}-${year}`;
			} else {
				return "";
			}
		},
	},

	mounted()
	{
		[this.selectedDates[0], this.selectedDates[1]] = [this.$route.query?.start_at, this.$route?.query?.end_at];

		this.month = (new Date()).getMonth() + 1;
		this.year = (new Date()).getUTCFullYear();
	}
}
</script>