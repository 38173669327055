<template>
    <div class="flex h-dvh" v-for="item in dataset">
        <div class="w-1/3 h-dvh bg-white rounded-lg mr-4 p-6">
            <div>
                <div class="px-4 sm:px-0">
                    <h3 class="text-base font-semibold leading-7 text-gray-900">Customer Information</h3>
                </div>
                <div class="mt-6 border-t border-gray-100">
                    <dl class="divide-y divide-gray-100">
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-sm leading-6 text-gray-500">Name</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ item.flinks_accounts[0].flinks_account_holder.name }}</dd>
                        </div>
                    </dl>
                    <dl class="divide-y divide-gray-100">
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-sm leading-6 text-gray-500">Email</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ item.flinks_accounts[0].flinks_account_holder.email }}</dd>
                        </div>
                    </dl>
                    <dl class="divide-y divide-gray-100">
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-sm leading-6 text-gray-500">Email</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ item.flinks_accounts[0].flinks_account_holder.civic_address }} <br> {{ item.flinks_accounts[0].flinks_account_holder.city }} , {{ item.flinks_accounts[0].flinks_account_holder.postal_code }}</dd>
                        </div>
                    </dl>
                    <dl class="divide-y divide-gray-100">
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-sm leading-6 text-gray-500">Financial Institution</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ item.institution }}</dd>
                        </div>
                    </dl>
                    <dl class="divide-y divide-gray-100">
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-sm leading-6 text-gray-500">Login ID</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ item.flinks_login_id }}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
        <div class="w-2/3 h-dvh bg-white rounded-lg mr-4 p-6">
            <div>
                <div
                    v-if="isLoading"
                    class="flex items-center h-[100px] justify-center"
                >
                    <loader-animation
                        size="24px"
                        border="2.5px solid rgb(209 213 219)"
                    />
                </div>
                <div
                    v-else-if="dataset.length === 0 && !isLoading"
                    class="flex h-[100px] items-center justify-center text-xs text-gray-400"
                >
                    No Data Available...
                </div>
                <div
                    v-else
                    class="flex flex-col"
                >
                    <div
                        v-for="account in item.flinks_accounts"
                        class="group text-sm font-medium text-gray-800 flex flex-col gap-2 rounded-lg bg-slate-100 p-5 mb-6"
                        tabindex="2"
                    >
                        <div class="flex cursor-pointer items-center justify-between mx-12">
                            <span> {{ account.name }} </span>
                            <span> {{ account.institution_number }} {{ account.transit_number }} {{ account.account_number }} </span>
                            <span> {{ account.account_type }} </span>
                            <span> {{ account.currency }} </span>
                        </div>
                        <div class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-full group-focus:opacity-100 group-focus:duration-1000">
                            <table class="min-w-full h-full border-separate border-spacing-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-[11px] text-gray-500 font-medium backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">Date</th>
                                        <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-[11px] text-gray-500 font-medium backdrop-blur backdrop-filter sm:table-cell">Description</th>
                                        <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-[11px] text-gray-500 font-medium backdrop-blur backdrop-filter lg:table-cell">Withdrawals</th>
                                        <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-[11px] text-gray-500 font-medium backdrop-blur backdrop-filter">Deposits</th>
                                        <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-[11px] text-gray-500 font-medium backdrop-blur backdrop-filter">Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(transaction, transactonIdx) in account.flinks_transactions" :key="transaction.flinks_id">
                                        <td :class="[transactonIdx !== transaction.flinks_id.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap hidden px-3 py-4 text-[12px] text-gray-500 sm:table-cell']">{{ transaction.date  }}</td>
                                        <td :class="[transactonIdx !== transaction.flinks_id.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap hidden px-3 py-4 text-[12px] text-gray-500 sm:table-cell']">{{ transaction.description }}</td>
                                        <td :class="[transactonIdx !== transaction.flinks_id.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap hidden px-3 py-4 text-[12px] text-gray-500 lg:table-cell']">{{ transaction.credit }}</td>
                                        <td :class="[transactonIdx !== transaction.flinks_id.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-[12px] text-gray-500']">{{ transaction.debit }}</td>
                                        <td :class="[transactonIdx !== transaction.flinks_id.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-[12px] text-gray-500']">{{ transaction.balance }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import ReloadIcon from "../../assets/icons/ReloadIcon.vue";
import LoaderAnimation from "../../assets/animations/LoaderAnimation.vue";
import ChevronLeftIcon from "../../assets/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon.vue";

export default
{
    emits: ['reload', 'update-limit', 'switch-pages'],

    props: {
        title: {
            type: String,
            default: 'Base Table',
        },
        dataset: {
            type: Array,
            default: [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Array,
            default: []
        },
        total: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            isOpen: true,
            allowedLimits: [5, 10, 25, 50, 75, 100, 150, 175, 200, 250, 500],
            swapLimitIsOpen: false,
            limit: 50,
            page: 1,
        }
    },

    components: {
        ChevronRightIcon,
        ChevronLeftIcon,
        LoaderAnimation,
        ReloadIcon,
        ChevronDownIcon
    },

    watch: {
        '$route.query': {
            handler: function(query) {
                console.log(query);
                this.limit = query?.limit;
            },
            deep: true,
            immediate: true
        }
    },

    methods: {
        reload() {
            this.$emit('reload', this.$route.query);
        },
    },

    mounted() {
        const queryParams = this.$route.query;
        const currentUrl = window.location.href;
        let newUrl = new URL(currentUrl);

        if (!('start_at' in queryParams) || !('end_at' in queryParams)) {
            const currentDate = new Date();
            const startOfDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                0,
                0,
                0
            );
            const endOfDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                23,
                59,
                59
            );

            queryParams.start_at = startOfDay.toISOString();
            queryParams.end_at = endOfDay.toISOString();
            newUrl.searchParams.set('start_at', startOfDay.toISOString());
            newUrl.searchParams.set('end_at', endOfDay.toISOString());
        } else {
            this.start_at = queryParams.start_at;
            this.end_at = queryParams.end_at;
        }

        if (!('page' in queryParams)) {
            newUrl.searchParams.set('page', "1");
            queryParams.page = 1;
            this.page = 1;
        } else {
            this.page = queryParams.page
        }

        if (!('limit' in queryParams)) {
            newUrl.searchParams.set('limit', "50");
            queryParams.limit = 50;
            this.limit = 50;
        } else {
            this.limit = queryParams.limit
        }

        this.$router.replace({path: this.$route.path, query: queryParams });
        window.history.pushState({}, '', newUrl.toString());

        this.reload();
    }
}
</script>

