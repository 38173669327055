<template>
    <div class="fixed side_bar_bg w-full">
        <div class="flex overflow-x-hidden h-screen">
            <aside
                :class="this.SideNavigationIsOpen ? 'w-fit' : 'w-[52px]'"
                class="flex-shrink-0 flex flex-col transition-all duration-700"
            >
                <div
                    :class="this.SideNavigationIsOpen ? 'justify-between' : 'justify-start'"
                    class="min-h-[52px] h-[52px] flex items-center w-full duration-700"
                >
                    <transition
                        enter-active-class="ease-out duration-300 delay-500"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-in duration-0 delay-0"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
						<span
                            v-show="this.SideNavigationIsOpen"
                            class="text-white text-opacity-95 bg-opacity-90 rounded-lg h-[37px] w-[37px] bg-[#4667d2] text-sm flex items-center justify-center font-normal cursor-pointer ml-[14px]"
                        >
							GK
						</span>
                    </transition>
                    <div :class="this.SideNavigationIsOpen ? 'mr-4' : 'ml-[11.5px]'">
                        <button
                            class="text-slate-500 group hover:border-[#c1ccf0] hover:bg-light-dark-blue min-h-[37px] min-w-[37px] flex items-center justify-center hover:rounded-md duration-500 border border-transparent"
                            @click="changeSideBaseState()"
                            aria-controls="sidebar"
                        >
                            <chevron-left-duo-icon
                                :class="[this.SideNavigationIsOpen ? 'rotate-0' : 'rotate-180', 'duration-700']"
                                size="16px"
                                class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                            />
                        </button>
                    </div>
                </div>
                <div class="flex flex-col mt-1 items-center w-[225px] h-full overflow-hidden text-gray-400 rounded">
                    <div class="w-full">
                        <div
                            :class="this.SideNavigationIsOpen ? 'pb-1.5 pt-0.5' : 'py-[14px]'"
                            class="flex flex-col items-center w-full"
                        >
                            <kronos-base-side-navigation-item
                                label="Quick Search"
                                :prevent-click="true"
                                :disable-active-state="true"
                                @click="this.$store.state.QuickSearchIsOpen = !this.$store.state.QuickSearchIsOpen"
                            >
                                <search-duo-icon
                                    size="32px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                                />
                            </kronos-base-side-navigation-item>
                        </div>


                        <div
                            :class="this.SideNavigationIsOpen ? 'gap-1.5 py-[12px]' : 'py-[14px] gap-2'"
                            class="flex flex-col items-center w-full border-gray-700"
                        >
                            <kronos-base-side-navigation-section label="Menu" />
                            <kronos-base-side-navigation-item
                                v-if="this.allowed_role_id.includes(ContextUser.role_id)"
                                label="Dashboard"
                                routing-to="/manager/dashboard"
                            >
                                <dashboard-duo-icon
                                    size="32px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                                />
                            </kronos-base-side-navigation-item>
                            <kronos-base-side-navigation-item
                                v-else
                                label="Dashboard"
                                routing-to="/"
                            >
                                <dashboard-duo-icon
                                    size="32px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                                />
                            </kronos-base-side-navigation-item>
                            <kronos-base-side-navigation-item
                                label="Clients"
                                routing-to="/clients"
                            >
                                <client-duo-icon
                                    size="32px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                                />
                            </kronos-base-side-navigation-item>
                            <kronos-base-side-navigation-item
                                label="Leads"
                                routing-to="/leads"
                            >
                                <file-duo-icon
                                    size="32px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                                />
                            </kronos-base-side-navigation-item>
                        </div>


                        <div v-if="false" class="flex flex-col items-center w-full border-gray-700">
                            <kronos-base-side-navigation-section label="Automation"/>
                            <kronos-base-side-navigation-item
                                label="Transactions Classification"
                                routing-to="/automation/transactions-classification"
                            >
                                <receipt-approved-icon
                                    size="16px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                                />
                            </kronos-base-side-navigation-item>
                        </div>

                        <div :class="[this.SideNavigationIsOpen ? 'gap-1.5 py-[12px]' : 'py-[14px] gap-2', 'flex flex-col items-center w-full ']">
                            <kronos-base-side-navigation-section label="Tools"/>

					        <kronos-base-side-navigation-item
						        label="Lead Dispatcher"
						        routing-to="/tools/lead-dispatcher"
					        >
						        <transfer-duo-icon
							        size="18px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
						        />
					        </kronos-base-side-navigation-item>

                            <kronos-base-side-navigation-item
                                label="IBV"
                                routing-to="/ibv-reports"
                            >
                              <cash-icon
                                  size="32px"
                                  class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                              />
                            </kronos-base-side-navigation-item>

                            <kronos-base-side-navigation-item
                                label="Automations"
                                routing-to="/automation"
                            >
                                <cash-icon
                                    size="32px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                                />
                            </kronos-base-side-navigation-item>

                            <kronos-base-side-navigation-item
                                label="Verifications"
                                routing-to="/lead-verifications"
                            >
                                <verifications-logo
                                    size="32px"
                                    class="group-hover:fill-[#4667d2] fill-[#E7E7E7]"
                                />
                            </kronos-base-side-navigation-item>
				        </div>
                    </div>
				</div>
            </aside>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import KronosBaseSideNavigationItem from "./KronosBaseSideNavigationItem.vue";
import KronosBaseSideNavigationSection from "./KronosBaseSideNavigationSection.vue";
import MenuIcon from "../../assets/icons/MenuIcon.vue";
import DashboardLayoutIcon from "../../assets/icons/DashboardLayoutIcon.vue";
import ReceiptApprovedIcon from "../../assets/icons/ReceiptApprovedIcon.vue";
import SortingArrowsHorizontalIcon from "../../assets/icons/SortingArrowsHorizontalIcon.vue";
import DashboardClientIcon from "../../assets/icons/DashboardClientIcon.vue";
import FileDuoIcon from "../../assets/icons/duotone/FileDuoIcon.vue";
import CashIcon from "../../assets/icons/duotone/CashIcon.vue";
import ClientDuoIcon from "../../assets/icons/duotone/ClientDuoIcon.vue";
import TransferDuoIcon from "../../assets/icons/duotone/TransferDuoIcon.vue";
import DashboardDuoIcon from "../../assets/icons/duotone/DashboardDuoIcon.vue";
import SearchDuoIcon from "../../assets/icons/duotone/SearchDuoIcon.vue";
import ChevronLeftDuoIcon from "../../assets/icons/duotone/ChevronLeftDuoIcon.vue";
import SettingsIcon from "../../assets/icons/SettingsIcon.vue";
import SearchIcon from "../../assets/icons/SearchIcon.vue";
import LogoutDuoIcon from "../../assets/icons/duotone/LogoutDuoIcon.vue";
import KronosBaseLeadSideNavigationForClientPage from "./KronosBaseLeadSideNavigationForClientPage.vue";
import VerificationsLogo from "../../assets/icons/duotone/VerificationsLogo.vue";

export default
{
	components: {
        VerificationsLogo,
		KronosBaseLeadSideNavigationForClientPage,
        LogoutDuoIcon,
        SearchIcon,
        SettingsIcon,
        CashIcon,
		ChevronLeftDuoIcon,
		SearchDuoIcon,
		DashboardDuoIcon,
		TransferDuoIcon,
		ClientDuoIcon,
		FileDuoIcon,
        DashboardClientIcon,
		SortingArrowsHorizontalIcon,
		ReceiptApprovedIcon,
		DashboardLayoutIcon,
		MenuIcon,
		KronosBaseSideNavigationSection,
		KronosBaseSideNavigationItem
	},

    data() {
        return {
            isPanelOpen: false,
            allowed_role_id: [3,4],
        };
    },

    computed: {
        ...mapState([
            'ProfileMenuIsOpen',
            'LeadDetailsIsOpen',
            'SideNavigationIsOpen',
            'ContextUser',
        ]),
    },

    methods: {
        changeSideBaseState() {
            this.$store.state.SideNavigationIsOpen = !this.$store.state.SideNavigationIsOpen;
            localStorage["this.SideNavigationIsOpen"] = this.$store.state.SideNavigationIsOpen;
        },
    }
}
</script>
