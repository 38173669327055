<template>
	<transition
		enter-active-class="transition ease-out duration-200"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="transition ease-out duration-100"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div
			v-if="isOpen"
			class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-60 z-50 flex items-center justify-center"
		>
			<div
				v-click-outside="close"
				class="w-full max-w-[650px] h-fit bg-white rounded-lg"
			>
				<div class="border-b  border-gray-100 h-[55px] flex items-center pl-[12pt] justify-between">
					<span class="font-medium text-[12pt]">
						Update Lead Order
					</span>
					<div class="flex">
						<div
							@click="fetchData()"
							class="border-l flex items-center justify-center border-gray-100 hover:bg-gray-50 duration-300 w-[55px] h-[55px] rounded-r-lg cursor-pointer"
						>
							<reload-icon
								:class="isLoading ? 'animate-spin' : ''"
								size="14px"
								fill="#6b7280"
							/>
						</div>
						<div
							@click="$emit('close')"
							class="border-l flex items-center justify-center border-gray-100 hover:bg-gray-50 duration-300 w-[55px] h-[55px] rounded-r-lg cursor-pointer"
						>
							<close-icon
								size="14px"
								fill="#6b7280"
							/>
						</div>
					</div>
				</div>
				<div v-if="isLoading" class="flex items-center h-[100px] justify-center">
					<loader-animation
						size="24px"
						border="2.5px solid rgb(209 213 219)"
					/>
				</div>
				<div v-else class="px-[14pt]">
					<div
						v-if="contextAccount"
						class="pb-[16pt] flex gap-6 flex-col pt-4"
					>
						<div class="text-center text-[10pt] text-gray-500 pl-[12pt] border-gray-200 border h-[45px] my-[10px] rounded-lg flex items-center justify-between">
							{{ contextAccount.name }}
							<div class="flex">
								<div class="border-l cursor-pointer hover:bg-gray-50 duration-500 w-[45px] h-[45px] flex items-center rounded-r-lg justify-center border-gray-100">
									<input
										v-model="contextAccount.threshold.threshold"
										class="w-[40px] h-[40px] text-center text-[11px]"
									/>
								</div>
							</div>
						</div>
						<div class="flex gap-4">
							<kronos-auth-input icon="DollarIcon" class="w-1/2" icon-size="14px" label="Completed" :value="contextAccount.lead_pricing.completed" v-model:value="contextAccount.lead_pricing.completed" />
							<kronos-auth-input icon="DollarIcon" class="w-1/2" icon-size="14px" label="Not Completed" :value="contextAccount.lead_pricing.not_completed" v-model:value="contextAccount.lead_pricing.not_completed" />
						</div>
						<div class="flex gap-4">
							<kronos-auth-input class="w-1/2" icon-size="14px" label="Daily Limit" :value="contextAccount.threshold.daily_limit" v-model:value="contextAccount.threshold.daily_limit"/>
							<kronos-auth-input class="w-1/2" icon-size="14px" label="Allowed Duplicated Days" :value="contextAccount.threshold.allowed_duplicate_days" v-model:value="contextAccount.threshold.allowed_duplicate_days" />
						</div>
						<hr class="border-gray-100" />
						<div class="flex gap-2">
							<div
								class="w-full rounded text-[9pt] h-7 flex text-gray-400 items-center justify-center cursor-pointer duration-300"
								:class="contextAccount.provinces[item] ? 'text-white bg-gray-800' : 'text-gray-400 border hover:bg-gray-50'"
								@click="contextAccount.provinces[item] = !contextAccount.provinces[item]"
								v-for="(item, index) in states"
							>
								{{ item }}
							</div>
						</div>
					</div>
					<div
						v-else
						class="max-h-[50%] py-[16pt]"
					>
						<div id="items-list" class="flex flex-col gap-4 rounded-lg">
							<div
								v-for="(account, index) in accounts"
								:key="account.id"
								:class="account.threshold.order === null ? 'cursor-not-allowed' : 'cursor-grab'"
								class="border rounded-lg text-gray-500 border-gray-200 h-[45px] pl-[12pt] text-[10pt] flex items-center justify-between duration-500"
								:draggable="account.threshold.order !== null"
								@dragstart="dragStart(index, $event)"
								@dragover="dragOver(index, $event)"
								@dragend="dragEnd"
							>
								<div class="flex items-center gap-2">
									{{ account.name }}
									<span
										v-if="account.threshold.order === null"
										class="text-rose-600 text-[10px]"
									>
										(inactive)
									</span>
									<span
										v-else
										class="text-emerald-600 text-[10px]"
									>
										(active)
									</span>
								</div>
								<div class="flex">
									<div
										v-if="account.threshold.order === null"
										@click="enableCompany(index)"
										class="border-l cursor-pointer hover:bg-gray-50 duration-500 w-[45px] h-[45px] flex items-center rounded-r-lg justify-center border-gray-100"
									>
										<play-icon
											v-if="account.threshold.order === null"
											size="14px"
											fill="#6b7280"
										/>
									</div>
									<div
										v-else
										@click="disableCompany(index)"
										class="border-l cursor-pointer hover:bg-gray-50 duration-500 w-[45px] h-[45px] flex items-center rounded-r-lg justify-center border-gray-100">
										<pause-icon
											size="14px"
											fill="#6b7280"
										/>
									</div>
									<div class="border-l cursor-pointer hover:bg-gray-50 duration-500 w-[45px] h-[45px] flex items-center rounded-r-lg justify-center border-gray-100">
										<input
											v-model="account.threshold.threshold"
											class="w-[40px] h-[40px] text-center text-[11px]"
										/>
									</div>
									<div
										@click="editAccount(index)"
										class="border-l cursor-pointer hover:bg-gray-50 duration-500 w-[45px] h-[45px] flex items-center rounded-r-lg justify-center border-gray-100"
									>
										<tune-icon
											size="14px"
											fill="#6b7280"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="!isLoading"
					class="h-[50px] rounded-b-lg flex items-center justify-around w-full bg-white border-t border-gray-100"
				>
					<div class="w-1/2">
						<button
							@click="contextAccount ? contextAccount = null : isOpen = false"
							class="cursor-pointer text-black text-base font-medium tracking-wide bg-gray-200 h-[50px] rounded-bl-lg w-full hover:bg-rose-100 hover:text-rose-600 duration-500"
						>
							Cancel
						</button>
					</div>
					<div class="w-1/2">
						<button
							@click="contextAccount ? this.updateAccount(contextAccount?.id, contextAccount) : updateAccounts()"
							class="cursor-pointer text-white text-base font-medium tracking-wide bg-black h-[50px] rounded-br-lg w-full hover:bg-gray-800 duration-500"
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import CloseIcon from "../../../assets/icons/CloseIcon.vue";
import {RetrieveAccounts} from "../../../services/LeadDispatcher";
import TuneIcon from "../../../assets/icons/TuneIcon.vue";
import PlayIcon from "../../../assets/icons/PlayIcon.vue";
import PauseIcon from "../../../assets/icons/PauseIcon.vue";
import LoaderAnimation from "../../../assets/animations/LoaderAnimation.vue";
import KronosAuthInput from "../../../UI/BaseComponents/KronosAuthInput.vue";
import KronosBaseSelect from "../../../UI/BaseComponents/KronosBaseSelect.vue";
import { UpdateAccount } from "../../../services/LeadDispatcher";
import NotificationMixin from "../../../mixins/NotificationMixin";
import ReloadIcon from "../../../assets/icons/ReloadIcon.vue";

export default {
	emits: ['close'],

	components: {
		ReloadIcon,
		KronosBaseSelect,
		KronosAuthInput,
		LoaderAnimation,
		PauseIcon,
		PlayIcon,
		TuneIcon,
		CloseIcon
	},

	mixins: [
		NotificationMixin,
	],

	props: {
		isOpen: {
			type: Boolean,
			default: false,
		}
	},

	data() {
		return  {
			accounts: [],
			isLoading: false,
			contextAccount: null,
			states: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YU'],
		}
	},

	watch: {
		'accounts': {
			handler: function(query) {
				query.forEach((item, index) => {
					if (item.threshold.order !== null) {
						item.threshold.order = index + 1;
					}
				});
			},
			deep: true,
			immediate: true
		},
		'isOpen': {
			handler: function(query) {
				if (query) {
					this.fetchData();
				}
			},
			deep: true,
			immediate: true
		}
	},

	methods: {
		dragStart(index, event) {
			this.draggedItem = { index };
			event.dataTransfer.effectAllowed = 'move';
		},
		dragOver(index, event) {
			event.preventDefault();
			const draggedIndex = this.draggedItem.index;

			if (index !== draggedIndex) {
				// Reorder items
				const itemsCopy = [...this.accounts];
				const [draggedItem] = itemsCopy.splice(draggedIndex, 1);
				itemsCopy.splice(index, 0, draggedItem);
				this.accounts = itemsCopy;
				this.draggedItem.index = index;
				this.accounts.forEach((account, index) => {
					if (account.threshold.order !== null) {
						account.threshold.order = index + 1;
					}
				})
			}
		},

		close() {
			this.contextAccount = null,
				this.$emit('close');
		},

		dragEnd() {
			this.draggedItem = null;
		},

		sortedAccounts(accounts) {
			// Custom sorting function
			const customSort = (a, b) => {
				const orderA = a.threshold.order;
				const orderB = b.threshold.order;

				if (orderA === null && orderB !== null) {
					return 1; // Move objects with null order to the bottom
				} else if (orderA !== null && orderB === null) {
					return -1; // Move objects with null order to the bottom
				} else if (orderA === null && orderB === null) {
					return 0; // Both have null order, maintain their relative order
				} else {
					return orderA - orderB; // Compare numeric order values
				}
			};

			return accounts.slice().sort(customSort);
		},

		enableCompany(index) {
			this.accounts[index].threshold.order = index + 1;
			this.accounts = this.sortedAccounts(this.accounts);
		},

		disableCompany(selectedIndex) {
			if (selectedIndex >= 0 && selectedIndex < this.accounts.length) {
				// Get the element at the selected index
				this.accounts[selectedIndex].threshold.order = null;
				const selectedElement = this.accounts[selectedIndex];

				// Remove the element from its current position
				this.accounts.splice(selectedIndex, 1);

				// Push the element to the end of the array
				this.accounts.push(selectedElement);

				this.accounts = this.sortedAccounts(this.accounts);
			}
		},

		async fetchData() {
			this.isLoading = true;
			await RetrieveAccounts()
				.then(response => {
					if (response.status === 200) {
						this.accounts = this.sortedAccounts(response.data.data.accounts);
					}
				})

			this.isLoading = false;
		},

		editAccount(index) {
			this.contextAccount = this.accounts[index];
		},

		async updateAccounts() {
			this.isLoading = true;

			for (const account of this.accounts) {
				await UpdateAccount(account['id'], account);
			}

			this.isLoading = false;

			this.close();

			this.showNotification('success', 'Accounts Successfully Updated');
		},

		async updateAccount(id, payload) {
			this.contextAccount ? this.isLoading = true : '';
			await UpdateAccount(id, payload);
			this.contextAccount ? this.isLoading = false : '';
			this.showNotification('success', 'Account Successfully Updated');
			this.contextAccount = null
		}
	},

	mounted() {
		this.fetchData()
	}
}
</script>

<style scoped>
#items-list {
	list-style: none;
	padding: 0;
}
</style>