<template>
	<div class="h-screen w-screen">

        <kronos-password-update-form-on-login />

        <kronos-base-email-editor v-if="this.GlobalEmailEditor.IsOpen" />

        <router-view
			v-if="!IsLoading"
		/>

        <transition
			enter-active-class="ease-out duration-500"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="ease-in duration-500"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
            <kronos-page-loader />
        </transition>
    </div>
</template>

<script>
import KronosPageLoader from "./UI/BaseComponents/KronosPageLoader.vue";
import { mapState } from "vuex";
import KronosBaseActionModal from "./UI/BaseComponents/KronosBaseActionModal.vue";
import KronosAuthInput from "./UI/BaseComponents/KronosAuthInput.vue";
import KronosPasswordUpdateFormOnLogin from "./UI/BaseComponents/KronosPasswordUpdateFormOnLogin.vue";
import KronosBaseEmailEditor from "./UI/BaseComponents/KronosBaseEmailEditor.vue";

export default
{
	components: {
        KronosBaseEmailEditor,
        KronosPasswordUpdateFormOnLogin,
        KronosAuthInput,
        KronosBaseActionModal,
		KronosPageLoader
	},

	computed: {
		...mapState([
			'IsLoading',
            'GlobalEmailEditor'
		]),
	},

    methods: {
        SetDate() {
            const options = { timeZone: 'America/Montreal', hour12: false };
            this.$store.state.StartAt = this.$route.query.start_at ?? new Date(new Date().setHours(0, 0, 0, 0)).getTime();
            this.$store.state.EndAt = this.$route.query.end_at ?? new Date(new Date().setHours(23, 59, 59, 999)).getTime();
        }
    },

    mounted() {
        this.SetDate();
    },

    created() {
        localStorage.theme = !('theme' in localStorage) ? 'light' : localStorage.theme;

        localStorage.getItem('theme') === 'dark'
            ? document.documentElement.classList.add('dark')
            : document.documentElement.classList.remove('dark')
    },
}
</script>
