<template>
	<div
		:class="[isOpen ? 'rounded-t-[6px] text-blue-500' : 'rounded-[6px] text-gray-400', 'gap-1 h-fit flex focus-within:text-blue-500 duration-500 flex-col w-full']"
        v-click-outside="close"
        @click="isOpen = !isOpen"
    >
        <label :class="[isOpen ? 'text-blue-500' : 'text-gray-400', 'text-[10px] flex items-center h-[16px] tracking-wide font-medium']">
            {{ label }}
        </label>
		<div
            :class="isOpen ? 'bg-blue-100 border-blue-500' : 'bg-gray-100 border-transparent'"
			class="flex items-center h-full border-[1px] w-full justify-between duration-500 rounded-[6px] text-base text-gray-400 cursor-pointer relative"
		>
            <div class="flex items-center justify-between w-full">
                <span
                    :class="isOpen ? 'text-blue-500' : 'text-gray-400'"
                    class="pl-[14px] text-[11px] font-medium tracking-wide"
                >
                    {{ value }}
                </span>
                <div class="w-[32px] h-[32px] flex items-center justify-center">
                    <chevron-down-icon
                        size="12px"
                        :class="isOpen ? 'fill-blue-500' : 'fill-gray-400'"
                    />
                </div>
            </div>
            <div
                v-if="isOpen"
                class="absolute top-0 bg-transparent w-full  text-[10pt] z-10 mt-[44px]"
            >
                <div class="custom-shadow-1 | rounded-[6px] bg-white">
                    <div
                        v-for="option in options"
                        class="text-[11px] h-[32px] font-medium last-of-type:rounded-b-[6px] first-of-type:rounded-t-[6px] tracking-wide flex items-center px-[12pt] hover:text-blue-500 cursor-pointer hover:bg-blue-100 duration-300 text-gray-400"
                        @click="updateValue(option.label)"
                    >
                        {{ option['label'] }}
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon.vue";
import CloseIcon from "../../assets/icons/CloseIcon.vue";

export default
{
	emits: ['update-value'],

	components: {
		CloseIcon,
		ChevronDownIcon
	},

	props: {
		label: {
			type: String,
			default: 'Select'
		},
		value: {
			type: String,
			default: null,
		},
		options: {
			type: Array,
			default: [
                {'label': 'TRUE'},
                {'label': 'FALSE'},
            ],
		},
		key: {
			type: String,
			default: 'key'
		}
	},

	data() {
		return {
			isOpen: false,
		}
	},

	methods: {
		updateValue(value)
		{
			this.$emit('update-value', value)
		},

        close() {
            this.isOpen = false;
        }
	}
}
</script>
