<template>
    <view-all-modal
        :IsOpen="modal.viewAll"
        :Title="`All ${selectedViewAllNoteType.label} notes`"
        :NotesData="ViewAllNotes"
        :NotesType="selectedViewAllNoteType"
        @IsClose="closeViewAllModal"
        @update="refreshNoteCards"
    />

    <delete-modal
        :IsOpen="modal.delete"
        :SelectedOpen="selectedNote"
        @close="modal.delete = false"
        @update="refreshNoteCards"
    />

    <update-modal
        :Note="selectedNote"
        :IsOpen="modal.update"
        @close="modal.update = false"
        @update="refreshNoteCards"
    />

    <div class="w-full flex gap-[16px]">
        <NoteCard
            v-for="(NoteType, Index) in NoteTypes"
            :ref="`noteCard-${Index}`"
            :key="Index"
            :ViewAll="true"
            :NoteType="NoteType"
            :NoteData="this.notes"
            :Title="NoteType.label"
            class="custom-shadow-1 | w-1/3 bg-white"
            @view-all-modal="openViewAllModal"
            @update-modal="openUpdateModal"
            @delete-modal="openDeleteModal"
        />
    </div>
</template>

<script>
import {mapState} from "vuex";
import NoteCard from "./card/NoteCard.vue";
import KronosBaseInformationCard from "../../../UI/BaseComponents/KronosBaseInformationCard.vue";
import leadGeneralInformation from "../LeadGeneralInformation.vue";
import NotificationMixin from "../../../mixins/NotificationMixin";
import TextField from "./ui/TextField.vue";
import Note from "./ui/Note.vue";
import UpdateModal from "./modal/UpdateModal.vue";
import DeleteModal from "./modal/DeleteModal.vue";
import ViewAllModal from "./modal/ViewAllModal.vue";
import KronosBaseTaskModal from "../../../UI/BaseComponents/KronosBaseTaskModal.vue";

export default
{
    mixins: [
        NotificationMixin
    ],

    components: {
        KronosBaseInformationCard,
        leadGeneralInformation,
        KronosBaseTaskModal,
        TextField,
        Note,
        UpdateModal,
        DeleteModal,
        NoteCard,
        ViewAllModal
    },

    data() {
        return {
            isLoading: false,
            ViewAllNotes: [],
            selectedViewAllNoteType: {},
            selectedNote: {},
            notes: [],
            modal: {
                update: false,
                delete: false,
                viewAll: false
            },
        };
    },

    computed: {
        ...mapState([
            'ContextLead',
            'ContextUser'
        ]),
        ...mapState('entities', ["NoteTypes"]),
    },

    methods: {
        closeViewAllModal() {
            this.ViewAllNotes = [];
            this.selectedViewAllNoteType = {};
            this.refreshNoteCards();
            this.modal.viewAll = false;
        },

        openViewAllModal(NoteType) {
            this.ViewAllNotes = NoteType.data;
            this.selectedViewAllNoteType = NoteType.selected;
            this.modal.viewAll = true;
        },

        openDeleteModal(data) {
            this.selectedNote = {id: data.id};
            this.modal.delete = true;
        },

        openUpdateModal(data) {
            this.selectedNote = {
                id: data.id,
                note_type_id: data.note_type_id,
                note: data.note
            }

            this.modal.update = true
        },

        refreshNoteCards() {
            this.NoteTypes.forEach((_, index) => {
                this.$refs[`noteCard-${index}`][0].getLeadNotes();
            });
        },
    },

    mounted() {
        this.$store.state.lead_id = this.$route.params.id
    },
}
</script>
