import api from './Api';

const BaseURL = 'v1/user-notification';

export const UpdateUserNotification = async (id, data) =>
{
    return await api().put(`${BaseURL}/${id}`, data)
}

export const RetrieveUserNotifications = async (user_id) =>
{
    return await api().get(`${BaseURL}/${user_id}`)
};