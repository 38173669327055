<template>
    <div class="w-1/2 h-full">
        <div class="h-[50px] w-full bg-white rounded-t-[4pt]">
            <p class="h-[50px] capitalize w-full border-b font-medium border-gray-100 tracking-wide text-[13px] text-[#6F6F6F] flex items-center pl-[18px]">
                General Information
            </p>
        </div>
        <div class="custom-height | min-h-[50px] w-full bg-white rounded-b-[4pt]">
            <div class="h-fit p-[18px] flex items-center justify-between gap-[16px] w-full bg-[#F9F9F9]">
                <div class="custom-shadow-0 | py-[6px] f px-[10px] rounded-[4pt] h-fit w-full bg-white">
                    <p class="text-[10.5px] text-[#464646]">Responsible</p>
                    <base-table-filter-dropdown
                        class="mt-2 mb-1"
                        :selected-option="this.responsible"
                        :options="this.ResponsibleOptions"
                        placeholder="Select a Responsible"
                        @update="this.responsible = $event"
                    />
                </div>
                <div class="custom-shadow-0 | py-[6px] px-[10px] rounded-[4pt] h-fit w-full bg-white">
                    <p class="text-[10.5px] text-[#464646]">Manager</p>
                    <base-table-filter-dropdown
                        class="mt-2 mb-1"
                        :selected-option="this.manager"
                        :options="this.ResponsibleOptions"
                        placeholder="Select a Responsible"
                        @update="this.manager = $event"
                    />
                </div>
            </div>
            <div>
                <div>
                    <p class="h-[50px] capitalize w-full font-medium tracking-wide text-[11px] text-[#6F6F6F] flex items-center pl-[18px]">
                        Loan Request Details
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTableFilterDropdown from "../../../UI/Tables/BaseTableFilterDropdown.vue";

export default
{
    components: {
        BaseTableFilterDropdown
    },

    data() {
        return {
            responsible: null,
            manager: null,
        }
    },

    computed: {
        ResponsibleOptions() {
            let responsibles = []

            this.$store.state.ContextUsers.forEach(item => {
                responsibles.push({label: `${item.first_name} ${item.last_name}`, value: item.id})
            })

            return responsibles;
        }
    },

    mounted() {
        this.responsible = this.$store.state.ContextLead.user_id;
        this.manager = this.$store.state.ContextLead.manager_id;
    }
}
</script>

<style scoped>
.custom-height
{
    height: calc(100% - 50px)
}
</style>