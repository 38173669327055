<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        :width="size"
        :height="size"
        :fill="fill"
    >
        <path
            fill-rule="evenodd"
            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
            clip-rule="evenodd"/>
    </svg>
</template>

<script>
export default
{
    props: {
        fill: {
            type: String,
            default: '#000000',
        },
        size: {
            type: String,
            default: '48px',
        }
    }
}
</script>
