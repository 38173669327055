<template>
    <add-custom-filter-modal
        v-if="AddCustomFilterModalIsOpen"
        :is-open="AddCustomFilterModalIsOpen"
        @close="AddCustomFilterModalIsOpen = false"
        :lenders="ContextUser?.account.lenders"
        @update-data="RetrieveCustomFilters"
    />

	<div class="h-screen w-screen">
		<kronos-base-side-navigation />

        <div
            :class="SideNavigationIsOpen ? 'left-[225px] full-width-1' : 'left-[58px] full-width-5'"
            class="fixed top-0 h-[52px] bg-white gap-[24px] z-10 border-b border-gray-200 flex items-center justify-between w-full border-l px-[22px] duration-500 bottom-shadow"
        >
            <div>
                <div
                    v-if="this.$route.name === 'UserProfilePage'"
                    class="flex gap-[4px] bg-gray-100 p-[4px] rounded-[6pt] cursor-pointer"
                >
                    <div
                        v-for="(Option, Index) in this.UserProfileSectionOptions"
                        :key="Index"
                        @click="this.$store.state.SelectedUserProfileSection = Index"
                        :class="SelectedUserProfileSection === Index ? 'bg-gray-200 text-[#272727]' : 'bg-gray-100 hover:text-[#272727]'"
                        class="w-[90px] text-[#818181] h-[24px] hover:bg-gray-200 rounded-[4pt] duration-500 tracking-wide flex items-center justify-center text-[10.5px]"
                    >
                        {{ Option.label }}
                    </div>
                </div>

                <div
                    v-if="['Dashboard', 'leads-page'].includes(this.$route.name)"
                    class="flex"
                >
                    <div
                        @click="CustomFiltersIsOpen =! CustomFiltersIsOpen"


                        class="relative flex gap-[4px] text-[#363636] font-medium items-center justify-between text-[9.5px] text-gray bg-[#EEEEEE] capitalize h-[30px] w-[175px] tracking-wide pl-[12px] rounded-[2pt] cursor-pointer"
                    >
                        <div class="text-[#909090] flex items-center h-[30px] mt-0.5">
                            {{ SelectedCustomFilter ?? `Custom Filters` }}
                        </div>
                        <div class="w-[30px] h-[30px] flex items-center justify-center">
                            <chevron-left-small-icon
                                class="rotate-[-90deg]"
                                size="14px"
                                color="#909090"
                            />
                        </div>
                        <div
                            v-if="CustomFiltersIsOpen"
                            class="w-full h-fit py-[4px] left-0 absolute bg-white custom-shadow-1 rounded-[4pt] top-[38px]"
                        >
                            <div
                                v-for="Filter in CustomFilters"
                                :key="Filter.id"
                                class="relative px-[12px] h-[24px] pt-[1px] duration-500 flex items-center text-[#909090] hover:text-theme-blue justify-start hover:bg-theme-blue hover:bg-opacity-20 group"
                            >
                                <a
                                    :href="`${Filter.model === 'LEAD' ? 'leads' : 'clients'}${this.GenerateLink(Filter.query)}`"
                                    class="flex-1"
                                >
                                    {{ Filter.name_en }}
                                </a>
                                <cross-icon
                                    v-if="ContextUser.id === Filter.user_id"
                                    @click="DeleteCustomFilter(Filter.id)"
                                    class="hidden group-hover:flex cursor-pointer ml-2"
                                    size="12px"
                                    color="#909090"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        @click="AddCustomFilterModalIsOpen = true; this.CustomFiltersIsOpen = false"
                        class="group ml-[4px] cursor-pointer right-[-34px] flex items-center justify-center hover:bg-theme-blue duration-500 hover:bg-opacity-20 rounded-[2pt] w-[30px] h-[30px]"
                    >
                        <cross-icon
                            class="hidden group-hover:block rotate-[-45deg]"
                            color="rgb(64 141 188)"
                            size="10px"
                        />
                        <cross-icon
                            class="block group-hover:hidden rotate-[-45deg]"
                            color="#909090"
                            size="10px"
                        />
                    </div>
                </div>
            </div>
            <div class="relative w-full flex justify-end items-center">
                <bell-icon color="rgb(107,114,128)" size="18px" stroke-width="2"/>
                <span v-if="notifications_count > 0" class="bg-red-700 text-[8px] text-white rounded-full h-[10px] w-[10px] flex items-center justify-center absolute bottom-2 mr-[-2px]">{{ notifications_count }}</span>
            </div>
            <div class="flex gap-[12px]">
                <div
                    class="w-[124px]"
                    v-click-outside="CloseTeamSelection"
                >
                    <div @click="this.$store.state.TeamSelectionIsOpen = !this.$store.state.TeamSelectionIsOpen" class="relative w-full h-full flex text-[11.5px] hover:bg-[#F7F7F7] rounded-[4pt] px-[10px] group py-[6px] items-center justify-between duration-500 cursor-pointer">
                        <chevron-left-small-icon
                            color="white"
                            size="18px"
                        />
                        <div class="w-full flex items-center justify-start text-[#818181] text-[10.5px] mt-0.5 tracking-wide group-hover:text-[#272727] duration-500">
                            {{ this.$store.state.TeamOptions[SelectedTeam].label }}
                        </div>
                        <chevron-left-small-icon
                            color="gray"
                            class="rotate-[-90deg]"
                            size="18px"
                        />
                    </div>

                    <div v-if="this.$store.state.TeamSelectionIsOpen" class="absolute w-[118px] border border-[#F2F2F2] h-fit bg-white py-[4px] top-[48px]">
                        <div
                            v-for="(Team, Index) in this.$store.state.TeamOptions"
                            :key="Index"
                            @click="this.$store.state.SelectedTeam = Index; this.$store.state.TeamSelectionIsOpen = false"
                            class="group w-full min-h-[28px] text-gray-600 rounded flex items-center"
                        >
                            <div class="pl-[20px] flex items-center justify-center hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                <span class="whitespace-nowrap flex items-center text-center tracking-wide justify-start w-full text-[10.5px] mt-0.5 font-normal text-[#818181] group-hover:text-[#272727]">
                                    {{ Team.label }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-click-outside="closeProfileMenu"
                    @click="ProfileMenuIsOpen = !ProfileMenuIsOpen"
                    class="relative h-[32px] w-[32px] cursor-pointer justify-center bg-gray-100 flex items-center rounded-full text-gray-500 text-[12.5px] font-semibold"
                >
                    {{ ContextUser.first_name[0] + ContextUser.last_name[0] }}
                    <div
                        class="h-[10px] w-[10px] rounded-full absolute bottom-0 right-0"
                        :class="StatusColor"
                    >
                    </div>
                    <transition
                        enter-active-class="ease-out duration-300"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-in duration-300"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <div
                            v-if="ProfileMenuIsOpen"
                            v-click-outside="closeProfileMenu"
                            class="custom-shadow-1 | bg-white w-[150px] cursor-pointer border border-[#F2F2F2] h-auto absolute top-[38px] right-[0px] flex flex-col justify-center items-start"
                        >
                            <a :href="`/user/${ContextUser.id}`" class="group w-full  min-h-[28px] text-gray-600 rounded mt-[4px] flex items-center">
                                <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                    <user-duo-icon
                                        size="14px"
                                        class="fill-gray-[#C0C0C0]"
                                    />
                                    <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                        My Profile
                                    </span>
                                </div>
                            </a>
                            <a :href="`/users`" class="group w-full  min-h-[28px] text-gray-600 rounded flex items-center mb-[4px]">
                                <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                    <users-duo-icon
                                        size="14px"
                                        class="fill-gray-[#C0C0C0]"
                                    />
                                    <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                        My Account
                                    </span>
                                </div>
                            </a>
                            <a href="/settings" class="group w-full border-t border-[#F2F2F2] min-h-[28px] pt-[4px] text-gray-600 rounded flex items-center">
                                <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                    <settings-icon
                                        size="14px"
                                        class="fill-gray-[#C0C0C0]"
                                    />
                                    <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                        Teams
                                    </span>
                                </div>
                            </a>
                            <a href="/settings" class="group w-full min-h-[28px] text-gray-600 rounded flex items-center">
                                <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                    <settings-icon
                                        size="14px"
                                        class="fill-gray-[#C0C0C0]"
                                    />
                                    <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                        Schedule
                                    </span>
                                </div>
                            </a>
                            <a href="/settings" class="group w-full min-h-[28px] text-gray-600 rounded flex items-center">
                                <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                    <settings-icon
                                        size="14px"
                                        class="fill-gray-[#C0C0C0]"
                                    />
                                    <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                        Accounts
                                    </span>
                                </div>
                            </a>
                            <a href="/settings" class="group w-full min-h-[28px] text-gray-600 rounded flex items-center mb-[4px]">
                                <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                    <settings-icon
                                        size="14px"
                                        class="fill-gray-[#C0C0C0]"
                                    />
                                    <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                        Users
                                    </span>
                                </div>
                            </a>
                            <div class="py-[4px] w-full border-t border-[#F2F2F2]">
                                <a
                                    @click="this.UpdateUserActivityStatus(1)"
                                    class="group w-full min-h-[28px] text-gray-600 rounded flex items-center"
                                >
                                    <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                        <div class="w-[14px] flex items-center justify-center h-[14px]">
                                            <div class="h-[8px] w-[8px] bg-green-500 rounded-full"></div>
                                        </div>
                                        <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                            Active
                                        </span>
                                    </div>
                                </a>
                                <a
                                    @click="this.UpdateUserActivityStatus(2); AwayUntilIsOpen=true"
                                    class="group w-full min-h-[28px] text-gray-600 rounded flex items-center"
                                >
                                    <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                        <div class="w-[14px] flex items-center justify-center h-[14px]">
                                            <div class="h-[8px] w-[8px] bg-amber-500 rounded-full"></div>
                                        </div>
                                        <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                            Away
                                        </span>
                                    </div>
                                </a>
                                <a
                                    @click="this.UpdateUserActivityStatus(3)"
                                    class="group w-full min-h-[28px] text-gray-600 rounded flex items-center"
                                >
                                    <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                        <div class="w-[14px] flex items-center justify-center h-[14px]">
                                            <div class="h-[8px] w-[8px] bg-red-500 rounded-full"></div>
                                        </div>
                                        <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                            Inactive
                                        </span>
                                    </div>
                                </a>
                            </div>
                            <div class="py-[4px] border-t border-[#F2F2F2] w-full">
                                <a href="/settings" class="group w-full min-h-[28px] text-gray-600 rounded flex items-center">
                                    <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                        <settings-icon
                                            size="14px"
                                            class="fill-gray-[#C0C0C0]"
                                        />
                                        <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                            Ticket
                                        </span>
                                    </div>
                                </a>
                                <a href="/settings" class="group w-full min-h-[28px] text-gray-600 rounded flex items-center">
                                    <div class="flex items-center justify-start hover:bg-[#F7F7F7] h-[28px] duration-500 cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                        <settings-icon
                                            size="14px"
                                            class="fill-gray-[#C0C0C0]"
                                        />
                                        <span class="whitespace-nowrap text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">
                                            Suggestions
                                        </span>
                                    </div>
                                </a>
                            </div>
                            <div class="border-t border-[#F2F2F2] py-[4px] cursor-pointer w-full">
                                <a @click="logout" class="cursor-pointer group w-full min-h-[28px] text-gray-600 rounded flex items-center">
                                    <div class="flex items-center justify-start hover:bg-[#F7F7F7] duration-500 h-[28px] cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                        <logout-duo-icon
                                            size="14px"
                                            fill="#4b5563"
                                            class="group-hover:fill-red-600"
                                        />
                                        <span class="whitespace-nowrap cursor-pointer text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">Logout</span>
                                    </div>
                                </a>
                                <a @click="GlobalLogout" class="cursor-pointer group w-full min-h-[28px] text-gray-600 rounded flex items-center">
                                    <div class="flex items-center justify-start hover:bg-[#F7F7F7] duration-500 h-[28px] cursor-pointer border border-transparent w-full gap-[7px] px-[10px]">
                                        <logout-duo-icon
                                            size="14px"
                                            fill="#4b5563"
                                            class="group-hover:fill-red-600"
                                        />
                                        <span class="whitespace-nowrap cursor-pointer text-[10.5px] mt-0.5 font-normal tracking-wide text-[#818181] group-hover:text-[#272727]">Logout <span class="text-[9px]">(All Devices)</span></span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

		<kronos-quick-search />

		<kronos-notification-manager />

		<div
            :class="[
                this.SideNavigationIsOpen ? 'ml-[225px] full-width-1' : 'ml-[58px] full-width-5',
                'full-height overflow-y-auto p-[16px] fixed duration-500 bg-[#EFEFEF] inner-shadow-left'
            ]"
		>
			<div class="mx-auto h-full">
				<slot />
			</div>
			<transition
				enter-active-class="slide-enter-active"
				leave-active-class="slide-leave-active"
			>
				<div
					:class="[!!LeadDetailsIsOpen ? 'w-[225px]' : 'w-0', SideNavigationIsOpen ? 'ml-[352px]' : 'ml-[165px]',  'h-screen duration-500']"
					class="inner-shadow-left | bg-white h-auto text-center fixed top-0 left-2 flex flex-col items-start gap-3"
					style="z-index: 100"
				>
					<div :class="[LeadDetailsIsOpen ? 'block' : 'hidden', 'delay-500']">
						<div class="w-[225px] flex flex-col text-gray-600 text-center items-center justify-center">
                            <div class="w-[225px] h-16 bg-gray-200 pt-5 uppercase text-xs font-semibold tracking-wider text-gray-400"></div>
                            <div class="w-full-1 mt-[-40px]">
                                <div class="custom-shadow-0 | h-[70px] w-[70px] bg-white rounded-full flex items-center justify-center">
                                    <div class="h-[60px] w-[60px] bg-gray-100 rounded-full flex items-center justify-center">
                                        <user-duo-icon
                                            size="24px"
                                            class="fill-[#E7E7E7] group-hover:fill-[#4667d2]"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col gap-1 font-semibold mt-5">
                                <span class="w-full text-center text-md tracking-wider">{{ this.$store.state?.HoveredLead.client?.first_name }} {{ this.$store.state?.HoveredLead.client?.last_name }}</span>
                                <span class="text-center text-xs tracking-wider text-gray-300">{{ this.$store.state?.HoveredLead?.amount }} $</span>
                            </div>
                            <div class="w-[200px] border-t border-gray-100 text-xs mt-10 py-3 font-medium text-gray-300 space-y-2 uppercase">
                                <div class="flex justify-between"><span class="text-gray-400"> Status</span>  <span>Not Paid</span></div>
                                <div class="flex justify-between"><span class="text-gray-400">Next payment</span> <span>24/03/2024</span> </div>
                            </div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import KronosNotificationManager from "../NotificationManager/KronosNotificationManager.vue";
import KronosBaseSideNavigation from "../Navigation/KronosBaseSideNavigation.vue";
import SettingsIcon from "../../assets/icons/SettingsIcon.vue";
import ClientDuoIcon from "../../assets/icons/duotone/ClientDuoIcon.vue";
import ProfileDuoIcon from "../../assets/icons/duotone/UserDuoIcon.vue";
import LogoutDuoIcon from "../../assets/icons/duotone/LogoutDuoIcon.vue";
import { Logout, GlobalLogout } from "../../services/Authentication";
import UserDuoIcon from "../../assets/icons/duotone/UserDuoIcon.vue";
import UsersDuoIcon from "../../assets/icons/duotone/UsersDuoIcon.vue";
import vClickOutside from "click-outside-vue3";
import KronosBaseLeadSideNavigationForClientPage from "../Navigation/KronosBaseLeadSideNavigationForClientPage.vue";
import KronosQuickSearch from "../BaseComponents/KronosQuickSearch.vue";
import ChevronLeftSmallIcon from "../../assets/svg/ChevronLeftSmallIcon.vue";
import {UpdateUser} from "../../services/User";
import KronosBaseActionModal from "../BaseComponents/KronosBaseActionModal.vue";
import Pusher from "pusher-js";
import BellIcon from "../../assets/svg/BellIcon.vue";
import notificationMixin from "../../mixins/NotificationMixin";
import store from "../../store";
import {UpdateUserTask} from "../../services/UserTask";
import {RetrieveUserNotifications, UpdateUserNotification} from "../../services/UserNotification";
import {RetrieveAllCustomFilter, DestroyCustomFilter, StoreCustomFilter} from "../../services/CustomFilter";
import CrossIcon from "../../assets/svg/CrossIcon.vue";
import AddCustomFilterModal from "../../components/CustomFilters/AddCustomFilterModal.vue";
import NotificationMixin from "../../mixins/NotificationMixin";

export default
{
    mixins : [
        NotificationMixin
    ],

    data() {
        return {
            ProfileMenuIsOpen: false,
            AwayUntilIsOpen: false,
            CustomFilters: [],
            CustomFiltersIsOpen: false,
            AddCustomFilterModalIsOpen: false,
            notifications : [],
            notifications_count: 0,
        }
    },

    computed: {
		...mapState([
            'ContextUser',
			'ProfileMenuIsOpen',
			'LeadDetailsIsOpen',
			'SideNavigationIsOpen',
			'ContextUser',
            'SelectedTeam',
            'TeamSelectionIsOpen',
            'TeamOptions',
            'UserProfileSectionOptions',
            'SelectedUserProfileSection',
            'NotificationCount',
		]),

		hasFolderWidth() {
			return ['ClientPage', 'LeadPage'].includes(this.$route.name);
		},

        SelectedCustomFilter() {
            let custom_filter = null;

            this.CustomFilters.forEach(Filter => {
                let query = Filter.query;

                if (this.$route.query.start_at) {
                    query += `&start_at=${this.$route.query.start_at}`
                }

                if (this.$route.query.end_at) {
                    query += `&end_at=${this.$route.query.end_at}`
                }

                if (this.$route.fullPath.includes(query)) {
                    custom_filter = Filter.name_en;
                }
            })

            return custom_filter;
        },

        StatusColor() {
            switch (this.ContextUser.activity_status.activity_status_name) {
                case 'ACTIVE':
                    return 'bg-green-500';
                case 'AWAY':
                    return 'bg-amber-500';
                case 'INACTIVE':
                    return 'bg-red-500';
                default:
                    return 'bg-gray-500';
            }
        }
	},

	directives: [
		vClickOutside
	],

	components: {
        AddCustomFilterModal,
        CrossIcon,
        ChevronLeftSmallIcon,
		KronosBaseLeadSideNavigationForClientPage,
		UsersDuoIcon,
        BellIcon,
        UserDuoIcon,
		LogoutDuoIcon,
		ClientDuoIcon,
		SettingsIcon,
		KronosBaseSideNavigation,
		KronosNotificationManager,
		ProfileDuoIcon,
		KronosQuickSearch,
	},

	mounted() {
        this.subscribeToChannel();
		window.addEventListener('keydown', this.handleKeyDown);
        this.RetrieveCustomFilters();
    },

    methods: {
        async RetrieveCustomFilters() {
            await RetrieveAllCustomFilter()
                .then((response) => {
                    console.log("filters", response)
                    this.CustomFilters = response.data.data.custom_filters;
                })
        },

        retrieveUserNotifications() {
            RetrieveUserNotifications(this.ContextUser.id)
                .then(response => {
                    this.notifications = response.data.data.user_notifications;
                    this.notifications_count = (this.notifications.filter(notification => notification.read === 0)).length;
                })
        },

        async DeleteCustomFilter(id) {
            await DestroyCustomFilter(id)
                .then((response) => {
                    if  (response.status === 200) {
                        this.showNotification("success", "Custom filter deleted successfully.");
                        this.RetrieveCustomFilters();
                    }
                }).catch((error) => {
                    console.error("Error deleting Custom Filter", error);
                    this.showNotification("error", "Error deleting Custom Filter");
                }).finally(() => {
                    this.AddCustomFilterModalIsOpen = false
                })
        },

		handleKeyDown(event) {
			if (event.metaKey && event.key === 's')  {
				event.preventDefault();
				this.$store.state.QuickSearchIsOpen = !this.$store.state.QuickSearchIsOpen;
			}
		},

		closeProfileMenu() {
			this.ProfileMenuIsOpen = false;
		},

        CloseTeamSelection() {
            this.$store.state.TeamSelectionIsOpen = false;
        },

        CloseCustomFilter() {
            this.CustomFiltersIsOpen = false;
        },

        async GlobalLogout() {
            await GlobalLogout()
                .then((response) => {
                    if (response.status === 200) {
                        window.location.href = '/login';
                    }
                })
        },

        async logout() {
            await Logout()
                .then(() => {
                    window.location.href = '/login';
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 500)
                });
        },

        GenerateLink(query) {
            if (this.$route.query.start_at) {
                query += `&start_at=${this.$route.query.start_at}`
            }

            if (this.$route.query.end_at) {
                query += `&end_at=${this.$route.query.end_at}`
            }

            return `?${query}`;
        },

        UpdateUserActivityStatus(status) {
            const statusNames = {
                1: 'ACTIVE',
                2: 'AWAY',
                3: 'INACTIVE'
            };
            const data = {
                'activity_status_id': status
            };


            UpdateUser(this.ContextUser.id, data)
                .then((response) => {
                    if (response.status === 200) {
                        this.ContextUser.activity_status.activity_status_name = statusNames[status];
                    }
                })
        },

        subscribeToChannel() {
            const pusher = new Pusher(import.meta.env.VITE_APP_PUSHER_ID, {
                cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER
            });

            const channel = pusher.subscribe(import.meta.env.VITE_APP_CHANNEL_NAME);

            const events = [
                'new_task','modified_task',
                'new_client', 'new_flinks_imported', 'new_client_document', 'new_lead',
                'lead_approved', 'request_denied', 'lead_status_changed', 'lead_transferred',
                'new_lead_assigned_to','sms_received', 'missed_phone_call', 'email_received',
                'new_voicemail', 'new_verification', 'new_verification_to_complete', 'new_issue',
                'new_suggestion', 'e_transfer_failed', 'non_sufficient_fund', 'new_schedule',
                'employee_break_Lunch'
            ];

            events.forEach(eventName => {
                channel.bind(eventName, data => {
                    console.log(data);
                    if (data.user_affected[0].split(', ').includes(String(this.ContextUser.id))) {
                        this.notifications_count++;
                    }
                });
            });
        },
	}
}
</script>

<style>
.full-width-1
{
	width: calc(100vw - 225px) !important;
}

.full-width-2
{
	width: calc(100vw - 58px);
}

.full-width-4
{
	width: calc(100vw - 150px);
}

.full-width-3
{
	width: calc(100vw - 225px) !important;
}

.full-width-5
{
    width: calc(100vw - 58px) !important;
}

.full-height {
    height: calc(100vh - 52px);
    margin-top: 52px
}

.inner-shadow-left {
	box-shadow: 10px 0px 11px -8px rgba(0,0,0,0.1) inset;
	-webkit-box-shadow: 10px 0px 11px -8px rgba(0,0,0,0.1) inset;
	-moz-box-shadow: 10px 0px 11px -8px rgba(0,0,0,0.1) inset;
}

.bottom-shadow {
    box-shadow: 0 8px 6px -4px rgba(0, 0, 0, 0.1);
}
</style>
