import api from './Api';

const BaseURL = 'v1/lead-approbation-step';

export const RetrieveLeadApprobationSteps = async (page = 1, limit = 1, start_at = null, end_at = null, filter_by = null) => {
    let url = `${BaseURL}?page=${page}&limit=${limit}`;

    if (start_at) {
        url += `&start_at=${start_at}`;
    }

    if (end_at) {
        url += `&end_at=${end_at}`;
    }

    if (filter_by) {
        url += `&filter_by=${filter_by}`;
    }

    return await api().get(url);
};

export const RetrieveLeadApprobationStepByLeadId = async (id) =>
{
    return await api().get(`${BaseURL}/lead/${id}`)
}

export const StoreLeadApprobationStep = async (data) =>
{
    return await api().post(`${BaseURL}`, data)
}

export const UpdateLeadApprobationStep = async (id, data) =>
{
    return await api().put(`${BaseURL}/${id}`, data)
}

export const UpdateLeadApprobationStepIsGenerated = async (id, data) =>
{
    return await api().put(`${BaseURL}/is-approved/${id}`, data)
}
