<template>
	<div class="relative">
		<div class="relative">
			<input
				v-maska
				:data-maska="mask"
				:type="type"
				:id="label"
				:name="label"
				:value="value"
				@input="updateValue"
				class="autocomplete-disable | pr-[52.5px] w-full pl-[12pt] h-[48px] font-normal text-base border-2 rounded-[4pt] bg-transparent"
				:class="[
					error
						? 'text-red-500 border-red-500 focus:outline-none focus:ring-0 focus:border-red-500'
						: isFocused
							? 'text-black border-gray-200 focus:outline-none focus:ring-0 focus:border-[#5c74d6] | dark:focus:border-[#5c74d6]'
							: !!value
								? 'text-gray-400 border-gray-200 focus:outline-none focus:ring-0 focus:border-[#5c74d6] | dark:border-gray-700'
								: 'text-gray-500 border-gray-200 focus:outline-none focus:ring-0 focus:border-[#5c74d6] | dark:border-gray-700'
				]"
				@focus="slideLabelUp"
				autocomplete="off"
				@blur="slideLabelDown"
			/>
			<label :for="label" class="absolute top-0 right-0 h-full w-[50px] flex items-center justify-center">
				<component
					:is="icon"
					:fill="error ? '#EF4444' : isFocused ? '#5c74d6' : '#9CA3AF'"
					class="duration-300 cursor-pointer"
					size="18px"
				/>
			</label>
			<transition
				enter-active-class="ease-out duration-500"
				enter-from-class="opacity-0 scale-95"
				enter-to-class="opacity-100 scale-100"
				leave-active-class="ease-in duration-500"
				leave-from-class="opacity-100 scale-100"
				leave-to-class="opacity-0 scale-95"
			>
				<div v-if="error && errorMessage" class="absolute right-0 text-[11px] tracking-wide text-light mt-1.5 ml-0.5 text-red-500 duration-300">{{ errorMessage }}</div>
			</transition>
		</div>
		<label
			:for="label"
			:class="['ring-0 outline-none absolute text-base transition-all duration-300',
                isFocused ?
                    error ? 'slide-up-label | top-[12px] left-[12pt] text-red-500 bg-white px-2 -mx-2 tracking-wide | dark:bg-[#1E2124]' : 'slide-up-label | top-[12px] left-[12pt] text-black bg-white px-2 -mx-2 tracking-wide | dark:bg-[#1E2124]' :
                        !!value ?
                            error ? 'slide-up-label | top-[8px] left-[12pt] text-red-500 bg-white px-2 -mx-2 tracking-wide | dark:bg-[#1E2124]' : 'slide-up-label | top-[12px] left-[12pt] text-gray-400 bg-white px-2 -mx-2 tracking-wide | dark:bg-[#1E2124]' :
                                error ? 'top-[12px] left-[12pt] slide-down-label text-red-500' : 'top-[12px] left-[12pt] slide-down-label text-gray-400'
            ]"
		>
			{{ label }}
		</label>
	</div>
</template>

<script>
import { vMaska } from "maska";
import AtSignIcon from "../../assets/icons/AtSignIcon.vue";
import KeyIcon from "../../assets/icons/KeyIcon.vue";
import PhoneIcon from "../../assets/icons/PhoneIcon.vue";
import PasswordKeyIcon from "../../assets/icons/PasswordKeyIcon.vue";

export default {
	emits: ['update:value'],

	directives: {
		maska: vMaska
	},

	props: {
		label: {
			type: String,
			default: 'label',
		},
		type: {
			type: String,
			default: 'text',
		},
		value: {
			type: [String, null],
			required: true,
		},
		icon: {
			type: String,
			default: null,
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: null,
		},
		mask: {
			type: String,
			default: '',
		}
	},

	data() {
		return {
			isFocused: false,
			form: {
				password: null
			}
		};
	},

	components: {
		PasswordKeyIcon,
		AtSignIcon,
		PhoneIcon,
	},

	methods: {
		slideLabelUp() {
			this.isFocused = true;
		},

		slideLabelDown() {
			this.isFocused = false;
		},

		updateValue(event) {
			this.$emit('update:value', event.target.value);
		},
	},
};
</script>

<style scoped>
.slide-up-label {
	top: -12px !important;
	font-size: 11px !important;
	font-weight: 500 !important;
}


.slide-up-input {
	padding-top: 24px;
}

.autocomplete-disable:-webkit-autofill,
.autocomplete-disable:-webkit-autofill:hover,
.autocomplete-disable:-webkit-autofill:focus,
.autocomplete-disable:-webkit-autofill:active {
	/* Disable autofill background color */
	-webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

.autocomplete-disable:-webkit-autofill::first-line {
	/* Override text color for autofilled values */
	color: inherit !important;
}
</style>
