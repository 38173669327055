<template>
	<div class="notification-manager | flex z-50 flex-col gap-2 fixed p-3">
		<transition-group
			name="notification-fade"
			tag="div"
			class="gap-2 flex flex-col"
		>
			<KronosBaseNotification
				class="w-[300px]"
				v-for="(notification, index) in notifications"
				:key="index"
				:type="notification.type"
				:message="notification.message"
			/>
		</transition-group>
	</div>
</template>

<script>
import KronosBaseNotification from './KronosBaseNotification.vue';
import { mapState } from "vuex";

export default {
	components: {
		KronosBaseNotification,
	},

	computed: {
		...mapState([
			'notifications'
		]),

		notificationClass() {
			return {
				// Add other classes as needed
				'fade-out': this.notification.dismissed,
			};
		},
	},

};
</script>

<style scoped>
.notification-manager {
	position: fixed;
	top: 0;
	right: 0;
}

.notification-fade-enter-active,
.notification-fade-leave-active {
	transition: opacity 0.5s;
}

.notification-fade-enter, .notification-fade-leave-to /* .notification-fade-leave-active in <2.1.8 */ {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}
</style>